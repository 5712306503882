import React from "react";
import bg from "../../../Assets/caprusit.mp4";
import Logo from "../../../Assets/logo.png";
import "./Userpage.css";
import axios from "axios";
import { uploadData, spinner } from "../../../store/actions.js";
import { connect } from "react-redux";
import Loading from "../../Containers/Loading_spinner/loadingComponent.js";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { Toast } from "primereact/toast";

class UserPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      loading: false,
    };
  }

  userPageValidationsOnit = () => {
    let errors = {};
    let formIsValid = true;

    if (!this.props.email) {
      formIsValid = false;
      this.toastTR.show({
        severity: "info",
        summary: "Info Message",
        detail: "Required",
        life: 3000,
      });
    }
    if (this.props.email != "") {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.props.email)) {
        formIsValid = false;
        this.toastTR.show({
          severity: "error",
          summary: "Error Message",
          detail: "Invalid Mail",
          life: 3000,
        });
      }
    }
    this.setState({ errors: errors });
    return formIsValid;
  };

  userPage = (e) => {
    this.props.dispatch(uploadData(e.target.value));
  };

  userPageSubmit = (e) => {
    e.preventDefault();
    if (this.userPageValidationsOnit()) {
      this.props.dispatch(spinner(true));
      axios
        .get("https://capv.live/portalprod/sendmail", {
          params: {
            email: this.props.email,
          },
        })

        .then((response) => {
          //(response);
          if (response.data.message == "email sent successfully") {
            localStorage.setItem("email", this.props.email);
            this.props.dispatch(spinner(false));
            this.props.history.push("/OtpPage");
            this.toastTR.show({
              severity: "Success",
              summary: "Success Message",
              detail: "Email Sent Successfully",
              life: 3000,
            });
          }
          return response.JSON;
        })
        .catch((err) => {
          this.props.dispatch(spinner(false));
        });
    } else {
      this.props.dispatch(spinner(false));
    }
  };
  refreshPage() {
    window.location.reload();
  }
  componentDidMount() {}

  render() {
    return (
      <div className="HRloginpage">
        <Toast ref={(el) => (this.toastTR = el)} position="top-right" />
        <video
          className="background_img"
          autoPlay
          loop
          muted
          plays-inline
          src={bg}
          type="video/mp4"
        />
        {this.props.isSpinner && <Loading />}
        <div className="Caprus_Image_EmployeeBtn">
          <div style={{ width: "50%" }}>
            <img
              className="CaprusImage"
              onClick={this.refreshPage}
              src={Logo}
              alt="capv"
            />
          </div>
          <div className="EmployeeBtn">
            <button
              onClick={() => this.props.history.push("/Admin")}
              className="Admin"
            >
              Admin
            </button>
          </div>
        </div>
        {/* image and btn ended */}
        <div className="UserPage_Card">
          <div className="userPage_card_Inside">
            <div className="inside_card">
              <span className="Emailtext">
                Enter Valid Email To Receive OTP
              </span>
              <div style={{ marginTop: "40px" }}>
                <form onSubmit={this.userPageSubmit.bind(this)}>
                  <div>
                    <input
                      className="Email_input_text"
                      type="text"
                      onChange={(e) => this.userPage(e)}
                      value={this.props.email}
                      name="Name"
                      placeholder="Email Id"
                      autoComplete="off"
                    ></input>
                  </div>
                  <br />
                  <div className="Submit_btn_userpage_admin_otp">
                    <button className="Otp_Submit_Btn">
                      GET OTP <i class="pi pi-send"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    email: state.email,
    data: state.data,
    isSpinner: state.isSpinner,

    //fresh code by Janya for sake of avoiding confusion. Hence redeclaring it.....
    email: state.email,
  };
};

export default connect(mapStateToProps)(UserPage);
