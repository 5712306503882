import React from "react";
import "./Header.css";
import Logo from "../../Assets/logo.png";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { loggingOut, uploadData } from "../../store/actions.js";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { ConfirmDialog } from "primereact/confirmdialog";
import axios from "axios";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogout: false,
      employeedata: {},
      ifAdminLogoutClicked: false,
      employeeStatus: "",
    };
    this.logoutHandler = this.logoutHandler.bind(this);
  }
  componentDidMount() {
    if (localStorage.getItem("empId") != null) {
      axios
        .get(
          "https://capv.live/portalprod/getEmployee?employeeId=" +
            localStorage.getItem("empId")
        )
        .then((response) => {
          this.setState({ employeeStatus: response.data.result.status });
        });
    }
  }

  localDelete = () => {
    localStorage.clear();
    this.props.dispatch(uploadData(""));
    this.props.history.push("/");
  };
  refreshPage() {
    localStorage.removeItem("experience-years");
    localStorage.removeItem("experience-in_2_years");
    window.location.reload();
  }
  toastHidden = () => {
    this.toastBC.clear();
  };
  redirectToLoginPage = () => {
    localStorage.clear();
    this.props.history.push("/");
  };
  logoutHandler = () => {
    this.setState({ ifAdminLogoutClicked: true });
  };
  closeAlert = () => {
    this.setState({ ifAdminLogoutClicked: false });
  };

  render() {
    return (
      <div>
        <div className="previousFormContent">
          <ConfirmDialog
            visible={this.state.ifAdminLogoutClicked}
            onHide={() => this.setState({ ifAdminLogoutClicked: false })}
            message="Are you sure you want to proceed?"
            header="Confirmation"
            icon="pi pi-exclamation-triangle"
            accept={this.redirectToLoginPage}
            reject={() => this.closeAlert}
          />
          <Toast ref={(el) => (this.toast = el)} />
        </div>
        <Toast ref={(el) => (this.toastBC = el)} position="bottom-center" />
        <div>
          <div className="Header_Form_Employee">
            <div className="Header_Form_Image">
              <img
                className="image_capv"
                onClick={this.refreshPage}
                src={Logo}
                alt="img"
              />
            </div>

            <div className="Header_Form_Text">
              {this.state.employeeStatus !== "Relieved" && (
                <h1 className="DeclarationFormText">Declaration Form</h1>
              )}
            </div>
            <div className="Header_Form_Btn">
              <Button
                onClick={this.logoutHandler}
                className="p-button-rounded p-button-warning capp2"
              >
                <i className="pi pi-sign-out"></i>SignOut
              </Button>
            </div>
          </div>
          <div className="Header_Form_Text_Mobile">
            {this.state.employeeStatus !== "Relieved" && (
              <h1 className="DeclarationFormText">Declaration Form</h1>
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    profile: state.profile,
    pfdetails: state.pfdetails,
    insurance: state.insurance,
    isDashboard: state.isDashboard,
  };
};
export default connect(mapStateToProps, null)(withRouter(Header));
