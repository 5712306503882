import React from "react";
import "./Pfdata.css";
import Header from "../../Header/Header.js";
import {
  savedSuceessfully,
  insuranceid,
  pfdataid,
  profileid,
  employeeclearance,
  exitinterviewfunction,
  previouslyUploadedDocuments,
  spinner,
  ifEmpIdNotGenerated,
  showEditButton,
  showViewButton,
  showFormPfIns,
  showButtonStatus,
} from "../../../store/actions.js";
import { connect } from "react-redux";
import axios from "axios";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { Toast } from "primereact/toast";
import Buttons from "../../Containers/Buttons_tab/buttons.js";

class Pfdata extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previousformDetails: {},
      requestTemplate: {
        empCode: "",
        empName: "",
        fatherName: "",
        maritalStatus: "",
        mobile: "",
        qualification: "",
        dateOfJoining: "",
        emailId: "",
        employeeNumber: Number(localStorage.getItem("empId")),
        dob: "",
        bankAccountNumber: "",
        bankName: "",
        ifscCode: "",
        nameAsPerBank: "",
        pancardNumber: "",
        nameAsPerPan: "",
        aadharNumber: "",
        nameAsPerAadhar: "",
        gender: "",
        uan: "",
      },
      fields: {
        empCode: "",
        empName: "",
        fatherName: "",
        maritalStatus: "",
        mobile: "",
        qualification: "",
        dateOfJoining: "",
        emailId: "",
        dob: "",
        bankAccountNumber: "",
        bankName: "",
        ifscCode: "",
        nameAsPerBank: "",
        pancardNumber: "",
        nameAsPerPan: "",
        aadharNumber: "",
        nameAsPerAadhar: "",
        gender: "",
        uan: "",
      },
      errors: {},
      ispfData: false,
      people: [],
      fetchpfdataid: null,
      employeCodeandIdea: false,
      isInvalid: false,
      isExist: true,
      currentdate: "",
      showEditForm: false,
      showViewForm: true,
      EmpData: [],
      refresh: false,
    };
    this.PfdataHandler = this.PfdataHandler.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(pfdataid(true));
    let date = new Date();
    let year = date.getFullYear();
    let month =
      date.getMonth() < 10 ? date.getMonth() + 1 : "0" + date.getMonth();
    let day = date.getDate() > 10 ? date.getDate() : date.getDate();
    let currentdate = year + "-" + month + "-" + day;
    this.setState({ ...this.state, currentdate: currentdate });
    axios
      .get(
        "https://capv.live/portalprod/getEmployee?employeeId=" +
          localStorage.getItem("empId")
      )
      .then((response) => {
        this.setState({ EmpData: response.data.result });
        this.previousFormData(response.data.result);
      });

    if (localStorage.getItem("empId") != null) {
      axios
        .get(
          "https://capv.live/portalprod/getEmployeeCodeById?employeeNumber=" +
            localStorage.getItem("empId")
        )
        .then((response) => {
          if (response.data.result.employeeCode != null) {
            this.props.dispatch(showFormPfIns(true));
            this.setState({ fetchpfdataid: response.data.result.employeeCode });
          }
        });
      axios
        .get(
          "https://capv.live/portalprod/getPfDetails?employeeId=" +
            localStorage.getItem("empId")
        )
        .then((response) => {
          if (response.data.result == null) {
            this.props.dispatch(showEditButton(true));
          }
          if (
            response.data.result != null &&
            response.data.result.empName != ""
          ) {
            this.props.dispatch(showViewButton(true));
            this.setState({
              requestTemplate: response.data.result,
            });
          }
        });
      this.props.dispatch(pfdataid(true));
    } else {
      this.props.history.push("/");
    }
    this.setState({ refresh: !this.state.refresh });
  }
  previousFormData = (v) => {
    let data = this.state.requestTemplate;
    data.empName = v.firstName;
    data.gender = v.gender;
    data.aadharNumber = v.aadharNumber;
    data.pancardNumber = v.panCardNumber;
    data.mobile = v.contactInfo[0].phone;
    data.qualification = v.educationInfo.degree;
    data.dob = v.dob;
    data.emailId = v.email;
  };
  pfValidationRequired = () => {
    let errors = {};
    let fields = this.state.requestTemplate;
    let formIsValid = true;

    //EmpName//

    if (fields.empName === "") {
      formIsValid = false;
      errors.empName = "Required";
    }

    //fatherName//

    if (fields.fatherName === "") {
      formIsValid = false;
      errors.fatherName = "Required";
    }

    //maritalStatus//

    if (fields.maritalStatus === "") {
      formIsValid = false;
      errors.maritalStatus = "Required";
    }
    //mobile//

    if (fields.mobile === "") {
      formIsValid = false;
      errors.mobile = "Required";
    }
    //qualification//

    if (fields.qualification === "") {
      formIsValid = false;
      errors.qualification = "Required";
    }

    //dateOfJoining
    if (fields.dateOfJoining === "") {
      formIsValid = false;
      errors.dateOfJoining = "Required";
    }

    //dateodbirth
    if (fields.dob === "") {
      formIsValid = false;
      errors.dob = "Required";
    }

    //Email//

    if (fields.emailId === "") {
      formIsValid = false;
      errors.emailId = "Required";
    }
    //Bank//

    if (fields.bankAccountNumber === "") {
      formIsValid = false;
      errors.bankAccountNumber = "Required";
    }
    //bankName//

    if (fields.bankName === "") {
      formIsValid = false;
      errors.bankName = "Required";
    }
    //ifscCode//

    if (fields.ifscCode === "") {
      formIsValid = false;
      errors.ifscCode = "Required";
    }

    //nameasperbank//

    if (fields.nameAsPerBank === "") {
      formIsValid = false;
      errors.nameAsPerBank = "Required";
    }

    //gender//
    if (fields.gender === "") {
      formIsValid = false;
      errors.gender = "Required";
    }

    //pancardNumber//

    if (fields.pancardNumber === "") {
      formIsValid = false;
      errors.pancardNumber = "Required";
    }

    //uan//

    // if (!fields.uan) {
    //   formIsValid = false;
    //   errors.uan = "Required";
    // }
    //nameasperaadhar
    if (fields.nameAsPerAadhar === "") {
      formIsValid = false;
      errors.nameAsPerAadhar = "Required";
    }
    //nameAsPerPan
    if (fields.nameAsPerPan === "") {
      formIsValid = false;
      errors.nameAsPerPan = "Required";
    }
    //aadharNumber
    if (fields.aadharNumber === "") {
      formIsValid = false;
      errors.aadharNumber = "Required";
    }

    this.setState({ fields: fields });
    this.setState({ errors: errors });
    return formIsValid;
  };

  pfValidation = () => {
    let errors = {};
    let fields = this.state.requestTemplate;
    let formIsValid = true;
    // errors.empCode = "Invalid";

    //EmpName//

    if (fields.empName != "") {
      if (!/^[a-zA-Z, ]{3,30}$/.test(fields.empName)) {
        formIsValid = false;
        errors.empName =
          "Special characters & numbers are not allowed, Min 3 and Max 30 characters are allowed";
      }
    }

    //fatherName//

    if (fields.fatherName != "") {
      if (!/^[a-zA-Z, ]{3,60}$/.test(fields.fatherName)) {
        formIsValid = false;
        errors.fatherName =
          "Special characters & numbers are not allowed. Min 3 and Max upto 60 characters";
      }
    }

    //maritalStatus//

    if (fields.maritalStatus != "") {
      if (!/^[a-zA-Z]+$/.test(fields.maritalStatus)) {
        formIsValid = false;
        errors.maritalStatus = "Special characters & numbers are not allowed";
      }
    }
    //mobile//
    if (fields.mobile != "") {
      if (!/^[0-9]{10}$/.test(fields.mobile)) {
        formIsValid = false;
        errors.mobile = "should be only 10 numbers";
      }
    }
    //qualification//

    if (fields.qualification != "") {
      if (!/^[a-zA-Z-,"." ,]{3,30}$/.test(fields.qualification)) {
        formIsValid = false;
        errors.qualification =
          "Special characters & numbers are not allowed, Min 3 and Max 30 characters are allowed";
      }
    }

    //Email//

    if (fields.emailId != "") {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(fields.emailId)) {
        formIsValid = false;
        errors.emailId = "Invalid Email";
      }
    }
    //Bank//

    if (fields.bankAccountNumber != "") {
      if (
        !/^([0-9]{11})|([0-9]{2}-[0-9]{3}-[0-9]{6})$/.test(
          fields.bankAccountNumber
        )
      ) {
        formIsValid = false;
        errors.bankAccountNumber = "contains only 11 numbers";
      }
    }
    //bankName//

    if (fields.bankName != "") {
      if (!/^[a-zA-Z, ]{3,30}$/.test(fields.bankName)) {
        formIsValid = false;
        errors.bankName =
          "Special characters & numbers are not allowed. Min 3 and Max upto 60 characters";
      }
    }
    //ifscCode//
    if (fields.ifscCode != "") {
      if (!/[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/.test(fields.ifscCode)) {
        formIsValid = false;
        errors.ifscCode = "Enter valid ifsccode(ex:AAAA0012345)";
      }
    }

    //nameasperbank//

    if (fields.nameAsPerBank != "") {
      if (!/^[a-zA-Z, ]{3,60}$/.test(fields.nameAsPerBank)) {
        formIsValid = false;
        errors.nameAsPerBank =
          "Special characters & numbers are not allowed. Min 3 and Max upto 60 characters";
      }
    }

    //pancardNumber//

    if (fields.pancardNumber != "") {
      if (!/[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/.test(fields.pancardNumber)) {
        formIsValid = false;
        errors.pancardNumber =
          "(ex:ABCDE1234F)Please Enter valid pancard Details";
      }
    }

    //uan//

    if (fields.uan != null && fields.uan != "") {
      if (!/^[0-9]+$/.test(fields.uan)) {
        formIsValid = false;
        errors.uan = "Contains only numbers";
      }
    }
    //nameasperaadhar
    if (fields.nameAsPerAadhar != "") {
      if (!/^[a-zA-Z, ]{3,30}$/.test(fields.nameAsPerAadhar)) {
        formIsValid = false;
        errors.nameAsPerAadhar =
          "Special characters & numbers are not allowed. Min 3 and Max upto 60 characters";
      }
    }
    //nameAsPerPan
    if (fields.nameAsPerPan != "") {
      if (!/^[a-zA-Z, ]{3,30}$/.test(fields.nameAsPerPan)) {
        formIsValid = false;
        errors.nameAsPerPan =
          "Special characters & numbers are not allowed. Min 3 and Max upto 60 characters";
      }
    }
    //aadharNumber
    if (fields.aadharNumber != "") {
      if (!/^[0-9]{12}$/.test(fields.aadharNumber)) {
        formIsValid = false;
        errors.aadharNumber = "Contains only 12 Numbers";
      }
    }

    this.setState({ fields: fields });
    this.setState({ errors: errors });
    return formIsValid;
  };

  PfdataHandler = (e) => {
    e.preventDefault();
    if (this.pfValidationRequired() && this.pfValidation()) {
      this.props.dispatch(spinner(true));
      let empcoder = this.state.requestTemplate;
      empcoder.empCode = this.state.fetchpfdataid;
      const request = {
        method: "POST",
        headers: {
          "content-type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(this.state.requestTemplate),
      };
      fetch(
        "https://capv.live/portalprod/savePfDetails?employeeId=" +
          localStorage.getItem("empId"),
        request
      ).then((response) => {
        if (response.ok) {
          this.props.dispatch(showViewButton(true));
          this.props.dispatch(spinner(false));

          this.setState({
            requestTemplate: {
              empCode: "",
              empName: "",
              fatherName: "",
              maritalStatus: "",
              mobile: "",
              qualification: "",
              dateOfJoining: "",
              emailId: "",
              dob: "",
              bankAccountNumber: "",
              bankName: "",
              ifscCode: "",
              nameAsPerBank: "",
              pancardNumber: "",
              nameAsPerPan: "",
              aadharNumber: "",
              nameAsPerAadhar: "",
              gender: "",
              uan: "",
            },
          });
          this.props.dispatch(savedSuceessfully(true));

          this.toastTR.show({
            severity: "success",
            summary: "Success Message",
            detail: "Saved Successfully",
            life: 3000,
          });
          this.setState({
            showViewForm: true,
            showEditForm: false,
          });

          axios
            .get(
              "https://capv.live/portalprod/getPfDetails?employeeId=" +
                localStorage.getItem("empId")
            )
            .then((response) => {
              this.props.dispatch(spinner(false));
              this.setState({ requestTemplate: response.data.result });
            });
        }
        setTimeout(
          function () {
            this.props.dispatch(savedSuceessfully(false));
          }.bind(this),
          1000
        );
      });
    }
  };

  changingHandlered = () => {
    this.setState({ employeCodeandIdea: true });
  };
  pfHandler_Onchange = (field, e) => {
    let contactinfo = this.state.requestTemplate;
    contactinfo[field] = e.target.value;
    this.setState({ requestTemplate: contactinfo });
    this.pfValidation();
  };
  inputView = () => {
    return this.props.showView ? "Input_fields_Disable" : "Input_fields";
  };

  render() {
    const {
      empName,
      fatherName,
      maritalStatus,
      ifscCode,
      mobile,
      aadharNumber,
      qualification,
      nameAsPerPan,
      dateOfJoining,
      emailId,
      dob,
      bankAccountNumber,
      bankName,
      nameAsPerBank,
      pancardNumber,
      nameAsPerAadhar,
      gender,
      uan,
    } = this.state.errors;
    return (
      <div>
        <div>
          {this.props.uploadedpfdata && (
            <Toast ref={(el) => (this.toastTR = el)} position="top-right" />
          )}
        </div>
        <div>
          <Header />
          <h6 className="Instructions_Form">
            <strong className="strong">Instructions :</strong> Please fill in
            the below details required PF Details
          </h6>
          <div>
            <Buttons />
          </div>
          <div className="personal_Info" style={{ padding: "15px 30px" }}>
            {this.props.enableFormForPfIns && (
              <h6 className="Form55">PF Details</h6>
            )}
            {this.props.enableFormForPfIns && (
              <form className="Submit_btn_" onSubmit={this.PfdataHandler}>
                <div className="personal_Info_fields">
                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>
                        <strong>Employee Id:</strong>
                      </span>
                    </div>
                    <div className="personal_Info_input">
                      <input
                        className="Input_fields_Disable"
                        disabled
                        name="empCode"
                        placeholder="Employee Id"
                        value={this.state.fetchpfdataid}
                        autoComplete="off"
                      ></input>
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile"></div>

                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>Name:</span>
                    </div>
                    <div className="personal_Info_input">
                      <input
                        className="Input_fields_Disable"
                        type="text"
                        name="empName"
                        disabled
                        placeholder="Employee name"
                        value={this.state.requestTemplate.empName}
                        onChange={(e) => this.pfHandler_Onchange("empName", e)}
                        autoComplete="off"
                      ></input>
                    </div>

                    <div className="personal_Info_Error">
                      {empName && <span className="Error">{empName}</span>}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {empName && <span className="Error">{empName}</span>}
                  </div>

                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>Father Name:</span>
                    </div>
                    <div className="personal_Info_input">
                      <input
                        className={this.inputView()}
                        type="text"
                        name="fatherName"
                        disabled={this.props.showView}
                        value={this.state.requestTemplate.fatherName}
                        onChange={(e) => {
                          this.pfHandler_Onchange("fatherName", e);
                        }}
                        placeholder="Father name"
                        autoComplete="off"
                      ></input>
                    </div>

                    <div className="personal_Info_Error">
                      {fatherName && (
                        <span className="Error">{fatherName}</span>
                      )}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {fatherName && <span className="Error">{fatherName}</span>}
                  </div>
                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>Marital status:</span>
                    </div>
                    <div className="personal_Info_input">
                      <select
                        className={this.inputView()}
                        type="text"
                        disabled={this.props.showView}
                        value={this.state.requestTemplate.maritalStatus}
                        onChange={(e) => {
                          this.pfHandler_Onchange("maritalStatus", e);
                        }}
                      >
                        <option value="">Select Status</option>
                        <option value="Single">Single</option>
                        <option value="Married">Married</option>
                        <option value="Widowed">Widowed</option>
                      </select>
                    </div>

                    <div className="personal_Info_Error">
                      {maritalStatus && (
                        <span className="Error">{maritalStatus}</span>
                      )}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {maritalStatus && (
                      <span className="Error">{maritalStatus}</span>
                    )}
                  </div>
                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span> Mobile:</span>
                    </div>
                    <div className="personal_Info_input">
                      <input
                        className="Input_fields_Disable"
                        name="mobile"
                        type="text"
                        disabled
                        value={this.state.requestTemplate.mobile}
                        onChange={(e) => {
                          this.pfHandler_Onchange("mobile", e);
                        }}
                        placeholder="Phone"
                        autoComplete="off"
                      ></input>
                    </div>

                    <div className="personal_Info_Error">
                      {mobile && <span className="Error">{mobile}</span>}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {mobile && <span className="Error">{mobile}</span>}
                  </div>

                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>Qualification:</span>
                    </div>
                    <div className="personal_Info_input">
                      <input
                        className="Input_fields_Disable"
                        name="bloodGroup"
                        type="text"
                        disabled
                        value={this.state.requestTemplate.qualification}
                        onChange={(e) => {
                          this.pfHandler_Onchange("qualification", e);
                        }}
                        placeholder="Qualification"
                        autoComplete="off"
                      ></input>
                    </div>

                    <div className="personal_Info_Error">
                      {qualification && (
                        <span className="Error">{qualification}</span>
                      )}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {qualification && (
                      <span className="Error">{qualification}</span>
                    )}
                  </div>

                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>Date Of Joining:</span>
                    </div>
                    <div className="personal_Info_input">
                      <input
                        className={this.inputView()}
                        type="date"
                        disabled={this.props.showView}
                        min="1930-01-01"
                        max={this.state.currentdate}
                        name="dateOfJoining"
                        value={
                          this.state.requestTemplate.dateOfJoining.split("T")[0]
                        }
                        onChange={(e) => {
                          this.pfHandler_Onchange("dateOfJoining", e);
                        }}
                        placeholder="DD/MM/YYYY"
                        autoComplete="off"
                      ></input>
                    </div>

                    <div className="personal_Info_Error">
                      {dateOfJoining && (
                        <span className="Error">{dateOfJoining}</span>
                      )}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {dateOfJoining && (
                      <span className="Error">{dateOfJoining}</span>
                    )}
                  </div>

                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>Email</span>
                    </div>
                    <div className="personal_Info_input">
                      <input
                        className="Input_fields_Disable"
                        type="text"
                        disabled
                        name="DateOfIssue"
                        value={this.state.requestTemplate.emailId}
                        onChange={(e) => {
                          this.pfHandler_Onchange("emailId", e);
                        }}
                        placeholder="Ex: xy@gmail.com"
                        autoComplete="off"
                      ></input>
                    </div>

                    <div className="personal_Info_Error">
                      {emailId && <span className="Error">{emailId}</span>}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {emailId && <span className="Error">{emailId}</span>}
                  </div>

                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>Date Of Birth:</span>
                    </div>
                    <div className="personal_Info_input">
                      <input
                        className="Input_fields_Disable"
                        type="date"
                        disabled
                        max={this.state.currentdate}
                        name="dob"
                        value={this.state.requestTemplate.dob.split("T")[0]}
                        onChange={(e) => {
                          this.pfHandler_Onchange("dob", e);
                        }}
                        placeholder="DD/MM/YYYY"
                        autoComplete="off"
                      ></input>
                    </div>

                    <div className="personal_Info_Error">
                      {dob && <span className="Error">{dob}</span>}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {dob && <span className="Error">{dob}</span>}
                  </div>
                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>Bank A/C:</span>
                    </div>
                    <div className="personal_Info_input">
                      <input
                        className={this.inputView()}
                        type="text"
                        disabled={this.props.showView}
                        name="Bank account number"
                        value={this.state.requestTemplate.bankAccountNumber}
                        onChange={(e) => {
                          this.pfHandler_Onchange("bankAccountNumber", e);
                        }}
                        placeholder="Bank Account Number"
                        autoComplete="off"
                      ></input>
                    </div>

                    <div className="personal_Info_Error">
                      {bankAccountNumber && (
                        <span className="Error">{bankAccountNumber}</span>
                      )}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {bankAccountNumber && (
                      <span className="Error">{bankAccountNumber}</span>
                    )}
                  </div>
                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>Bank Name:</span>
                    </div>
                    <div className="personal_Info_input">
                      <input
                        className={this.inputView()}
                        type="text"
                        disabled={this.props.showView}
                        name="bankName"
                        value={this.state.requestTemplate.bankName}
                        onChange={(e) => {
                          this.pfHandler_Onchange("bankName", e);
                        }}
                        placeholder="Bank name"
                        autoComplete="off"
                      ></input>
                    </div>

                    <div className="personal_Info_Error">
                      {bankName && <span className="Error">{bankName}</span>}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {bankName && <span className="Error">{bankName}</span>}
                  </div>
                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>IFSC Code:</span>
                    </div>
                    <div className="personal_Info_input">
                      <input
                        className={this.inputView()}
                        type="text"
                        name="ifscCode"
                        disabled={this.props.showView}
                        value={this.state.requestTemplate.ifscCode}
                        onChange={(e) => {
                          this.pfHandler_Onchange("ifscCode", e);
                        }}
                        placeholder="Ifsc code"
                        autoComplete="off"
                      ></input>
                    </div>

                    <div className="personal_Info_Error">
                      {ifscCode && <span className="Error">{ifscCode}</span>}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {ifscCode && <span className="Error">{ifscCode}</span>}
                  </div>
                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>Name as per bank:</span>
                    </div>
                    <div className="personal_Info_input">
                      <input
                        className={this.inputView()}
                        type="text"
                        disabled={this.props.showView}
                        name="Name As Per Bank"
                        value={this.state.requestTemplate.nameAsPerBank}
                        onChange={(e) => {
                          this.pfHandler_Onchange("nameAsPerBank", e);
                        }}
                        placeholder="Name as per bank"
                        autoComplete="off"
                      ></input>
                    </div>

                    <div className="personal_Info_Error">
                      {nameAsPerBank && (
                        <span className="Error">{nameAsPerBank}</span>
                      )}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {nameAsPerBank && (
                      <span className="Error">{nameAsPerBank}</span>
                    )}
                  </div>
                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>PAN Number</span>
                    </div>
                    <div className="personal_Info_input">
                      <input
                        className="Input_fields_Disable"
                        type="text"
                        disabled
                        name="pancardNumber"
                        value={this.state.requestTemplate.pancardNumber}
                        onChange={(e) => {
                          this.pfHandler_Onchange("pancardNumber", e);
                        }}
                        placeholder="Pan"
                        autoComplete="off"
                        style={{ textTransform: "uppercase" }}
                      ></input>
                    </div>

                    <div className="personal_Info_Error">
                      {pancardNumber && (
                        <span className="Error">{pancardNumber}</span>
                      )}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {pancardNumber && (
                      <span className="Error">{pancardNumber}</span>
                    )}
                  </div>
                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>Name as per PAN</span>
                    </div>
                    <div className="personal_Info_input">
                      <input
                        className={this.inputView()}
                        type="text"
                        disabled={this.props.showView}
                        name="nameAsPerPan"
                        value={this.state.requestTemplate.nameAsPerPan}
                        onChange={(e) => {
                          this.pfHandler_Onchange("nameAsPerPan", e);
                        }}
                        placeholder="Name as per PAN"
                        autoComplete="off"
                      ></input>
                    </div>

                    <div className="personal_Info_Error">
                      {nameAsPerPan && (
                        <span className="Error">{nameAsPerPan}</span>
                      )}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {nameAsPerPan && (
                      <span className="Error">{nameAsPerPan}</span>
                    )}
                  </div>
                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>AADHAR number</span>
                    </div>
                    <div className="personal_Info_input">
                      <input
                        className="Input_fields_Disable"
                        type="text"
                        disabled
                        name="aadharNumber"
                        value={this.state.requestTemplate.aadharNumber}
                        onChange={(e) => {
                          this.pfHandler_Onchange("aadharNumber", e);
                        }}
                        placeholder="AADHAR Number"
                        autoComplete="off"
                      ></input>
                    </div>

                    <div className="personal_Info_Error">
                      {aadharNumber && (
                        <span className="Error">{aadharNumber}</span>
                      )}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {aadharNumber && (
                      <span className="Error">{aadharNumber}</span>
                    )}
                  </div>
                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>Name as per AADHAR</span>
                    </div>
                    <div className="personal_Info_input">
                      <input
                        className={this.inputView()}
                        type="text"
                        disabled={this.props.showView}
                        name="nameAsPerAadhar"
                        value={this.state.requestTemplate.nameAsPerAadhar}
                        onChange={(e) => {
                          this.pfHandler_Onchange("nameAsPerAadhar", e);
                        }}
                        placeholder="Name as per AADHAR"
                        autoComplete="off"
                      ></input>
                    </div>

                    <div className="personal_Info_Error">
                      {nameAsPerAadhar && (
                        <span className="Error">{nameAsPerAadhar}</span>
                      )}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {nameAsPerAadhar && (
                      <span className="Error">{nameAsPerAadhar}</span>
                    )}
                  </div>
                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>Gender</span>
                    </div>
                    <div className="personal_Info_input">
                      <select
                        className="Input_fields_Disable"
                        type="text"
                        disabled
                        name="gender"
                        value={this.state.requestTemplate.gender}
                        onChange={(e) => {
                          this.pfHandler_Onchange("gender", e);
                        }}
                        placeholder="gender"
                      >
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="FeMale">Female</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>

                    <div className="personal_Info_Error">
                      {gender && <span className="Error">{gender}</span>}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {gender && <span className="Error">{gender}</span>}
                  </div>
                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>UAN(optional)</span>
                    </div>
                    <div className="personal_Info_input">
                      <input
                        className={this.inputView()}
                        type="text"
                        disabled={this.props.showView}
                        name="uan"
                        value={this.state.requestTemplate.uan}
                        onChange={(e) => {
                          this.pfHandler_Onchange("uan", e);
                        }}
                        placeholder="UAN"
                        autoComplete="off"
                      ></input>
                    </div>

                    <div className="personal_Info_Error">
                      {uan && <span className="Error">{uan}</span>}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {uan && <span className="Error">{uan}</span>}
                  </div>
                </div>

                <div className="Submit_btn_userpage_admin_otp">
                  {this.props.showView == false && (
                    <button style={{ marginTop: "12px" }} className="save-btn">
                      Submit <i class="pi pi-send"></i>
                    </button>
                  )}
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    empId: state.empId,
    uploadedpfdata: state.uploadedpfdata,
    profile: state.profile,
    pfdetails: state.pfdetails,
    insurance: state.insurance,
    empClearance: state.empClearance,
    exitinterview: state.exitinterview,
    empDocuments: state.empDocuments,
    isSpinner: state.isSpinner,
    empIdNotAssigned: state.empIdNotAssigned,
    showEdit: state.showEdit,
    showView: state.showView,
    enableFormForPfIns: state.enableFormForPfIns,
    btnStatus: state.btnStatus,
  };
};

export default connect(mapStateToProps)(Pfdata);
