import React, { Component } from "react";
import "./Insurance.css";
import Header from "../../Header/Header.js";
import { connect } from "react-redux";
import {
  insuranceid,
  pfdataid,
  pfdataemployee,
  profileid,
  employeeclearance,
  exitinterviewfunction,
  previouslyUploadedDocuments,
  spinner,
  showViewButton,
} from "../../../store/actions.js";
import axios from "axios";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { Toast } from "primereact/toast";
import Buttons from "../../Containers/Buttons_tab/buttons.js";

class Insurance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Nominee: true,
      Nominee12: false,
      insuranceId: 0,
      empDetails: [
        {
          // "empId": "",
          empCode: "",
          name: "",
          relation: "",
          dob: "",
          employeeNumber: localStorage.getItem("empId"),
          age: "",
          nominee: "",
          options: [],
        },
      ],
      fields: {
        empId: "",
        empCode: "",
        name: "",
        relation: "",
        dob: "",
        age: "",
      },
      data: "",
      errors: [],
      errors: {},
      ispfData: false,
      employeCodeandId: false,
      relationdata: [],
      fetchpfdataid: [],
      tempRelationData: [],
      selectedstatus: null,
      isInvalid: false,
      isExist: true,
      currentdate: "",
      readyToUpdate: true,
      readyToUpdateData: false,
      showEditForm: false,
      showViewForm: true,
      insurance_view_id: 1,
      EmpData: [],
      AddHover: false,
      reduceHover: false,
      yearCalculation: "",
    };

    this.insuranceHandler = this.insuranceHandler.bind(this);
    this.insuranceSubmit = this.insuranceSubmit.bind(this);
    this.insuranceHandler_onClick = this.insuranceHandler_onClick.bind(this);
  }

  AddButton = (e) => {
    e.preventDefault();
    if (this.state.empDetails.length <= 4) {
      let dummy = this.state.empDetails;
      dummy.push({
        empCode: this.state.fetchpfdataid,
        name: "",
        relation: "",
        dob: "",
        employeeNumber: Number(localStorage.getItem("empId")),
        age: "",
        nominee: "",
        options: this.state.relationdata,
      });
      this.setState({ empDetails: dummy });
    }
  };

  RemoveButton = (e) => {
    e.preventDefault();
    if (this.state.empDetails.length > 0) {
      let dummy = this.state.empDetails;
      dummy.pop();
      this.setState({ empDetails: dummy });
    } else {
      alert("you can add only 5 memebers");
    }
  };

  insuranceSubmit = (e) => {
    e.preventDefault();
    this.props.dispatch(spinner(true));
    this.setState({ ispfData: false });
    for (let i = 0; i > this.state.empDetails.length > 0; i++) {
      let empcoder = this.state.empDetails;
      empcoder[0].empCode = this.state.fetchpfdataid;
      empcoder[0].employeeNumber = Number(localStorage.getItem("empId"));
    }
    const request = {
      method: "POST",
      headers: {
        "content-type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(this.state.empDetails),
    };
    fetch("https://capv.live/portalprod/saveInsurance", request).then(
      (response) => {
        if (response.ok) {
          this.props.dispatch(spinner(false));
          this.props.dispatch(pfdataemployee(this.state.empDetails[0].empCode));
          this.setState({ ispfData: true });
          this.setState({
            empDetails: [
              {
                id: 0,
                name: "",
                relation: "",
                dob: "",
                age: "",
                nominee: "",
                options: [],
              },
            ],
          });
          this.toastTR.show({
            severity: "success",
            summary: "Success Message",
            detail: "Saved Successfully",
            life: 3000,
          });
        }
        axios
          .get(
            "https://capv.live/portalprod/getInsurance?employeeId=" +
              localStorage.getItem("empId")
          )
          .then((response) => {
            this.setState({ empDetails: response.data.result });
          });
        setTimeout(
          function () {
            this.props.dispatch(showViewButton(true));
          }.bind(this),
          500
        );
        this.props.dispatch(showViewButton(true));
      }
    );
  };
  componentDidMount() {
    let date = new Date();
    let year = date.getFullYear();
    let month =
      date.getMonth() < 10 ? date.getMonth() + 1 : "0" + date.getMonth();
    let day = date.getDate() > 10 ? date.getDate() : date.getDate();
    let currentdate = year + "-" + month + "-" + day;
    let nextYear = year + 1;
    let updatedMonth =
      date.getMonth() < 10 ? date.getMonth() + 1 : "0" + date.getMonth();
    let i = updatedMonth > 9 ? updatedMonth : "0" + updatedMonth;
    let j = day > 9 ? day : "0" + day;
    let upadtednextYear = nextYear + "-" + i + "-" + j;

    this.setState({
      ...this.state,
      currentdate: currentdate,
      yearCalculation: upadtednextYear,
    });
    axios
      .get(
        "https://capv.live/portalprod/getEmployee?employeeId=" +
          localStorage.getItem("empId")
      )
      .then((response) => {
        this.setState({ EmpData: response.data.result });
      });

    axios.get("https://capv.live/portalprod/getRelationData").then((response) => {
      let empDetails = this.state.empDetails;
      empDetails[0].options = response.data.result;

      let relationdata = response.data.result.map((relation) => {
        return {
          ...relation,
          isSelected: false,
        };
      });

      this.setState({
        relationdata: relationdata,
        empDetails: empDetails,
      });
    });
    axios
      .get(
        "https://capv.live/portalprod/getEmployeeCodeById?employeeNumber=" +
          localStorage.getItem("empId")
      )
      .then((response) => {
        this.setState({ fetchpfdataid: response.data.result.employeeCode });
        let data = this.state.empDetails;
        data[0].empCode = this.state.fetchpfdataid;
      });
    axios
      .get(
        "https://capv.live/portalprod/getInsurance?employeeId=" +
          localStorage.getItem("empId")
      )
      .then((response) => {
        if (response.data.result != null && response.data.result.name != "") {
          this.props.dispatch(showViewButton(true));
          this.setState({
            empDetails: response.data.result,
            nominee: true,
          });
        }
      });
    if (localStorage.getItem("empId") != null) {
      this.props.dispatch(insuranceid(true));
    } else {
      this.props.history.push("/");
    }
  }
  insuranceHandler = (e, id, field) => {
    let dummy = this.state.empDetails;
    dummy[id][field] = e.target.value;
    if (field === "dob") {
      let currentValue = e.target.value;
      let enteredYear = currentValue.split("-")[0];
      let enteredMonth = currentValue.split("-")[1];
      let currentMonth = this.state.currentdate.split("-")[1];
      let currentYear = this.state.currentdate.split("-")[0];
      if (currentYear === enteredYear) {
        dummy[id]["age"] = "-" + enteredMonth;
        this.setState({ empDetails: dummy });
      } else if (enteredYear > currentYear) {
        dummy[id]["age"] = "- 12";
        this.setState({ empDetails: dummy });
      } else {
        let r =
          new Date().getFullYear() - new Date(e.target.value).getFullYear();
        dummy[id]["age"] = r;
        this.setState({ empDetails: dummy });
      }
    }
    this.setState({ empDetails: dummy });
    if (field == "nominee" && e.target.value == "yes") {
      this.setState({ Nominee: false, insuranceId: id });
    }
    if (e.target.value == "no") {
      let index = this.state.empDetails.findIndex((el) => el.nominee == "yes");
      if (index < 0) {
        this.setState({ insuranceId: -1, Nominee: true });
      }
    }
  };

  changingHandler = () => {
    this.setState({ employeCodeandId: true });
  };
  insuranceHandlerchanges = (e, id, field) => {
    let dummy = this.state.empDetails;
    dummy[id][field] = e.target.value;
    if (field === "relation" && e.target.value === "self") {
      dummy[id]["name"] = this.state.EmpData.firstName;
      dummy[id]["dob"] = this.state.EmpData.dob;
    } else {
      dummy[id]["name"] = "";
      dummy[id]["dob"] = "";
      dummy[id]["age"] = "";
    }

    this.setState({ ...this.state, empDetails: dummy });

    let relations = this.state.relationdata;

    let selectedRelations = [];
    for (var i = 0; i < this.state.empDetails.length; i++) {
      if (this.state.empDetails[i].relation)
        selectedRelations.push(this.state.empDetails[i].relation);
    }

    relations.forEach((rel, i) => {
      if (selectedRelations.includes(rel.name)) relations[i].isSelected = true;
      else relations[i].isSelected = false;
    });
    this.setState({ relationdata: relations });
  };
  madeVisible = () => {
    this.setState({ ...this.state, showViewForm: false, showEditForm: true });
  };

  madeVisibleoff = () => {
    this.setState({ ...this.state, showViewForm: true, showEditForm: false });
  };
  insuranceHandler_onClick = () => {
    this.props.dispatch(insuranceid(true));
    this.props.history.push("/Insurance");
  };
  pfHandler = () => {
    this.props.dispatch(pfdataid(true));
    this.props.history.push("/Pfdata");
  };
  profileHandler = () => {
    this.props.dispatch(profileid(true));
    this.props.history.push("/Profile");
  };
  empClearanceHandler = () => {
    this.props.dispatch(employeeclearance(true));
    this.props.history.push("/Employeeclearanceform");
  };

  exitinterviewhandler = () => {
    this.props.dispatch(exitinterviewfunction(true));
    this.props.history.push("/ExitInterviewForm");
  };
  empDocumentsHandler = () => {
    this.props.dispatch(previouslyUploadedDocuments(true));
    this.props.history.push("/PreviouslyUploadedDocuments");
  };

  inputView = () => {
    return this.props.showView ? "Input_fields_Disable" : "Input_fields";
  };

  render() {
    return (
      <div>
        <div>
          {this.state.ispfData && (
            <Toast ref={(el) => (this.toastTR = el)} position="top-right" />
          )}
        </div>
        <div>
          <Header />
          <h6 className="Instructions_Form">
            <strong className="strong">Instructions :</strong>Please fill the
            below details as per the format mentioned: Dependents are
            <br />
            -- your parents
            <br />
            -- parents & in-laws for married women employees
            <br />
            -- spouse if married
            <br />
            -- first, two children if married
            <br />
            DO NOT add your sisters & brothers.You can add up to 5 dependents in
            mediclaim policy as per the above criteria:
          </h6>
          <div>
            <Buttons />
          </div>
          <div className="personal_Info">
            {this.props.enableFormForPfIns && (
              <h1 className="Form55">Insurance Details</h1>
            )}
            <div>
              {this.props.enableFormForPfIns && (
                <div>
                  <form
                    className="Submit_btn_"
                    id="myForm"
                    onSubmit={this.insuranceSubmit}
                  >
                    <div className="Insurance_Header_text">
                      <div
                        className={
                          this.props.showEdit
                            ? "each_fiedname1"
                            : "each_fiedname_disabled"
                        }
                      >
                        <span>S.No</span>
                      </div>
                      <div
                        className={
                          this.props.showEdit
                            ? "each_fiedname1"
                            : "each_fiedname_disabled"
                        }
                      >
                        <span>Employee Id</span>
                      </div>
                      <div
                        className={
                          this.props.showEdit
                            ? "each_fiedname1"
                            : "each_fiedname_disabled"
                        }
                      >
                        <span>Employee Relation</span>
                      </div>
                      <div
                        className={
                          this.props.showEdit
                            ? "each_fiedname1"
                            : "each_fiedname_disabled"
                        }
                      >
                        <span>Employee Name</span>
                      </div>
                      <div
                        className={
                          this.props.showEdit
                            ? "each_fiedname1"
                            : "each_fiedname_disabled"
                        }
                      >
                        <span>Dob</span>
                      </div>
                      <div
                        className={
                          this.props.showEdit
                            ? "each_fiedname1"
                            : "each_fiedname_disabled"
                        }
                      >
                        <span>Age</span>
                      </div>
                      <div
                        className={
                          this.props.showEdit
                            ? "each_fiedname1"
                            : "each_fiedname_disabled"
                        }
                      >
                        <span>Nominee</span>
                        {/* <input type="date" onChange={this.selectedDate} /> */}
                      </div>
                    </div>
                    {this.state.empDetails.length > 0 &&
                      this.state.empDetails.map((employee, id) => {
                        return (
                          <div>
                            <div className="Insurance_Fields">
                              <div className="each_fiedname">
                                <span
                                  style={{
                                    color: !this.props.showView
                                      ? "#284caf"
                                      : "gray",
                                  }}
                                >
                                  {id + 1}
                                </span>
                                {/* {this.state.insurance_view_id + id} */}
                              </div>
                              <div className="each_fiedname">
                                <input
                                  className="Input_fields_Disable"
                                  disabled
                                  name="empId"
                                  placeholder="Emp ID"
                                  value={this.state.fetchpfdataid}
                                  autocomplete="off"
                                  required
                                ></input>
                              </div>
                              <div className="each_fiedname">
                                <select
                                  value={employee.relation}
                                  className={this.inputView()}
                                  disabled={this.props.showView}
                                  onChange={(e) => {
                                    this.insuranceHandlerchanges(
                                      e,
                                      id,
                                      "relation"
                                    );
                                  }}
                                  title="Please select any one of the options"
                                  required
                                >
                                  <option value="" disabled selected>
                                    Relation
                                  </option>
                                  {this.state.relationdata.map((rel, i) => {
                                    if (rel.isSelected === false)
                                      return (
                                        <option value={rel.name} key={i}>
                                          {rel.name}
                                        </option>
                                      );
                                    else
                                      return (
                                        <option
                                          value={rel.name}
                                          disabled
                                          key={i}
                                        >
                                          {rel.name}
                                        </option>
                                      );
                                  })}
                                </select>
                              </div>
                              <div className="each_fiedname">
                                <input
                                  className={this.inputView()}
                                  type="text"
                                  name="name"
                                  disabled={this.props.showView}
                                  placeholder="Name"
                                  pattern="[A-Za-z, . \n]{3,60}"
                                  title="Min 3 characters and Max 60 characters should be entered. Numbers and special characters are not allowed"
                                  value={employee.name}
                                  onChange={(e) => {
                                    this.insuranceHandler(e, id, "name");
                                  }}
                                  required
                                  autocomplete="off"
                                ></input>
                              </div>
                              <div className="each_fiedname">
                                <input
                                  className={this.inputView()}
                                  type="date"
                                  disabled={this.props.showView}
                                  max={this.state.yearCalculation}
                                  name="dob"
                                  //pattern = "/^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$"
                                  value={employee.dob.split("T")[0]}
                                  min="1930-01-01"
                                  title="Please select the date"
                                  onChange={(e) => {
                                    this.insuranceHandler(e, id, "dob");
                                  }}
                                  required
                                  autocomplete="on"
                                />
                              </div>
                              <div className="each_fiedname">
                                <input
                                  className="Input_fields_Disable"
                                  type="text"
                                  name="age"
                                  disabled
                                  pattern="0[5-9]|[1-9]{2}"
                                  placeholder="Age"
                                  title="Age should be greater than 5"
                                  value={employee.age}
                                  onChange={(e) => {
                                    this.insuranceHandler(e, id, "age");
                                  }}
                                  required
                                  autocomplete="off"
                                ></input>
                              </div>

                              {employee.relation !== "self" && (
                                <div className="each_fiedname">
                                  {(this.state.Nominee ||
                                    this.state.insuranceId == id) && (
                                    <select
                                      disabled={this.props.showView}
                                      className={this.inputView()}
                                      name="nominee"
                                      value={employee.nominee}
                                      onChange={(e) => {
                                        this.insuranceHandler(e, id, "nominee");
                                      }}
                                      required
                                      autocomplete="off"
                                    >
                                      <option value="" disabled selected>
                                        Nominee
                                      </option>
                                      <option value="yes">Yes</option>
                                      <option value="no">No</option>
                                    </select>
                                  )}

                                  {!this.state.Nominee &&
                                    this.state.insuranceId != id && (
                                      <select
                                        disabled={this.props.showView}
                                        className={this.inputView()}
                                        name="nominee"
                                        value={employee.nominee}
                                        onChange={(e) => {
                                          this.insuranceHandler(
                                            e,
                                            id,
                                            "nominee"
                                          );
                                        }}
                                        required
                                        autocomplete="off"
                                      >
                                        <option value="" disabled selected>
                                          Nominee
                                        </option>
                                        <option value="no">No</option>
                                      </select>
                                    )}
                                </div>
                              )}
                              {this.props.showEdit && (
                                <div className="btns_add_sub">
                                  {id === this.state.empDetails.length - 1 &&
                                    this.state.empDetails.length < 5 && (
                                      <div
                                        className="pluswithtooltip"
                                        style={{ position: "relative" }}
                                      >
                                        <button
                                          className="plus"
                                          onClick={this.AddButton}
                                        >
                                          +
                                        </button>
                                        <div className="plusTooltip">
                                          <span className="addMore">
                                            Add Record
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  {id === this.state.empDetails.length - 1 &&
                                    this.state.empDetails.length > 1 && (
                                      <div
                                        className="minuswithtooltip"
                                        style={{ position: "relative" }}
                                      >
                                        <button
                                          className="negative"
                                          onClick={this.RemoveButton}
                                          onMouseOver={() =>
                                            this.setState({ reduceHover: true })
                                          }
                                          onMouseOut={() =>
                                            this.setState({
                                              reduceHover: false,
                                            })
                                          }
                                        >
                                          -
                                        </button>
                                        <div
                                          className="minusTooltip"
                                          style={{
                                            visibility: this.state.reduceHover
                                              ? "visible"
                                              : "hidden",
                                          }}
                                        >
                                          <span className="reduceMore">
                                            Remove
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    {this.props.showEdit && (
                      <div className="Submit_btn_userpage_admin_otp">
                        <button className="save-btn">
                          Submit <i class="pi pi-send"></i>
                        </button>
                      </div>
                    )}
                  </form>
                </div>
              )}
            </div>
          </div>
          {/* mobileview */}
          {this.props.enableFormForPfIns && (
            <div className="insurance_ForDesktop_Mobile">
              <form
                className="Submit_btn_"
                id="myForm"
                onSubmit={this.insuranceSubmit}
              >
                {this.state.empDetails.length > 0 &&
                  this.state.empDetails.map((employee, id) => {
                    return (
                      <div>
                        <div className="Insurance_Fields">
                          {/* s.no */}
                          {/* <div className="each_fiedname_mobileView">
                            <div className="each_field_label_Mobile">S.No</div>
                            <div className="each_field_inputField_Mobile">
                              {" "}
                              {this.state.insurance_view_id + id}
                            </div>
                          </div> */}
                          {/* empid */}
                          <div className="each_fiedname_mobileView">
                            <div className="each_field_label_Mobile">
                              Emp Id:
                            </div>
                            <div className="each_field_inputField_Mobile">
                              <input
                                className="Input_fields"
                                disabled
                                name="empId"
                                placeholder="Emp ID"
                                value={this.state.fetchpfdataid}
                                autocomplete="off"
                                required
                              ></input>
                            </div>
                          </div>
                          {/* relation */}
                          <div className="each_fiedname_mobileView">
                            <div className="each_field_label_Mobile">
                              Relation:
                            </div>
                            <div className="each_field_inputField_Mobile">
                              <select
                                value={employee.relation}
                                className="Input_Select"
                                disabled={this.props.showView}
                                onChange={(e) => {
                                  this.insuranceHandlerchanges(
                                    e,
                                    id,
                                    "relation"
                                  );
                                }}
                                required
                              >
                                <option value="" disabled selected>
                                  Relation:
                                </option>
                                {this.state.relationdata.map((rel, i) => {
                                  if (rel.isSelected === false)
                                    return (
                                      <option value={rel.name} key={i}>
                                        {rel.name}
                                      </option>
                                    );
                                  else
                                    return (
                                      <option value={rel.name} disabled key={i}>
                                        {rel.name}
                                      </option>
                                    );
                                })}
                              </select>
                            </div>
                          </div>
                          {/* ins name */}
                          <div className="each_fiedname_mobileView">
                            <div className="each_field_label_Mobile">
                              Emp Name:
                            </div>
                            <div className="each_field_inputField_Mobile">
                              <input
                                className="Input_fields"
                                type="text"
                                name="name"
                                disabled={this.props.showView}
                                placeholder="Name"
                                value={employee.name}
                                maxLength="50"
                                onChange={(e) => {
                                  this.insuranceHandler(e, id, "name");
                                }}
                                required
                                autocomplete="off"
                              ></input>
                            </div>
                          </div>
                          {/* Dob */}
                          <div className="each_fiedname_mobileView">
                            <div className="each_field_label_Mobile">Dob:</div>
                            <div className="each_field_inputField_Mobile">
                              <input
                                className="Input_date_mobileView"
                                type="date"
                                disabled={this.props.showView}
                                max={this.state.currentdate}
                                name="dob"
                                value={employee.dob.split("T")[0]}
                                onChange={(e) => {
                                  this.insuranceHandler(e, id, "dob");
                                }}
                                required
                                autocomplete="off"
                              ></input>
                            </div>
                          </div>
                          {/* age */}
                          <div className="each_fiedname_mobileView">
                            <div className="each_field_label_Mobile">Age:</div>
                            <div className="each_field_inputField_Mobile">
                              <input
                                className="Input_fields"
                                type="text"
                                name="age"
                                disabled={this.props.showView}
                                pattern="[0-9]{0,2}"
                                placeholder="Age"
                                value={employee.age}
                                onChange={(e) => {
                                  this.insuranceHandler(e, id, "age");
                                }}
                                required
                                autocomplete="off"
                              ></input>
                            </div>
                          </div>
                          {/* nominee */}
                          {employee.relation !== "self" && (
                            <div className="each_fiedname_mobileView">
                              <div className="each_field_label_Mobile">
                                Nominee:
                              </div>
                              <div className="each_field_inputField_Mobile">
                                {(this.state.Nominee ||
                                  this.state.insuranceId == id) && (
                                  <select
                                    disabled={this.props.showView}
                                    className="Input_Select"
                                    name="nominee"
                                    value={employee.nominee}
                                    onChange={(e) => {
                                      this.insuranceHandler(e, id, "nominee");
                                    }}
                                    required
                                    autocomplete="off"
                                  >
                                    <option value="" disabled selected>
                                      Nominee
                                    </option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                  </select>
                                )}
                                {!this.state.Nominee &&
                                  this.state.insuranceId != id && (
                                    <select
                                      disabled={this.props.showView}
                                      className="Input_fields"
                                      name="nominee"
                                      value={employee.nominee}
                                      onChange={(e) => {
                                        this.insuranceHandler(e, id, "nominee");
                                      }}
                                      required
                                      autocomplete="off"
                                    >
                                      <option value="" disabled selected>
                                        Nominee
                                      </option>
                                      <option value="no">No</option>
                                    </select>
                                  )}
                              </div>
                            </div>
                          )}
                          {this.props.showEdit && (
                            <div className="btns_add_sub_Mobile">
                              {id === this.state.empDetails.length - 1 &&
                                this.state.empDetails.length < 5 && (
                                  <button
                                    className="plus"
                                    onClick={this.AddButton}
                                  >
                                    +
                                  </button>
                                )}
                              {id === this.state.empDetails.length - 1 &&
                                this.state.empDetails.length > 1 && (
                                  <button
                                    className="negative"
                                    onClick={this.RemoveButton}
                                  >
                                    -
                                  </button>
                                )}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                {this.props.showEdit && (
                  <div className="Submit_btn_userpage_admin_otp">
                    <button className="save-btn">
                      Submit <i class="pi pi-send"></i>
                    </button>
                  </div>
                )}
              </form>
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    insuranceemployeeId: state.insuranceemployeeId,
    empId: state.empId,
    profile: state.profile,
    pfdetails: state.pfdetails,
    insurance: state.insurance,
    empClearance: state.empClearance,
    exitinterview: state.exitinterview,
    empDocuments: state.empDocuments,
    isSpinner: state.isSpinner,
    empIdNotAssigned: state.empIdNotAssigned,
    showEdit: state.showEdit,
    showView: state.showView,
    enableFormForPfIns: state.enableFormForPfIns,
    btnStatus: state.btnStatus,
  };
};
export default connect(mapStateToProps)(Insurance);
