import React, { Component } from "react";
import "./Form.css";
import { connect } from "react-redux";
import { InputSwitch } from "primereact/inputswitch";
import {
  uploadid,
  isexpirienceThere,
  isSelectedValue,
  alwaysThere,
  isAddExpirience,
  LinearProgressSpinner,
  spinner,
} from "../../../store/actions.js";
import { InputTextarea } from "primereact/inputtextarea";
import Header from "../../Header/Header.js";

class Form extends Component {
  constructor(props) {
    super(props);
    this.childone = React.createRef();
    this.state = {
      LoadingSpinner: true,
      empId: "",
      requestTemplate: {
        firstName: "",
        lastName: "",
        otherName: "",
        gender: "",
        dob: "",
        bloodGroup: "",
        passportNumber: "",
        dateOfIssue: "",
        dateOfExpiry: "",
        panCardNumber: "",
        aadharNumber: "",
        email: "",
        place: "",
        date: "",
        status: null,
        contactInfo: [
          {
            address: "",
            city: "",
            pincode: "",
            state: "",
            country: "",
            nationality: "",
            phone: "",
            emergencyContact: "",
            alternateContact: "",
          },
          {
            address: "",
            city: "",
            pincode: "",
            state: "",
            country: "",
            nationality: "",
            phone: "",
            emergencyContact: "",
            alternateContact: "",
          },
        ],
        educationInfo: {
          degree: "",
          collegeName: "",
          universityName: "",
          subject: "",
          courseStartDate: "",
          courseEndDate: "",
          courseType: "",
          addressOfInstitution: "",
          city: "",
          pinCode: "",
          landline1: "",
          landline2: "",
        },
        employmentInfo: [],
      },
      fields: {
        firstName: "",
        lastName: "",
        otherName: "",
        gender: "",
        dob: "",
        bloodGroup: "",
        passportNumber: "",
        dateOfIssue: "",
        dateOfExpiry: "",
        panCardNumber: "",
        aadharNumber: "",
        place: "",
        date: "",
        contactInfo: [
          {
            address: "",
            city: "",
            pincode: "",
            state: "",
            country: "",
            nationality: "",
            phone: "",
            email: "",
            emergencyContact: "",
            alternateContact: "",
          },
          {
            address: "",
            city: "",
            pincode: "",
            state: "",
            country: "",
            nationality: "",
            phone: "",
            email: "",
            emergencyContact: "",
            alternateContact: "",
          },
        ],
        educationInfo: {
          degree: "",
          collegeName: "",
          universityName: "",
          subject: "",
          courseStartDate: "",
          courseEndDate: "",
          courseType: "",
          addressOfInstitution: "",
          city: "",
          pinCode: "",
          landline1: "",
          landline2: "",
        },
        employmentInfo: [
          {
            companyName: "",
            employedPlace: "",
            companyAddress: "",
            city: "",
            state: "",
            pincode: "",
            fromDate: "",
            toDate: "",
            designation: "",
            remuneration: "",
            employeeCode: "",
            employmentNature: "",
            supervisorName: "",
            supervisorDesignation: "",
            supervisorEmailId: "",
            leavingReason: "",
          },
        ],
      },
      address: {
        address: "",
        city: "",
        pincode: "",
        state: "",
        country: "",
        nationality: "",
        phone: "",
        email: "",
        emergencyContact: "",
        alternateContact: "",
      },
      expirienceInYears: 0,
      errors: {},
      file: [],
      filer: [],
      isValid: false,
      isModal: false,
      checked1: false,
      checked: false,
      isAddressExist: true,
      removedExp: false,
      addexpo: true,
      selectedValue: "",
      makeExpo: true,
      isRender: false,
      rendered: false,
      button: false,
      currentdate: "",
    };
    // this.experienceRemove = this.experienceRemove.bind(this);
    this.experienceAdd = this.experienceAdd.bind(this);
    this.employmentHandler = this.employmentHandler.bind(this);
    this.contactHandler = this.contactHandler.bind(this);
    this.personalHandler = this.personalHandler.bind(this);
    this.educationHandler = this.educationHandler.bind(this);
  }
  RequiredValidation() {
    //debugger
    let fields = this.state.requestTemplate;
    let errors = {};
    let formIsValid = true;

    //checked1
    if (!this.state.checked) {
      formIsValid = false;
      errors.checked = "Mandatory";
    }
    //checked1
    //   if(!this.props.selectedValue) {
    //     formIsValid = false;
    //     errors.selected = 'Mandatory'
    // }
    //FirstName//
    if (!fields.firstName) {
      formIsValid = false;
      errors.firstName = "Required";
    }

    //place//
    if (!fields.place) {
      formIsValid = false;
      errors.place = "Required";
    }

    if (!fields.date) {
      formIsValid = false;
      errors.date = "Required";
    }

    //LastName//
    // if (!fields.lastName) {
    //   formIsValid = false;
    //   errors.lastName = "Required";
    // }

    //OtherName//
    if (!fields.otherName) {
      formIsValid = false;
      errors.otherName = "Required";
    }

    //Gender//
    if (!fields.gender) {
      formIsValid = false;
      errors.gender = "Required";
    }

    //DateOfBirth
    if (!fields["dob"]) {
      formIsValid = false;
      errors["dob"] = "Required";
    }

    //bloodGroup//
    if (!fields.bloodGroup) {
      formIsValid = false;
      errors.bloodGroup = "Required";
    }

    // else if (!/^[a-zA-Z, ]+$/.test(fields.bloodGroup)) {
    //     formIsValid = false;
    //     errors.bloodGroup = "Only Letters"
    // }

    //passportNumber//
    // if (!fields.passportNumber) {
    //   formIsValid = false;
    //   errors.passportNumber = "Required";
    // }

    //dateOfIssue//
    // if (!fields["dateOfIssue"]) {
    //   formIsValid = false;
    //   errors["dateOfIssue"] = "Required";
    // }
    //dateOfExpiry//
    // if (!fields["dateOfExpiry"]) {
    //   formIsValid = false;
    //   errors["dateOfExpiry"] = "Required";
    // }

    //PanCardNumber//
    if (!fields.panCardNumber) {
      formIsValid = false;
      errors.panCardNumber = "Required";
    }

    if (!fields.aadharNumber) {
      formIsValid = false;
      errors.aadharNumber = "Required";
    }

    //address//
    if (!fields.contactInfo[0].address) {
      formIsValid = false;
      errors.address = "Required";
    }
    // //address//
    if (!fields.contactInfo[1].address) {
      formIsValid = false;
      errors.address1 = "Required";
    }

    //City//
    if (!fields.contactInfo[0].city) {
      formIsValid = false;
      errors.city = "Required";
    }

    //City//
    if (!fields.contactInfo[1].city) {
      formIsValid = false;
      errors.citty = "Required";
    }

    //Pincode//
    if (!fields.contactInfo[0].pincode) {
      formIsValid = false;
      errors.contactInfopincode = "Required";
    }

    //Pincode//
    if (!fields.contactInfo[1].pincode) {
      formIsValid = false;
      errors.contactInfopincode1 = "Required";
    }

    //State//
    if (!fields.contactInfo[0].state) {
      formIsValid = false;
      errors.state = "Required";
    }

    //State//
    if (!fields.contactInfo[1].state) {
      formIsValid = false;
      errors.state1 = "Required";
    }

    //Country//
    if (!fields.contactInfo[0].country) {
      formIsValid = false;
      errors.country = "Required";
    }

    //Country//
    if (!fields.contactInfo[1].country) {
      formIsValid = false;
      errors.country1 = "Required";
    }

    //Nationality//
    if (!fields.contactInfo[0].nationality) {
      formIsValid = false;
      errors.nationality = "Required";
    }

    //Nationality//
    if (!fields.contactInfo[1].nationality) {
      formIsValid = false;
      errors.nationality1 = "Required";
    }

    //Phone//
    if (!fields.contactInfo[0].phone) {
      formIsValid = false;
      errors.phone = "Required";
    }

    //Phone//
    if (!fields.contactInfo[1].phone) {
      formIsValid = false;
      errors.phone1 = "Required";
    }

    //EmergencyContact//

    if (!fields.contactInfo[0].emergencyContact) {
      formIsValid = false;
      errors.emergencyContact = "Required";
    }

    //EmergencyContact//
    if (!fields.contactInfo[1].emergencyContact) {
      formIsValid = false;
      errors.emergencyContact1 = "Required";
    }

    //AlternateContact//
    if (!fields.contactInfo[0].alternateContact) {
      formIsValid = false;
      errors.alternateContact = "Required";
    }

    //AlternateContact//
    if (!fields.contactInfo[1].alternateContact) {
      formIsValid = false;
      errors.alternateContact1 = "Required";
    }

    //Degree//
    if (!fields.educationInfo.degree) {
      formIsValid = false;
      errors.degree = "Required";
    }

    //CollegeName//
    if (!fields.educationInfo.collegeName) {
      formIsValid = false;
      errors.collegeName = "Required";
    }

    //UniversityName//
    if (!fields.educationInfo.universityName) {
      formIsValid = false;
      errors.universityName = "Required";
    }

    //Subject//
    if (!fields.educationInfo.subject) {
      formIsValid = false;
      errors.subject = "Required";
    }
    //CourseStartDate//
    if (!fields.educationInfo["courseStartDate"]) {
      formIsValid = false;
      errors["courseStartDate"] = "Required";
    }

    //CourseEndDate//
    if (!fields.educationInfo["courseEndDate"]) {
      formIsValid = false;
      errors["courseEndDate"] = "Required";
    }

    //CourseType//
    if (!fields.educationInfo.courseType) {
      formIsValid = false;
      errors.courseType = "Required";
    }

    //AddressOfInstitution//
    if (!fields.educationInfo.addressOfInstitution) {
      formIsValid = false;
      errors.addressOfInstitution = "Required";
    }

    //City//
    if (!fields.educationInfo.city) {
      formIsValid = false;
      errors.cityeducatio = "Required";
    }

    //PinCode//
    if (!fields.educationInfo.pinCode) {
      formIsValid = false;
      errors.educationInfopinCode = "Required";
    }
    //Landline1//
    // if (!fields.educationInfo.landline1) {
    //   formIsValid = false;
    //   errors.landline1 = "Required";
    // }

    //Landline2//
    // if (!fields.educationInfo.landline2) {
    //   formIsValid = false;
    //   errors.landline2 = "Required";
    // }

    for (let i = 0; i < this.state.requestTemplate.employmentInfo.length; i++) {
      //company Name//

      if (!fields.employmentInfo[i].companyName) {
        formIsValid = false;
        if (i == 0) {
          errors.companyName = "Required";
        }
        if (i == 1) {
          errors.exp1_companyName = "Required";
        }
        if (i == 2) {
          errors.exp2_companyName = "Required";
        }
        if (i == 3) {
          errors.exp3_companyName = "Required";
        }
        if (i == 4) {
          errors.exp4_companyName = "Required";
        }
      }

      //employedPlace
      if (!fields.employmentInfo[i].employedPlace) {
        formIsValid = false;
        if (i == 0) {
          errors.employedPlace = "Required";
        }
        if (i == 1) {
          errors.exp1_employedPlace = "Required";
        }
        if (i == 2) {
          errors.exp2_employedPlace = "Required";
        }
        if (i == 3) {
          errors.exp3_employedPlace = "Required";
        }
        if (i == 4) {
          errors.exp4_employedPlace = "Required";
        }
      }

      //company Address//
      if (!fields.employmentInfo[i].companyAddress) {
        formIsValid = false;
        if (i == 0) {
          errors.companyAddress = "Required";
        }
        if (i == 1) {
          errors.exp1_companyAddress = "Required";
        }
        if (i == 2) {
          errors.exp2_companyAddress = "Required";
        }
        if (i == 3) {
          errors.exp3_companyAddress = "Required";
        }
        if (i == 4) {
          errors.exp4_companyAddress = "Required";
        }
      }

      ////city

      if (!fields.employmentInfo[i].city) {
        formIsValid = false;
        if (i == 0) {
          errors.cityemployment = "Required";
        }
        if (i == 1) {
          errors.exp1_cityemployment = "Required";
        }
        if (i == 2) {
          errors.exp2_cityemployment = "Required";
        }
        if (i == 3) {
          errors.exp3_cityemployment = "Required";
        }
        if (i == 4) {
          errors.exp4_cityemployment = "Required";
        }
      }

      ////state

      if (!fields.employmentInfo[i].state) {
        formIsValid = false;
        if (i == 0) {
          errors.stateemployment = "Required";
        }
        if (i == 1) {
          errors.exp1_stateemployment = "Required";
        }
        if (i == 2) {
          errors.exp2_stateemployment = "Required";
        }
        if (i == 3) {
          errors.exp3_stateemployment = "Required";
        }
        if (i == 4) {
          errors.exp4_stateemployment = "Required";
        }
      }
      ////pincode

      if (!fields.employmentInfo[i].pincode) {
        formIsValid = false;
        if (i == 0) {
          errors.pincodeemployement = "Required";
        }
        if (i == 1) {
          errors.exp1_pincodeemployement = "Required";
        }
        if (i == 2) {
          errors.exp2_pincodeemployement = "Required";
        }
        if (i == 3) {
          errors.exp3_pincodeemployement = "Required";
        }
        if (i == 4) {
          errors.exp4_pincodeemployement = "Required";
        }
      }

      //fromDate
      if (!fields.employmentInfo[i].fromDate) {
        formIsValid = false;
        if (i == 0) {
          errors.fromDate = "Required";
        }
        if (i == 1) {
          errors.exp1_fromDate = "Required";
        }
        if (i == 2) {
          errors.exp2_fromDate = "Required";
        }
        if (i == 3) {
          errors.exp3_fromDate = "Required";
        }
        if (i == 4) {
          errors.exp4_fromDate = "Required";
        }
      }

      //toDate
      if (!fields.employmentInfo[i].toDate) {
        formIsValid = false;
        if (i == 0) {
          errors.toDate = "Required";
        }
        if (i == 1) {
          errors.exp1_toDate = "Required";
        }
        if (i == 2) {
          errors.exp2_toDate = "Required";
        }
        if (i == 3) {
          errors.exp3_toDate = "Required";
        }
        if (i == 4) {
          errors.exp4_toDate = "Required";
        }
      }

      //designation
      if (!fields.employmentInfo[i].designation) {
        formIsValid = false;
        if (i == 0) {
          errors.designation = "Required";
        }
        if (i == 1) {
          errors.exp1_designation = "Required";
        }
        if (i == 2) {
          errors.exp2_designation = "Required";
        }
        if (i == 3) {
          errors.exp3_designation = "Required";
        }
        if (i == 4) {
          errors.exp4_designation = "Required";
        }
      }

      //remuneration

      if (!fields.employmentInfo[i].remuneration) {
        formIsValid = false;
        if (i == 0) {
          errors.remuneration = "Required";
        }
        if (i == 1) {
          errors.exp1_remuneration = "Required";
        }
        if (i == 2) {
          errors.exp2_remuneration = "Required";
        }
        if (i == 3) {
          errors.exp3_remuneration = "Required";
        }
        if (i == 4) {
          errors.exp4_remuneration = "Required";
        }
      }
      //supervisorEmailId

      if (!fields.employmentInfo[i].supervisorEmailId) {
        formIsValid = false;
        if (i == 0) {
          errors.supervisorEmailId = "Required";
        }
        if (i == 1) {
          errors.exp1_supervisorEmailId = "Required";
        }
        if (i == 2) {
          errors.exp2_supervisorEmailId = "Required";
        }
        if (i == 3) {
          errors.exp3_supervisorEmailId = "Required";
        }
        if (i == 4) {
          errors.exp4_supervisorEmailId = "Required";
        }
      }

      //employeeCode

      if (!fields.employmentInfo[i].employeeCode) {
        formIsValid = false;
        if (i == 0) {
          errors.employeeCode = "Required";
        }
        if (i == 1) {
          errors.exp1_employeeCode = "Required";
        }
        if (i == 2) {
          errors.exp2_employeeCode = "Required";
        }
        if (i == 3) {
          errors.exp3_employeeCode = "Required";
        }
        if (i == 4) {
          errors.exp4_employeeCode = "Required";
        }
      }

      //employmentNature

      if (!fields.employmentInfo[i].employmentNature) {
        formIsValid = false;
        if (i == 0) {
          errors.employmentNature = "Required";
        }
        if (i == 1) {
          errors.exp1_employmentNature = "Required";
        }
        if (i == 2) {
          errors.exp2_employmentNature = "Required";
        }
        if (i == 3) {
          errors.exp3_employmentNature = "Required";
        }
        if (i == 4) {
          errors.exp4_employmentNature = "Required";
        }
      }

      //supervisorName

      if (!fields.employmentInfo[i].supervisorName) {
        formIsValid = false;
        if (i == 0) {
          errors.supervisorName = "Required";
        }
        if (i == 1) {
          errors.exp1_supervisorName = "Required";
        }
        if (i == 2) {
          errors.exp2_supervisorName = "Required";
        }
        if (i == 3) {
          errors.exp3_supervisorName = "Required";
        }
        if (i == 4) {
          errors.exp4_supervisorName = "Required";
        }
      }

      //supervisorDesignation

      if (!fields.employmentInfo[i].supervisorDesignation) {
        formIsValid = false;
        if (i == 0) {
          errors.supervisorDesignation = "Required";
        }
        if (i == 1) {
          errors.exp1_supervisorDesignation = "Required";
        }
        if (i == 2) {
          errors.exp2_supervisorDesignation = "Required";
        }
        if (i == 3) {
          errors.exp3_supervisorDesignation = "Required";
        }
        if (i == 4) {
          errors.exp4_supervisorDesignation = "Required";
        }
      }

      //leavingReason

      if (!fields.employmentInfo[i].leavingReason) {
        formIsValid = false;
        if (i == 0) {
          errors.leavingReason = "Required";
        }
        if (i == 1) {
          errors.exp1_leavingReason = "Required";
        }
        if (i == 2) {
          errors.exp2_leavingReason = "Required";
        }
        if (i == 3) {
          errors.exp3_leavingReason = "Required";
        }
        if (i == 4) {
          errors.exp4_leavingReason = "Required";
        }
      }
    }

    this.setState({ fields: fields });
    this.setState({ errors: errors });
    return formIsValid;
  }

  handleValidation() {
    //debugger
    let fields = this.state.requestTemplate;
    let errors = {};
    let formIsValid = true;

    //FirstName//
    if (fields.firstName != "") {
      if (!/^[a-zA-Z, ]{3,30}$/.test(fields.firstName)) {
        formIsValid = false;
        errors.firstName =
          "Special characters & numbers are not allowed Min 3 and Max 30 characters.";
      }
    }

    //place//
    // if (fields.place != "") {
    //   if (!/^[a-zA-Z]+$/.test(fields.place)) {
    //     formIsValid = false;
    //     errors.place = "Special characters & numbers are not allowed";
    //   }
    // }

    //LastName//

    // if (fields.lastName != "") {
    //   if (!/^[a-zA-Z]+$/.test(fields.lastName)) {
    //     formIsValid = false;
    //     errors.lastName = "Special characters & numbers are not allowed";
    //   }
    // }

    //OtherName//

    if (fields.otherName != "") {
      if (!/^[a-zA-Z, ]{3,30}$/.test(fields.otherName)) {
        formIsValid = false;
        errors.otherName =
          "Special characters & numbers are not allowed Min 3 and Max 30 characters.";
      }
    }

    //bloodGroup//

    if (fields.bloodGroup != "") {
      if (!/^(A|B|AB|O)[+-]$/i.test(fields.bloodGroup)) {
        formIsValid = false;
        errors.bloodGroup = "Enter valid blood group";
      }
    }

    //passportNumber//
    if (fields.passportNumber != "") {
      if (!/^[A-Za-z]{1}[0-9]{7}$/.test(fields.passportNumber)) {
        formIsValid = false;
        errors.passportNumber = "Enter valid passport number";
      }
    }

    //PanCardNumber//
    if (fields.panCardNumber != "") {
      if (!/[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/.test(fields.panCardNumber)) {
        formIsValid = false;
        errors.panCardNumber = "Enter valid Pancard number";
      }
    }

    if (fields.aadharNumber != "") {
      if (!/^[0-9]{12}$/.test(fields.aadharNumber)) {
        formIsValid = false;
        errors.aadharNumber = "Aadhaar number must be 12 digits";
      }
    }

    //address//
    if (fields.contactInfo[0].address != "") {
      if (
        !/^[a-zA-Z0-9!\n-._ :/ , ]{3,100}$/.test(fields.contactInfo[0].address)
      ) {
        formIsValid = false;
        errors.address =
          "Special characters are not allowed. Max 100 characters are allowed";
      }
    }
    // //address//
    if (fields.contactInfo[1].address != "") {
      if (
        !/^[a-zA-Z0-9!\n-._ :/ , ]{3,100}$/.test(fields.contactInfo[1].address)
      ) {
        formIsValid = false;
        errors.address1 =
          "Special characters are not allowed. Max 100 characters are allowed";
      }
    }

    //City//
    if (fields.contactInfo[0].city != "") {
      if (!/^[a-zA-Z]{3,30}$/.test(fields.contactInfo[0].city)) {
        formIsValid = false;
        errors.city =
          "Special characters & numbers are not allowed. Min 3 and Max 30 characters";
      }
    }

    //City//
    if (fields.contactInfo[1].city != "") {
      if (!/^[a-zA-Z]{3,30}$/.test(fields.contactInfo[1].city)) {
        formIsValid = false;
        errors.citty = "Special characters & numbers are not allowed";
      }
    }

    //Pincode//
    if (fields.contactInfo[0].pincode != "") {
      if (!/^[0-9]{6}$/.test(fields.contactInfo[0].pincode)) {
        formIsValid = false;
        errors.contactInfopincode = "Pincode must be 6 numbers";
      }
    }

    //Pincode//
    if (fields.contactInfo[1].pincode != "") {
      if (!/^[0-9]{6}$/.test(fields.contactInfo[1].pincode)) {
        formIsValid = false;
        errors.contactInfopincode1 = "Pincode must be 6 numbers";
      }
    }

    //State//
    if (fields.contactInfo[0].state != "") {
      if (!/^[a-zA-Z ,]{3,100}$/.test(fields.contactInfo[0].state)) {
        formIsValid = false;
        errors.state =
          "Special characters & numbers are not allowed. Max upto 100 characters are allowed";
      }
    }

    //State//
    if (fields.contactInfo[1].state != "") {
      if (!/^[a-zA-Z ,]{3,100}$/.test(fields.contactInfo[1].state)) {
        formIsValid = false;
        errors.state1 = "Special characters & numbers are not allowed";
      }
    }
    //Country//
    if (fields.contactInfo[0].country != "") {
      if (!/^[a-zA-Z ,]+$/.test(fields.contactInfo[0].country)) {
        formIsValid = false;
        errors.country = "Special characters & numbers are not allowed";
      }
    }

    //Country//
    if (fields.contactInfo[1].country != "") {
      if (!/^[a-zA-Z ,]+$/.test(fields.contactInfo[1].country)) {
        formIsValid = false;
        errors.country1 = "Special characters & numbers are not allowed";
      }
    }

    if (fields.contactInfo[0].nationality != "") {
      if (!/^[a-zA-Z]{3,30}$/.test(fields.contactInfo[0].nationality)) {
        formIsValid = false;
        errors.nationality =
          "Special characters & numbers are not allowed, Min 3 and Max 30 characters are allowed";
      }
    }

    //Nationality//
    if (fields.contactInfo[1].nationality != "") {
      if (!/^[a-zA-Z]{3,30}$/.test(fields.contactInfo[1].nationality)) {
        formIsValid = false;
        errors.nationality1 =
          "Special characters & numbers are not allowed, Min 3 and Max 30 characters are allowed";
      }
    }

    //Phone//

    if (fields.contactInfo[0].phone != "") {
      if (!/^[0-9]{10}$/.test(fields.contactInfo[0].phone)) {
        formIsValid = false;
        errors.phone = "Mobile number must be 10 numbers";
      }
    }

    //Phone//

    if (fields.contactInfo[1].phone != "") {
      if (!/^[0-9]{10}$/.test(fields.contactInfo[1].phone)) {
        formIsValid = false;
        errors.phone1 = "Mobile number must be 10 numbers";
      }
    }

    //EmergencyContact//

    if (fields.contactInfo[0].emergencyContact != "") {
      if (!/^[0-9]{10}$/.test(fields.contactInfo[0].emergencyContact)) {
        formIsValid = false;
        errors.emergencyContact = "Mobile number must be 10 numbers";
      }
    }

    //EmergencyContact//
    if (fields.contactInfo[1].emergencyContact != "") {
      if (!/^[0-9]{10}$/.test(fields.contactInfo[1].emergencyContact)) {
        formIsValid = false;
        errors.emergencyContact1 = "Mobile number must be 10 numbers";
      }
    }

    //AlternateContact//
    if (fields.contactInfo[0].alternateContact != "") {
      if (!/^[0-9]{10}$/.test(fields.contactInfo[0].alternateContact)) {
        formIsValid = false;
        errors.alternateContact = "Mobile number must be 10 numbers";
      }
    }

    //AlternateContact//
    if (fields.contactInfo[1].alternateContact != "") {
      if (!/^[0-9]{10}$/.test(fields.contactInfo[1].alternateContact)) {
        formIsValid = false;
        errors.alternateContact1 = "Mobile number must be 10 numbers";
      }
    }

    //Degree//
    if (fields.educationInfo.degree != "") {
      if (!/^[a-zA-Z-,"." ,]{3,30}$/.test(fields.educationInfo.degree)) {
        formIsValid = false;
        errors.degree =
          "Special characters & numbers are not allowed, Min 3 and Max 30 characters are allowed";
      }
    }

    //CollegeName//
    if (fields.educationInfo.collegeName != "") {
      if (!/^[a-zA-Z, ]{3,30}$/.test(fields.educationInfo.collegeName)) {
        formIsValid = false;
        errors.collegeName =
          "Special characters & numbers are not allowed, Min 3 and Max 30 characters are allowed";
      }
    }

    //UniversityName//
    if (fields.educationInfo.universityName != "") {
      if (!/^[a-zA-Z, ]{3,30}$/.test(fields.educationInfo.universityName)) {
        formIsValid = false;
        errors.universityName =
          "Special characters & numbers are not allowed, Min 3 and Max 30 characters are allowed";
      }
    }

    //Subject//
    if (fields.educationInfo.subject != "") {
      if (!/^[a-zA-Z, ]{3,30}$/.test(fields.educationInfo.subject)) {
        formIsValid = false;
        errors.subject =
          "Special characters & numbers are not allowed, Min 3 and Max 30 characters are allowed";
      }
    }
    //CourseStartDate//

    //CourseType//
    if (fields.educationInfo.courseType != "") {
      if (!/^[a-zA-Z, ]+$/.test(fields.educationInfo.courseType)) {
        formIsValid = false;
        errors.courseType = "Special characters & numbers are not allowed";
      }
    }

    if (fields.educationInfo.addressOfInstitution != "") {
      if (
        !/^[a-zA-Z0-9!\n-._ :/ , ]{3,150}$/.test(
          fields.educationInfo.addressOfInstitution
        )
      ) {
        formIsValid = false;
        errors.addressOfInstitution =
          "Special characters are not allowed and Max upto 150 characters are allowed";
      }
    }

    //City//
    if (fields.educationInfo.city != "") {
      if (!/^[a-zA-Z]{3,30}$/.test(fields.educationInfo.city)) {
        formIsValid = false;
        errors.cityeducatio =
          "Special characters are not allowed and Max upto 150 characters are allowed";
      }
    }

    //PinCode//
    if (fields.educationInfo.pinCode != "") {
      if (!/^[0-9]{6}$/.test(fields.educationInfo.pinCode)) {
        formIsValid = false;
        errors.educationInfopinCode = "Pincode must be 6 numbers";
      }
    }

    //Landline1//
    if (fields.educationInfo.landline1 != "") {
      // if (!/^[0-9]\d{2,4}-\d{6,8}$/.test(fields.educationInfo.landline1)) {
      if (!/^[0-9]{8,11}$/.test(fields.educationInfo.landline1)) {
        formIsValid = false;
        errors.landline1 = "Please enter valid landline number";
      }
    }

    //Landline2//
    if (fields.educationInfo.landline2 != "") {
      if (!/^[0-9]{8,11}$/.test(fields.educationInfo.landline2)) {
        formIsValid = false;
        errors.landline2 = "Please enter valid landline number";
      }
    }

    for (let i = 0; i < this.state.requestTemplate.employmentInfo.length; i++) {
      //company Name//

      if (fields.employmentInfo[i].companyName != "") {
        if (
          !/^[a-zA-Z0-9 ]{2,40}$/.test(fields.employmentInfo[i].companyName)
        ) {
          formIsValid = false;

          if (i == 0) {
            errors.companyName =
              "Special characters are not allowed. Min 2 and Max 40 characters";
          }
          if (i == 1) {
            errors.exp1_companyName =
              "Special characters are not allowed. Min 2 and Max 40 characters";
          }
          if (i == 2) {
            errors.exp2_companyName =
              "Special characters are not allowed. Min 2 and Max 40 characters";
          }
          if (i == 3) {
            errors.exp3_companyName =
              "Special characters are not allowed. Min 2 and Max 40 characters";
          }
          if (i == 4) {
            errors.exp4_companyName =
              "Special characters are not allowed. Min 2 and Max 40 characters";
          }
        }
      }

      //employedPlace
      if (fields.employmentInfo[i].employedPlace != "") {
        if (
          !/^[a-zA-Z, ]{3,30}$/.test(fields.employmentInfo[i].employedPlace)
        ) {
          formIsValid = false;

          if (i == 0) {
            errors.employedPlace =
              "Special characters are not allowed and Max upto 30 characters are allowed";
          }
          if (i == 1) {
            errors.exp1_employedPlace =
              "Special characters are not allowed and Max upto 30 characters are allowed";
          }
          if (i == 2) {
            errors.exp2_employedPlace =
              "Special characters are not allowed and Max upto 30 characters are allowed";
          }
          if (i == 3) {
            errors.exp3_employedPlace =
              "Special characters are not allowed and Max upto 30 characters are allowed";
          }
          if (i == 4) {
            errors.exp4_employedPlace =
              "Special characters are not allowed and Max upto 30 characters are allowed";
          }
        }
      }

      if (fields.employmentInfo[i].companyAddress != "") {
        if (
          !/^[a-zA-Z0-9!\n-._ :/ , ]{3,150}$/.test(
            fields.employmentInfo[i].companyAddress
          )
        ) {
          formIsValid = false;

          if (i == 0) {
            errors.companyAddress =
              "Special characters are not allowed and Max upto 150 characters are allowed";
          }
          if (i == 1) {
            errors.exp1_companyAddress =
              "Special characters are not allowed and Max upto 150 characters are allowed";
          }
          if (i == 2) {
            errors.exp2_companyAddress =
              "Special characters are not allowed and Max upto 150 characters are allowed";
          }
          if (i == 3) {
            errors.exp3_companyAddress =
              "Special characters are not allowed and Max upto 150 characters are allowed";
          }
          if (i == 4) {
            errors.exp4_companyAddress =
              "Special characters are not allowed and Max upto 150 characters are allowed";
          }
        }
      }

      ////city

      if (fields.employmentInfo[i].city != "") {
        if (!/^[a-zA-Z, ]{3,30}$/.test(fields.employmentInfo[i].city)) {
          formIsValid = false;
          if (i == 0) {
            errors.cityemployment =
              "Special characters are not allowed and Max upto 30 characters are allowed";
          }
          if (i == 1) {
            errors.exp1_cityemployment =
              "Special characters are not allowed and Max upto 30 characters are allowed";
          }
          if (i == 2) {
            errors.exp2_cityemployment =
              "Special characters are not allowed and Max upto 30 characters are allowed";
          }
          if (i == 3) {
            errors.exp3_cityemployment =
              "Special characters are not allowed and Max upto 30 characters are allowed";
          }
          if (i == 4) {
            errors.exp4_cityemployment =
              "Special characters are not allowed and Max upto 30 characters are allowed";
          }
        }
      }

      //company Address//

      ////state
      if (fields.employmentInfo[i].state != "") {
        if (!/^[a-zA-Z, ]{3,30}$/.test(fields.employmentInfo[i].state)) {
          formIsValid = false;
          if (i == 0) {
            errors.stateemployment =
              "Special characters & numbers are not allowed";
          }
          if (i == 1) {
            errors.exp1_stateemployment =
              "Special characters & numbers are not allowed, Min 3 and Max 30 characters are allowed";
          }
          if (i == 2) {
            errors.exp2_stateemployment =
              "Special characters & numbers are not allowed, Min 3 and Max 30 characters are allowed";
          }
          if (i == 3) {
            errors.exp3_stateemployment =
              "Special characters & numbers are not allowed, Min 3 and Max 30 characters are allowed";
          }
          if (i == 4) {
            errors.exp4_stateemployment =
              "Special characters & numbers are not allowed, Min 3 and Max 30 characters are allowed";
          }
        }
      }

      ////pincode

      if (fields.employmentInfo[i].pincode != "") {
        if (!/^[0-9]{6}$/.test(fields.employmentInfo[i].pincode)) {
          formIsValid = false;

          if (i == 0) {
            errors.pincodeemployement = "Pincode must be 6 numbers";
          }
          if (i == 1) {
            errors.exp1_pincodeemployement = "Pincode must be 6 numbers";
          }
          if (i == 2) {
            errors.exp2_pincodeemployement = "Pincode must be 6 numbers";
          }
          if (i == 3) {
            errors.exp3_pincodeemployement = "Pincode must be 6 numbers";
          }
          if (i == 4) {
            errors.exp4_pincodeemployement = "Pincode must be 6 numbers";
          }
        }
      }

      //fromDate

      //designation
      if (fields.employmentInfo[i].designation != "") {
        if (!/^[a-zA-Z, ]{3,30}$/.test(fields.employmentInfo[i].designation)) {
          formIsValid = false;

          if (i == 0) {
            errors.designation = "Special characters & numbers are not allowed";
          }
          if (i == 1) {
            errors.exp1_designation =
              "Special characters & numbers are not allowed";
          }
          if (i == 2) {
            errors.exp2_designation =
              "Special characters & numbers are not allowed";
          }
          if (i == 3) {
            errors.exp3_designation =
              "Special characters & numbers are not allowed";
          }
          if (i == 4) {
            errors.exp4_designation =
              "Special characters & numbers are not allowed";
          }
        }
      }

      if (fields.employmentInfo[i].remuneration != "") {
        if (!/^[0-9]{6,8}$/.test(fields.employmentInfo[i].remuneration)) {
          formIsValid = false;
          if (i == 0) {
            errors.remuneration = "Enter 6 to 8 digits";
          }
          if (i == 1) {
            errors.exp1_remuneration = "Enter 6 to 8 digits";
          }
          if (i == 2) {
            errors.exp2_remuneration = "Enter 6 to 8 digits";
          }
          if (i == 3) {
            errors.exp3_remuneration = "Enter 6 to 8 digits";
          }
          if (i == 4) {
            errors.exp4_remuneration = "Enter 6 to 8 digits";
          }
        }
      }
      //supervisorEmailId

      if (fields.employmentInfo[i].supervisorEmailId != "") {
        if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
            fields.employmentInfo[i].supervisorEmailId
          )
        ) {
          formIsValid = false;
          if (i == 0) {
            errors.supervisorEmailId = "Please enter valid email";
          }
          if (i == 1) {
            errors.exp1_supervisorEmailId = "Please enter valid email";
          }
          if (i == 2) {
            errors.exp2_supervisorEmailId = "Please enter valid email";
          }
          if (i == 3) {
            errors.exp3_supervisorEmailId = "Please enter valid email";
          }
          if (i == 4) {
            errors.exp4_supervisorEmailId = "Please enter valid email";
          }
        }
      }

      //employeeCode

      if (fields.employmentInfo[i].employeeCode != "") {
        if (
          !/^[a-zA-Z0-9, ]{3,20}$/.test(fields.employmentInfo[i].employeeCode)
        ) {
          formIsValid = false;
          if (i == 0) {
            errors.employeeCode =
              "Special characters & numbers are not allowed. Min 3 and Max 20 characters are allowed";
          }
          if (i == 1) {
            errors.exp1_employeeCode =
              "Special characters & numbers are not allowed. Min 3 and Max 20 characters are allowed";
          }
          if (i == 2) {
            errors.exp2_employeeCode =
              "Special characters & numbers are not allowed. Min 3 and Max 20 characters are allowed";
          }
          if (i == 3) {
            errors.exp3_employeeCode =
              "Special characters & numbers are not allowed. Min 3 and Max 20 characters are allowed";
          }
          if (i == 4) {
            errors.exp4_employeeCode =
              "Special characters & numbers are not allowed. Min 3 and Max 20 characters are allowed";
          }
        }
      }

      //employmentNature
      if (fields.employmentInfo[i].employmentNature != "") {
        if (
          !/^[a-zA-Z, ]{3,30}$/.test(fields.employmentInfo[i].employmentNature)
        ) {
          formIsValid = false;
          if (i == 0) {
            errors.employmentNature =
              "Special characters & numbers are not allowed. Min 3 and Max 30 characters are allowed";
          }
          if (i == 1) {
            errors.exp1_employmentNature =
              "Special characters & numbers are not allowed. Min 3 and Max 30 characters are allowed";
          }
          if (i == 2) {
            errors.exp2_employmentNature =
              "Special characters & numbers are not allowed. Min 3 and Max 30 characters are allowed";
          }
          if (i == 3) {
            errors.exp3_employmentNature =
              "Special characters & numbers are not allowed. Min 3 and Max 30 characters are allowed";
          }
          if (i == 4) {
            errors.exp4_employmentNature =
              "Special characters & numbers are not allowed. Min 3 and Max 30 characters are allowed";
          }
        }
      }

      //supervisorName
      if (fields.employmentInfo[i].supervisorName != "") {
        if (
          !/^[a-zA-Z, ]{3,30}$/.test(fields.employmentInfo[i].supervisorName)
        ) {
          formIsValid = false;
          if (i == 0) {
            errors.supervisorName =
              "Special characters & numbers are not allowed. Min 3 and Max 30 characters are allowed";
          }
          if (i == 1) {
            errors.exp1_supervisorName =
              "Special characters & numbers are not allowed. Min 3 and Max 30 characters are allowed";
          }
          if (i == 2) {
            errors.exp2_supervisorName =
              "Special characters & numbers are not allowed. Min 3 and Max 30 characters are allowed";
          }
          if (i == 3) {
            errors.exp3_supervisorName =
              "Special characters & numbers are not allowed. Min 3 and Max 30 characters are allowed";
          }
          if (i == 4) {
            errors.exp4_supervisorName =
              "Special characters & numbers are not allowed. Min 3 and Max 30 characters are allowed. Min 3 and Max 30 characters are allowed";
          }
        }
      }

      //supervisorDesignation

      if (fields.employmentInfo[i].supervisorDesignation != "") {
        if (
          !/^[a-zA-Z, ]{3,30}$/.test(
            fields.employmentInfo[i].supervisorDesignation
          )
        ) {
          formIsValid = false;
          if (i == 0) {
            errors.supervisorDesignation =
              "Special characters & numbers are not allowed. Min 3 and Max 30 characters are allowed";
          }
          if (i == 1) {
            errors.exp1_supervisorDesignation =
              "Special characters & numbers are not allowed. Min 3 and Max 30 characters are allowed";
          }
          if (i == 2) {
            errors.exp2_supervisorDesignation =
              "Special characters & numbers are not allowed. Min 3 and Max 30 characters are allowed";
          }
          if (i == 3) {
            errors.exp3_supervisorDesignation =
              "Special characters & numbers are not allowed. Min 3 and Max 30 characters are allowed";
          }
          if (i == 4) {
            errors.exp4_supervisorDesignation =
              "Special characters & numbers are not allowed. Min 3 and Max 30 characters are allowed";
          }
        }
      }

      //leavingReason

      if (fields.employmentInfo[i].leavingReason != "") {
        if (
          !/^[a-zA-Z0-9!\n-._ :/ , ]{10,300}$/.test(
            fields.employmentInfo[i].leavingReason
          )
        ) {
          formIsValid = false;
          if (i == 0) {
            errors.leavingReason =
              "Special characters & numbers are not allowed. Min 10 and Max 100 characters are allowed";
          }
          if (i == 1) {
            errors.exp1_leavingReason =
              "Special characters & numbers are not allowed. Min 10 and Max 100 characters are allowed";
          }
          if (i == 2) {
            errors.exp2_leavingReason =
              "Special characters & numbers are not allowed. Min 10 and Max 100 characters are allowed";
          }
          if (i == 3) {
            errors.exp3_leavingReason =
              "Special characters & numbers are not allowed. Min 10 and Max 100 characters are allowed";
          }
          if (i == 4) {
            errors.exp4_leavingReason =
              "Special characters & numbers are not allowed. Min 10 and Max 100 characters are allowed";
          }
        }
      }
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  componentDidMount() {
    let date = new Date();
    let year = date.getFullYear();
    let month =
      date.getMonth() < 10 ? date.getMonth() + 1 : "0" + date.getMonth();
    let day = date.getDate() > 10 ? date.getDate() : date.getDate();

    let currentdate = year + "-" + month + "-" + day;
    this.setState({ ...this.state, currentdate: currentdate });
    // if (localStorage.getItem("empId") !== null) {
    //   this.props.history.push("/UploadDocuments");
    // }

    window.onunload = () => {
      window.localStorage.removeItem("experience-years");
      window.localStorage.removeItem("experience-in_2_years");
    };
  }

  contactSubmit(e) {
    e.preventDefault();
    this.props.dispatch(LinearProgressSpinner(true));
    if (this.RequiredValidation() && this.handleValidation()) {
      this.setState({ isRender: false });
      this.props.dispatch(spinner(true));
      let requestTemplate = this.state.requestTemplate;
      requestTemplate.email = localStorage.getItem("email");
      requestTemplate.contactInfo.sort((a, b) => a.index - b.index);
      const request = {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(this.state.requestTemplate),
      };
      fetch("https://capv.live/portalprod/saveEmployee", request)
        .then((response) => response.json())
        .then((response) => {
          this.props.dispatch(spinner(false));
          this.props.dispatch(LinearProgressSpinner(false));
          this.setState({ linearProgress: false });
          this.props.dispatch(uploadid(response.result.id));
          localStorage.setItem("empId", response.result.id);
          this.props.history.push("/UploadDocuments");
        });
    } else {
      this.props.dispatch(spinner(false));
      this.props.dispatch(LinearProgressSpinner(false));
      this.setState({ isRender: true });
    }
  }

  experienceAdd = (e) => {
    e.preventDefault();
    this.setState({
      expirienceInYears: this.state.expirienceInYears + 1,
    });

    if (this.state.requestTemplate.employmentInfo.length < 5) {
      let employmentInfo_CreateNew = this.state.requestTemplate.employmentInfo;
      employmentInfo_CreateNew.push({
        companyName: "",
        employedPlace: "",
        companyAddress: "",
        city: "",
        state: "",
        pincode: "",
        fromDate: "",
        toDate: "",
        designation: "",
        remuneration: "",
        employeeCode: "",
        employmentNature: "",
        supervisorName: "",
        supervisorDesignation: "",
        supervisorEmailId: "",
        leavingReason: "",
      });
    }

    localStorage.setItem("experience-years", this.state.expirienceInYears + 1);
  };

  RemoveExperience = (index, e) => {
    e.preventDefault();
    this.setState({
      expirienceInYears: this.state.expirienceInYears - 1,
    });
    let experienceDelete = this.state.requestTemplate.employmentInfo;
    experienceDelete.splice(index, 1);
    localStorage.setItem("experience-years", this.state.expirienceInYears - 1);
  };

  selectedinfo = (e) => {
    e.preventDefault();
    this.props.dispatch(isSelectedValue(e.target.value));

    if (e.target.value == "Expirience") {
      this.props.dispatch(isexpirienceThere(true));
      localStorage.setItem("experience-years", this.state.expirienceInYears);
      let employmentInfo_CreateNew = this.state.requestTemplate.employmentInfo;
      employmentInfo_CreateNew.push({
        companyName: "",
        employedPlace: "",
        companyAddress: "",
        city: "",
        state: "",
        pincode: "",
        fromDate: "",
        toDate: "",
        designation: "",
        remuneration: "",
        employeeCode: "",
        employmentNature: "",
        supervisorName: "",
        supervisorDesignation: "",
        supervisorEmailId: "",
        leavingReason: "",
      });
    }
    if (e.target.value == "Fresher") {
      this.props.dispatch(isexpirienceThere(false));
      let empinfo = this.state.requestTemplate.employmentInfo;
      empinfo.length = 0;
      this.setState({ employmentInfo: empinfo });
      localStorage.removeItem("experience-years");
    }
  };
  uploadFiles = () => {
    this.childone.current.testFunction();
  };
  checkedHandler = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  checkedAddress = (e) => {
    let addressTemplate = this.state.requestTemplate;

    this.setState({ [e.target.name]: !this.state.checked1 });

    if (this.state.checked1 === false) {
      addressTemplate.contactInfo[1] = addressTemplate.contactInfo[0];
      this.setState({
        requestTemplate: addressTemplate,
      });
    } else {
      addressTemplate.contactInfo[1] = this.state.address;
      this.setState({
        requestTemplate: addressTemplate,
      });
    }
  };

  contactHandler = (field, e, id) => {
    let fields = this.state.requestTemplate;

    fields.contactInfo[id][field] = e.target.value;

    this.setState({ requestTemplate: fields, isRender: false });
    this.handleValidation();
  };

  personalHandler = (field, e) => {
    let contactinfo = this.state.requestTemplate;
    contactinfo[field] = e.target.value;
    this.setState({ requestTemplate: contactinfo, isRender: false });
    this.handleValidation();
  };

  educationHandler = (field, e) => {
    let contactinfooo = this.state.requestTemplate.educationInfo;
    contactinfooo[field] = e.target.value;
    this.setState({ fields: contactinfooo, isRender: false });
    this.handleValidation();
  };

  employmentHandler = (field, e, index) => {
    let employmentsave = this.state.requestTemplate;
    employmentsave.employmentInfo[index][field] = e.target.value;
    this.setState({ requestTemplate: employmentsave, isRender: false });
    this.handleValidation();
  };

  render() {
    this.state.requestTemplate.contactInfo.sort((a, b) => a.index - b.index);
    const {
      place,
      companyName1,
      selected,
      date,
      address1,
      firstName,
      lastName,
      companyName,
      fromDate,
      toDate,
      cityemployment,
      stateemployment,
      designation,
      remuneration,
      employeeCode,
      pincodeemployement,
      employmentNature,
      supervisorName,
      supervisorDesignation,
      supervisorEmailId,
      leavingReason,
      employedPlace,
      companyAddress,
      otherName,
      gender,
      dob,
      bloodGroup,
      checked,
      passportNumber,
      dateOfIssue,
      dateOfExpiry,
      aadharNumber,
      panCardNumber,
      address,
      city,
      contactInfopincode,
      state,
      country,
      cityeducatio,
      nationality,
      phone,
      email,
      emergencyContact,
      alternateContact,
      degree,
      collegeName,
      universityName,
      subject,
      courseStartDate,
      courseEndDate,
      courseType,
      addressOfInstitution,
      citty,
      educationInfopinCode,
      landline1,
      landline2,
      alternateContact1,
      contactInfopincode1,
      country1,
      state1,
      nationality1,
      phone1,
      email1,
      disableEditForm,
      emergencyContact1,
      exp1_companyName,
      exp2_companyName,
      exp3_companyName,
      exp4_companyName,
      exp1_employedPlace,
      exp2_employedPlace,
      exp3_employedPlace,
      exp4_employedPlace,
      exp1_companyAddress,
      exp2_companyAddress,
      exp3_companyAddress,
      exp4_companyAddress,
      exp1_cityemployment,
      exp2_cityemployment,
      exp3_cityemployment,
      exp4_cityemployment,
      exp1_stateemployment,
      exp2_stateemployment,
      exp3_stateemployment,
      exp4_stateemployment,
      exp1_pincodeemployement,
      exp2_pincodeemployement,
      exp3_pincodeemployement,
      exp4_pincodeemployement,
      exp1_designation,
      exp2_designation,
      exp3_designation,
      exp4_designation,
      exp1_remuneration,
      exp2_remuneration,
      exp3_remuneration,
      exp4_remuneration,
      exp1_supervisorEmailId,
      exp2_supervisorEmailId,
      exp3_supervisorEmailId,
      exp4_supervisorEmailId,
      exp1_employeeCode,
      exp2_employeeCode,
      exp3_employeeCode,
      exp4_employeeCode,
      exp1_employmentNature,
      exp2_employmentNature,
      exp3_employmentNature,
      exp4_employmentNature,
      exp1_supervisorName,
      exp2_supervisorName,
      exp3_supervisorName,
      exp4_supervisorName,
      exp1_supervisorDesignation,
      exp2_supervisorDesignation,
      exp3_supervisorDesignation,
      exp4_supervisorDesignation,
      exp1_leavingReason,
      exp2_leavingReason,
      exp3_leavingReason,
      exp4_leavingReason,
      exp1_fromDate,
      exp2_fromDate,
      exp3_fromDate,
      exp4_fromDate,
      exp1_toDate,
      exp2_toDate,
      exp3_toDate,
      exp4_toDate,
    } = this.state.errors;
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <div>
          <Header />
          <div className="Instructions">
            <h6 className="Instructions_Form">
              <strong className="strong">Instructions :</strong> Please provide
              all the information requested in this form. Incomplete Candidate
              Declaration Forms (CDFs) will be returned. All supporting
              documents must accompany this form. Photocopies must be legible.
              We have included a checklist to assist you to complete your
              application comprehensively
            </h6>
          </div>
          <>
            <form
              id="myForm"
              onSubmit={this.contactSubmit.bind(this)}
              autocomplete="off"
            >
              <div className="personal_Info">
                <h6 className="Form55">1. Personal Information</h6>
                <div className="personal_Info_fields">
                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>First Name:</span>
                    </div>
                    <input
                      className="Input_fields"
                      type="text"
                      name="first Name"
                      placeholder="First Name"
                      value={this.state.requestTemplate.firstName}
                      onChange={(e) => {
                        this.personalHandler("firstName", e);
                      }}
                    ></input>
                    <div className="personal_Info_Error">
                      {firstName && <span className="Error">{firstName}</span>}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {firstName && <span className="Error">{firstName}</span>}
                  </div>

                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>Middle Name (optional):</span>
                    </div>
                    <div className="personal_Info_input">
                      <input
                        className="Input_fields"
                        type="text"
                        name="first Name"
                        placeholder="Middle Name"
                        value={this.state.requestTemplate.lastName}
                        onChange={(e) => {
                          this.personalHandler("lastName", e);
                        }}
                      ></input>
                    </div>
                    <div className="personal_Info_Error">
                      {lastName && <span className="Error">{lastName}</span>}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {lastName && <span className="Error">{lastName}</span>}
                  </div>

                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>Last Name:</span>
                    </div>
                    <div className="personal_Info_input">
                      <input
                        className=" Input_fields"
                        type="text"
                        name="otherName"
                        placeholder="Last Name"
                        value={this.state.requestTemplate.otherName}
                        onChange={(e) => this.personalHandler("otherName", e)}
                      ></input>
                    </div>
                    <div className="personal_Info_Error">
                      {otherName && <span className="Error">{otherName}</span>}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {otherName && <span className="Error">{otherName}</span>}
                  </div>

                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>Gender:</span>
                    </div>
                    <div className="personal_Info_input">
                      <select
                        className="Input_Select"
                        name="gender"
                        type="text"
                        value={this.state.requestTemplate.gender}
                        onChange={(e) => {
                          this.personalHandler("gender", e);
                        }}
                      >
                        <option value="" disabled>
                          Select Gender
                        </option>
                        <option value="Male">Male</option>
                        <option value="FeMale">Female</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    <div className="personal_Info_Error">
                      {gender && <span className="Error">{gender}</span>}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {gender && <span className="Error">{gender}</span>}
                  </div>

                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>Date Of Birth:</span>
                    </div>
                    <div className="personal_Info_input">
                      <input
                        className="Input_date"
                        name="Dob"
                        max={this.state.currentdate}
                        type="date"
                        value={this.state.requestTemplate.dob}
                        min="1930-01-01"
                        // max="2000-12-31"
                        onChange={(e) => {
                          this.personalHandler("dob", e);
                        }}
                        placeholder="DD/MM/YYYY"
                      ></input>
                    </div>
                    <div className="personal_Info_Error">
                      {dob && <span className="Error">{dob}</span>}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {dob && <span className="Error">{dob}</span>}
                  </div>

                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>BloodGroup:</span>
                    </div>
                    <div className="personal_Info_input">
                      <input
                        className="Input_fields"
                        name="bloodGroup"
                        type="text"
                        value={this.state.requestTemplate.BloodGroup}
                        onChange={(e) => {
                          this.personalHandler("bloodGroup", e);
                        }}
                        placeholder="ex:A+"
                        style={{ textTransform: "uppercase" }}
                      ></input>
                    </div>
                    <div className="personal_Info_Error">
                      {bloodGroup && (
                        <span className="Error">{bloodGroup}</span>
                      )}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {bloodGroup && <span className="Error">{bloodGroup}</span>}
                  </div>

                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>Passport (optional):</span>
                    </div>
                    <div className="personal_Info_input">
                      <input
                        className="Input_fields"
                        type="text"
                        name="passportNumber"
                        style={{ textTransform: "uppercase" }}
                        value={this.state.requestTemplate.passportNumber}
                        onChange={(e) => {
                          this.personalHandler("passportNumber", e);
                        }}
                        placeholder="Passport"
                      ></input>
                    </div>
                    <div className="personal_Info_Error">
                      {passportNumber && (
                        <span className="Error">{passportNumber}</span>
                      )}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {passportNumber && (
                      <span className="Error">{passportNumber}</span>
                    )}
                  </div>

                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>Date Of Issue (optional):</span>
                    </div>
                    <div className="personal_Info_input">
                      <input
                        className="Input_date"
                        type="date"
                        min="1930-01-01"
                        max={this.state.currentdate}
                        name="dateOfIssue"
                        value={this.state.requestTemplate.dateOfIssue}
                        onChange={(e) => {
                          this.personalHandler("dateOfIssue", e);
                        }}
                        placeholder="DD/MM/YYYY"
                      ></input>
                    </div>
                    <div className="personal_Info_Error">
                      {dateOfIssue && (
                        <span className="Error">{dateOfIssue}</span>
                      )}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {dateOfIssue && (
                      <span className="Error">{dateOfIssue}</span>
                    )}
                  </div>

                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>Date Of Expiry (optional):</span>
                    </div>
                    <div className="personal_Info_input">
                      <input
                        className="Input_date"
                        type="date"
                        min={this.state.requestTemplate.dateOfIssue}
                        max="2038-12-31"
                        name="dateOfExpiry"
                        value={this.state.requestTemplate.dateOfExpiry}
                        onChange={(e) => {
                          this.personalHandler("dateOfExpiry", e);
                        }}
                        placeholder="DD/MM/YYYY"
                      ></input>
                    </div>
                    <div className="personal_Info_Error">
                      {dateOfExpiry && (
                        <span className="Error">{dateOfExpiry}</span>
                      )}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {dateOfExpiry && (
                      <span className="Error">{dateOfExpiry}</span>
                    )}
                  </div>

                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>PAN Number / Social Security Number:</span>
                    </div>
                    <div className="personal_Info_input">
                      <input
                        className="Input_fields"
                        type="text"
                        name="PanCardNumber"
                        value={this.state.requestTemplate.panCardNumber}
                        onChange={(e) => {
                          this.personalHandler("panCardNumber", e);
                        }}
                        placeholder="Number"
                        style={{ textTransform: "uppercase" }}
                      ></input>
                    </div>
                    <div className="personal_Info_Error">
                      {panCardNumber && (
                        <span className="Error">{panCardNumber}</span>
                      )}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {panCardNumber && (
                      <span className="Error">{panCardNumber}</span>
                    )}
                  </div>

                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>AADHAR Number:</span>
                    </div>
                    <div className="personal_Info_input">
                      <input
                        className="Input_fields"
                        type="text"
                        name="aadharNumber"
                        value={this.state.requestTemplate.aadharNumber}
                        onChange={(e) => {
                          this.personalHandler("aadharNumber", e);
                        }}
                        placeholder="AADHAR Number"
                      ></input>
                    </div>
                    <div className="personal_Info_Error">
                      {aadharNumber && (
                        <span className="Error">{aadharNumber}</span>
                      )}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {aadharNumber && (
                      <span className="Error">{aadharNumber}</span>
                    )}
                  </div>

                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>Email:</span>
                    </div>
                    <div className="personal_Info_input">
                      <input
                        className="Input_fields"
                        disabled
                        value={localStorage.getItem("email")}
                        placeholder="Ex: Hello@gmail.com"
                      ></input>
                    </div>
                    <div className="personal_Info_Error">
                      {email && <span className="Error">{email}</span>}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {email && <span className="Error">{email}</span>}
                  </div>
                </div>
              </div>

              <div className="personal_Info">
                <h1 className="Form55" style={{ marginTop: 30 }}>
                  2.Contact Information
                </h1>
                {this.state.requestTemplate.contactInfo
                  .sort((a, b) => a.index - b.index)
                  .map((g, index) => (
                    <div>
                      <div key={index} className="personal_Info_fields">
                        <div className="personal_Info_label_input">
                          {index === 0 && (
                            <div className="personal_Info_label">
                              <span>Present Address:</span>
                            </div>
                          )}
                          {index === 1 && (
                            <div className="personal_Info_label">
                              <span>Permanent Address:</span>
                            </div>
                          )}
                          <div className="personal_Info_input">
                            <InputTextarea
                              rows={2}
                              cols={25}
                              autoResize={true}
                              className="Input_fields_textArea"
                              disabled={index == 1 && this.state.checked1}
                              type="text"
                              name="address"
                              value={g.address}
                              onChange={(e) => {
                                this.contactHandler("address", e, index);
                              }}
                              placeholder="Address"
                              autocomplete="true"
                            />
                          </div>
                          <div className="personal_Info_Error">
                            {index === 0 && (
                              <span className="Error">{address}</span>
                            )}
                            {index === 1 && (
                              <span className="Error">{address1}</span>
                            )}
                          </div>
                        </div>
                        <div className="personal_Info_Error_Mobile">
                          {index === 0 && (
                            <span className="Error">{address}</span>
                          )}
                          {index === 1 && (
                            <span className="Error">{address1}</span>
                          )}
                        </div>
                        <div className="personal_Info_label_input">
                          <div className="personal_Info_label">
                            <span>City:</span>
                          </div>
                          <div className="personal_Info_input">
                            <input
                              className="Input_fields"
                              type="text"
                              name="city"
                              disabled={index == 1 && this.state.checked1}
                              value={g.city}
                              onChange={(e) => {
                                this.contactHandler("city", e, index);
                              }}
                              placeholder="City"
                              autocomplete="true"
                            ></input>
                          </div>
                          <div className="personal_Info_Error">
                            {index === 0 && (
                              <span className="Error">{city}</span>
                            )}
                            {index === 1 && (
                              <span className="Error">{citty}</span>
                            )}
                          </div>
                        </div>
                        <div className="personal_Info_Error_Mobile">
                          {index === 0 && <span className="Error">{city}</span>}
                          {index === 1 && (
                            <span className="Error">{citty}</span>
                          )}
                        </div>
                        <div className="personal_Info_label_input">
                          <div className="personal_Info_label">
                            <span>Pincode:</span>
                          </div>
                          <div className="personal_Info_input">
                            <input
                              className="Input_fields"
                              type="text"
                              name="pincode"
                              disabled={index == 1 && this.state.checked1}
                              value={g.pincode}
                              onChange={(e) => {
                                this.contactHandler("pincode", e, index);
                              }}
                              placeholder="Pincode"
                              autocomplete="true"
                            ></input>
                          </div>
                          <div className="personal_Info_Error">
                            {index === 0 && (
                              <span className="Error">
                                {contactInfopincode}
                              </span>
                            )}
                            {index === 1 && (
                              <span className="Error">
                                {contactInfopincode1}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="personal_Info_Error_Mobile">
                          {index === 0 && (
                            <span className="Error">{contactInfopincode}</span>
                          )}
                          {index === 1 && (
                            <span className="Error">{contactInfopincode1}</span>
                          )}
                        </div>
                        <div className="personal_Info_label_input">
                          <div className="personal_Info_label">
                            <span>State Of Residence:</span>
                          </div>
                          <div className="personal_Info_input">
                            <select
                              className="Input_Select"
                              type=""
                              disabled={index == 1 && this.state.checked1}
                              value={g.state}
                              onChange={(e) => {
                                this.contactHandler("state", e, index);
                              }}
                              required
                            >
                              <option value="" disabled>
                                Select State
                              </option>
                              <option value="Andhra Pradesh">
                                Andhra Pradesh
                              </option>
                              <option value="Andaman and Nicobar Islands">
                                Andaman and Nicobar Islands
                              </option>
                              <option value="Arunachal Pradesh">
                                Arunachal Pradesh
                              </option>
                              <option value="Assam">Assam</option>
                              <option value="Bihar">Bihar</option>
                              <option value="Chandigarh">Chandigarh</option>
                              <option value="Chhattisgarh">Chhattisgarh</option>
                              <option value="Dadar and Nagar Haveli">
                                Dadar and Nagar Haveli
                              </option>
                              <option value="Daman and Diu">
                                Daman and Diu
                              </option>
                              <option value="Delhi">Delhi</option>
                              <option value="Lakshadweep">Lakshadweep</option>
                              <option value="Puducherry">Puducherry</option>
                              <option value="Goa">Goa</option>
                              <option value="Gujarat">Gujarat</option>
                              <option value="Haryana">Haryana</option>
                              <option value="Himachal Pradesh">
                                Himachal Pradesh
                              </option>
                              <option value="Jammu and Kashmir">
                                Jammu and Kashmir
                              </option>
                              <option value="Jharkhand">Jharkhand</option>
                              <option value="Karnataka">Karnataka</option>
                              <option value="Kerala">Kerala</option>
                              <option value="Madhya Pradesh">
                                Madhya Pradesh
                              </option>
                              <option value="Maharashtra">Maharashtra</option>
                              <option value="Manipur">Manipur</option>
                              <option value="Meghalaya">Meghalaya</option>
                              <option value="Mizoram">Mizoram</option>
                              <option value="Nagaland">Nagaland</option>
                              <option value="Odisha">Odisha</option>
                              <option value="Punjab">Punjab</option>
                              <option value="Rajasthan">Rajasthan</option>
                              <option value="Sikkim">Sikkim</option>
                              <option value="Tamil Nadu">Tamil Nadu</option>
                              <option value="Telangana">Telangana</option>
                              <option value="Tripura">Tripura</option>
                              <option value="Uttar Pradesh">
                                Uttar Pradesh
                              </option>
                              <option value="Uttarakhand">Uttarakhand</option>
                              <option value="West Bengal">West Bengal</option>
                            </select>
                          </div>
                          <div className="personal_Info_Error">
                            {index === 0 && (
                              <span className="Error">{state}</span>
                            )}
                            {index === 1 && (
                              <span className="Error">{state1}</span>
                            )}
                          </div>
                        </div>
                        <div className="personal_Info_Error_Mobile">
                          {index === 0 && (
                            <span className="Error">{state}</span>
                          )}
                          {index === 1 && (
                            <span className="Error">{state1}</span>
                          )}
                        </div>
                        <div className="personal_Info_label_input">
                          <div className="personal_Info_label">
                            <span>Country Of Residence:</span>
                          </div>
                          <div className="personal_Info_input">
                            <select
                              className="Input_Select"
                              type="text"
                              disabled={index == 1 && this.state.checked1}
                              name="state"
                              value={g.country}
                              onChange={(e) => {
                                this.contactHandler("country", e, index);
                              }}
                              placeholder="Country Living"
                              autocomplete="true"
                              required
                            >
                              <option value="" disabled>
                                {" "}
                                Select Country{" "}
                              </option>
                              <option selected="" value="United States">
                                United States
                              </option>
                              <option value="Canada">Canada</option>
                              <option value="Albania">Albania</option>
                              <option value="Algeria">Algeria</option>
                              <option value="American Samoa">
                                American Samoa
                              </option>
                              <option value="Andorra">Andorra</option>
                              <option value="Angola">Angola</option>
                              <option value="Anguilla">Anguilla</option>
                              <option value="Antarctica">Antarctica</option>
                              <option value="Antigua">Antigua</option>
                              <option value="Argentina">Argentina</option>
                              <option value="Armenia">Armenia</option>
                              <option value="Aruba">Aruba</option>
                              <option value="Australia">Australia</option>
                              <option value="Austria">Austria</option>
                              <option value="Azerbaijani">Azerbaijani</option>
                              <option value="Bahamas">Bahamas</option>
                              <option value="Bahrain">Bahrain</option>
                              <option value="Bangladesh">Bangladesh</option>
                              <option value="Barbados">Barbados</option>
                              <option value="Belarus">Belarus</option>
                              <option value="Netherlands">Belgium</option>
                              <option value="Belize">Belize</option>
                              <option value="Benin">Benin</option>
                              <option value="Bermuda">Bermuda</option>
                              <option value="Bhutan">Bhutan</option>
                              <option value="Bolivia">Bolivia</option>
                              <option value="Bosnia-Hercegovina">
                                Bosnia-Hercegovina
                              </option>
                              <option value="Botswana">Botswana</option>
                              <option value="Bouvet Island">
                                Bouvet Island
                              </option>
                              <option value="Brazil">Brazil</option>
                              <option value="British IOT">British IOT</option>
                              <option value="Brunei Darussalam">
                                Brunei Darussalam
                              </option>
                              <option value="Bulgaria">Bulgaria</option>
                              <option value="Burkina Faso">Burkina Faso</option>
                              <option value="Burundi">Burundi</option>
                              <option value="Cambodia">Cambodia</option>
                              <option value="Cameroon">Cameroon</option>
                              <option value="Cape Verde">Cape Verde</option>
                              <option value="Cayman Islands">
                                Cayman Islands
                              </option>
                              <option value="Central African Rep">
                                Central African Rep
                              </option>
                              <option value="Chad">Chad</option>
                              <option value="Chile">Chile</option>
                              <option value="China">China</option>
                              <option value="Christmas Island">
                                Christmas Island
                              </option>
                              <option value="Cocos Islands">
                                Cocos Islands
                              </option>
                              <option value="Colombia">Colombia</option>
                              <option value="Comoros">Comoros</option>
                              <option value="Congo">Congo</option>
                              <option value="Cook Islands">Cook Islands</option>
                              <option value="Costa Rica">Costa Rica</option>
                              <option value="Croatia">Croatia</option>
                              <option value="Cyprus">Cyprus</option>
                              <option value="Czech Republic">
                                Czech Republic
                              </option>
                              <option value="Denmark">Denmark</option>
                              <option value="Djibouti">Djibouti</option>
                              <option value="Dominica">Dominica</option>
                              <option value="Dominican Republic">
                                Dominican Republic
                              </option>
                              <option value="East Timor">East Timor</option>
                              <option value="Ecuador">Ecuador</option>
                              <option value="Egypt">Egypt</option>
                              <option value="El Salvador">El Salvador</option>
                              <option value="Equatorial Guinea">
                                Equatorial Guinea
                              </option>
                              <option value="Eritrea">Eritrea</option>
                              <option value="Estonia">Estonia</option>
                              <option value="Ethiopia">Ethiopia</option>
                              <option value="Faeroe Islands">
                                Faeroe Islands
                              </option>
                              <option value="Falkland Islands">
                                Falkland Islands
                              </option>
                              <option value="Fiji">Fiji</option>
                              <option value="Finland">Finland</option>
                              <option value="France">France</option>
                              <option value="French Guiana">
                                French Guiana
                              </option>
                              <option value="French Polynesia">
                                French Polynesia
                              </option>
                              <option value="French Southern">
                                French Southern
                              </option>
                              <option value="French Southern Ter">
                                French Southern Ter
                              </option>
                              <option value="Gabon">Gabon</option>
                              <option value="Gambia">Gambia</option>
                              <option value="Georgia">Georgia</option>
                              <option value="Germany">Germany</option>
                              <option value="Ghana">Ghana</option>
                              <option value="Gibraltar">Gibraltar</option>
                              <option value="Greece">Greece</option>
                              <option value="Greenland">Greenland</option>
                              <option value="Grenada">Grenada</option>
                              <option value="Guadeloupe">Guadeloupe</option>
                              <option value="Guam">Guam</option>
                              <option value="Guatemala">Guatemala</option>
                              <option value="Guinea">Guinea</option>
                              <option value="Guinea-Bissau">
                                Guinea-Bissau
                              </option>
                              <option value="Guyana">Guyana</option>
                              <option value="Haiti">Haiti</option>
                              <option value="Heard">Heard</option>
                              <option value="Honduras">Honduras</option>
                              <option value="Hong Kong">Hong Kong</option>
                              <option value="Hungary">Hungary</option>
                              <option value="Iceland">Iceland</option>
                              <option value="India">India</option>
                              <option value="Indonesia">Indonesia</option>
                              <option value="Ireland">Ireland</option>
                              <option value="Israel">Israel</option>
                              <option value="Italy">Italy</option>
                              <option value="Ivory Coast">Ivory Coast</option>
                              <option value="Jamaica">Jamaica</option>
                              <option value="Japan">Japan</option>
                              <option value="Jordan">Jordan</option>
                              <option value="Kazakhstan">Kazakhstan</option>
                              <option value="Kenya">Kenya</option>
                              <option value="Kiribati">Kiribati</option>
                              <option value="Kuwait">Kuwait</option>
                              <option value="Kyrgyzstan">Kyrgyzstan</option>
                              <option value="Laos">Laos</option>
                              <option value="Latvia">Latvia</option>
                              <option value="Lebanon">Lebanon</option>
                              <option value="Lesotho">Lesotho</option>
                              <option value="Liberia">Liberia</option>
                              <option value="Liechtenstein">
                                Liechtenstein
                              </option>
                              <option value="Lithuania">Lithuania</option>
                              <option value="Luxembourg">Luxembourg</option>
                              <option value="Macau">Macau</option>
                              <option value="Macedonia">Macedonia</option>
                              <option value="Madagascar">Madagascar</option>
                              <option value="Malawi">Malawi</option>
                              <option value="Malaysia">Malaysia</option>
                              <option value="Maldives">Maldives</option>
                              <option value="Mali">Mali</option>
                              <option value="Malta">Malta</option>
                              <option value="Marshall Islands">
                                Marshall Islands
                              </option>
                              <option value="Martinique">Martinique</option>
                              <option value="Mauritania">Mauritania</option>
                              <option value="Mauritius">Mauritius</option>
                              <option value="Mayotte">Mayotte</option>
                              <option value="Mexico">Mexico</option>
                              <option value="Micronesia">Micronesia</option>
                              <option value="MNP">MNP</option>
                              <option value="Moldova">Moldova</option>
                              <option value="Monaco">Monaco</option>
                              <option value="Mongolia">Mongolia</option>
                              <option value="Montserrat">Montserrat</option>
                              <option value="Morocco">Morocco</option>
                              <option value="Mozambique">Mozambique</option>
                              <option value="Myanmar">Myanmar</option>
                              <option value="Namibia">Namibia</option>
                              <option value="Nauru">Nauru</option>
                              <option value="Nepal">Nepal</option>
                              <option value="NER">NER</option>
                              <option value="Netherlands">Netherlands</option>
                              <option value="Neutral Zone">Neutral Zone</option>
                              <option value="New Caledonia">
                                New Caledonia
                              </option>
                              <option value="New Zealand">New Zealand</option>
                              <option value="Nicaragua">Nicaragua</option>
                              <option value="Nigeria">Nigeria</option>
                              <option value="Niue">Niue</option>
                              <option value="Norfolk Island">
                                Norfolk Island
                              </option>
                              <option value="North Korea">North Korea</option>
                              <option value="Norway">Norway</option>
                              <option value="Oman">Oman</option>
                              <option value="Pakistan">Pakistan</option>
                              <option value="Palau">Palau</option>
                              <option value="Panama">Panama</option>
                              <option value="Papua New Guinea">
                                Papua New Guinea
                              </option>
                              <option value="Paraguay">Paraguay</option>
                              <option value="Peru">Peru</option>
                              <option value="Philippines">Philippines</option>
                              <option value="Pitcairn">Pitcairn</option>
                              <option value="Poland">Poland</option>
                              <option value="Portugal">Portugal</option>
                              <option value="Puerto Rico">Puerto Rico</option>
                              <option value="Qatar">Qatar</option>
                              <option value="Reunion">Reunion</option>
                              <option value="Romania">Romania</option>
                              <option value="Russia">Russia</option>
                              <option value="Rwanda">Rwanda</option>
                              <option value="Saint Helena">Saint Helena</option>
                              <option value="Saint Lucia">Saint Lucia</option>
                              <option value="Saint Pierre">Saint Pierre</option>
                              <option value="Saint Vincent">
                                Saint Vincent
                              </option>
                              <option value="Samoa">Samoa</option>
                              <option value="San Marino">San Marino</option>
                              <option value="Saudi Arabia">Saudi Arabia</option>
                              <option value="Scotland">Scotland</option>
                              <option value="Senegal">Senegal</option>
                              <option value="Seychelles">Seychelles</option>
                              <option value="Sierra Leone">Sierra Leone</option>
                              <option value="Singapore">Singapore</option>
                              <option value="Slovak Republic">
                                Slovak Republic
                              </option>
                              <option value="Slovenia">Slovenia</option>
                              <option value="Solomon Islands">
                                Solomon Islands
                              </option>
                              <option value="Somali Democratic">
                                Somali Democratic
                              </option>
                              <option value="South Africa">South Africa</option>
                              <option value="South Georgia">
                                South Georgia
                              </option>
                              <option value="South Korea">South Korea</option>
                              <option value="Spain">Spain</option>
                              <option value="Sri Lanka">Sri Lanka</option>
                              <option value="St. Kitts and Nevis">
                                St. Kitts and Nevis
                              </option>
                              <option value="STP">STP</option>
                              <option value="Suriname">Suriname</option>
                              <option value="Svalbard">Svalbard</option>
                              <option value="Swaziland">Swaziland</option>
                              <option value="Sweden">Sweden</option>
                              <option value="Switzerland">Switzerland</option>
                              <option value="Syria">Syria</option>
                              <option value="Taiwan">Taiwan</option>
                              <option value="Tajikistan">Tajikistan</option>
                              <option value="Tanzania">Tanzania</option>
                              <option value="TCA">TCA</option>
                              <option value="Thailand">Thailand</option>
                              <option value="Toga">Toga</option>
                              <option value="Togolese">Togolese</option>
                              <option value="Tokelau">Tokelau</option>
                              <option value="Tongo">Tongo</option>
                              <option value="Trinidad and Tobago">
                                Trinidad and Tobago
                              </option>
                              <option value="Tunisia">Tunisia</option>
                              <option value="Turkey">Turkey</option>
                              <option value="Turkmenistan">Turkmenistan</option>
                              <option value="Tuvalu">Tuvalu</option>
                              <option value="Uganda">Uganda</option>
                              <option value="Ukraine">Ukraine</option>
                              <option value="United Arab">United Arab</option>
                              <option value="United Kingdom">
                                United Kingdom
                              </option>
                              <option value="Upper Volta">Upper Volta</option>
                              <option value="Uruguay">Uruguay</option>
                              <option value="USSR(Former)">USSR(Former)</option>
                              <option value="Uzbekistan">Uzbekistan</option>
                              <option value="Vanuatu">Vanuatu</option>
                              <option value="Vatican City">Vatican City</option>
                              <option value="Venezuela">Venezuela</option>
                              <option value="VI, British">VI, British</option>
                              <option value="Viet Nam">Viet Nam</option>
                              <option value="Virgin Islands, USA">
                                Virgin Islands, USA
                              </option>
                              <option value="Western Sahara">
                                Western Sahara
                              </option>
                              <option value="WLF">WLF</option>
                              <option value="Yemen">Yemen</option>
                              <option value="Yugoslavia">Yugoslavia</option>
                              <option value="Zaire">Zaire</option>
                              <option value="Zambia">Zambia</option>
                              <option value="Zimbabwe">Zimbabwe</option>
                            </select>
                          </div>
                          <div className="personal_Info_Error">
                            {index === 0 && (
                              <span className="Error">{country}</span>
                            )}
                            {index === 1 && (
                              <span className="Error">{country1}</span>
                            )}
                          </div>
                        </div>
                        <div className="personal_Info_Error_Mobile">
                          {index === 0 && (
                            <span className="Error">{country}</span>
                          )}
                          {index === 1 && (
                            <span className="Error">{country1}</span>
                          )}
                        </div>
                        <div className="personal_Info_label_input">
                          <div className="personal_Info_label">
                            <span>Nationality:</span>
                          </div>
                          <div className="personal_Info_input">
                            <input
                              className="Input_fields"
                              type="text"
                              disabled={index == 1 && this.state.checked1}
                              name="nationality"
                              value={g.nationality}
                              onChange={(e) => {
                                this.contactHandler("nationality", e, index);
                              }}
                              placeholder="Ex: Indian"
                            ></input>
                          </div>
                          <div className="personal_Info_Error">
                            {index === 0 && (
                              <span className="Error">{nationality}</span>
                            )}
                            {index === 1 && (
                              <span className="Error">{nationality1}</span>
                            )}
                          </div>
                        </div>
                        <div className="personal_Info_Error_Mobile">
                          {index === 0 && (
                            <span className="Error">{nationality}</span>
                          )}
                          {index === 1 && (
                            <span className="Error">{nationality1}</span>
                          )}
                        </div>
                        <div className="personal_Info_label_input">
                          <div className="personal_Info_label">
                            <span>Mobile:</span>
                          </div>
                          <div className="personal_Info_input">
                            <input
                              className="Input_fields"
                              type="text"
                              disabled={index == 1 && this.state.checked1}
                              name="phone"
                              value={g.phone}
                              onChange={(e) => {
                                this.contactHandler("phone", e, index);
                              }}
                              placeholder="Mobile"
                              autoComplete="true"
                            ></input>
                          </div>
                          <div className="personal_Info_Error">
                            {index === 0 && (
                              <span className="Error">{phone}</span>
                            )}
                            {index === 1 && (
                              <span className="Error">{phone1}</span>
                            )}
                          </div>
                        </div>
                        <div className="personal_Info_Error_Mobile">
                          {index === 0 && (
                            <span className="Error">{phone}</span>
                          )}
                          {index === 1 && (
                            <span className="Error">{phone1}</span>
                          )}
                        </div>
                        <div className="personal_Info_label_input">
                          <div className="personal_Info_label">
                            <span>Alternate Contact 1:</span>
                          </div>
                          <div className="personal_Info_input">
                            <input
                              className="Input_fields"
                              type="text"
                              disabled={index == 1 && this.state.checked1}
                              name="EmergencyContact"
                              value={g.emergencyContact}
                              onChange={(e) => {
                                this.contactHandler(
                                  "emergencyContact",
                                  e,
                                  index
                                );
                              }}
                              placeholder="Mobile"
                              autoComplete="true"
                            ></input>
                          </div>
                          <div className="personal_Info_Error">
                            {index === 0 && (
                              <span className="Error">{emergencyContact}</span>
                            )}
                            {index === 1 && (
                              <span className="Error">{emergencyContact1}</span>
                            )}
                          </div>
                        </div>
                        <div className="personal_Info_Error_Mobile">
                          {index === 0 && (
                            <span className="Error">{emergencyContact}</span>
                          )}
                          {index === 1 && (
                            <span className="Error">{emergencyContact1}</span>
                          )}
                        </div>
                        <div className="personal_Info_label_input">
                          <div className="personal_Info_label">
                            <span>Alternate Contact 2:</span>
                          </div>
                          <div className="personal_Info_input">
                            <input
                              className="Input_fields"
                              type="text"
                              disabled={index == 1 && this.state.checked1}
                              name="AlternateContact"
                              value={g.alternateContact}
                              onChange={(e) => {
                                this.contactHandler(
                                  "alternateContact",
                                  e,
                                  index
                                );
                              }}
                              placeholder="Mobile"
                              autoComplete="true"
                            ></input>
                          </div>
                          <div className="personal_Info_Error">
                            {index === 0 && (
                              <span className="Error">{alternateContact}</span>
                            )}
                            {index === 1 && (
                              <span className="Error">{alternateContact1}</span>
                            )}
                          </div>
                        </div>
                        <div className="personal_Info_Error_Mobile">
                          {index === 0 && (
                            <span className="Error">{alternateContact}</span>
                          )}
                          {index === 1 && (
                            <span className="Error">{alternateContact1}</span>
                          )}
                        </div>
                      </div>
                      {index == 0 && (
                        <div className="useSameAddress_">
                          <span style={{ fontSize: 18 }}>
                            Use above address as permanent address:
                          </span>
                          <InputSwitch
                            style={{ marginLeft: "6px" }}
                            name="checked1"
                            checked={this.state.checked1}
                            onChange={this.checkedAddress}
                          />
                        </div>
                      )}
                    </div>
                  ))}
              </div>

              <div className="personal_Info">
                <h1 className="Form55" style={{ marginTop: "30px" }}>
                  3.Education Information
                </h1>
                <div className="personal_Info_fields">
                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>Degree:</span>
                    </div>
                    <div className="personal_Info_input">
                      <input
                        className="Input_fields"
                        type="text"
                        name="Degree"
                        value={this.state.requestTemplate.educationInfo.degree}
                        onChange={(e) => {
                          this.educationHandler("degree", e);
                        }}
                        placeholder="Degree"
                      ></input>
                    </div>
                    <div className="personal_Info_Error">
                      {degree && <span className="Error">{degree}</span>}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {degree && <span className="Error">{degree}</span>}
                  </div>

                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>College Name:</span>
                    </div>
                    <div className="personal_Info_input">
                      <input
                        className="Input_fields"
                        type="text"
                        name="collegeName"
                        value={
                          this.state.requestTemplate.educationInfo.collegeName
                        }
                        onChange={(e) => {
                          this.educationHandler("collegeName", e);
                        }}
                        placeholder="College Name"
                      ></input>
                    </div>
                    <div className="personal_Info_Error">
                      {collegeName && (
                        <span className="Error">{collegeName}</span>
                      )}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {collegeName && (
                      <span className="Error">{collegeName}</span>
                    )}
                  </div>

                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>University:</span>
                    </div>
                    <div className="personal_Info_input">
                      <input
                        className="Input_fields"
                        type="text"
                        name="universityName"
                        value={
                          this.state.requestTemplate.educationInfo
                            .universityName
                        }
                        onChange={(e) => {
                          this.educationHandler("universityName", e);
                        }}
                        placeholder="University"
                      ></input>
                    </div>
                    <div className="personal_Info_Error">
                      {universityName && (
                        <span className="Error">{universityName}</span>
                      )}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {universityName && (
                      <span className="Error">{universityName}</span>
                    )}
                  </div>

                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>Subject:</span>
                    </div>
                    <div className="personal_Info_input">
                      <input
                        className="Input_fields"
                        type="text"
                        name="subject"
                        value={this.state.requestTemplate.educationInfo.subject}
                        onChange={(e) => {
                          this.educationHandler("subject", e);
                        }}
                        placeholder="Subject"
                      ></input>
                    </div>
                    <div className="personal_Info_Error">
                      {subject && <span className="Error">{subject}</span>}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {subject && <span className="Error">{subject}</span>}
                  </div>

                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>Course Start Date:</span>
                    </div>
                    <div className="personal_Info_input">
                      <input
                        className="Input_date"
                        type="date"
                        max={this.state.currentdate}
                        min="1990-01-01"
                        name="CourseStartDate"
                        value={
                          this.state.requestTemplate.educationInfo
                            .courseStartDate
                        }
                        onChange={(e) => {
                          this.educationHandler("courseStartDate", e);
                        }}
                        placeholder="Course Start Date"
                      ></input>
                    </div>
                    <div className="personal_Info_Error">
                      {courseStartDate && (
                        <span className="Error">{courseStartDate}</span>
                      )}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {courseStartDate && (
                      <span className="Error">{courseStartDate}</span>
                    )}
                  </div>

                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>Course End Date:</span>
                    </div>
                    <div className="personal_Info_input">
                      <input
                        className="Input_date"
                        type="date"
                        min={
                          this.state.requestTemplate.educationInfo
                            .courseStartDate
                        }
                        max={this.state.currentdate}
                        name="courseEndDate"
                        value={
                          this.state.requestTemplate.educationInfo.courseEndDate
                        }
                        onChange={(e) => {
                          this.educationHandler("courseEndDate", e);
                        }}
                        placeholder="Course End Date"
                      ></input>
                    </div>
                    <div className="personal_Info_Error">
                      {courseEndDate && (
                        <span className="Error">{courseEndDate}</span>
                      )}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {courseEndDate && (
                      <span className="Error">{courseEndDate}</span>
                    )}
                  </div>

                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>Course Type:</span>
                    </div>
                    <div className="personal_Info_input">
                      <select
                        className="Input_Select"
                        type="text"
                        name="courseType"
                        value={
                          this.state.requestTemplate.educationInfo.courseType
                        }
                        onChange={(e) => {
                          this.educationHandler("courseType", e);
                        }}
                        placeholder="Course Type"
                        required
                      >
                        <option value="" disabled selected>
                          Select One
                        </option>
                        <option value="Regular">Regular</option>
                        <option value="Distance">Distance</option>
                      </select>
                    </div>
                    <div className="personal_Info_Error">
                      {courseType && (
                        <span className="Error">{courseType}</span>
                      )}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {courseType && <span className="Error">{courseType}</span>}
                  </div>

                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>Address of Institution:</span>
                    </div>
                    <div className="personal_Info_input">
                      {/* <input
                      className="Input_fields"
                      type="text"
                      name="addressOfInstitution"
                      value={
                        this.state.requestTemplate.educationInfo
                          .addressOfInstitution
                      }
                      onChange={(e) => {
                        this.educationHandler("addressOfInstitution", e);
                      }}
                      placeholder="Address"
                      autocomplete="true"
                    ></input> */}
                      <InputTextarea
                        rows={2}
                        cols={25}
                        autoResize={true}
                        className="Input_fields_textArea"
                        type="text"
                        name="addressOfInstitution"
                        value={
                          this.state.requestTemplate.educationInfo
                            .addressOfInstitution
                        }
                        onChange={(e) => {
                          this.educationHandler("addressOfInstitution", e);
                        }}
                        placeholder="Address"
                        autocomplete="true"
                      />
                    </div>
                    <div className="personal_Info_Error">
                      {addressOfInstitution && (
                        <span className="Error">{addressOfInstitution}</span>
                      )}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {addressOfInstitution && (
                      <span className="Error">{addressOfInstitution}</span>
                    )}
                  </div>

                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>City:</span>
                    </div>
                    <div className="personal_Info_input">
                      <input
                        className="Input_fields"
                        type="text"
                        name="city"
                        value={this.state.requestTemplate.educationInfo.city}
                        onChange={(e) => {
                          this.educationHandler("city", e);
                        }}
                        placeholder="City"
                        autocomplete="true"
                      ></input>
                    </div>
                    <div className="personal_Info_Error">
                      {cityeducatio && (
                        <span className="Error">{cityeducatio}</span>
                      )}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {cityeducatio && (
                      <span className="Error">{cityeducatio}</span>
                    )}
                  </div>

                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>Pincode:</span>
                    </div>
                    <div className="personal_Info_input">
                      <input
                        className="Input_fields"
                        type="text"
                        name="pinCode"
                        value={this.state.requestTemplate.educationInfo.pinCode}
                        onChange={(e) => {
                          this.educationHandler("pinCode", e);
                        }}
                        placeholder="Pincode"
                        autocomplete="true"
                      ></input>
                    </div>
                    <div className="personal_Info_Error">
                      {educationInfopinCode && (
                        <span className="Error">{educationInfopinCode}</span>
                      )}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {educationInfopinCode && (
                      <span className="Error">{educationInfopinCode}</span>
                    )}
                  </div>

                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>Landline 1 (optional):</span>
                    </div>
                    <div className="personal_Info_input">
                      <input
                        className="Input_fields"
                        type="text"
                        name="landline1"
                        value={
                          this.state.requestTemplate.educationInfo.landline1
                        }
                        onChange={(e) => {
                          this.educationHandler("landline1", e);
                        }}
                        placeholder="Landline 1"
                      ></input>
                    </div>
                    <div className="personal_Info_Error">
                      {landline1 && <span className="Error">{landline1}</span>}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {landline1 && <span className="Error">{landline1}</span>}
                  </div>

                  <div className="personal_Info_label_input">
                    <div className="personal_Info_label">
                      <span>Landline 2 (optional):</span>
                    </div>
                    <div className="personal_Info_input">
                      <input
                        className="Input_fields"
                        type="text"
                        name="landline2"
                        value={
                          this.state.requestTemplate.educationInfo.Landline2
                        }
                        onChange={(e) => {
                          this.educationHandler("landline2", e);
                        }}
                        placeholder="Landline 2"
                      ></input>
                    </div>
                    <div className="personal_Info_Error">
                      {landline2 && <span className="Error">{landline2}</span>}
                    </div>
                  </div>
                  <div className="personal_Info_Error_Mobile">
                    {landline2 && <span className="Error">{landline2}</span>}
                  </div>
                </div>
              </div>

              <div>
                <div className="personal_Info">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop:
                        this.props.selectedValue === "Expirience" && "20px",
                    }}
                  >
                    <h1 className="Form55">3.Employment Information</h1>

                    <select
                      className="addExpireance"
                      value={this.props.selectedValue}
                      onChange={this.selectedinfo.bind(this)}
                      required
                    >
                      <option value="" disabled selected>
                        Select One
                      </option>
                      <option value="Expirience">Experience</option>
                      <option value="Fresher">Fresher</option>
                    </select>
                  </div>
                  {this.props.isThere &&
                    this.state.requestTemplate.employmentInfo.length > 0 &&
                    this.state.requestTemplate.employmentInfo.map(
                      (v, index) => (
                        <div
                          key={index}
                          style={{ marginTop: index !== 0 && "20px" }}
                          className={"personal_Info_fields"}
                        >
                          {/* {//(v, index)} */}
                          <div className="personal_Info_label_input">
                            <div className="personal_Info_label">
                              <span>Company Name:</span>
                            </div>
                            <div className="personal_Info_input">
                              <input
                                className="Input_fields"
                                type="text"
                                value={v.companyName}
                                onChange={(e) => {
                                  this.employmentHandler(
                                    "companyName",
                                    e,
                                    index
                                  );
                                }}
                                placeholder="Company"
                                autocomplete="true"
                              ></input>
                            </div>
                            <div className="personal_Info_Error">
                              {index == 0 && (
                                <span className="Error">{companyName}</span>
                              )}
                              {index == 1 && (
                                <span className="Error">
                                  {exp1_companyName}
                                </span>
                              )}
                              {index == 2 && (
                                <span className="Error">
                                  {exp2_companyName}
                                </span>
                              )}
                              {index == 3 && (
                                <span className="Error">
                                  {exp3_companyName}
                                </span>
                              )}
                              {index == 4 && (
                                <span className="Error">
                                  {exp4_companyName}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="personal_Info_Error_Mobile">
                            {index == 0 && (
                              <span className="Error">{companyName}</span>
                            )}
                            {index == 1 && (
                              <span className="Error">{exp1_companyName}</span>
                            )}
                            {index == 2 && (
                              <span className="Error">{exp2_companyName}</span>
                            )}
                            {index == 3 && (
                              <span className="Error">{exp3_companyName}</span>
                            )}
                            {index == 4 && (
                              <span className="Error">{exp4_companyName}</span>
                            )}
                          </div>

                          <div className="personal_Info_label_input">
                            <div className="personal_Info_label">
                              <span>Employed Place:</span>
                            </div>
                            <div className="personal_Info_input">
                              <input
                                className="Input_fields"
                                type="text"
                                value={v.employedPlace}
                                onChange={(e) => {
                                  this.employmentHandler(
                                    "employedPlace",
                                    e,
                                    index
                                  );
                                }}
                                placeholder="Place"
                              ></input>
                            </div>
                            <div className="personal_Info_Error">
                              {index == 0 && (
                                <span className="Error">{employedPlace}</span>
                              )}
                              {index == 1 && (
                                <span className="Error">
                                  {exp1_employedPlace}
                                </span>
                              )}
                              {index == 2 && (
                                <span className="Error">
                                  {exp2_employedPlace}
                                </span>
                              )}
                              {index == 3 && (
                                <span className="Error">
                                  {exp3_employedPlace}
                                </span>
                              )}
                              {index == 4 && (
                                <span className="Error">
                                  {exp4_employedPlace}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="personal_Info_Error_Mobile">
                            {index == 0 && (
                              <span className="Error">{employedPlace}</span>
                            )}
                            {index == 1 && (
                              <span className="Error">
                                {exp1_employedPlace}
                              </span>
                            )}
                            {index == 2 && (
                              <span className="Error">
                                {exp2_employedPlace}
                              </span>
                            )}
                            {index == 3 && (
                              <span className="Error">
                                {exp3_employedPlace}
                              </span>
                            )}
                            {index == 4 && (
                              <span className="Error">
                                {exp4_employedPlace}
                              </span>
                            )}
                          </div>

                          <div className="personal_Info_label_input">
                            <div className="personal_Info_label">
                              <span>Company Address:</span>
                            </div>
                            <div className="personal_Info_input">
                              {/* <input
                            className="Input_fields"
                            type="text"
                            value={v.companyAddress}
                            onChange={(e) => {
                              this.employmentHandler(
                                "companyAddress",
                                e,
                                index
                              );
                            }}
                            placeholder="Company Address"
                            autocomplete="true"
                          ></input> */}
                              <InputTextarea
                                rows={2}
                                autoResize={true}
                                cols={25}
                                className="Input_fields_textArea"
                                type="text"
                                value={v.companyAddress}
                                onChange={(e) => {
                                  this.employmentHandler(
                                    "companyAddress",
                                    e,
                                    index
                                  );
                                }}
                                placeholder="Company Address"
                                autocomplete="true"
                              />
                            </div>
                            <div className="personal_Info_Error">
                              {index == 0 && (
                                <span className="Error">{companyAddress}</span>
                              )}
                              {index == 1 && (
                                <span className="Error">
                                  {exp1_companyAddress}
                                </span>
                              )}
                              {index == 2 && (
                                <span className="Error">
                                  {exp2_companyAddress}
                                </span>
                              )}
                              {index == 3 && (
                                <span className="Error">
                                  {exp3_companyAddress}
                                </span>
                              )}
                              {index == 4 && (
                                <span className="Error">
                                  {exp4_companyAddress}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="personal_Info_Error_Mobile">
                            {index == 0 && (
                              <span className="Error">{companyAddress}</span>
                            )}
                            {index == 1 && (
                              <span className="Error">
                                {exp1_companyAddress}
                              </span>
                            )}
                            {index == 2 && (
                              <span className="Error">
                                {exp2_companyAddress}
                              </span>
                            )}
                            {index == 3 && (
                              <span className="Error">
                                {exp3_companyAddress}
                              </span>
                            )}
                            {index == 4 && (
                              <span className="Error">
                                {exp4_companyAddress}
                              </span>
                            )}
                          </div>

                          <div className="personal_Info_label_input">
                            <div className="personal_Info_label">
                              <span>City:</span>
                            </div>
                            <div className="personal_Info_input">
                              <input
                                className="Input_fields"
                                type="text"
                                value={v.city}
                                onChange={(e) => {
                                  this.employmentHandler("city", e, index);
                                }}
                                placeholder="City"
                                autocomplete="true"
                              ></input>
                            </div>
                            <div className="personal_Info_Error">
                              {index == 0 && (
                                <span className="Error">{cityemployment}</span>
                              )}
                              {index == 1 && (
                                <span className="Error">
                                  {exp1_cityemployment}
                                </span>
                              )}
                              {index == 2 && (
                                <span className="Error">
                                  {exp2_cityemployment}
                                </span>
                              )}
                              {index == 3 && (
                                <span className="Error">
                                  {exp3_cityemployment}
                                </span>
                              )}
                              {index == 4 && (
                                <span className="Error">
                                  {exp4_cityemployment}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="personal_Info_Error_Mobile">
                            {index == 0 && (
                              <span className="Error">{cityemployment}</span>
                            )}
                            {index == 1 && (
                              <span className="Error">
                                {exp1_cityemployment}
                              </span>
                            )}
                            {index == 2 && (
                              <span className="Error">
                                {exp2_cityemployment}
                              </span>
                            )}
                            {index == 3 && (
                              <span className="Error">
                                {exp3_cityemployment}
                              </span>
                            )}
                            {index == 4 && (
                              <span className="Error">
                                {exp4_cityemployment}
                              </span>
                            )}
                          </div>

                          <div className="personal_Info_label_input">
                            <div className="personal_Info_label">
                              <span>State:</span>
                            </div>
                            <div className="personal_Info_input">
                              {/* <input
                                className="Input_fields"
                                type="text"
                                value={v.state}
                                onChange={(e) => {
                                  this.employmentHandler("state", e, index);
                                }}
                                placeholder="State"
                                autocomplete="true"
                              ></input> */}
                              <select
                                className="Input_Select"
                                type=""
                                value={v.state}
                                onChange={(e) => {
                                  this.employmentHandler("state", e, index);
                                }}
                                placeholder="State"
                                autocomplete="true"
                                required
                              >
                                <option value="" disabled>
                                  Select State
                                </option>
                                <option value="Andhra Pradesh">
                                  Andhra Pradesh
                                </option>
                                <option value="Andaman and Nicobar Islands">
                                  Andaman and Nicobar Islands
                                </option>
                                <option value="Arunachal Pradesh">
                                  Arunachal Pradesh
                                </option>
                                <option value="Assam">Assam</option>
                                <option value="Bihar">Bihar</option>
                                <option value="Chandigarh">Chandigarh</option>
                                <option value="Chhattisgarh">
                                  Chhattisgarh
                                </option>
                                <option value="Dadar and Nagar Haveli">
                                  Dadar and Nagar Haveli
                                </option>
                                <option value="Daman and Diu">
                                  Daman and Diu
                                </option>
                                <option value="Delhi">Delhi</option>
                                <option value="Lakshadweep">Lakshadweep</option>
                                <option value="Puducherry">Puducherry</option>
                                <option value="Goa">Goa</option>
                                <option value="Gujarat">Gujarat</option>
                                <option value="Haryana">Haryana</option>
                                <option value="Himachal Pradesh">
                                  Himachal Pradesh
                                </option>
                                <option value="Jammu and Kashmir">
                                  Jammu and Kashmir
                                </option>
                                <option value="Jharkhand">Jharkhand</option>
                                <option value="Karnataka">Karnataka</option>
                                <option value="Kerala">Kerala</option>
                                <option value="Madhya Pradesh">
                                  Madhya Pradesh
                                </option>
                                <option value="Maharashtra">Maharashtra</option>
                                <option value="Manipur">Manipur</option>
                                <option value="Meghalaya">Meghalaya</option>
                                <option value="Mizoram">Mizoram</option>
                                <option value="Nagaland">Nagaland</option>
                                <option value="Odisha">Odisha</option>
                                <option value="Punjab">Punjab</option>
                                <option value="Rajasthan">Rajasthan</option>
                                <option value="Sikkim">Sikkim</option>
                                <option value="Tamil Nadu">Tamil Nadu</option>
                                <option value="Telangana">Telangana</option>
                                <option value="Tripura">Tripura</option>
                                <option value="Uttar Pradesh">
                                  Uttar Pradesh
                                </option>
                                <option value="Uttarakhand">Uttarakhand</option>
                                <option value="West Bengal">West Bengal</option>
                              </select>
                            </div>
                            <div className="personal_Info_Error">
                              {index == 0 && (
                                <span className="Error">{stateemployment}</span>
                              )}
                              {index == 1 && (
                                <span className="Error">
                                  {exp1_stateemployment}
                                </span>
                              )}
                              {index == 2 && (
                                <span className="Error">
                                  {exp2_stateemployment}
                                </span>
                              )}
                              {index == 3 && (
                                <span className="Error">
                                  {exp3_stateemployment}
                                </span>
                              )}
                              {index == 4 && (
                                <span className="Error">
                                  {exp4_stateemployment}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="personal_Info_Error_Mobile">
                            {index == 0 && (
                              <span className="Error">{stateemployment}</span>
                            )}
                            {index == 1 && (
                              <span className="Error">
                                {exp1_stateemployment}
                              </span>
                            )}
                            {index == 2 && (
                              <span className="Error">
                                {exp2_stateemployment}
                              </span>
                            )}
                            {index == 3 && (
                              <span className="Error">
                                {exp3_stateemployment}
                              </span>
                            )}
                            {index == 4 && (
                              <span className="Error">
                                {exp4_stateemployment}
                              </span>
                            )}
                          </div>

                          <div className="personal_Info_label_input">
                            <div className="personal_Info_label">
                              <span>Pincode:</span>
                            </div>
                            <div className="personal_Info_input">
                              <input
                                className="Input_fields"
                                type="text"
                                value={v.pincode}
                                onChange={(e) => {
                                  this.employmentHandler("pincode", e, index);
                                }}
                                placeholder="Pincode"
                                autocomplete="true"
                              ></input>
                            </div>
                            <div className="personal_Info_Error">
                              {index == 0 && (
                                <span className="Error">
                                  {pincodeemployement}
                                </span>
                              )}
                              {index == 1 && (
                                <span className="Error">
                                  {exp1_pincodeemployement}
                                </span>
                              )}
                              {index == 2 && (
                                <span className="Error">
                                  {exp2_pincodeemployement}
                                </span>
                              )}
                              {index == 3 && (
                                <span className="Error">
                                  {exp3_pincodeemployement}
                                </span>
                              )}
                              {index == 4 && (
                                <span className="Error">
                                  {exp4_pincodeemployement}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="personal_Info_Error_Mobile">
                            {index == 0 && (
                              <span className="Error">
                                {pincodeemployement}
                              </span>
                            )}
                            {index == 1 && (
                              <span className="Error">
                                {exp1_pincodeemployement}
                              </span>
                            )}
                            {index == 2 && (
                              <span className="Error">
                                {exp2_pincodeemployement}
                              </span>
                            )}
                            {index == 3 && (
                              <span className="Error">
                                {exp3_pincodeemployement}
                              </span>
                            )}
                            {index == 4 && (
                              <span className="Error">
                                {exp4_pincodeemployement}
                              </span>
                            )}
                          </div>

                          <div className="personal_Info_label_input">
                            <div className="personal_Info_label">
                              <span>From Date:</span>
                            </div>
                            <div className="personal_Info_input">
                              <input
                                className="Input_date"
                                type="date"
                                min="1930-01-01"
                                max={this.state.currentdate}
                                value={v.fromDate}
                                onChange={(e) => {
                                  this.employmentHandler("fromDate", e, index);
                                }}
                                placeholder="From Date"
                                autocomplete="true"
                              ></input>
                            </div>
                            <div className="personal_Info_Error">
                              {index == 0 && (
                                <span className="Error">{fromDate}</span>
                              )}
                              {index == 1 && (
                                <span className="Error">{exp1_fromDate}</span>
                              )}
                              {index == 2 && (
                                <span className="Error">{exp2_fromDate}</span>
                              )}
                              {index == 3 && (
                                <span className="Error">{exp3_fromDate}</span>
                              )}
                              {index == 4 && (
                                <span className="Error">{exp4_fromDate}</span>
                              )}
                            </div>
                          </div>
                          <div className="personal_Info_Error_Mobile">
                            {index == 0 && (
                              <span className="Error">{fromDate}</span>
                            )}
                            {index == 1 && (
                              <span className="Error">{exp1_fromDate}</span>
                            )}
                            {index == 2 && (
                              <span className="Error">{exp2_fromDate}</span>
                            )}
                            {index == 3 && (
                              <span className="Error">{exp3_fromDate}</span>
                            )}
                            {index == 4 && (
                              <span className="Error">{exp4_fromDate}</span>
                            )}
                          </div>

                          <div className="personal_Info_label_input">
                            <div className="personal_Info_label">
                              <span>To Date:</span>
                            </div>
                            <div className="personal_Info_input">
                              <input
                                className="Input_date"
                                type="date"
                                min={v.fromDate}
                                max={this.state.currentdate}
                                value={v.toDate}
                                onChange={(e) => {
                                  this.employmentHandler("toDate", e, index);
                                }}
                                placeholder="To Date"
                                autocomplete="true"
                              ></input>
                            </div>
                            <div className="personal_Info_Error">
                              {index == 0 && (
                                <span className="Error">{toDate}</span>
                              )}
                              {index == 1 && (
                                <span className="Error">{exp1_toDate}</span>
                              )}
                              {index == 2 && (
                                <span className="Error">{exp2_toDate}</span>
                              )}
                              {index == 3 && (
                                <span className="Error">{exp3_toDate}</span>
                              )}
                              {index == 4 && (
                                <span className="Error">{exp4_toDate}</span>
                              )}
                            </div>
                          </div>
                          <div className="personal_Info_Error_Mobile">
                            {index == 0 && (
                              <span className="Error">{toDate}</span>
                            )}
                            {index == 1 && (
                              <span className="Error">{exp1_toDate}</span>
                            )}
                            {index == 2 && (
                              <span className="Error">{exp2_toDate}</span>
                            )}
                            {index == 3 && (
                              <span className="Error">{exp3_toDate}</span>
                            )}
                            {index == 4 && (
                              <span className="Error">{exp4_toDate}</span>
                            )}
                          </div>

                          <div className="personal_Info_label_input">
                            <div className="personal_Info_label">
                              <span>Designation:</span>
                            </div>
                            <div className="personal_Info_input">
                              <input
                                className="Input_fields"
                                type="text"
                                value={v.designation}
                                onChange={(e) => {
                                  this.employmentHandler(
                                    "designation",
                                    e,
                                    index
                                  );
                                }}
                                placeholder="Designation"
                                autocomplete="true"
                              ></input>
                            </div>
                            <div className="personal_Info_Error">
                              {index == 0 && (
                                <span className="Error">{designation}</span>
                              )}
                              {index == 1 && (
                                <span className="Error">
                                  {exp1_designation}
                                </span>
                              )}
                              {index == 2 && (
                                <span className="Error">
                                  {exp2_designation}
                                </span>
                              )}
                              {index == 3 && (
                                <span className="Error">
                                  {exp3_designation}
                                </span>
                              )}
                              {index == 4 && (
                                <span className="Error">
                                  {exp4_designation}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="personal_Info_Error_Mobile">
                            {index == 0 && (
                              <span className="Error">{designation}</span>
                            )}
                            {index == 1 && (
                              <span className="Error">{exp1_designation}</span>
                            )}
                            {index == 2 && (
                              <span className="Error">{exp2_designation}</span>
                            )}
                            {index == 3 && (
                              <span className="Error">{exp3_designation}</span>
                            )}
                            {index == 4 && (
                              <span className="Error">{exp4_designation}</span>
                            )}
                          </div>

                          <div className="personal_Info_label_input">
                            <div className="personal_Info_label">
                              <span>Salary:</span>
                            </div>
                            <div className="personal_Info_input">
                              <input
                                className="Input_fields"
                                type="text"
                                value={v.remuneration}
                                onChange={(e) => {
                                  this.employmentHandler(
                                    "remuneration",
                                    e,
                                    index
                                  );
                                }}
                                placeholder="Salary"
                                autocomplete="true"
                              ></input>
                            </div>
                            <div className="personal_Info_Error">
                              {index == 0 && (
                                <span className="Error">{remuneration}</span>
                              )}
                              {index == 1 && (
                                <span className="Error">
                                  {exp1_remuneration}
                                </span>
                              )}
                              {index == 2 && (
                                <span className="Error">
                                  {exp2_remuneration}
                                </span>
                              )}
                              {index == 3 && (
                                <span className="Error">
                                  {exp3_remuneration}
                                </span>
                              )}
                              {index == 4 && (
                                <span className="Error">
                                  {exp4_remuneration}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="personal_Info_Error_Mobile">
                            {index == 0 && (
                              <span className="Error">{remuneration}</span>
                            )}
                            {index == 1 && (
                              <span className="Error">{exp1_remuneration}</span>
                            )}
                            {index == 2 && (
                              <span className="Error">{exp2_remuneration}</span>
                            )}
                            {index == 3 && (
                              <span className="Error">{exp3_remuneration}</span>
                            )}
                            {index == 4 && (
                              <span className="Error">{exp4_remuneration}</span>
                            )}
                          </div>

                          <div className="personal_Info_label_input">
                            <div className="personal_Info_label">
                              <span>Employee Code:</span>
                            </div>
                            <div className="personal_Info_input">
                              <input
                                className="Input_fields"
                                type="text"
                                value={v.employeeCode}
                                onChange={(e) => {
                                  this.employmentHandler(
                                    "employeeCode",
                                    e,
                                    index
                                  );
                                }}
                                placeholder="Employee Code"
                                autocomplete="true"
                              ></input>
                            </div>
                            <div className="personal_Info_Error">
                              {index == 0 && (
                                <span className="Error">{employeeCode}</span>
                              )}
                              {index == 1 && (
                                <span className="Error">
                                  {exp1_employeeCode}
                                </span>
                              )}
                              {index == 2 && (
                                <span className="Error">
                                  {exp2_employeeCode}
                                </span>
                              )}
                              {index == 3 && (
                                <span className="Error">
                                  {exp3_employeeCode}
                                </span>
                              )}
                              {index == 4 && (
                                <span className="Error">
                                  {exp4_employeeCode}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="personal_Info_Error_Mobile">
                            {index == 0 && (
                              <span className="Error">{employeeCode}</span>
                            )}
                            {index == 1 && (
                              <span className="Error">{exp1_employeeCode}</span>
                            )}
                            {index == 2 && (
                              <span className="Error">{exp2_employeeCode}</span>
                            )}
                            {index == 3 && (
                              <span className="Error">{exp3_employeeCode}</span>
                            )}
                            {index == 4 && (
                              <span className="Error">{exp4_employeeCode}</span>
                            )}
                          </div>

                          <div className="personal_Info_label_input">
                            <div className="personal_Info_label">
                              <span>Employment Nature:</span>
                            </div>
                            <div className="personal_Info_input">
                              <input
                                className="Input_fields"
                                type="text"
                                value={v.employmentNature}
                                onChange={(e) => {
                                  this.employmentHandler(
                                    "employmentNature",
                                    e,
                                    index
                                  );
                                }}
                                placeholder="Employment Nature"
                                autocomplete="true"
                              ></input>
                            </div>
                            <div className="personal_Info_Error">
                              {index == 0 && (
                                <span className="Error">
                                  {employmentNature}
                                </span>
                              )}
                              {index == 1 && (
                                <span className="Error">
                                  {exp1_employmentNature}
                                </span>
                              )}
                              {index == 2 && (
                                <span className="Error">
                                  {exp2_employmentNature}
                                </span>
                              )}
                              {index == 3 && (
                                <span className="Error">
                                  {exp3_employmentNature}
                                </span>
                              )}
                              {index == 4 && (
                                <span className="Error">
                                  {exp4_employmentNature}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="personal_Info_Error_Mobile">
                            {index == 0 && (
                              <span className="Error">{employmentNature}</span>
                            )}
                            {index == 1 && (
                              <span className="Error">
                                {exp1_employmentNature}
                              </span>
                            )}
                            {index == 2 && (
                              <span className="Error">
                                {exp2_employmentNature}
                              </span>
                            )}
                            {index == 3 && (
                              <span className="Error">
                                {exp3_employmentNature}
                              </span>
                            )}
                            {index == 4 && (
                              <span className="Error">
                                {exp4_employmentNature}
                              </span>
                            )}
                          </div>

                          <div className="personal_Info_label_input">
                            <div className="personal_Info_label">
                              <span>Supervisor Name:</span>
                            </div>
                            <div className="personal_Info_input">
                              <input
                                className="Input_fields"
                                type="text"
                                value={v.supervisorName}
                                onChange={(e) => {
                                  this.employmentHandler(
                                    "supervisorName",
                                    e,
                                    index
                                  );
                                }}
                                placeholder="Supervisor Name"
                                autocomplete="true"
                              ></input>
                            </div>
                            <div className="personal_Info_Error">
                              {index == 0 && (
                                <span className="Error">{supervisorName}</span>
                              )}
                              {index == 1 && (
                                <span className="Error">
                                  {exp1_supervisorName}
                                </span>
                              )}
                              {index == 2 && (
                                <span className="Error">
                                  {exp2_supervisorName}
                                </span>
                              )}
                              {index == 3 && (
                                <span className="Error">
                                  {exp3_supervisorName}
                                </span>
                              )}
                              {index == 4 && (
                                <span className="Error">
                                  {exp4_supervisorName}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="personal_Info_Error_Mobile">
                            {index == 0 && (
                              <span className="Error">{supervisorName}</span>
                            )}
                            {index == 1 && (
                              <span className="Error">
                                {exp1_supervisorName}
                              </span>
                            )}
                            {index == 2 && (
                              <span className="Error">
                                {exp2_supervisorName}
                              </span>
                            )}
                            {index == 3 && (
                              <span className="Error">
                                {exp3_supervisorName}
                              </span>
                            )}
                            {index == 4 && (
                              <span className="Error">
                                {exp4_supervisorName}
                              </span>
                            )}
                          </div>

                          <div className="personal_Info_label_input">
                            <div className="personal_Info_label">
                              <span>Supervisor Designation:</span>
                            </div>
                            <div className="personal_Info_input">
                              <input
                                className="Input_fields"
                                type="text"
                                value={v.supervisorDesignation}
                                onChange={(e) => {
                                  this.employmentHandler(
                                    "supervisorDesignation",
                                    e,
                                    index
                                  );
                                }}
                                placeholder="Supervisor Designation"
                                autocomplete="true"
                              ></input>
                            </div>
                            <div className="personal_Info_Error">
                              {index == 0 && (
                                <span className="Error">
                                  {supervisorDesignation}
                                </span>
                              )}
                              {index == 1 && (
                                <span className="Error">
                                  {exp1_supervisorDesignation}
                                </span>
                              )}
                              {index == 2 && (
                                <span className="Error">
                                  {exp2_supervisorDesignation}
                                </span>
                              )}
                              {index == 3 && (
                                <span className="Error">
                                  {exp3_supervisorDesignation}
                                </span>
                              )}
                              {index == 4 && (
                                <span className="Error">
                                  {exp4_supervisorDesignation}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="personal_Info_Error_Mobile">
                            {index == 0 && (
                              <span className="Error">
                                {supervisorDesignation}
                              </span>
                            )}
                            {index == 1 && (
                              <span className="Error">
                                {exp1_supervisorDesignation}
                              </span>
                            )}
                            {index == 2 && (
                              <span className="Error">
                                {exp2_supervisorDesignation}
                              </span>
                            )}
                            {index == 3 && (
                              <span className="Error">
                                {exp3_supervisorDesignation}
                              </span>
                            )}
                            {index == 4 && (
                              <span className="Error">
                                {exp4_supervisorDesignation}
                              </span>
                            )}
                          </div>

                          <div className="personal_Info_label_input">
                            <div className="personal_Info_label">
                              <span>Supervisor Email:</span>
                            </div>
                            <div className="personal_Info_input">
                              <input
                                className="Input_fields"
                                type="text"
                                value={v.supervisorEmailId}
                                onChange={(e) => {
                                  this.employmentHandler(
                                    "supervisorEmailId",
                                    e,
                                    index
                                  );
                                }}
                                placeholder="Supervisor Email "
                                autocomplete="true"
                              ></input>
                            </div>
                            <div className="personal_Info_Error">
                              {index == 0 && (
                                <span className="Error">
                                  {supervisorEmailId}
                                </span>
                              )}
                              {index == 1 && (
                                <span className="Error">
                                  {exp1_supervisorEmailId}
                                </span>
                              )}
                              {index == 2 && (
                                <span className="Error">
                                  {exp2_supervisorEmailId}
                                </span>
                              )}
                              {index == 3 && (
                                <span className="Error">
                                  {exp3_supervisorEmailId}
                                </span>
                              )}
                              {index == 4 && (
                                <span className="Error">
                                  {exp4_supervisorEmailId}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="personal_Info_Error_Mobile">
                            {index == 0 && (
                              <span className="Error">{supervisorEmailId}</span>
                            )}
                            {index == 1 && (
                              <span className="Error">
                                {exp1_supervisorEmailId}
                              </span>
                            )}
                            {index == 2 && (
                              <span className="Error">
                                {exp2_supervisorEmailId}
                              </span>
                            )}
                            {index == 3 && (
                              <span className="Error">
                                {exp3_supervisorEmailId}
                              </span>
                            )}
                            {index == 4 && (
                              <span className="Error">
                                {exp4_supervisorEmailId}
                              </span>
                            )}
                          </div>

                          <div className="personal_Info_label_input">
                            <div className="personal_Info_label">
                              <span>Leaving Reason:</span>
                            </div>
                            <div className="personal_Info_input">
                              {/* <input
                                className="Input_fields"
                                type="text"
                                value={v.leavingReason}
                                onChange={(e) => {
                                  this.employmentHandler(
                                    "leavingReason",
                                    e,
                                    index
                                  );
                                }}
                                placeholder="Leaving Reason"
                                autocomplete="true"
                              ></input> */}
                              <InputTextarea
                                rows={2}
                                cols={25}
                                autoResize={true}
                                className="Input_fields_textArea"
                                value={v.leavingReason}
                                onChange={(e) => {
                                  this.employmentHandler(
                                    "leavingReason",
                                    e,
                                    index
                                  );
                                }}
                                placeholder="Leaving Reason"
                                autocomplete="true"
                              />
                            </div>
                            <div className="personal_Info_Error">
                              {index == 0 && (
                                <span className="Error">{leavingReason}</span>
                              )}
                              {index == 1 && (
                                <span className="Error">
                                  {exp1_leavingReason}
                                </span>
                              )}
                              {index == 2 && (
                                <span className="Error">
                                  {exp2_leavingReason}
                                </span>
                              )}
                              {index == 3 && (
                                <span className="Error">
                                  {exp3_leavingReason}
                                </span>
                              )}
                              {index == 4 && (
                                <span className="Error">
                                  {exp4_leavingReason}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="personal_Info_Error_Mobile">
                            {index == 0 && (
                              <span className="Error">{leavingReason}</span>
                            )}
                            {index == 1 && (
                              <span className="Error">
                                {exp1_leavingReason}
                              </span>
                            )}
                            {index == 2 && (
                              <span className="Error">
                                {exp2_leavingReason}
                              </span>
                            )}
                            {index == 3 && (
                              <span className="Error">
                                {exp3_leavingReason}
                              </span>
                            )}
                            {index == 4 && (
                              <span className="Error">
                                {exp4_leavingReason}
                              </span>
                            )}
                          </div>

                          <div className="addExpireienceBtns">
                            {this.state.requestTemplate.employmentInfo.length <
                              5 && (
                              <button
                                type="button"
                                className="addExpireienceBtn"
                                onClick={this.experienceAdd.bind(this)}
                              >
                                Add More Experience
                              </button>
                            )}
                            {this.state.requestTemplate.employmentInfo.length >
                              1 && (
                              <button
                                type="button"
                                className="addExpireienceBtn"
                                onClick={this.RemoveExperience.bind(
                                  this,
                                  index
                                )}
                              >
                                Remove Experience
                              </button>
                            )}
                          </div>
                        </div>
                      )
                    )}
                </div>
              </div>

              <div className="personal_Info" style={{ borderBottom: "none" }}>
                <h1 className="Form55">
                  4.Authorization/ Declaration and Undertaking
                </h1>
                <div className="personal_Info_fields">
                  <h6 className="fonter">
                    If Employed with{" "}
                    <strong style={{ fontWeight: "600" }}>
                      CAPRUS IT PRIVATE LIMITED
                    </strong>
                    , I agree to provide copies of all relevant certificates. I
                    understand that employment with{" "}
                    <strong style={{ fontWeight: "600" }}>
                      CAPRUS IT PRIVATE LIMITED
                    </strong>
                    is governed by their employment policies as applicable,
                    including satisfactory information from background checks. I
                    hereby certify that all information provided herein, is true
                    and complete to the best of my knowledge and belief. I
                    authorize{" "}
                    <strong style={{ fontWeight: "800" }}>
                      CAPRUS IT PRIVATE LIMITED
                    </strong>{" "}
                    and its representative to authenticate information I have
                    provided in my resume and this Candidate Declaration Form
                    (CDF). To conduct enquiries as may be necessary at the
                    company’s discretion, I authorize all who may have
                    information relevant to this enquiry to disclose it to{" "}
                    <strong style={{ fontWeight: "600" }}>
                      CAPRUS IT PRIVATE LIMITED
                    </strong>{" "}
                    and/or its representative. I release all concerned from any
                    liability on account of such disclosures. I also declare
                    that the information provided by me in my resume and
                    application for employment to{" "}
                    <strong style={{ fontWeight: "800" }}>
                      CAPRUS IT PRIVATE LIMITED
                    </strong>{" "}
                    and its representative is authentic and I am liable for all
                    inaccuracies and omissions. I promise to extend total
                    co-operation and provide relevant documents required.
                  </h6>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "",
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="Hereby_Accept">
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="checked"
                        checked={this.state.checked}
                        onChange={this.checkedHandler}
                        style={{ float: "left", width: "42px", height: "26px" }}
                      ></input>
                      <h6 className="check">I hereby Accept It</h6>
                      {checked && (
                        <span
                          style={{
                            color: "red",
                            width: "0%",
                            marginLeft: "10px",
                          }}
                        >
                          {checked}
                        </span>
                      )}
                    </div>
                    <div>
                      <div
                        style={{
                          padding: "0px 10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginTop: 10,
                          }}
                        >
                          <div className="personal_Info_label">
                            <span>Place:</span>
                          </div>

                          <div className="personal_Info_input">
                            <input
                              className="Input_fields"
                              type="text"
                              name="place"
                              value={this.state.requestTemplate.place}
                              onChange={(e) => {
                                this.personalHandler("place", e);
                              }}
                              placeholder="Place"
                            ></input>
                          </div>
                          <div className="declaration_input">
                            {place && <span className="Error">{place}</span>}
                          </div>
                        </div>
                      </div>
                      <div className="personal_Info_Error_Mobile"></div>
                      <div style={{ padding: "0px 10px" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginTop: 10,
                          }}
                        >
                          <div className="personal_Info_label">
                            <span>Date:</span>
                          </div>
                          <div className="personal_Info_input">
                            <input
                              className="Input_date"
                              style={{ marginTop: 0 }}
                              type="date"
                              min="2021-01-01"
                              max={this.state.currentdate}
                              value={this.state.requestTemplate.date}
                              onChange={(e) => {
                                this.personalHandler("date", e);
                              }}
                              name="date"
                            ></input>
                          </div>
                          <div className="declaration_input">
                            {date && <span className="Error">{date}</span>}
                          </div>
                        </div>
                      </div>
                      <div className="personal_Info_Error_Mobile"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  marginTop: "10px",
                  justifyContent: "center",
                }}
              >
                {/* {this.state.isRender && (
                  <span className="incorrect_data">Incorrect data</span>
                )} */}
              </div>

              <div className="Submit_btn_userpage_admin_otp">
                <button className="save-btn">
                  Submit<i class="pi pi-send"></i>
                </button>
              </div>
            </form>
          </>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (state) => {
  return {
    empId: state.empId,
    email: state.email,
    isThere: state.isThere,
    selectedValue: state.selectedValue,
    always: state.always,
    AddedValue: state.AddedValue,
    linearProgress: state.linearProgress,
    isSpinner: state.isSpinner,
  };
};

export default connect(mapDispatchToProps)(Form);
