import React from "react";

class Relieved extends React.Component {
  render() {
    return (
      <div
        style={{
          width: "100%",
          height: window.innerHeight - 140,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h2 style={{ color: "#fc4a1a" }}>
          Sorry, you are relieved from company.
        </h2>
      </div>
    );
  }
}
export default Relieved;
