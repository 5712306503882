export const EMAILUPLOAD = "EMAILUPLOAD";
export const EMAILDATA = "EMAILDATA";
export const LOADING = "LOADING";
export const EMAILID = "EMAILID";
export const SAVE = "SAVE";
export const DASHBOARD = "DASHBOARD";
export const PFDATAID = "PFDATAID";
export const ISLOOGEDOUT = "ISLOOGEDOUT";
export const ISADMINLOOGEDOUT = "ISADMINLOOGEDOUT";
export const ISINSURANCEOUTTED = "ISINSURANCEOUTTED";
export const ISPDDONE = "ISPDDONE";
export const HEADER11 = "HEADER11";
export const PROFILEID = "PROFILEID";
export const ISGUEST = "ISGUEST";
export const PFDATASUCCESS = "PFDATASUCCESS";
export const STATUSMODELER = "STATUSMODELER";
export const GUESTSTATUSMODELER = "GUESTSTATUSMODELER";
export const INSURANCESUCCESS = "INSURANCESUCCESS";
export const EXPIRIENCEEXIST = "EXPIRIENCEEXIST";
export const SELECTEDVALUE = "SELECTEDVALUE";
export const ALWAYSEXIST = "ALWAYSEXIST";
export const ALWAYSASSIGNED = "ALWAYSASSIGNED";
export const ADDMOREEXPIRIENCE = "ADDMOREEXPIRIENCE";
export const ADMINLOGOUT = "ADMINLOGOUT";
export const EMPLOYEELOGOUT = "EMPLOYEELOGOUT";
export const LINEARPROGRESS = "LINEARPROGRESS";
export const EMPLOYEEDATA = "EMPLOYEEDATA";
export const PROFILE = "PROFILE";
export const INSURANCE = "INSURANCE";
export const PFDETAILS = "PFDETAILS";
export const UPLOADED_DOCUMENTS = "UPLOADED_DOCUMENTS";
export const EMPLOYEE_CLEARANCE = "EMPLOYEE_CLEARANCE";
export const EXIT_INTERVIEW = "EXIT_INTERVIEW";
export const IFEMPLOYEEIDNOTGENERATED = "IFEMPLOYEEIDNOTGENERATED";
export const SHOWEDITFORM = "SHOWEDITFORM";
export const SHOWVIEWFORM = "SHOWVIEWFORM";
export const SHOWPF = "SHOWPF";
export const BUTTONSTATUS = "BUTTONSTATUS";

//fresh code by Janya
export const SETEMAIL = "SETEMAIL";
export const CREATEMPLOYEEPROFILEDATA = "CREATEMPLOYEEPROFILEDATA";
export const CREATEMPLOYEEPFDATA = "CREATEMPLOYEEPFDATA";
export const CREATEMPLOYEEINSURANCEDATA = "CREATEMPLOYEEINSURANCEDATA";
export const SETEMPLOYEECLEARANCEFORM = "SETEMPLOYEECLEARANCEFORM";
export const SETEXITINTERVIEWFORM = "SETEXITINTERVIEWFORM";


