import React, { Component } from "react";
import axios from "axios";
import Header from "../../Header/Header.js";
import "./previouslyUploadedDocuments.css";
import {
  previouslyUploadedDocuments,
  spinner,
} from "../../../store/actions.js";
import { connect } from "react-redux";
import Buttons from "../../Containers/Buttons_tab/buttons.js";
import { Toast } from "primereact/toast";

class PreviouslyUploadedDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previouslyUploadedDocuments: null,
      profile1: {},
      files: [],
      uploadTemplateLength: 0,
      errors: {},
    };
  }
  componentDidMount() {
    this.props.dispatch(previouslyUploadedDocuments(true));
    this.props.dispatch(spinner(true));
    axios
      .get(
        "https://capv.live/portalprod/filesByEmployeeId/" +
          localStorage.getItem("empId")
      )
      .then((response) => {
        if (response.data.length > 0) {
          this.props.dispatch(spinner(false));
          this.setState({ previouslyUploadedDocuments: response.data });
        } else {
          this.toastTR.show({
            severity: "info",
            summary: "Message",
            detail: "Internal server error",
            life: 3000,
          });
        }
      });
    axios
      .get(
        "https://capv.live/portalprod/getEmployee?employeeId=" +
          localStorage.getItem("empId")
      )
      .then((response) => {
        this.setState({ profile1: response.data.result });
      });
    axios
      .get(
        "https://capv.live/portalprod/filesByEmployeeId/" +
          localStorage.getItem("empId")
      )
      .then((response) => {
        this.filesStoringState(response.data);
      });
  }

  FileValidation = (file) => {
    let result = false;
    // if (file !== "undefined") {
    if (
      (file !== undefined && file.size > 2000000) ||
      (file !== undefined &&
        file.type !== "image/png" &&
        file.type !== "image/jpg" &&
        file.type !== "image/jpeg" &&
        file.type !== "application/pdf" &&
        file.type !== "text/csv")
    ) {
      result = true;
    }

    return result;
    // }
  };

  uploadValidationForSize = () => {
    let errors = {};
    let chooseFiles = this.state.files;
    let formIsValid = true;

    for (let i = 0; i < chooseFiles.length; i++) {
      //ChooseFile0
      if (chooseFiles[i].chooseFile !== null) {
        if (this.FileValidation(chooseFiles[i].chooseFile)) {
          formIsValid = false;
          errors[`chooseFile${i}`] =
            // "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
            `${chooseFiles[i].fileName} size should be less than 2mb and only PNG/JPG/PDF/CSV Format.`;
        }
      }
    }
    this.setState({ errors: errors });
    return formIsValid;
  };
  refreshPage = () => {
    window.location.reload();
  };

  filesStoringState = (b) => {
    // for(let i=0; i<b.length; i++) {
    //   delete b[i].fileType
    //   delete b[i].name
    // }
    let a = b.map((c) => {
      return {
        ...c,
        chooseFile: null,
      };
    });
    this.setState({ files: a });
  };

  uploadHandler = (key, e) => {
    let fileTemplate = this.state.files;
    fileTemplate[key].chooseFile = e.target.files[0];
    this.setState({ files: fileTemplate });
    let uploadTemplate = this.state.files.filter((h) => h.chooseFile !== null);
    let uploadTemplateUndefined = this.state.files.filter(
      (h) => h.chooseFile === undefined
    );
    let length = uploadTemplate.length - uploadTemplateUndefined.length;
    this.setState({ uploadTemplateLength: length });
    this.uploadValidationForSize();
  };

  UpdateHandlerBtn = () => {
    // if(!this.state.errors) {
    this.props.dispatch(spinner(true));
    let uploadTemplate = this.state.files.filter((h) => h.chooseFile !== null);
    for (let i = 0; i < uploadTemplate.length; i++) {
      delete uploadTemplate[i].fileType;
      delete uploadTemplate[i].name;
      const formdata = new FormData();
      formdata.append("file", uploadTemplate[i].chooseFile);
      formdata.append("fileId", uploadTemplate[i].fileId);
      formdata.append("empId", localStorage.getItem("empId"));
      formdata.append("fileName", uploadTemplate[i].fileName);
      axios
        .put("https://capv.live/portalprod/updateFile", formdata, {
          params: {},
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          this.toastTR.show({
            severity: "success",
            summary: "Success Message",
            detail: "Saved Successfully",
            life: 3000,
          });
          if (response.status === 200) {
            this.refreshPage();
            this.props.dispatch(spinner(false));
          }
        });
    }
  };
  fetchingUploadedFilesAgain = () => {
    axios
      .get(
        "https://capv.live/portalprod/filesByEmployeeId/" +
          localStorage.getItem("empId")
      )
      .then((response) => {
        this.setState({ previouslyUploadedDocuments: response.data });
        this.filesStoringState(response.data);
      });
  };

  render() {
    return (
      <div>
        <Header />
        <h6 className="Instructions_Form">
          <strong className="strong">Instructions :</strong> You worked with us
          & have contributed your share towards this organization. We appreciate
          your services & are interested in having your views towards the
          company & your colleagues, during your tenure with us. We value your
          honest, objective & unbiased views on the following issues. We assure
          you that sharing this information with others would be kept anonymous
          & used to increase our commitment towards being a fair organization.
        </h6>
        <div>
          <Buttons />
        </div>
        <Toast ref={(el) => (this.toastTR = el)} position="top-right" />

        <div className="uploadDocument_Info" style={{ padding: "15px 25px" }}>
          <h1 className="Form55">Uploaded Documents</h1>

          <div
            className="previouslyUploadedDocx"
            style={{
              border: "1px solid #284caf",
              backgroundColor: "transparent",
            }}
          >
            {/* 1 */}
            <div
              style={{
                alignItems: "center",
                width: "100%",
                height: "100%",
              }}
            >
              {this.state.previouslyUploadedDocuments != null &&
                this.state.previouslyUploadedDocuments.map((p, index) => (
                  <>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                      }}
                      key={index}
                    >
                      <div className="previouslyUploadedDocx_filename">
                        <span>{p.fileName}:</span>
                      </div>
                      <div className="previouslyUploadedDocx_file">
                        <input
                          className="Input_fields"
                          style={{
                            border: "none",
                            padding: 10,
                          }}
                          type="file"
                          onChange={(e) => {
                            this.uploadHandler(index, e);
                          }}
                        />
                      </div>
                      <div style={{ width: "20%" }}>
                        <span
                          className="Input_fields_disabled"
                          style={{
                            textAlign: "start",
                            display: "flex",
                            alignItems: "center",
                            height: "auto",
                            fontSize: "14px",
                            color: "#284caf",
                          }}
                          disabled
                        >
                          {p.name}
                        </span>
                      </div>
                      {this.state.files.length > 0 && (
                        <div className="previouslyUploadedDocx_fileDownload">
                          <a
                            href={
                              "https://capv.live/portalprod/files/" +
                              this.state.files[index].fileId
                            }
                            className=""
                          >
                            <i className="pi pi-download"> Download</i>
                          </a>
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        color: "red",
                        width: "78%",
                      }}
                    >
                      {index === 0 && (
                        <span>{this.state.errors.chooseFile0}</span>
                      )}
                      {index === 1 && (
                        <span>{this.state.errors.chooseFile1}</span>
                      )}
                      {index === 2 && (
                        <span>{this.state.errors.chooseFile2}</span>
                      )}
                      {index === 3 && (
                        <span>{this.state.errors.chooseFile3}</span>
                      )}
                      {index === 4 && (
                        <span>{this.state.errors.chooseFile4}</span>
                      )}
                      {index === 5 && (
                        <span>{this.state.errors.chooseFile5}</span>
                      )}
                      {index === 6 && (
                        <span>{this.state.errors.chooseFile6}</span>
                      )}
                      {index === 7 && (
                        <span>{this.state.errors.chooseFile7}</span>
                      )}
                      {index === 8 && (
                        <span>{this.state.errors.chooseFile8}</span>
                      )}
                      {index === 9 && (
                        <span>{this.state.errors.chooseFile9}</span>
                      )}
                      {index === 10 && (
                        <span>{this.state.errors.chooseFile10}</span>
                      )}
                      {index === 11 && (
                        <span>{this.state.errors.chooseFile11}</span>
                      )}
                      {index === 12 && (
                        <span>{this.state.errors.chooseFile12}</span>
                      )}
                      {index === 13 && (
                        <span>{this.state.errors.chooseFile13}</span>
                      )}
                      {index === 14 && (
                        <span>{this.state.errors.chooseFile14}</span>
                      )}
                      {index === 15 && (
                        <span>{this.state.errors.chooseFile15}</span>
                      )}
                      {index === 16 && (
                        <span>{this.state.errors.chooseFile16}</span>
                      )}
                      {index === 17 && (
                        <span>{this.state.errors.chooseFile17}</span>
                      )}
                      {index === 18 && (
                        <span>{this.state.errors.chooseFile18}</span>
                      )}
                      {index === 19 && (
                        <span>{this.state.errors.chooseFile19}</span>
                      )}
                      {index === 20 && (
                        <span>{this.state.errors.chooseFile20}</span>
                      )}
                      {index === 21 && (
                        <span>{this.state.errors.chooseFile21}</span>
                      )}
                    </div>
                  </>
                ))}
            </div>
          </div>
        </div>
        {/* {(this.state.uploadTemplateLength > 0  && Object.keys(this.state.errors).length >0  ) && */}
        {this.state.uploadTemplateLength > 0 && (
          <div className="Submit_btn_userpage_admin_otp">
            <button className="save-btn" onClick={this.UpdateHandlerBtn}>
              Submit<i className="pi pi-send"></i>
            </button>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    save: state.save,
    empId: state.empId,
    email: state.email,
    profile: state.profile,
    pfdetails: state.pfdetails,
    insurance: state.insurance,
    empClearance: state.empClearance,
    exitinterview: state.exitinterview,
    empDocuments: state.empDocuments,
    empIdNotAssigned: state.empIdNotAssigned,
    showEdit: state.showEdit,
    showView: state.showView,
    enableFormForPfIns: state.enableFormForPfIns,
    btnStatus: state.btnStatus,
    isSpinner: state.isSpinner,
  };
};
export default connect(mapStateToProps)(PreviouslyUploadedDocuments);
