import axios from "axios";
import React from "react";
import "../previousForms.css";
import images from "../../../Assets/logo.png";
import Select from "react-select";
import "primeicons/primeicons.css";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import svg from "../../../Assets/excel.svg";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Checkbox } from "primereact/checkbox";
import {
  statusModeled,
  isassigneddata,
  spinner,
} from "../../../store/actions.js";
import { connect } from "react-redux";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import infinity from "../../../Assets/infinity3.gif";
import pacman from "../../../Assets/pacman.gif";

class previousForms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      SaveComment: {
        id: "",
        employeeId: "",
        comment: "",
        status: "",
        makerId: "",
      },

      updatedSaveComment: {
        id: "",
        employeeId: "",
        comment: "",
        status: "",
        makerId: "",
      },

      updateLocationComany: {
        id: "",
        employeeId: "",
        company: "",
        workLocation: "",
      },
      previousUpdatedLocationComapany: null,
      previousUpdatedLocation: null,
      verifyUpdateSaveComment: null,
      verifyRoleView: null,
      submitBtnDisable: true,
      highestemployementlength: 0,
      ifAdminLogoutClicked: false,
      alreadyAssigned: false,
      statusTemplate: null,
      errors: {},
      employeecodestore: {},
      allemployeesCode: [],
      searchKey: "",
      employeePfData: [],
      employeeInsuranceData: [],
      updatedInsurancedata: null,
      updatedstatus: false,
      isCorrect: false,
      employees: [],
      notYetUpdated: false,
      updatedEmployees: [],
      currentUserRole: "",
      status: [],
      employeeId: "",
      dataHandled: false,
      employeeidStatus: "",
      empdetails: "",
      id: 1,
      excelUrl: "",
      userstatus: null,
      empcodeassign: "",
      pdfTaker: [],
      relationStatus: null,
      statuscodesubmitted: false,
      changes: "",
      isLogout: false,
      employeeStatus: null,
      failedTo: false,
      generatedCode: [],
      selectedOption: null,
      selectedUser: null,
      userstatusselection: {},
      selectedstatus: {},
      IsAssigned: true,
      files: [],
      disableButton: false,
      showdetails: "empdetails",
      fields: {},
      insurance_Id: 1,
      insurance_view_id: 1,
      ifSidebarClicked: true,
      employeestatusValue: null,
      getComments: null,
      errors: {},
      selectedstatusUpadted: "",
      apiData: [],
      allemployeePFData: [],
      _exporter: React.createRef(),
      displayExcelModal: false,
      financeTable: [
        {
          Sno: "1",
          itemName: "Loans/Advance",
          Remarks: "",
          signature: "",
        },
        {
          Sno: "2",
          itemName: "Travel Advance",
          Remarks: "",
          signature: "",
        },
        {
          Sno: "3",
          itemName: "Invoice Pending (If any)",
          Remarks: "",
          signature: "",
        },
      ],

      activityTable: [
        {
          SNo: "1",
          activityname: "",
          status: "",
          pending: true,
          completed: false,
        },

        {
          SNo: "2",
          activityname: "",
          status: "",
          pending: true,
          completed: false,
        },

        {
          SNo: "3",
          activityname: "",
          status: "",
          pending: true,
          completed: false,
        },
      ],
    };
    this.dataHandled = this.dataHandled.bind(this);
    this.dataHandled_forMobile = this.dataHandled_forMobile.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.pdfHandler = this.pdfHandler.bind(this);
    this.selectHandler = this.selectHandler.bind(this);
    this.renderFooter = this.renderFooter.bind(this);
    this.excelforAllEmployees = this.excelforAllEmployees.bind(this);
    this.excelforCurrentEmployee = this.excelforCurrentEmployee.bind(this);
  }

  componentDidMount() {
    this.setState({ selectedstatus: "" });
    this.setState({ userstatusselection: "" });
    if (localStorage.getItem("guestloggedIn") != null) {
      if (localStorage.getItem("guestloggedIn") == "profilelens") {
        axios
          .get(
            "https://capv.live/portalprod/getAllEmployeesByRole?role=profilelens"
          )
          .then((response) => {
            if (response.data.result) {
              this.dataHandled(
                response.data.result[0].id,
                response.data.result[0].id
              );
              this.setState({
                ...this.state,
                employees: response.data.result,
              });
            }
          });
      }
      if (localStorage.getItem("guestloggedIn") == "acheckglobalit") {
        axios
          .get(
            "https://capv.live/portalprod/getAllEmployeesByRole?role=acheckglobalit"
          )
          .then((response) => {
            if (response.data.result) {
              this.dataHandled(
                response.data.result[0].id,
                response.data.result[0].id
              );
              this.setState({
                ...this.state,
                employees: response.data.result,
              });
            }
          });
      }
      axios
        .get("https://capv.live/portalprod/getEmployeeStatus")
        .then((response) => {
          this.setState({ employeeStatus: response.data });
        });

      axios.get("https://capv.live/portalprod/getUsers").then((response) => {
        this.setState({ userstatus: response.data.result });
      });

      axios.get("https://capv.live/portalprod/getRelationData").then((response) => {
        this.setState({ relationStatus: response.data.result });
      });
    } else {
      this.props.history.push("/Admin");
    }
  }

  selectHandler = (e) => {
    if (this.state.selectedstatusUpadted == "") {
      this.setState({
        selectedstatus: e.target.value,
        userstatusselection: "",
      });
    }

    if (this.state.selectedstatusUpadted != "") {
      if (this.state.selectedstatusUpadted.code <= e.target.value.code) {
        this.setState({
          selectedstatus: e.target.value,
        });
        axios
          .get(
            "https://capv.live/portalprod/getEmployee?employeeId=" +
              this.state.employeeId
          )
          .then((response) => {
            this.setState({
              statusTemplate: response.data.result,
              currentUserRole: response.data.result.role,
            });
          });
      } else {
        if (
          this.state.selectedstatusUpadted.name === "Onboarded" &&
          e.target.value.code >= 2 &&
          e.target.value.code <= 4
        ) {
          this.setState({ selectedstatus: e.target.value });
        } else {
          this.toastTR.show({
            severity: "warn",
            summary: "Warn Message",
            detail: "You Cannot Change",
            life: 3000,
          });
        }
      }
    }
  };
  userselectHandler = (selectedUser) => {
    this.setState({ userstatusselection: selectedUser });
  };
  selectedstatusDisplay = (result) => {
    if (result == null) {
      result = "New Employee";
    }
    if (result != null) {
      let sampleData = this.state.employeeStatus.findIndex(
        (v) => v.status === result
      );
      let sampledataLength = sampleData + 1;
      let temObject = { name: result, code: sampledataLength };
      this.setState({
        selectedstatusUpadted: temObject,
        selectedstatus: temObject,
      });
    } else if (result === null) {
      console.log("fell");
    }
  };

  dataHandled = (employeeid, empdetails) => {
    this.setState({
      ...this.state,
      isCorrect: true,
      employeeId: employeeid,
      employeeNumber: employeeid,
      empdetails: empdetails,
    });

    this.setState({ showdetails: "empdetails" });
    axios
      .get("https://capv.live/portalprod/getEmployee?employeeId=" + employeeid)
      .then((response) => {
        if (response.data.result.status === null) {
          response.data.result.status = "New Employee";
          this.selectedstatusDisplay(response.data.result.status);
        } else {
          this.selectedstatusDisplay(response.data.result.status);
        }

        this.setState({ statusTemplate: response.data.result });
      });

    axios
      .get(
        `https://capv.live/portalprod/getEmployeeCodeById?employeeNumber=${employeeid}`
      )
      .then((response) => {
        this.setState({ employeecodestore: response.data.result });
      });

    axios
      .get(
        "https://capv.live/portalprod/getCommentsByEmployeeId?employeeId=" +
          employeeid
      )
      .then((response) => {
        if (response.data.result.length > 0) {
          this.setState({
            SaveComment: response.data.result[0],
            updatedSaveComment: response.data.result[0],
            verifyUpdateSaveComment: response.data.result[0].status,
          });
        } else {
          this.setState({
            SaveComment: {
              id: "",
              employeeId: "",
              comment: "",
              status: "",
              makerId: "",
            },

            updatedSaveComment: {
              id: "",
              employeeId: "",
              comment: "",
              status: "",
              makerId: "",
            },
          });
        }
      });
    axios
      .get(
        "https://capv.live/portalprod/getEmployeeLocation?employeeId=" + employeeid
      )
      .then((response) => {
        if (response.data.result != null) {
          this.setState({
            updateLocationComany: response.data.result,
            previousUpdatedLocationComapany: response.data.result.company,
            previousUpdatedLocation: response.data.result.workLocation,
          });
        } else {
          this.setState({
            updateLocationComany: {
              id: "",
              employeeId: "",
              company: "",
              workLocation: "",
            },
          });
        }
      });
  };

  dataHandled_forMobile = (employeeid, empdetails) => {
    this.setState({
      ifSidebarClicked: false,
      isCorrect: true,
      employeeId: employeeid,
      employeeNumber: employeeid,
      empdetails: empdetails,
      selectedstatus: "",
    });

    if (this.state.employeeId != "") {
      this.setState({ showdetails: "empdetails" });
    }

    axios
      .get(
        `https://capv.live/portalprod/getEmployeeCodeById?employeeNumber=${employeeid}`
      )
      .then((response) => {
        this.setState({ employeecodestore: response.data.result });
      });
  };
  closeAlert = () => {
    this.setState({ ifAdminLogoutClicked: false });
  };

  redirectToLoginPage = () => {
    localStorage.removeItem("adminloggedIn");
    this.props.history.push("/Admin");
  };

  searchHandler = (e) => {
    this.setState({ searchKey: e.target.value });
  };
  changedHandler = (e) => {
    this.setState({ empcodeassign: e.target.value });
  };
  submittedStatus = () => {
    this.props.dispatch(statusModeled(true));
  };
  downloadedpdf = () => {
    axios.post(
      "https://capv.live/portalprod/getEmployeePdf?employeeId=" +
        this.state.employeeId
    );
  };

  yesHandler = () => {
    this.setState({ submitBtnDisable: false });
    if (this.ValidationsForLocation()) {
      this.props.dispatch(spinner(true));

      if (
        this.state.selectedstatus.name === "Consultant" ||
        this.state.selectedstatus.name === "Notice period" ||
        this.state.selectedstatus.name === "Relieved"
      ) {
        this.setState({ currentUserRole: "" });
      }
      let template = this.state.statusTemplate;
      template.status = this.state.selectedstatus.name;
      template.role = this.state.userstatusselection.label;

      axios
        .put("https://capv.live/portalprod/updateEmployee", template)
        .then((response) => {
          if (response.data.message == "successfully updated") {
            this.props.dispatch(spinner(false));
            this.setState({
              updatedstatus: true,
              disableButton: true,
              dataHandled: true,
            });
            this.toastTR.show({
              severity: "success",
              summary: "Success Message",
              detail: "Updated Successfully",
              life: 1000,
            });
          }
          setTimeout(
            function () {
              this.setState({
                updatedstatus: false,
                disableButton: false,
                showdetails: "empdetails",
                submitBtnDisable: true,
              });
              if (localStorage.getItem("guestloggedIn") == "profilelens") {
                axios
                  .get(
                    "https://capv.live/portalprod/getAllEmployeesByRole?role=profilelens"
                  )
                  .then((response) => {
                    if (response.data.result) {
                      this.dataHandled(
                        response.data.result[0].id,
                        response.data.result[0].id
                      );
                      this.setState({
                        ...this.state,
                        employees: response.data.result,
                      });
                    }
                  });
              }
              if (localStorage.getItem("guestloggedIn") == "acheckglobalit") {
                axios
                  .get(
                    "https://capv.live/portalprod/getAllEmployeesByRole?role=acheckglobalit"
                  )
                  .then((response) => {
                    if (response.data.result) {
                      this.dataHandled(
                        response.data.result[0].id,
                        response.data.result[0].id
                      );
                      this.setState({
                        ...this.state,
                        employees: response.data.result,
                      });
                    }
                  });
              }
            }.bind(this),
            3000
          );
        });
      let completeStatus = this.state.updateLocationComany;
      completeStatus.employeeId = this.state.employeeId;

      if (completeStatus.id === "") {
        axios.post("https://capv.live/portalprod/saveLocation", completeStatus);
      } else if (completeStatus.id !== "") {
        axios.put(
          "https://capv.live/portalprod/updateEmployeeLocation",
          completeStatus
        );
      }

      this.props.dispatch(statusModeled(false));

      if (this.state.SaveComment.id == "") {
        let savedComments = this.state.SaveComment;
        savedComments.employeeId = this.state.employeeId;
        savedComments.comment = this.state.selectedstatus.name;
        savedComments.makerId = this.state.employeeId;
        axios.post("https://capv.live/portalprod/saveComment", savedComments);
      }

      if (this.state.SaveComment.id != "") {
        let savedComments = this.state.SaveComment;
        axios.put(
          "https://capv.live/portalprod/updateComment?id=" + this.state.employeeId,
          savedComments
        );
      }
    } else {
      this.props.dispatch(spinner(false));
      this.toastTR.show({
        severity: "error",
        summary: "Error Message",
        detail: "Validation failed",
        life: 3000,
      });
      this.props.dispatch(statusModeled(false));
    }
  };
  noHandler = () => {
    this.props.dispatch(statusModeled(false));
  };
  userStatus = (role) => {
    if (role != null && role != "") {
      let roleUpdated = this.state.userstatus.filter((v) => v.username == role);
      let roleView = { label: role, value: roleUpdated[0].role };
      this.setState({ userstatusselection: roleView, verifyRoleView: role });
    }
  };

  displaydetails = (showid) => {
    this.props.dispatch(isassigneddata(true));
    this.setState({ showdetails: showid });

    axios
      .get(
        `https://capv.live/portalprod/getEmployeeCodeById?employeeNumber=${this.state.employeeId}`
      )
      .then((response) => {
        this.setState({ employeecodestore: response.data.result });
      });
    axios
      .get(
        "https://capv.live/portalprod/getEmployee?employeeId=" +
          this.state.employeeId
      )
      .then((response) => {
        this.userStatus(response.data.result.role);
      });
    axios
      .get(
        "https://capv.live/portalprod/filesByEmployeeId/" + this.state.employeeId
      )
      .then((response) => {
        this.setState({ files: response.data });
      });
    axios
      .get(
        "https://capv.live/portalprod/getPfDetails?employeeId=" +
          this.state.employeeId
      )
      .then((response) => {
        this.setState({ employeePfData: [response.data.result] });
      });
    axios
      .get(
        "https://capv.live/portalprod/getInsurance?employeeId=" +
          this.state.employeeId
      )
      .then((response) => {
        this.setState({
          employeeInsuranceData: response.data.result,
        });
      });

    axios
      .get(
        "https://capv.live/portalprod/getEmployeeCodeById?employeeNumber=" +
          this.state.employeeId
      )
      .then((response) => {
        this.setState({ employeecodestore: response.data.result });
      });
    axios
      .get(
        "https://capv.live/portalprod/getEmployee?employeeId=" +
          this.state.employeeId
      )
      .then((response) => {
        this.setState({ statusTemplate: response.data.result });
      });
  };

  showConfirm = () => {
    this.setState({ ifAdminLogoutClicked: true });
  };

  onSidebar_click = () => {
    this.setState({ ifSidebarClicked: false });
    if (this.state.ifSidebarClicked == false) {
      this.setState({ ifSidebarClicked: true });
    }
  };
  FinanceChangeHandler = (e, id, field) => {
    let financeOnChange = this.state.financeTable;
    financeOnChange[id][field] = e.target.value;
    this.setState({ financeTable: financeOnChange });
  };

  adminClearanceHandler = (e, id, field) => {
    let onChangeVal = this.state.activityTable;
    onChangeVal[id][field] = e.target.value;
    if (field == "pending") {
      onChangeVal[id][field] = true;
      onChangeVal[id].completed = false;
    } else if (field == "completed") {
      onChangeVal[id][field] = true;
      onChangeVal[id].pending = false;
    }
    this.setState({ activityTable: onChangeVal });
  };

  renderFooter() {
    return (
      <div>
        <Button
          label="All Employees"
          icon="pi pi-users"
          onClick={this.excelforAllEmployees}
          className="p-button-help"
        />
        <Button
          label="Current Employee"
          icon="pi pi-user"
          onClick={this.excelforCurrentEmployee}
          autoFocus
        />
      </div>
    );
  }
  excelforAllEmployees = () => {
    this.setState({ displayExcelModal: false });
    let excelString = "";
    let emplen = 0;
    const mainFields = [
      "id",
      "aadharNumber",
      "bloodGroup",
      "date",
      "dateOfExpiry",
      "dateOfIssue",
      "dob",
      "email",
      "firstName",
      "lastName",
      "otherName",
      "passportNumber",
      "panCardNumber",
      "place",
      "role",
    ];

    const contactInfoObjKeys = [
      "address",
      "alternateContact",
      "city",
      "country",
      "emergencyContact",
      "nationality",
      "pincode",
    ];

    const contactInfoObjKeys2 = [
      "address2",
      "alternateContact2",
      "city2",
      "country2",
      "emergencyContact2",
      "nationality2",
      "pincode2",
    ];

    const educationInfoObj = [
      "degree",
      "collegeName",
      "universityName",
      "subject",
      "courseStartDate",
      "courseEndDate",
      "courseType",
      "addressOfInstitution",
      "city",
      "pincode",
      "landline1",
      "landline2",
    ];

    const employmentInfoObj = [
      "companyName",
      "companyAddress",
      "employedPlace",
      "city",
      "state",
      "pincode",
      "fromDate",
      "toDate",
      "designation",
      "remuneration",
      "employeeCode",
      "employmentNature",
      "supervisorName",
      "supervisorDesignation",
      "supervisorEmailId",
      "leavingReason",
    ];

    const employmentInfoObj2 = [
      "companyName2",
      "companyAddress2",
      "employedPlace2",
      "city2",
      "state2",
      "pincode2",
      "fromDate2",
      "toDate2",
      "designation2",
      "remuneration2",
      "employeeCode2",
      "employmentNature2",
      "supervisorName2",
      "supervisorDesignation2",
      "supervisorEmailId2",
      "leavingReason2",
    ];

    const employmentInfoObj3 = [
      "companyName3",
      "companyAddress3",
      "employedPlace3",
      "city3",
      "state3",
      "pincode3",
      "fromDate3",
      "toDate3",
      "designation3",
      "remuneration3",
      "employeeCode3",
      "employmentNature3",
      "supervisorName3",
      "supervisorDesignation3",
      "supervisorEmailId3",
      "leavingReason3",
    ];

    const employmentInfoObj4 = [
      "companyName4",
      "companyAddress4",
      "employedPlace4",
      "city4",
      "state4",
      "pincode4",
      "fromDate4",
      "toDate4",
      "designation4",
      "remuneration4",
      "employeeCode4",
      "employmentNature4",
      "supervisorName4",
      "supervisorDesignation4",
      "supervisorEmailId4",
      "leavingReason4",
    ];

    const employmentInfoObj5 = [
      "companyName5",
      "companyAddress5",
      "employedPlace5",
      "city5",
      "state5",
      "pincode5",
      "fromDate5",
      "toDate5",
      "designation5",
      "remuneration5",
      "employeeCode5",
      "employmentNature5",
      "supervisorName5",
      "supervisorDesignation5",
      "supervisorEmailId5",
      "leavingReason5",
    ];

    excelString += mainFields.join(",") + ",";
    excelString += contactInfoObjKeys.join(",") + ",";
    excelString += contactInfoObjKeys2.join(",") + ",";
    excelString += educationInfoObj.join(",") + ",";
    excelString += employmentInfoObj.join(",") + ",";
    excelString += employmentInfoObj2.join(",") + ",";
    excelString += employmentInfoObj3.join(",") + ",";
    excelString += employmentInfoObj4.join(",") + ",";
    excelString += employmentInfoObj5.join(",") + "\n";
    let highemplength = 0;
    this.state.employees.forEach((employee, index) => {
      mainFields.forEach((field) => {
        let currentemp = this.state.allemployeesCode.filter(
          (v) => v.employeeNumber === employee.id
        );

        excelString += employee[field]
          ? field === "id"
            ? currentemp.length > 0
              ? currentemp[0].code + " " + ","
              : "-" + ","
            : employee[field].toString().indexOf("T00:00:00") !== -1
            ? employee[field].toString().replaceAll("T00:00:00.000+00:00", "") +
              " " +
              ","
            : employee[field].toString() + " " + ","
          : "-" + ",";
      });

      employee.contactInfo.forEach((conactDetails) => {
        contactInfoObjKeys.forEach((conact) => {
          let data = "-";
          if (conactDetails[conact]) {
            data = conactDetails[conact].toString().replaceAll("\n", " ");
            data = data.toString().replaceAll(",", " ");
          }

          excelString += data + ",";
        });
      });

      //educationInfoObj
      educationInfoObj.forEach((conact) => {
        let data = "-";
        if (employee.educationInfo[conact]) {
          data = employee.educationInfo[conact]
            .toString()
            .replaceAll("\n", " ");

          data = data.toString().replaceAll(",", " ");

          if (data.toString().indexOf("T00:") !== -1) {
            data = data.toString().replaceAll("T00:00:00.000+00:00", " ");
          }
        }

        excelString += data + ",";
      });

      employee.employmentInfo.forEach((conactDetails) => {
        employmentInfoObj.forEach((conact) => {
          let data = "-";
          if (conactDetails[conact]) {
            data = conactDetails[conact].toString().replaceAll("\n", " ");

            data = data.toString().replaceAll(",", " ");

            if (data.toString().indexOf("T00:") !== -1) {
              data = data.toString().replaceAll("T00:00:00.000+00:00", " ");
            }
          }

          excelString += data + ",";
        });
      });
      if (employee.employmentInfo.length > emplen) {
        emplen = employee.employmentInfo.length;
      }

      excelString += "\n";
    });

    this.setState({ highestemployementlength: emplen }, () => {
      if (this.state.highestemployementlength === 0) {
        excelString = excelString.replace(
          employmentInfoObj.join(",") +
            "," +
            employmentInfoObj2.join(",") +
            "," +
            employmentInfoObj3.join(",") +
            "," +
            employmentInfoObj4.join(",") +
            "," +
            employmentInfoObj5.join(","),
          " "
        );
      }

      if (this.state.highestemployementlength === 1) {
        excelString = excelString.replace(
          employmentInfoObj2.join(",") +
            "," +
            employmentInfoObj3.join(",") +
            "," +
            employmentInfoObj4.join(",") +
            "," +
            employmentInfoObj5.join(","),
          ","
        );
      } else if (this.state.highestemployementlength === 2) {
        excelString = excelString.replace(
          employmentInfoObj3.join(",") +
            "," +
            employmentInfoObj4.join(",") +
            "," +
            employmentInfoObj5.join(","),
          ","
        );
      } else if (this.state.highestemployementlength === 3) {
        excelString = excelString.replace(
          employmentInfoObj4.join(",") + "," + employmentInfoObj5.join(","),
          ","
        );
      } else if (this.state.highestemployementlength === 4) {
        excelString = excelString.replace(employmentInfoObj5.join(","), ",");
      }
    });

    setTimeout(() => {
      if (excelString) {
        const blob = new Blob([excelString], { type: "text/csv" });

        let url = window.URL.createObjectURL(blob);

        this.setState({ ...this.state, excelUrl: url });

        const a = document.createElement("a");

        a.href = this.state.excelUrl;

        a.download = "EmployeesInfo.csv";

        a.click();
      }
    }, 500);
  };

  excelforCurrentEmployee = () => {
    this.setState({ displayExcelModal: false });
    let excelString = "";
    const mainFields = [
      "id",
      "aadharNumber",
      "bloodGroup",
      "date",
      "dateOfExpiry",
      "dateOfIssue",
      "dob",
      "email",
      "firstName",
      "lastName",
      "otherName",
      "passportNumber",
      "panCardNumber",
      "place",
      "role",
    ];

    const contactInfoObjKeys = [
      "address",
      "alternateContact",
      "city",
      "country",
      "emergencyContact",
      "nationality",
      "pincode",
    ];

    const contactInfoObjKeys2 = [
      "address2",
      "alternateContact2",
      "city2",
      "country2",
      "emergencyContact2",
      "nationality2",
      "pincode2",
    ];

    const educationInfoObj = [
      "degree",
      "collegeName",
      "universityName",
      "subject",
      "courseStartDate",
      "courseEndDate",
      "courseType",
      "addressOfInstitution",
      "city",
      "pincode",
      "landline1",
      "landline2",
    ];

    const employmentInfoObj = [
      "companyName",
      "companyAddress",
      "employedPlace",
      "city",
      "state",
      "pincode",
      "fromDate",
      "toDate",
      "designation",
      "remuneration",
      "employeeCode",
      "employmentNature",
      "supervisorName",
      "supervisorDesignation",
      "supervisorEmailId",
      "leavingReason",
    ];

    const employmentInfoObj2 = [
      "companyName2",
      "companyAddress2",
      "employedPlace2",
      "city2",
      "state2",
      "pincode2",
      "fromDate2",
      "toDate2",
      "designation2",
      "remuneration2",
      "employeeCode2",
      "employmentNature2",
      "supervisorName2",
      "supervisorDesignation2",
      "supervisorEmailId2",
      "leavingReason2",
    ];

    const employmentInfoObj3 = [
      "companyName3",
      "companyAddress3",
      "employedPlace3",
      "city3",
      "state3",
      "pincode3",
      "fromDate3",
      "toDate3",
      "designation3",
      "remuneration3",
      "employeeCode3",
      "employmentNature3",
      "supervisorName3",
      "supervisorDesignation3",
      "supervisorEmailId3",
      "leavingReason3",
    ];

    const employmentInfoObj4 = [
      "companyName4",
      "companyAddress4",
      "employedPlace4",
      "city4",
      "state4",
      "pincode4",
      "fromDate4",
      "toDate4",
      "designation4",
      "remuneration4",
      "employeeCode4",
      "employmentNature4",
      "supervisorName4",
      "supervisorDesignation4",
      "supervisorEmailId4",
      "leavingReason4",
    ];

    const employmentInfoObj5 = [
      "companyName5",
      "companyAddress5",
      "employedPlace5",
      "city5",
      "state5",
      "pincode5",
      "fromDate5",
      "toDate5",
      "designation5",
      "remuneration5",
      "employeeCode5",
      "employmentNature5",
      "supervisorName5",
      "supervisorDesignation5",
      "supervisorEmailId5",
      "leavingReason5",
    ];

    excelString += mainFields.join(",") + ",";
    excelString += contactInfoObjKeys.join(",") + ",";
    excelString += contactInfoObjKeys2.join(",") + ",";
    excelString += educationInfoObj.join(",") + ",";
    excelString += employmentInfoObj.join(",") + ",";
    excelString += employmentInfoObj2.join(",") + ",";
    excelString += employmentInfoObj3.join(",") + ",";
    excelString += employmentInfoObj4.join(",") + ",";
    excelString += employmentInfoObj5.join(",") + "\n";

    axios
      .get(
        "https://capv.live/portalprod/getEmployee?employeeId=" +
          this.state.employeeId
      )
      .then((response) => {
        if (response.data.status === "success") {
          let employee = response.data.result;

          mainFields.forEach((field) => {
            excelString += employee[field]
              ? field === "id"
                ? this.state.employeecodestore.employeeCode
                  ? this.state.employeecodestore.employeeCode + " " + ","
                  : "-" + ","
                : employee[field].toString().indexOf("T00:00:00") !== -1
                ? employee[field]
                    .toString()
                    .replaceAll("T00:00:00.000+00:00", "") +
                  " " +
                  ","
                : employee[field].toString() + " " + ","
              : "-" + ",";
          });

          employee.contactInfo.forEach((conactDetails) => {
            contactInfoObjKeys.forEach((conact) => {
              let data = "-";
              if (conactDetails[conact]) {
                data = conactDetails[conact].toString().replaceAll("\n", " ");

                data = data.toString().replaceAll(",", " ");
                if (data.toString().indexOf("T00:") !== -1) {
                  data = data.toString().replaceAll("T00:00:00.000+00:00", " ");
                }
              }

              excelString += data + ",";
            });
          });

          educationInfoObj.forEach((conact) => {
            let data = "-";
            if (employee.educationInfo[conact]) {
              data = employee.educationInfo[conact]
                .toString()
                .replaceAll("\n", " ");

              data = data.toString().replaceAll(",", " ");

              if (data.toString().indexOf("T00:") !== -1) {
                data = data.toString().replaceAll("T00:00:00.000+00:00", " ");
              }
            }

            excelString += data + ",";
          });

          employee.employmentInfo.forEach((conactDetails) => {
            employmentInfoObj.forEach((conact) => {
              let data = "-";
              if (conactDetails[conact]) {
                data = conactDetails[conact].toString().replaceAll("\n", " ");
                data = data.toString().replaceAll(",", " ");
                if (data.toString().indexOf("T00:") !== -1) {
                  data = data.toString().replaceAll("T00:00:00.000+00:00", " ");
                }
              }
              excelString += data + ",";
            });
          });
          if (employee.employmentInfo.length === 0) {
            excelString = excelString.replace(
              employmentInfoObj.join(",") +
                "," +
                employmentInfoObj2.join(",") +
                "," +
                employmentInfoObj3.join(",") +
                "," +
                employmentInfoObj4.join(",") +
                "," +
                employmentInfoObj5.join(","),
              " "
            );
          }

          if (employee.employmentInfo.length === 1) {
            excelString = excelString.replace(
              employmentInfoObj2.join(",") +
                "," +
                employmentInfoObj3.join(",") +
                "," +
                employmentInfoObj4.join(",") +
                "," +
                employmentInfoObj5.join(","),
              ","
            );
          } else if (employee.employmentInfo.length === 2) {
            excelString = excelString.replace(
              employmentInfoObj3.join(",") +
                "," +
                employmentInfoObj4.join(",") +
                "," +
                employmentInfoObj5.join(","),
              ","
            );
          } else if (employee.employmentInfo.length === 3) {
            excelString = excelString.replace(
              employmentInfoObj4.join(",") + "," + employmentInfoObj5.join(","),
              ","
            );
          } else if (employee.employmentInfo.length === 4) {
            excelString = excelString.replace(
              employmentInfoObj5.join(","),
              ","
            );
          }
        }

        excelString += "\n";

        // setTimeout(() => {
        if (excelString) {
          const blob = new Blob([excelString], { type: "text/csv" });

          let url = window.URL.createObjectURL(blob);

          this.setState({ ...this.state, excelUrl: url });

          const a = document.createElement("a");

          a.href = this.state.excelUrl;

          a.download = "CurrentEmployeeInfo.csv";

          a.click();
        }
        // }, 500)
      });

    this.setState({ displayExcelModal: false });
  };

  pdfHandler = () => {
    const PFdetailsObj = [
      "empCode",
      "empName",
      "fatherName",
      "maritalStatus",
      "mobile",
      "qualification",
      "dateOfJoining",
      "emailId",
      "employeeNumber",
      "dob",
      "bankAccountNumber",
      "bankName",
      "ifscCode",
      "nameAsPerBank",
      "pancardNumber",
      "nameAsPerPan",
      "aadharNumber",
      "nameAsPerAadhar",
      "gender",
      "uan",
    ];

    const insuranceObj = [
      "id",
      "empCode",
      "name",
      "relation",
      "dob",
      "age",
      "nominee",
      "employeeNumber",
      "employee",
    ];
    let excelString = "";
    if (this.state.showdetails === "empdetails") {
      this.setState({ displayExcelModal: true });
    } else if (this.state.showdetails == "pfdetails" && this.state.employeeId) {
      const getPfDetails = async () => {
        await axios
          .get(
            "https://capv.live/portalprod/getPfDetails?employeeId=" +
              this.state.employeeId
          )
          .then((response) => {
            if (response.data.status === "success") {
              if (response.data["result"] !== null) {
                excelString += PFdetailsObj.join(",") + "\n";
                PFdetailsObj.forEach((field) => {
                  let data = "-";
                  if (response.data["result"][field]) {
                    data = response.data["result"][field]
                      .toString()
                      .replaceAll("\n", " ");

                    data = data.toString().replaceAll(",", " ");
                  }

                  excelString += data + ",";
                });
              } else {
                this.toast.show({
                  severity: "error",
                  summary: "Error",
                  detail: "No Data to download..!",
                  life: 3000,
                });
                return;
              }
            } else {
              this.toast.show({
                severity: "error",
                summary: "Error",
                detail: "No Data to download..!",
                life: 3000,
              });
              return;
            }
          });
      };

      getPfDetails();
    } else if (
      this.state.showdetails == "insurancedetails" &&
      this.state.employeeId
    ) {
      const getInsurancedetails = async () => {
        await axios
          .get(
            "https://capv.live/portalprod/getInsurance?employeeId=" +
              this.state.employeeId
          )
          .then((response) => {
            this.setState({ employeeInsuranceData: response.data.result });

            if (response.data.status === "success") {
              if (response.data.result) {
                excelString += insuranceObj.join(",") + "\n";
                response.data.result.map((eachinsuree, index) => {
                  insuranceObj.forEach((eachfield) => {
                    let data = "-";
                    if (eachinsuree[eachfield]) {
                      data = eachinsuree[eachfield]
                        .toString()
                        .replaceAll("\n", " ");

                      data = data.toString().replaceAll(",", " ");
                    }

                    excelString += data + ",";
                  });
                  excelString += "\n";
                });
              } else {
                this.toast.show({
                  severity: "error",
                  summary: "Error",
                  detail: "No Insurance Data to download..!",
                  life: 3000,
                });
                return;
              }
            } else {
              this.toast.show({
                severity: "error",
                summary: "Error",
                detail: "No Insurance Data to download..!",
                life: 3000,
              });
              return;
            }
          });
      };

      getInsurancedetails();
    }

    setTimeout(() => {
      if (excelString) {
        const blob = new Blob([excelString], { type: "text/csv" });

        let url = window.URL.createObjectURL(blob);

        this.setState({ ...this.state, excelUrl: url });

        const a = document.createElement("a");

        a.href = this.state.excelUrl;

        a.download =
          this.state.showdetails == "pfdetails"
            ? "PFInfo.csv"
            : "InsuranceInfo.csv";

        a.click();
      }
    }, 500);
  };
  onChangeCompany = (e, field) => {
    let companyDetails = this.state.updateLocationComany;
    companyDetails[field] = e.target.value;
    this.setState({ updateLocationComany: companyDetails });
    this.ValidationsForLocation();
  };
  onChangelocation = (e, field) => {
    let companyLocation = this.state.updateLocationComany;
    companyLocation[field] = e.target.value;
    this.setState({ updateLocationComany: companyLocation });
    this.ValidationsForLocation();
  };
  savecommentHandler = (e, field) => {
    let commentsOnSave = this.state.SaveComment;
    commentsOnSave[field] = e.target.value;
    this.setState({ updatedSaveComment: commentsOnSave });
  };
  ValidationsForLocation = () => {
    let fields = this.state.updateLocationComany;
    let errors = {};
    let formIsValid = true;
    //company
    if (fields.company != "") {
      if (!/^[a-zA-Z, \n]{2,30}$/.test(fields.company)) {
        formIsValid = false;
        errors.company = "Special characters & numbers are not allowed";
      }
    }
    //workLocation
    if (fields.workLocation != "") {
      if (!/^[a-zA-Z]+$/.test(fields.workLocation)) {
        formIsValid = false;
        errors.workLocation = "Special characters & numbers are not allowed";
      }
    }
    this.setState({ errors: errors });
    return formIsValid;
  };
  render() {
    const { company, workLocation } = this.state.errors;

    let filteredemp = this.state.searchKey.length
      ? this.state.employees.filter(
          (item) =>
            (item.firstName != "" &&
              item.firstName
                .toLowerCase()
                .includes(this.state.searchKey.toLowerCase())) ||
            (item.lastName != "" &&
              item.lastName
                .toLowerCase()
                .includes(this.state.searchKey.toLowerCase())) ||
            (item.email != null &&
              item.email
                .toLowerCase()
                .includes(this.state.searchKey.toLowerCase()))
        )
      : this.state.employees;
    let filteredemployees = [];
    if (this.state.employees.length > 0) {
      filteredemployees = this.state.employees.filter(
        (emp) => emp.id === this.state.employeeId
      );
    }
    let filteredemployeespfdata = [];
    if (this.state.employeePfData[0] != null) {
      filteredemployeespfdata = this.state.employeePfData.filter(
        (emp) => emp.employeeNumber == this.state.employeeId
      );
    }
    let options = this.state.employeeStatus
      ? this.state.employeeStatus.map((v) => ({
          name: v.status,
          code: v.statusCode,
        }))
      : [{ name: "Select", code: "Select" }];
    let requestOptions = this.state.userstatus
      ? this.state.userstatus.map((v) => ({
          label: v.username,
          value: v.role,
        }))
      : [{ label: "Select", value: "Select" }];
    return (
      <div className="previousFormContent">
        <ConfirmDialog
          visible={this.state.ifAdminLogoutClicked}
          onHide={() => this.setState({ ifAdminLogoutClicked: false })}
          message="Are you sure you want to proceed?"
          header="Confirmation"
          icon="pi pi-exclamation-triangle"
          accept={this.redirectToLoginPage}
          reject={() => this.closeAlert}
        />
        <Toast ref={(el) => (this.toast = el)} />
        <Toast ref={(el1) => (this.toastTR = el1)} />
        {this.props.statusModal && (
          <div className="uploadSaved1">
            <ConfirmDialog
              visible={this.props.statusModal}
              message="Are you sure you want to change?"
              header="Confirmation"
              icon="pi pi-exclamation-triangle"
              onHide={() => this.props.dispatch(statusModeled(false))}
              accept={this.yesHandler}
              reject={this.noHandler}
            />
          </div>
        )}
        <div className="headerPart_forAdimn">
          <img className="image_capv" src={images} alt="capv" />
          <div className="signOut_Btn">
            <Button
              onClick={this.showConfirm}
              className="p-button-rounded p-button-warning capp2"
            >
              <i className="pi pi-sign-out"></i>SignOut
            </Button>
          </div>
        </div>
        {this.props.isSpinner && (
          <div style={{ width: "100%", height: "100%" }}>
            <div className="LoadingCmp_">
              <div className="LoadingCmp_Image">
                <img src={infinity} alt="pop" />
              </div>
            </div>
          </div>
        )}
        {this.state.employees.length <= 0 && (
          <div style={{ width: "100%", height: "100%" }}>
            <div className="LoadingCmp_">
              <div className="LoadingCmp_Image">
                <img src={pacman} alt="pop" />
              </div>
              <div>
                <h2>No Data Found</h2>
              </div>
            </div>
          </div>
          // <div className="LoadingCmp_">
          //   <div class="wrapper">
          //     <div class="loader-box">
          //       <div class="loader"></div>
          //       <div class="loader-text">Loading...</div>
          //     </div>
          //   </div>
          // </div>
        )}
        {this.state.employees.length > 0 && (
          <div className="Parent_forBody">
            {/* mobile view */}
            <div>
              {this.state.isCorrect && (
                <div className="employee_switchingBtns_forMobile">
                  <button
                    className={
                      this.state.showdetails == "empdetails"
                        ? "buttonactive"
                        : "button"
                    }
                    onClick={this.displaydetails.bind(this, "empdetails")}
                    type="button"
                  >
                    Employee
                  </button>
                  <button
                    className={
                      this.state.showdetails == "status"
                        ? "buttonactive"
                        : "button"
                    }
                    onClick={this.displaydetails.bind(this, "status")}
                    type="button"
                  >
                    Status
                  </button>

                  <button
                    className={
                      this.state.showdetails == "uploadeddocuments"
                        ? "buttonactive"
                        : "button"
                    }
                    onClick={this.displaydetails.bind(
                      this,
                      "uploadeddocuments"
                    )}
                    type="button"
                  >
                    Documents
                  </button>
                </div>
              )}
            </div>
            {/* // mobile view ended */}
            {/* sidebar for mobile */}
            {this.state.ifSidebarClicked == false && (
              <div className="sideClosed">
                <i onClick={this.onSidebar_click} className="pi pi-bars"></i>
              </div>
            )}
            {this.state.ifSidebarClicked && (
              <div className="Employee_userNames_mobileView">
                <div>
                  <i onClick={this.onSidebar_click} className="pi pi-bars"></i>

                  <h6 className="mail">Employee</h6>
                </div>

                <div className="capp6">
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <span className="p-input-icon-left ">
                      <i className="pi pi-search" />
                      <InputText
                        value={this.state.searchKey}
                        onChange={(e) => this.searchHandler(e)}
                        placeholder="Search"
                      />
                    </span>
                  </div>
                  {filteredemp.length > 0 && (
                    <ul>
                      {filteredemp.map((employeename) => {
                        return (
                          <li
                            key={employeename.id}
                            className={
                              this.state.employeeId == employeename.id
                                ? "liactive"
                                : "employeedata"
                            }
                            onClick={this.dataHandled_forMobile.bind(
                              this,
                              employeename.id
                            )}
                          >
                            {" "}
                            {employeename.firstName} {employeename.otherName}{" "}
                            {employeename.lastName}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </div>
            )}
            {/* sidebar for mobile ended */}
            {/* sidebar */}

            <div className="Employee_userNames">
              <div>
                <h6 className="mail">Employee</h6>
              </div>

              <div className="capp6">
                <div style={{ width: "100%", textAlign: "center" }}>
                  <span className="p-input-icon-left ">
                    <i className="pi pi-search" />
                    <InputText
                      value={this.state.searchKey}
                      onChange={(e) => this.searchHandler(e)}
                      placeholder="Search"
                    />
                  </span>
                </div>
                {filteredemp.length > 0 && (
                  <ul>
                    {filteredemp.map((employeename) => {
                      return (
                        <li
                          key={employeename.id}
                          className={
                            this.state.employeeId == employeename.id
                              ? "liactive"
                              : "employeedata"
                          }
                          onClick={this.dataHandled.bind(this, employeename.id)}
                        >
                          {" "}
                          {employeename.firstName} {employeename.otherName}{" "}
                          {employeename.lastName}{" "}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            </div>

            {/* sidebar ended */}
            {/* body */}
            <div className="employee_body">
              {/* employee_btns */}
              {this.state.isCorrect && (
                <div className="employee_switchingBtns">
                  <button
                    className={
                      this.state.showdetails == "empdetails"
                        ? "buttonactive"
                        : "button"
                    }
                    onClick={this.displaydetails.bind(this, "empdetails")}
                    type="button"
                  >
                    Employee Details
                  </button>
                  <button
                    className={
                      this.state.showdetails == "status"
                        ? "buttonactive"
                        : "button"
                    }
                    onClick={this.displaydetails.bind(this, "status")}
                    type="button"
                  >
                    Status
                  </button>
                  <button
                    className={
                      this.state.showdetails == "uploadeddocuments"
                        ? "buttonactive"
                        : "button"
                    }
                    onClick={this.displaydetails.bind(
                      this,
                      "uploadeddocuments"
                    )}
                    type="button"
                  >
                    Documents
                  </button>
                </div>
              )}
              {/* employees_btns ended */}
              {this.state.showdetails == "uploadeddocuments" &&
                this.state.isCorrect && (
                  <div className="capp8">
                    {this.state.files.map((emp) => {
                      return (
                        <ul key={emp.id}>
                          <li>
                            <div className="Employee_Details_">
                              <div className="empdetailsinadmin_H">
                                <span className="empdetailsinadmin">
                                  {emp.fileName}
                                </span>
                              </div>
                              <div className="colonalignment">:</div>
                              <div className="empdetailsinadmin_D">
                                <a
                                  href={
                                    "https://capv.live/portalprod/files/" +
                                    emp.fileId
                                  }
                                  className="FilesD"
                                >
                                  <i
                                    className="filesDowload"
                                    className="fa fa-download"
                                  >
                                    {" "}
                                    Download
                                  </i>
                                </a>
                              </div>
                            </div>
                          </li>
                        </ul>
                      );
                    })}
                  </div>
                )}

              {this.state.showdetails == "empdetails" && this.state.isCorrect && (
                <div className="redux">
                  <div className="capp8">
                    {filteredemployees != null &&
                      filteredemployees.map((empDetails) => {
                        return (
                          <ul key={empDetails.id}>
                            <li>
                              {this.state.isCorrect && (
                                <div className="employee_footer_uploadExcel_1266">
                                  {/* <img src={svg} alt="svh" /> */}
                                  <img
                                    alt="svh"
                                    src={svg}
                                    data-pr-tooltip="Download Excel"
                                  />
                                </div>
                              )}
                              {/* <div className="empdetailsinadminHeading">
                              <span
                                style={{
                                  color: "rgb(3 51 228)",
                                  fontWeight: "700",
                                  fontSize: "15px",
                                }}
                              >
                                Generated Code
                              </span>
                            </div> */}
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Employee Id
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <div>
                                    {this.state.employeecodestore
                                      .employeeCode == null ? (
                                      <div>
                                        <span className="empdetailsinadminD">
                                          Not Assigned
                                        </span>
                                      </div>
                                    ) : (
                                      <div>
                                        <span className="empdetailsinadminD">
                                          {
                                            this.state.employeecodestore
                                              .employeeCode
                                          }
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="empdetailsinadminHeading">
                                <span
                                  style={{
                                    color: "rgb(3 51 228)",
                                    fontWeight: "700",
                                    fontSize: "15px",
                                  }}
                                >
                                  Status
                                </span>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Role
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>

                                {empDetails.role == "" ||
                                empDetails.role == null ? (
                                  <div className="empdetailsinadmin_D">
                                    <span className="empdetailsinadminD">
                                      Not Updated
                                    </span>
                                  </div>
                                ) : (
                                  <div className="empdetailsinadmin_D">
                                    <span className="empdetailsinadminD">
                                      {empDetails.role}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Status
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>

                                <div className="empdetailsinadmin_D">
                                  {empDetails.status == null ? (
                                    <div>
                                      <span>New Employee</span>
                                    </div>
                                  ) : (
                                    <div>
                                      <span>{empDetails.status}</span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="empdetailsinadminHeading">
                                <span
                                  style={{
                                    color: "rgb(3 51 228)",
                                    fontWeight: "700",
                                    fontSize: "15px",
                                  }}
                                >
                                  Personal Info
                                </span>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    First Name
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {empDetails.firstName}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Last Name
                                  </span>
                                </div>

                                <div className="colonalignment">:</div>

                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {empDetails.lastName}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Middle Name
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {empDetails.otherName}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Gender
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {empDetails.gender}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">Dob</span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {/* {empDetails.dob != null &&
                                      empDetails.dob.split("T")[0]} */}
                                    {empDetails.dob != null &&
                                      empDetails.dob
                                        .split("-")[2]
                                        .split("T")[0]}
                                    -
                                    {empDetails.dob != null &&
                                      empDetails.dob.split("-")[1]}
                                    -
                                    {empDetails.dob != null &&
                                      empDetails.dob.split("-")[0]}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Email
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {empDetails.email}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Blood Group
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>

                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {empDetails.bloodGroup}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Passport Number
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {empDetails.passportNumber}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Date Of Issue
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {/* {empDetails.dateOfIssue != null &&
                                      empDetails.dateOfIssue.split("T")[0]} */}
                                    {empDetails.dateOfIssue != null &&
                                      empDetails.dateOfIssue
                                        .split("-")[2]
                                        .split("T")[0]}
                                    -
                                    {empDetails.dateOfIssue != null &&
                                      empDetails.dob.split("-")[1]}
                                    -
                                    {empDetails.dateOfIssue != null &&
                                      empDetails.dateOfIssue.split("-")[0]}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Date Of Expiry
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {/* {empDetails.dateOfExpiry != null &&
                                      empDetails.dateOfExpiry.split("T")[0]} */}
                                    {empDetails.dateOfExpiry != null &&
                                      empDetails.dateOfExpiry
                                        .split("-")[2]
                                        .split("T")[0]}
                                    -
                                    {empDetails.dateOfExpiry != null &&
                                      empDetails.dob.split("-")[1]}
                                    -
                                    {empDetails.dateOfExpiry != null &&
                                      empDetails.dateOfExpiry.split("-")[0]}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Pan Card Number
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {empDetails.panCardNumber}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Aadhar Number
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {empDetails.aadharNumber}
                                  </span>
                                </div>
                              </div>
                            </li>
                            {empDetails.contactInfo
                              .sort((a, b) => a.id - b.id)
                              .map((s, index) => (
                                <div key={index}>
                                  <br />
                                  <li>
                                    <div className="empdetailsinadminHeading">
                                      <span
                                        style={{
                                          color: "rgb(3 51 228)",
                                          fontWeight: "700",
                                          fontSize: "15px",
                                        }}
                                      >
                                        {index == 0
                                          ? "Present Address"
                                          : "Permanent Address"}
                                      </span>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Address
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {s.address}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          City
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {s.city}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Pincode
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {s.pincode}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          State
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {s.state}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Country
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {s.country}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Nationality
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {s.nationality}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Phone
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {s.phone}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Emergency Contact
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {s.emergencyContact}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Alternate Contact
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {s.alternateContact}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                </div>
                              ))}
                            <br />
                            <div>
                              <li>
                                <div className="empdetailsinadminHeading">
                                  <span
                                    style={{
                                      color: "rgb(3 51 228)",
                                      fontWeight: "700",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Education Info
                                  </span>
                                </div>
                              </li>
                              {filteredemployees.educationInfo == null && (
                                <div>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Degree
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {empDetails.educationInfo.degree}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          College Name
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {empDetails.educationInfo.collegeName}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          University Name
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {
                                            empDetails.educationInfo
                                              .universityName
                                          }
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Subject
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {empDetails.educationInfo.subject}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Course Start Date
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {/* {empDetails.educationInfo
                                            .courseStartDate != null &&
                                            empDetails.educationInfo.courseStartDate.split(
                                              "T"
                                            )[0]} */}
                                          {empDetails.educationInfo
                                            .courseStartDate != null &&
                                            empDetails.educationInfo.courseStartDate
                                              .split("-")[2]
                                              .split("T")[0]}
                                          -
                                          {empDetails.educationInfo
                                            .courseStartDate != null &&
                                            empDetails.educationInfo.courseStartDate.split(
                                              "-"
                                            )[1]}
                                          -
                                          {empDetails.educationInfo
                                            .courseStartDate != null &&
                                            empDetails.educationInfo.courseStartDate.split(
                                              "-"
                                            )[0]}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Course End Date
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {/* {empDetails.educationInfo
                                            .courseEndDate != null &&
                                            empDetails.educationInfo.courseEndDate.split(
                                              "T"
                                            )[0]} */}
                                          {empDetails.educationInfo
                                            .courseEndDate != null &&
                                            empDetails.educationInfo.courseEndDate
                                              .split("-")[2]
                                              .split("T")[0]}
                                          -
                                          {empDetails.educationInfo
                                            .courseEndDate != null &&
                                            empDetails.educationInfo.courseEndDate.split(
                                              "-"
                                            )[1]}
                                          -
                                          {empDetails.educationInfo
                                            .courseEndDate != null &&
                                            empDetails.educationInfo.courseEndDate.split(
                                              "-"
                                            )[0]}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Course Type
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {empDetails.educationInfo.courseType}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Address Of Institution
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {
                                            empDetails.educationInfo
                                              .addressOfInstitution
                                          }
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          City
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {empDetails.educationInfo.city}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          PinCode
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {empDetails.educationInfo.pinCode}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Landline1
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {empDetails.educationInfo.landline1}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Landline2
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {empDetails.educationInfo.landline2}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                </div>
                              )}
                              <br />
                            </div>
                            {empDetails.employmentInfo.length > 0 &&
                              empDetails.employmentInfo.map((el) => (
                                <div key={el.id}>
                                  <li>
                                    <div className="empdetailsinadminHeading">
                                      <span
                                        style={{
                                          color: "rgb(3 51 228)",
                                          fontWeight: "700",
                                          fontSize: "15px",
                                        }}
                                      >
                                        Experience
                                      </span>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Company Name
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {el.companyName}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Employed Place
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {el.employedPlace}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Company Address
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {el.companyAddress}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          City
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {el.city}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          State
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {el.state}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Pincode
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {el.pincode}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          From Date
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {/* {el.fromDate != null &&
                                            el.fromDate.split("T")[0]} */}
                                          {el.fromDate != null &&
                                            el.fromDate
                                              .split("-")[2]
                                              .split("T")[0]}
                                          -
                                          {el.fromDate != null &&
                                            el.fromDate.split("-")[1]}
                                          -
                                          {el.fromDate != null &&
                                            el.fromDate.split("-")[0]}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          To Date
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {/* {el.toDate != null &&
                                            el.toDate.split("T")[0]} */}
                                          {el.toDate != null &&
                                            el.toDate
                                              .split("-")[2]
                                              .split("T")[0]}
                                          -
                                          {el.toDate != null &&
                                            el.toDate.split("-")[1]}
                                          -
                                          {el.toDate != null &&
                                            el.toDate.split("-")[0]}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Designation
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {el.designation}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Remuneration
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {el.remuneration}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Employee Code
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {el.employeeCode}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Employment Nature
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {el.employmentNature}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Supervisor Name
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {el.supervisorName}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Supervisor Designation
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {el.supervisorDesignation}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Superrvisor EmailId
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {el.supervisorEmailId}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Leaving Reason
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {el.leavingReason}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                </div>
                              ))}
                          </ul>
                        );
                      })}
                  </div>
                </div>
              )}
              {this.state.showdetails == "status" && this.state.isCorrect && (
                <div>
                  <div className="personal_Info_label_input_Admin">
                    <div className="personal_Info_label_Admin">
                      <span>Status</span>
                    </div>
                    <div className="personal_Info_label_Admin_Colon">
                      <span>:</span>
                    </div>
                    <div className="personal_Info_input">
                      <Dropdown
                        value={this.state.selectedstatus}
                        options={options.filter(
                          (li) =>
                            li.name != "Onboarded" &&
                            li.name != "Relieved" &&
                            li.name != "New Employee" &&
                            li.name != "Consultant" &&
                            li.name != "Notice period"
                        )}
                        onChange={this.selectHandler}
                        optionLabel="name"
                        autoFocus
                        placeholder="Select any status"
                      />
                    </div>
                  </div>
                  {this.state.selectedstatus.name == "Send for Verification" &&
                    this.state.showdetails == "status" && (
                      <div>
                        <div className="personal_Info_label_input_Admin">
                          <div className="personal_Info_label_Admin">
                            <span>Role</span>
                          </div>
                          <div className="personal_Info_label_Admin_Colon">
                            <span>:</span>
                          </div>
                          <div className="personal_Info_input">
                            <Select
                              className="selecthandler2"
                              options={requestOptions}
                              value={this.state.userstatusselection}
                              onChange={this.userselectHandler}
                            ></Select>
                          </div>
                        </div>
                      </div>
                    )}
                  {(this.state.selectedstatusUpadted == "Onboarded" ||
                    this.state.selectedstatusUpadted == "Consultant" ||
                    this.state.selectedstatus.name == "Consultant" ||
                    this.state.selectedstatus.name == "Onboarded") && (
                    <div>
                      <div className="personal_Info_label_input_Admin">
                        <div className="personal_Info_label_Admin">
                          <span>Client</span>
                        </div>
                        <div className="personal_Info_label_Admin_Colon">
                          <span>:</span>
                        </div>
                        <div className="personal_Info_input">
                          <input
                            className="Input_fields_Admin"
                            type="text"
                            placeholder="Name"
                            value={this.state.updateLocationComany.company}
                            onChange={(e) => this.onChangeCompany(e, "company")}
                          ></input>
                        </div>
                        <div className="personal_Info_Error_Admin">
                          {company && <span className="Error">{company}</span>}
                        </div>
                      </div>
                      <div className="personal_Info_Error_Mobile_Admin">
                        {company && <span className="Error">{company}</span>}
                      </div>
                      <div className="personal_Info_label_input_Admin">
                        <div className="personal_Info_label_Admin">
                          <span>Work Location</span>
                        </div>
                        <div className="personal_Info_label_Admin_Colon">
                          <span>:</span>
                        </div>
                        <div className="personal_Info_input">
                          <input
                            className="Input_fields_Admin"
                            type="text"
                            placeholder="Location"
                            value={this.state.updateLocationComany.workLocation}
                            onChange={(e) =>
                              this.onChangelocation(e, "workLocation")
                            }
                          ></input>
                        </div>
                        <div className="personal_Info_Error_Admin">
                          {workLocation && (
                            <span className="Error">{workLocation}</span>
                          )}
                        </div>
                      </div>
                      <div className="personal_Info_Error_Mobile_Admin">
                        {workLocation && (
                          <span className="Error">{workLocation}</span>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="personal_Info_label_input_Admin">
                    <div className="personal_Info_label_Admin">
                      <span>Comment</span>
                    </div>
                    <div className="personal_Info_label_Admin_Colon">
                      <span>:</span>
                    </div>
                    <div className="personal_Info_input_Admin">
                      <InputTextarea
                        placeholder="Add a description...."
                        value={this.state.SaveComment.status}
                        onChange={(e) => this.savecommentHandler(e, "status")}
                        rows={6}
                        cols={40}
                        autoResize={true}
                      />
                    </div>
                    <div className="personal_Info_input_480">
                      <InputTextarea
                        placeholder="Add a description...."
                        autoResize={true}
                        value={this.state.SaveComment.status}
                        onChange={(e) => this.savecommentHandler(e, "status")}
                        rows={6}
                        cols={30}
                      />
                    </div>
                  </div>
                </div>
              )}
              {this.state.showdetails == "status" && this.state.isCorrect && (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  {this.state.updatedstatus && (
                    <Toast
                      ref={(el) => (this.toastTR = el)}
                      position="top-right"
                    />
                  )}
                </div>
              )}
              {this.state.showdetails == "status" && (
                <div className="Status_selection">
                  {this.state.submitBtnDisable &&
                    (this.state.previousUpdatedLocationComapany !==
                      this.state.updateLocationComany.company ||
                      this.state.previousUpdatedLocation !==
                        this.state.updateLocationComany.workLocation ||
                      this.state.selectedstatus.name !==
                        this.state.selectedstatusUpadted.name ||
                      this.state.verifyUpdateSaveComment !==
                        this.state.SaveComment.status ||
                      this.state.verifyRoleView !==
                        this.state.userstatusselection.label) && (
                      <button
                        className={
                          this.state.selectedstatus.name ==
                          "Send for Verification"
                            ? "buttonempcodeSubmit"
                            : "buttonempcodeSubmit1"
                        }
                        onClick={this.submittedStatus}
                        type="button"
                      >
                        Submit
                      </button>
                    )}
                </div>
              )}
            </div>
            {this.state.showdetails == "Relieved" ||
              this.state.showdetails == "uploadeddocuments" ||
              this.state.showdetails == "status" || (
                <div className="employee_footer_uploadExcel">
                  {/* <img  src={svg} alt="svh" /> */}

                  <Tooltip
                    target=".download_excel"
                    mouseTrack
                    position="left"
                    mouseTrackRight={16}
                  />
                  <img
                    className="download_excel"
                    onClick={this.pdfHandler}
                    alt="svh"
                    src={svg}
                    data-pr-tooltip="download data"
                  />

                  <Dialog
                    header="Header"
                    visible={this.state.displayExcelModal}
                    style={{ width: "50vw" }}
                    footer={this.renderFooter}
                    onHide={() => this.setState({ displayExcelModal: false })}
                  >
                    <p className="p-m-0">
                      {" "}
                      Please select any of the following options
                    </p>
                  </Dialog>
                </div>
              )}
          </div>
        )}
        {/* {this.state.employees.length <= 0 && (
          <h1 style={{ textAlign: "center" }}>No Data Found....</h1>
        )} */}
        ;
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isGuest: state.isGuest,
    statusModal: state.statusModal,
    IsAssigned: state.IsAssigned,
    admin_loggedOut: state.admin_loggedOut,
    isSpinner: state.isSpinner,
  };
};
export default connect(mapStateToProps)(previousForms);
