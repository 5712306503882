import React from "react";
import OtpPage from "./Components/Employee_Admin_Auth/Employee_otp/OtpPage.js";
import Form from "./Components/Employeee/Form/Form.js";
import Userpage from "./Components/Employee_Admin_Auth/Employee_login/Userpage.js";
import previousForms from "./Components/Admin/PreviousForms.js";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import Admin from "./Components/Employee_Admin_Auth/Admin_login/Admin.js";
import Pfdata from "./Components/Employeee/Pf/Pfdata.js";
import Profile from "./Components/Employeee/Profile/Profile.js";
import { connect } from "react-redux";
import Insurance from "./Components/Employeee/Insurance/Insurance.js";
import UploadDocuments from "./Components/Employeee/Documents/uploadDocuments.js";
import PreviousformsGuest from "./Components/Admin/Guest/previousformsGuest.js";
import "@lottiefiles/lottie-player";
import Employeeclearanceform from "./Components/Employeee/Clearance/EmployeeClearance.js";
import ExitInterviewForm from "./Components/Employeee/Exit_interview/ExitInterviewForm";
import PreviouslyUploadedDocuments from "./Components/Employeee/PreviuslyUploadedDocx/previouslyUploadedDocuments.js";
import LoadingComponent from "./Components/Containers/Loading_spinner/loadingComponent.js";
import {
  insuranceid,
  pfdataid,
  profileid,
  employeeclearance,
  exitinterviewfunction,
  previouslyUploadedDocuments,
  ifEmpIdNotGenerated,
  showEditButton,
  showViewButton,
  showFormPfIns,
  showButtonStatus,
} from "./store/actions.js";
import axios from "axios";

class App extends React.Component {
  componentDidMount() {
    if (localStorage.getItem("empId") != null) {
      axios
        .get(
          "https://capv.live/portalprod/getEmployee?employeeId=" +
            localStorage.getItem("empId")
        )
        .then((response) => {
          this.setState({ EmpData: response.data.result });
        });
    }
  }
  render() {
    return (
      <div>
        {this.props.isSpinner && <LoadingComponent />}
        <Router>
          <div>
            <Switch>
              <Route exact path="/" component={Userpage}></Route>
              <Route path="/OtpPage" component={OtpPage}></Route>
              <Route path="/Form" component={Form}></Route>
              <Route path="/previousForms" component={previousForms}></Route>
              <Route path="/Admin" component={Admin}></Route>
              <Route path="/Pfdata" component={Pfdata}></Route>
              <Route path="/Profile" component={Profile}></Route>
              <Route path="/Insurance" component={Insurance}></Route>
              <Route
                path="/ExitInterviewForm"
                component={ExitInterviewForm}
              ></Route>
              <Route
                path="/EmployeeClearanceForm"
                component={Employeeclearanceform}
              ></Route>
              <Route
                path="/UploadDocuments"
                component={UploadDocuments}
              ></Route>
              <Route
                path="/PreviousformsGuest"
                component={PreviousformsGuest}
              ></Route>
              <Route
                path="/PreviouslyUploadedDocuments"
                component={PreviouslyUploadedDocuments}
              ></Route>
            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isSpinner: state.isSpinner,
  };
};

export default connect(mapStateToProps)(App);

// import './App.css';
// import React, { Suspense, lazy } from 'react';
// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// const Userpage = lazy(() => import('./Components/Userpage.js'));
// const OtpPage = lazy(() => import('./Components/OtpPage.js'));
// const Form = lazy(() => import('./Components/Form.js'));
// const previousForms = lazy(() => import('./Components/PreviousForms.js'));
// const Admin = lazy(() => import('./Components/Admin.js'));
// const Pfdata = lazy(() => import('./Components/Pfdata.js'));
// const Profile = lazy(() => import('./Components/Profile.js'));
// const Insurance = lazy(() => import('./Components/Insurance.js'));
// const UploadDocuments = lazy(() => import('./Components/uploadDocuments.js'));
// const PreviousformsGuest = lazy(() => import('./Components/previousformsGuest.js'));

// class App extends React.Component {
//   render () {
//     return(
//       <div>
//       <Router>
//       <Suspense fallback={<div>Page is Loading...</div>}>
//         <div>
//           <Switch>
//             <Route exact path="/" component={Userpage} ></Route>
//             <Route  path="/OtpPage" component={OtpPage} ></Route>
//             <Route  path="/Form" component={Form}></Route>
//             <Route  path="/previousForms" component={previousForms}></Route>
//             <Route  path="/Admin" component={Admin}></Route>
//             <Route  path="/Pfdata" component={Pfdata}></Route>
//             <Route  path="/Profile" component={Profile}></Route>
//             <Route  path="/Insurance" component={Insurance}></Route>
//             <Route  path="/UploadDocuments" component={UploadDocuments}></Route>
//             <Route  path="/PreviousformsGuest" component={PreviousformsGuest}></Route>
//           </Switch>
//         </div>
//         </Suspense>
//       </Router>
//       </div>
//     )
//   }
// }

// export default App;
