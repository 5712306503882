import React from "react";
import "./uploadDocument.css";
import axios from "axios";
import { connect } from "react-redux";
import Header from "../../Header/Header.js";
import { spinner } from "../../../store/actions.js";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { Toast } from "primereact/toast";

class UploadDocuments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      empId: "",
      chooseFile: null,
      errors: {},
      file: [],
      previouslyUploadedDocuments: null,
      uploadTemplate: [
        {
          id: 0,
          fileName: "photo",
          ChooseFile: null,
        },
        {
          id: 1,
          fileName: "Aadhar",
          ChooseFile: null,
        },
        {
          id: 2,
          fileName: "Passport",
          ChooseFile: null,
        },
        {
          id: 3,
          fileName: "Pan",
          ChooseFile: null,
        },
        {
          id: 4,
          fileName: "EducationalMarksheet",
          ChooseFile: null,
        },
        {
          id: 5,
          fileName: "Address",
          ChooseFile: null,
        },
        {
          id: 6,
          fileName: "AllYearMarksheet",
          ChooseFile: null,
        },
        {
          id: 7,
          fileName: "Appointment Letter1",
          ChooseFile: null,
        },
        {
          id: 8,
          fileName: "Salary Slip1",
          ChooseFile: null,
        },
        {
          id: 9,
          fileName: "Release Letter1",
          ChooseFile: null,
        },
        {
          id: 10,
          fileName: "Appointment Letter2",
          ChooseFile: null,
        },
        {
          id: 11,
          fileName: "Salary Slip2",
          ChooseFile: null,
        },
        {
          id: 12,
          fileName: "Release Letter2",
          ChooseFile: null,
        },
        {
          id: 13,
          fileName: "Appointment Letter3",
          ChooseFile: null,
        },
        {
          id: 14,
          fileName: "Salary Slip3",
          ChooseFile: null,
        },
        {
          id: 15,
          fileName: "Release Letter3",
          ChooseFile: null,
        },
        {
          id: 16,
          fileName: "Appointment Letter4",
          ChooseFile: null,
        },
        {
          id: 17,
          fileName: "Salary Slip4",
          ChooseFile: null,
        },
        {
          id: 18,
          fileName: "Release Letter4",
          ChooseFile: null,
        },
        {
          id: 19,
          fileName: "Appointment Letter5",
          ChooseFile: null,
        },
        {
          id: 20,
          fileName: "Salary Slip5",
          ChooseFile: null,
        },
        {
          id: 21,
          fileName: "Release Letter5",
          ChooseFile: null,
        },
      ],

      filer: [],
      isValid: false,
      isModal: false,
      isExpirenceExist: false,
      maxfilesize: 1024 * 1024,
      filesize: "",
      file_empty: true,
      size_validation: true,
      previous_formDetails: {},
      incorrect_data: false,
    };
    this.uploadHandler = this.uploadHandler.bind(this);
  }

  uploadHandler = (i, e) => {
    let requiredFields = this.state.uploadTemplate;
    requiredFields[i].ChooseFile = e.target.files[0];
    this.setState({ uploadTemplate: requiredFields });
    this.uploadValidationForSize();
  };

  FileValidation = (file) => {
    let result = false;

    if (
      file.size > 2000000 ||
      (file.type !== "image/png" &&
        file.type !== "image/jpg" &&
        file.type !== "image/jpeg" &&
        file.type !== "application/pdf" &&
        file.type !== "text/csv")
    ) {
      result = true;
    }
    //(result);
    return result;
  };

  uploadValidationForSize = () => {
    let errors = {};
    let requiredFields = this.state.uploadTemplate;
    let formIsValid = true;

    //ChooseFile0
    if (requiredFields[0].ChooseFile !== null) {
      if (this.FileValidation(requiredFields[0].ChooseFile)) {
        formIsValid = false;
        errors["ChooseFile0"] =
          "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
      }
    }
    // [^\\/]{3,}\.(pdf|txt|doc|docx|png|gif|jpeg|jpg|zip|rar)$

    if (requiredFields[1].ChooseFile !== null) {
      if (this.FileValidation(requiredFields[1].ChooseFile)) {
        formIsValid = false;
        errors["ChooseFile1"] =
          "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
      }
    }

    if (requiredFields[2].ChooseFile !== null) {
      if (this.FileValidation(requiredFields[2].ChooseFile)) {
        formIsValid = false;
        errors["ChooseFile2"] =
          "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
      }
    }
    if (requiredFields[3].ChooseFile !== null) {
      if (this.FileValidation(requiredFields[3].ChooseFile)) {
        formIsValid = false;
        errors["ChooseFile3"] =
          "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
      }
    }
    if (requiredFields[4].ChooseFile !== null) {
      if (this.FileValidation(requiredFields[4].ChooseFile)) {
        formIsValid = false;
        errors["ChooseFile4"] =
          "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
      }
    }
    if (requiredFields[5].ChooseFile !== null) {
      if (this.FileValidation(requiredFields[5].ChooseFile)) {
        formIsValid = false;
        errors["ChooseFile5"] =
          "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
      }
    }
    if (requiredFields[6].ChooseFile !== null) {
      if (this.FileValidation(requiredFields[6].ChooseFile)) {
        formIsValid = false;
        errors["ChooseFile6"] =
          "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
      }
    }
    if (this.state.previous_formDetails === 1) {
      //ChooseFile
      if (requiredFields[7].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[7].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile7"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
      //ChooseFile8
      if (requiredFields[8].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[8].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile8"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
      //ChooseFile9
      if (requiredFields[9].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[9].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile9"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
    }

    if (this.state.previous_formDetails === 2) {
      //ChooseFile
      if (requiredFields[7].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[7].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile7"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
      //ChooseFile8
      if (requiredFields[8].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[8].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile8"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
      //ChooseFile9
      if (requiredFields[9].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[9].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile9"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
      //ChooseFile12
      if (requiredFields[10].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[10].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile10"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
      //ChooseFile13
      if (requiredFields[11].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[11].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile11"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
      //ChooseFile14
      if (requiredFields[12].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[12].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile12"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
    }
    if (this.state.previous_formDetails === 3) {
      //ChooseFile7
      if (requiredFields[7].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[7].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile7"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
      //ChooseFile8
      if (requiredFields[8].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[8].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile8"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
      //ChooseFile9
      if (requiredFields[9].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[9].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile9"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
      //ChooseFile10
      if (requiredFields[10].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[10].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile10"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
      //ChooseFile11
      if (requiredFields[11].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[11].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile11"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
      //ChooseFile12
      if (requiredFields[12].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[12].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile12"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
      //ChooseFile13
      if (requiredFields[13].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[13].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile13"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
      //ChooseFile14
      if (requiredFields[14].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[14].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile14"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
      //ChooseFile15
      if (requiredFields[15].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[15].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile15"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
    }
    if (this.state.previous_formDetails === 4) {
      //ChooseFile
      if (requiredFields[7].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[7].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile7"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
      //ChooseFile8
      if (requiredFields[8].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[8].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile8"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
      //ChooseFile9
      if (requiredFields[9].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[9].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile9"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
      //ChooseFile10
      if (requiredFields[10].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[10].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile10"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
      //ChooseFile11
      if (requiredFields[11].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[11].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile11"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
      //ChooseFile12
      if (requiredFields[12].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[12].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile12"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
      //ChooseFile13
      if (requiredFields[13].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[13].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile13"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
      //ChooseFile14
      if (requiredFields[14].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[14].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile14"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
      //ChooseFile15
      if (requiredFields[15].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[15].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile15"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
      //ChooseFile16
      if (requiredFields[16].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[16].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile16"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
      //ChooseFile17
      if (requiredFields[17].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[17].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile17"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
      //ChooseFile18
      if (requiredFields[18].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[18].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile18"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
    }
    if (this.state.previous_formDetails === 5) {
      //ChooseFile7
      if (requiredFields[7].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[7].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile7"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
      //ChooseFile8
      if (requiredFields[8].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[8].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile8"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
      //ChooseFile9
      if (requiredFields[9].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[9].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile9"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
      //ChooseFile10
      if (requiredFields[10].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[10].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile10"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
      //ChooseFile11
      if (requiredFields[11].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[11].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile11"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
      //ChooseFile12
      if (requiredFields[12].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[12].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile12"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
      //ChooseFile13
      if (requiredFields[13].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[13].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile13"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
      //ChooseFile14
      if (requiredFields[14].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[14].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile14"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
      //ChooseFile15
      if (requiredFields[15].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[15].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile15"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
      //ChooseFile16
      if (requiredFields[16].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[16].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile16"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
      //ChooseFile17
      if (requiredFields[17].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[17].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile17"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
      //ChooseFile18
      if (requiredFields[18].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[18].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile18"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
      //ChooseFile19
      if (requiredFields[19].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[19].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile19"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
      //ChooseFile20
      if (requiredFields[20].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[20].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile20"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
      //ChooseFile21
      if (requiredFields[21].ChooseFile !== null) {
        if (this.FileValidation(requiredFields[21].ChooseFile)) {
          formIsValid = false;
          errors["ChooseFile21"] =
            "Only PNG/JPG/PDF/CSV Format File size should be less than 2mb";
        }
      }
    }
    this.setState({ errors: errors });
    return formIsValid;
  };

  handleValidation = () => {
    let errors = {};
    let requiredFields = this.state.uploadTemplate;
    let formIsValid = true;

    //ChooseFile0
    if (!requiredFields[0].ChooseFile) {
      formIsValid = false;
      errors["ChooseFile0"] = "Required";
    }

    //ChooseFile1
    if (!requiredFields[1].ChooseFile) {
      formIsValid = false;
      errors["ChooseFile1"] = "Required";
    }

    //ChooseFile2
    // if (!requiredFields[2].ChooseFile) {
    //   formIsValid = false;
    //   errors["ChooseFile2"] = "Required";
    // }

    //ChooseFile3
    if (!requiredFields[3].ChooseFile) {
      formIsValid = false;
      errors["ChooseFile3"] = "Required";
    }

    //ChooseFile4
    if (!requiredFields[4].ChooseFile) {
      formIsValid = false;
      errors["ChooseFile4"] = "Required";
    }

    //ChooseFile5
    if (!requiredFields[5].ChooseFile) {
      formIsValid = false;
      errors["ChooseFile5"] = "Required";
    }

    //ChooseFile6
    if (!requiredFields[6].ChooseFile) {
      formIsValid = false;
      errors["ChooseFile6"] = "Required";
    }

    if (this.state.previous_formDetails === 1) {
      //ChooseFile7
      if (!requiredFields[7].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile7"] = "Required";
      }
      //ChooseFile8
      if (!requiredFields[8].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile8"] = "Required";
      }
      //ChooseFile9
      if (!requiredFields[9].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile9"] = "Required";
      }
    }
    if (this.state.previous_formDetails === 2) {
      //ChooseFile7
      if (!requiredFields[7].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile7"] = "Required";
      }
      //ChooseFile8
      if (!requiredFields[8].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile8"] = "Required";
      }
      //ChooseFile9
      if (!requiredFields[9].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile9"] = "Required";
      }
      //ChooseFile10
      if (!requiredFields[10].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile10"] = "Required";
      }
      //ChooseFile11
      if (!requiredFields[11].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile11"] = "Required";
      }
      //ChooseFile12
      if (!requiredFields[12].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile12"] = "Required";
      }
    }
    if (this.state.previous_formDetails === 3) {
      //ChooseFile7
      if (!requiredFields[7].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile7"] = "Required";
      }
      //ChooseFile8
      if (!requiredFields[8].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile8"] = "Required";
      }
      //ChooseFile9
      if (!requiredFields[9].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile9"] = "Required";
      }
      //ChooseFile10
      if (!requiredFields[10].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile10"] = "Required";
      }
      //ChooseFile11
      if (!requiredFields[11].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile11"] = "Required";
      }
      //ChooseFile12
      if (!requiredFields[12].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile12"] = "Required";
      }
      //ChooseFile13
      if (!requiredFields[13].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile13"] = "Required";
      }
      //ChooseFile14
      if (!requiredFields[14].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile14"] = "Required";
      }
      //ChooseFile15
      if (!requiredFields[15].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile15"] = "Required";
      }
    }
    if (this.state.previous_formDetails === 4) {
      //ChooseFile7
      if (!requiredFields[7].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile7"] = "Required";
      }
      //ChooseFile8
      if (!requiredFields[8].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile8"] = "Required";
      }
      //ChooseFile9
      if (!requiredFields[9].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile9"] = "Required";
      }
      //ChooseFile10
      if (!requiredFields[10].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile10"] = "Required";
      }
      //ChooseFile11
      if (!requiredFields[11].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile11"] = "Required";
      }
      //ChooseFile12
      if (!requiredFields[12].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile12"] = "Required";
      }
      //ChooseFile13
      if (!requiredFields[13].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile13"] = "Required";
      }
      //ChooseFile14
      if (!requiredFields[14].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile14"] = "Required";
      }
      //ChooseFile15
      if (!requiredFields[15].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile15"] = "Required";
      }
      //ChooseFile16
      if (!requiredFields[16].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile16"] = "Required";
      }
      //ChooseFile17
      if (!requiredFields[17].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile17"] = "Required";
      }
      //ChooseFile18
      if (!requiredFields[18].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile18"] = "Required";
      }
    }
    if (this.state.previous_formDetails === 5) {
      //ChooseFile7
      if (!requiredFields[7].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile7"] = "Required";
      }
      //ChooseFile8
      if (!requiredFields[8].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile8"] = "Required";
      }
      //ChooseFile9
      if (!requiredFields[9].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile9"] = "Required";
      }
      //ChooseFile10
      if (!requiredFields[10].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile10"] = "Required";
      }
      //ChooseFile11
      if (!requiredFields[11].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile11"] = "Required";
      }
      //ChooseFile12
      if (!requiredFields[12].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile12"] = "Required";
      }
      //ChooseFile13
      if (!requiredFields[13].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile13"] = "Required";
      }
      //ChooseFile14
      if (!requiredFields[14].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile14"] = "Required";
      }
      //ChooseFile15
      if (!requiredFields[15].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile15"] = "Required";
      }
      //ChooseFile16
      if (!requiredFields[16].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile16"] = "Required";
      }
      //ChooseFile17
      if (!requiredFields[17].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile17"] = "Required";
      }
      //ChooseFile18
      if (!requiredFields[18].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile18"] = "Required";
      }
      //ChooseFile19
      if (!requiredFields[19].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile19"] = "Required";
      }
      //ChooseFile20
      if (!requiredFields[20].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile20"] = "Required";
      }
      //ChooseFile21
      if (!requiredFields[21].ChooseFile) {
        formIsValid = false;
        errors["ChooseFile21"] = "Required";
      }
    }
    this.setState({ errors: errors });
    return formIsValid;
  };

  madeSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidation() && this.uploadValidationForSize()) {
      this.setState({ incorrect_data: false });
      this.props.dispatch(spinner(true));
      let m;
      for (m = 0; m <= 12; m++) {
        const formdata = new FormData();
        formdata.append("file", this.state.uploadTemplate[m].ChooseFile);
        formdata.append("empId", localStorage.getItem("empId"));
        formdata.append("fileName", this.state.uploadTemplate[m].fileName);
        axios
          .post("https://capv.live/portalprod/upload", formdata, {
            params: {},
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response) => {
            if (response.status === 200) {
              this.props.dispatch(spinner(false));
              this.props.history.push("/Profile");
              this.toastBC.show({
                severity: "success",
                summary: "Success Message",
                detail: "Saved Successfully",
                life: 3000,
              });
            }
            return response;
          })
          .then((data) => {
            this.setState({ filer: [data] });
          })
          .catch((err) => {});
      }
    } else {
      this.toastBC.show({
        severity: "error",
        summary: "Error Message",
        detail: "Incorrect Data",
        life: 500,
      });
    }
  };
  createFileInputs = (file) => {
    //(file);
    this.setState({ previous_formDetails: file });
  };
  componentDidMount() {
    axios
      .get(
        "https://capv.live/portalprod/getEmployee?employeeId=" +
          localStorage.getItem("empId")
      )
      .then((response) => {
        this.setState({
          previous_formDetails: response.data.result,
        });
        this.createFileInputs(
          this.state.previous_formDetails.employmentInfo.length
        );
      });
    axios
      .get(
        "https://capv.live/portalprod/filesByEmployeeId/" +
          localStorage.getItem("empId")
      )
      .then((response) => {
        if (response.data.length > 0) {
          this.setState({ previouslyUploadedDocuments: response.data });
        }
      });
  }

  render() {
    const {
      ChooseFile0,
      ChooseFile1,
      ChooseFile2,
      ChooseFile3,
      ChooseFile4,
      ChooseFile5,
      ChooseFile6,
      ChooseFile7,
      ChooseFile8,
      ChooseFile9,
      ChooseFile10,
      ChooseFile11,
      ChooseFile12,
      ChooseFile13,
      ChooseFile14,
      ChooseFile15,
      ChooseFile16,
      ChooseFile17,
      ChooseFile18,
      ChooseFile19,
      ChooseFile20,
      ChooseFile21,
    } = this.state.errors;
    return (
      <div>
        <div className="Form11">
          <Header />
          <div>
            <form onSubmit={this.madeSubmit.bind(this)}>
              <div
                className="uploadDocument_Info"
                style={{ padding: "15px 25px" }}
              >
                <h1 className="Form55">Upload Documents</h1>
                <div
                  className="uploadDocument_fields"
                  style={{
                    border: "1px solid black",
                    backgroundColor: "transparent",
                  }}
                >
                  {/* 1 */}
                  <div className="uploadDocument_label_input">
                    <div className="UploadDocument_Label">
                      <span>Upload Photo:</span>
                    </div>
                    <div className="uploadDocument_input">
                      <input
                        className="Input_fields_upload_doc "
                        type="file"
                        max-size="2000000"
                        onChange={(e) => {
                          this.uploadHandler(0, e);
                        }}
                        style={{ border: "none" }}
                      ></input>
                    </div>
                    <div className="uploadDocument_Error">
                      {ChooseFile0 && (
                        <span className="Error">{ChooseFile0}</span>
                      )}
                    </div>
                  </div>
                  <div className="uploadDocument_Error_Mobile">
                    {ChooseFile0 && (
                      <span className="Error">{ChooseFile0}</span>
                    )}
                  </div>
                  {/* 2 */}
                  <div className="uploadDocument_label_input">
                    <div className="UploadDocument_Label">
                      <span>Upload Aadhar:</span>
                    </div>
                    <div className="uploadDocument_input">
                      <input
                        className="Input_fields_upload_doc"
                        style={{ border: "none" }}
                        type="file"
                        onChange={(e) => {
                          this.uploadHandler(1, e);
                        }}
                      ></input>
                    </div>
                    <div className="uploadDocument_Error">
                      {ChooseFile1 && (
                        <span className="Error">{ChooseFile1}</span>
                      )}
                    </div>
                  </div>
                  <div className="uploadDocument_Error_Mobile">
                    {ChooseFile1 && (
                      <span className="Error">{ChooseFile1}</span>
                    )}
                  </div>

                  {/* 3 */}
                  <div className="uploadDocument_label_input">
                    <div className="UploadDocument_Label">
                      <span>Upload Passport (optional) :</span>
                    </div>

                    <div className="uploadDocument_input">
                      <input
                        className="Input_fields_upload_doc"
                        type="file"
                        onChange={(e) => {
                          this.uploadHandler(2, e);
                        }}
                      ></input>
                    </div>
                    <div className="uploadDocument_Error">
                      {ChooseFile2 && (
                        <span className="Error">{ChooseFile2}</span>
                      )}
                    </div>
                  </div>
                  <div className="uploadDocument_Error_Mobile">
                    {ChooseFile2 && (
                      <span className="Error">{ChooseFile2}</span>
                    )}
                  </div>
                  {/* 4 */}
                  <div className="uploadDocument_label_input">
                    <div className="UploadDocument_Label">
                      <span>Upload Pan:</span>
                    </div>
                    <div className="uploadDocument_input">
                      <input
                        className="Input_fields_upload_doc"
                        type="file"
                        onChange={(e) => {
                          this.uploadHandler(3, e);
                        }}
                      ></input>
                    </div>
                    <div className="uploadDocument_Error">
                      {ChooseFile3 && (
                        <span className="Error">{ChooseFile3}</span>
                      )}
                    </div>
                  </div>
                  <div className="uploadDocument_Error_Mobile">
                    {ChooseFile3 && (
                      <span className="Error">{ChooseFile3}</span>
                    )}
                  </div>
                  {/* 4 */}
                  <div className="uploadDocument_label_input">
                    <div className="UploadDocument_Label">
                      <span>Educational Marksheets/Certificates:</span>
                    </div>
                    <div className="uploadDocument_input">
                      <input
                        className="Input_fields_upload_doc"
                        type="file"
                        onChange={(e) => {
                          this.uploadHandler(4, e);
                        }}
                      ></input>
                    </div>
                    <div className="uploadDocument_Error">
                      {ChooseFile4 && (
                        <span className="Error">{ChooseFile4}</span>
                      )}
                    </div>
                  </div>
                  <div className="uploadDocument_Error_Mobile">
                    {ChooseFile4 && (
                      <span className="Error">{ChooseFile4}</span>
                    )}
                  </div>
                  {/* 5 */}
                  <div className="uploadDocument_label_input">
                    <div className="UploadDocument_Label">
                      <span> Upload Address Proof:</span>
                    </div>
                    <div className="uploadDocument_input">
                      <input
                        className="Input_fields_upload_doc"
                        type="file"
                        onChange={(e) => {
                          this.uploadHandler(5, e);
                        }}
                      ></input>
                    </div>
                    <div className="uploadDocument_Error">
                      {ChooseFile5 && (
                        <span className="Error">{ChooseFile5}</span>
                      )}
                    </div>
                  </div>
                  <div className="uploadDocument_Error_Mobile">
                    {ChooseFile5 && (
                      <span className="Error">{ChooseFile5}</span>
                    )}
                  </div>
                  {/* 6 */}
                  <div className="uploadDocument_label_input">
                    <div className="UploadDocument_Label">
                      <span>
                        {" "}
                        Consolidated Certificate and provisional certificate:
                      </span>
                    </div>
                    <div className="uploadDocument_input">
                      <input
                        className="Input_fields_upload_doc"
                        type="file"
                        onChange={(e) => {
                          this.uploadHandler(6, e);
                        }}
                      ></input>
                    </div>
                    <div className="uploadDocument_Error">
                      {ChooseFile6 && (
                        <span className="Error">{ChooseFile6}</span>
                      )}
                    </div>
                  </div>
                  <div className="uploadDocument_Error_Mobile">
                    {ChooseFile6 && (
                      <span className="Error">{ChooseFile6}</span>
                    )}
                  </div>
                  {this.state.previous_formDetails === 1 && (
                    <>
                      <h3>Company-1 Details:</h3>
                      <div>
                        {/* 7 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span>Appointment Letter:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(7, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile7 && (
                              <span className="Error">{ChooseFile7}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile7 && (
                            <span className="Error">{ChooseFile7}</span>
                          )}
                        </div>
                        {/* 8 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span> Salary Slip:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(8, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile8 && (
                              <span className="Error">{ChooseFile8}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile8 && (
                            <span className="Error">{ChooseFile8}</span>
                          )}
                        </div>
                        {/* 9 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span> Release Letter:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(9, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile9 && (
                              <span className="Error">{ChooseFile9}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile9 && (
                            <span className="Error">{ChooseFile9}</span>
                          )}
                        </div>
                      </div>
                    </>
                  )}

                  {this.state.previous_formDetails === 2 && (
                    <>
                      <h3>Company-1 Details:</h3>
                      <div>
                        {/* 7 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span>Appointment Letter:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(7, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile7 && (
                              <span className="Error">{ChooseFile7}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile7 && (
                            <span className="Error">{ChooseFile7}</span>
                          )}
                        </div>
                        {/* 8 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span> Salary Slip:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(8, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile8 && (
                              <span className="Error">{ChooseFile8}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile8 && (
                            <span className="Error">{ChooseFile8}</span>
                          )}
                        </div>
                        {/* 9 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span> Release Letter:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(9, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile9 && (
                              <span className="Error">{ChooseFile9}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile9 && (
                            <span className="Error">{ChooseFile9}</span>
                          )}
                        </div>
                      </div>
                      <h3>Company-2 Details:</h3>
                      <div>
                        {" "}
                        {/* 10 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span>Appointment Letter:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(10, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile10 && (
                              <span className="Error">{ChooseFile10}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile10 && (
                            <span className="Error">{ChooseFile10}</span>
                          )}
                        </div>
                        {/* 11 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span> Salary Slip:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(11, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile11 && (
                              <span className="Error">{ChooseFile11}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile11 && (
                            <span className="Error">{ChooseFile11}</span>
                          )}
                        </div>
                        {/* 12 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span> Release Letter:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(12, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile12 && (
                              <span className="Error">{ChooseFile12}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile12 && (
                            <span className="Error">{ChooseFile12}</span>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {this.state.previous_formDetails === 3 && (
                    <>
                      <h3>Company-1 Details:</h3>
                      <div>
                        {/* 7 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span>Appointment Letter:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(7, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile7 && (
                              <span className="Error">{ChooseFile7}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile7 && (
                            <span className="Error">{ChooseFile7}</span>
                          )}
                        </div>
                        {/* 8 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span> Salary Slip:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(8, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile8 && (
                              <span className="Error">{ChooseFile8}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile8 && (
                            <span className="Error">{ChooseFile8}</span>
                          )}
                        </div>
                        {/* 9 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span> Release Letter:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(9, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile9 && (
                              <span className="Error">{ChooseFile9}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile9 && (
                            <span className="Error">{ChooseFile9}</span>
                          )}
                        </div>
                      </div>
                      <h3>Company-2 Details:</h3>
                      <div>
                        {/* 10 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span>Appointment Letter:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(10, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile10 && (
                              <span className="Error">{ChooseFile10}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile10 && (
                            <span className="Error">{ChooseFile10}</span>
                          )}
                        </div>
                        {/* 11 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span> Salary Slip:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(11, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile11 && (
                              <span className="Error">{ChooseFile11}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile11 && (
                            <span className="Error">{ChooseFile11}</span>
                          )}
                        </div>
                        {/* 12 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span> Release Letter:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(12, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile12 && (
                              <span className="Error">{ChooseFile12}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile12 && (
                            <span className="Error">{ChooseFile12}</span>
                          )}
                        </div>
                        <h3>Company-3 Details:</h3>
                        {/* 13 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span>Appointment Letter:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(13, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile13 && (
                              <span className="Error">{ChooseFile13}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile13 && (
                            <span className="Error">{ChooseFile13}</span>
                          )}
                        </div>
                        {/* 14 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span> Salary Slip:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(14, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile14 && (
                              <span className="Error">{ChooseFile14}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile14 && (
                            <span className="Error">{ChooseFile14}</span>
                          )}
                        </div>
                        {/* 15 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span> Release Letter:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(15, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile15 && (
                              <span className="Error">{ChooseFile15}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile15 && (
                            <span className="Error">{ChooseFile15}</span>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {this.state.previous_formDetails === 4 && (
                    <>
                      <h3>Company-1 Details:</h3>
                      {/* 7 */}
                      <div className="uploadDocument_label_input">
                        <div className="UploadDocument_Label">
                          <span>Appointment Letter:</span>
                        </div>
                        <div className="uploadDocument_input">
                          <input
                            className="Input_fields_upload_doc"
                            type="file"
                            onChange={(e) => {
                              this.uploadHandler(7, e);
                            }}
                          ></input>
                        </div>
                        <div className="uploadDocument_Error">
                          {ChooseFile7 && (
                            <span className="Error">{ChooseFile7}</span>
                          )}
                        </div>
                      </div>
                      <div className="uploadDocument_Error_Mobile">
                        {ChooseFile7 && (
                          <span className="Error">{ChooseFile7}</span>
                        )}
                      </div>
                      {/* 8 */}
                      <div className="uploadDocument_label_input">
                        <div className="UploadDocument_Label">
                          <span> Salary Slip:</span>
                        </div>
                        <div className="uploadDocument_input">
                          <input
                            className="Input_fields_upload_doc"
                            type="file"
                            onChange={(e) => {
                              this.uploadHandler(8, e);
                            }}
                          ></input>
                        </div>
                        <div className="uploadDocument_Error">
                          {ChooseFile8 && (
                            <span className="Error">{ChooseFile8}</span>
                          )}
                        </div>
                      </div>
                      <div className="uploadDocument_Error_Mobile">
                        {ChooseFile8 && (
                          <span className="Error">{ChooseFile8}</span>
                        )}
                      </div>
                      {/* 9 */}
                      <div className="uploadDocument_label_input">
                        <div className="UploadDocument_Label">
                          <span> Release Letter:</span>
                        </div>
                        <div className="uploadDocument_input">
                          <input
                            className="Input_fields_upload_doc"
                            type="file"
                            onChange={(e) => {
                              this.uploadHandler(9, e);
                            }}
                          ></input>
                        </div>
                        <div className="uploadDocument_Error">
                          {ChooseFile9 && (
                            <span className="Error">{ChooseFile9}</span>
                          )}
                        </div>
                      </div>
                      <div className="uploadDocument_Error_Mobile">
                        {ChooseFile9 && (
                          <span className="Error">{ChooseFile9}</span>
                        )}
                      </div>
                      <h3>Company-2 Details:</h3>
                      <div>
                        {/* 10 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span>Appointment Letter:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(10, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile10 && (
                              <span className="Error">{ChooseFile10}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile10 && (
                            <span className="Error">{ChooseFile10}</span>
                          )}
                        </div>
                        {/* 11 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span> Salary Slip:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(11, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile11 && (
                              <span className="Error">{ChooseFile11}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile11 && (
                            <span className="Error">{ChooseFile11}</span>
                          )}
                        </div>
                        {/* 12 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span> Release Letter:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(12, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile12 && (
                              <span className="Error">{ChooseFile12}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile12 && (
                            <span className="Error">{ChooseFile12}</span>
                          )}
                        </div>
                        <h3>Company-3 Details:</h3>
                        {/* 13 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span>Appointment Letter:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(13, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile13 && (
                              <span className="Error">{ChooseFile13}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile13 && (
                            <span className="Error">{ChooseFile13}</span>
                          )}
                        </div>
                        {/* 14 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span> Salary Slip:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(14, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile14 && (
                              <span className="Error">{ChooseFile14}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile14 && (
                            <span className="Error">{ChooseFile14}</span>
                          )}
                        </div>
                        {/* 15 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span> Release Letter:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(15, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile15 && (
                              <span className="Error">{ChooseFile15}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile15 && (
                            <span className="Error">{ChooseFile15}</span>
                          )}
                        </div>
                        <h3>Company-4 Details:</h3>
                        {/* 16 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span>Appointment Letter:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(16, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile16 && (
                              <span className="Error">{ChooseFile16}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile16 && (
                            <span className="Error">{ChooseFile16}</span>
                          )}
                        </div>
                        {/* 17 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span> Salary Slip:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(17, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile17 && (
                              <span className="Error">{ChooseFile17}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile17 && (
                            <span className="Error">{ChooseFile17}</span>
                          )}
                        </div>
                        {/* 18 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span> Release Letter:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(18, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile18 && (
                              <span className="Error">{ChooseFile15}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile18 && (
                            <span className="Error">{ChooseFile15}</span>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {this.state.previous_formDetails === 5 && (
                    <>
                      <h3>Company-1 Details:</h3>
                      <div>
                        {/* 7 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span>Appointment Letter:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(7, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile7 && (
                              <span className="Error">{ChooseFile7}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile7 && (
                            <span className="Error">{ChooseFile7}</span>
                          )}
                        </div>
                        {/* 8 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span> Salary Slip:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(8, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile8 && (
                              <span className="Error">{ChooseFile8}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile8 && (
                            <span className="Error">{ChooseFile8}</span>
                          )}
                        </div>
                        {/* 9 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span> Release Letter:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(9, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile9 && (
                              <span className="Error">{ChooseFile9}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile9 && (
                            <span className="Error">{ChooseFile9}</span>
                          )}
                        </div>
                      </div>
                      <h3>Company-2 Details:</h3>
                      <div>
                        {/* 10 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span>Appointment Letter:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(10, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile10 && (
                              <span className="Error">{ChooseFile10}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile10 && (
                            <span className="Error">{ChooseFile10}</span>
                          )}
                        </div>
                        {/* 11 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span> Salary Slip:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(11, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile11 && (
                              <span className="Error">{ChooseFile11}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile11 && (
                            <span className="Error">{ChooseFile11}</span>
                          )}
                        </div>
                        {/* 12 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span> Release Letter:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(12, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile12 && (
                              <span className="Error">{ChooseFile12}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile12 && (
                            <span className="Error">{ChooseFile12}</span>
                          )}
                        </div>
                        <h3>Company-3 Details:</h3>
                        {/* 13 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span>Appointment Letter:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(13, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile13 && (
                              <span className="Error">{ChooseFile13}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile13 && (
                            <span className="Error">{ChooseFile13}</span>
                          )}
                        </div>
                        {/* 14 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span> Salary Slip:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(14, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile14 && (
                              <span className="Error">{ChooseFile14}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile14 && (
                            <span className="Error">{ChooseFile14}</span>
                          )}
                        </div>
                        {/* 15 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span> Release Letter:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(15, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile15 && (
                              <span className="Error">{ChooseFile15}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile15 && (
                            <span className="Error">{ChooseFile15}</span>
                          )}
                        </div>
                        <h3>Company-4 Details:</h3>
                        {/* 16 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span>Appointment Letter:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(16, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile16 && (
                              <span className="Error">{ChooseFile16}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile16 && (
                            <span className="Error">{ChooseFile16}</span>
                          )}
                        </div>
                        {/* 17 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span> Salary Slip:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(17, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile17 && (
                              <span className="Error">{ChooseFile17}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile17 && (
                            <span className="Error">{ChooseFile17}</span>
                          )}
                        </div>
                        {/* 18 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span> Release Letter:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(18, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile18 && (
                              <span className="Error">{ChooseFile15}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile18 && (
                            <span className="Error">{ChooseFile15}</span>
                          )}
                        </div>
                        <h3>Company-5 Details:</h3>
                        {/* 19 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span>Appointment Letter:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(19, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile19 && (
                              <span className="Error">{ChooseFile19}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile19 && (
                            <span className="Error">{ChooseFile19}</span>
                          )}
                        </div>
                        {/* 20 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span> Salary Slip:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(20, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile20 && (
                              <span className="Error">{ChooseFile20}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile20 && (
                            <span className="Error">{ChooseFile20}</span>
                          )}
                        </div>
                        {/* 21 */}
                        <div className="uploadDocument_label_input">
                          <div className="UploadDocument_Label">
                            <span> Release Letter:</span>
                          </div>
                          <div className="uploadDocument_input">
                            <input
                              className="Input_fields_upload_doc"
                              type="file"
                              onChange={(e) => {
                                this.uploadHandler(21, e);
                              }}
                            ></input>
                          </div>
                          <div className="uploadDocument_Error">
                            {ChooseFile21 && (
                              <span className="Error">{ChooseFile21}</span>
                            )}
                          </div>
                        </div>
                        <div className="uploadDocument_Error_Mobile">
                          {ChooseFile21 && (
                            <span className="Error">{ChooseFile21}</span>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <Toast
                ref={(re) => (this.toastBC = re)}
                position="bottom-center"
              />
              {/* {this.state.incorrect_data && (
                <div style={{ textAlign: "center", color: "red" }}>
                  <span>Incorrect data</span>
                </div>
              )} */}
              <div className="Submit_btn_userpage_admin_otp">
                <button
                  className="save-btn"
                  onClick={(e) => this.madeSubmit(e)}
                >
                  Submit<i class="pi pi-send"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    empId: state.empId,
    selectedValue: state.selectedValue,
    AddedValue: state.AddedValue,
    isSpinner: state.isSpinner,
  };
};
export default connect(mapStateToProps)(UploadDocuments);
