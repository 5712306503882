import React, { Component } from "react";
import Header from "../../Header/Header.js";
import "./EmployeeClearance.css";
import "../Insurance/Insurance.css";
import {
  insuranceid,
  pfdataid,
  profileid,
  employeeclearance,
  spinner,
  exitinterviewfunction,
  ifEmpIdNotGenerated,
  showEditButton,
  showViewButton,
  showFormPfIns,
  showButtonStatus,
} from "../../../store/actions.js";
import axios from "axios";
import { connect } from "react-redux";
import { Toast } from "primereact/toast";
import { InputTextarea } from "primereact/inputtextarea";
import Buttons from "../../Containers/Buttons_tab/buttons.js";
import Icon, { FontAwesome } from "react-web-vector-icons";

const disable = "Input_fields_Disable";

class Employeeclearance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Employeeclearanceform1: {
        name: "",
        employeeNumber: Number(localStorage.getItem("empId")),
        empCode: "",
        designation: "",
        department: "",
        joiningDate: "",
        resignationDate: "",
        relievingDate: "",
        currentDate: "",
        email: "",
        contactAddress: "",
        mobile: "",
        alternateMobile: "",
        chooseFile: null,
      },
      errors: {},
      getPFData: [],
      getProfileData: [],
      showViewForm: true,
      EmpData: [],
      AreDetailsSubmitted: false,
      repetitionVal: 1,
      deviceList: [],
      employeeClearanceObject: null,
      employeeCode: "",
      prevEmployeeClearance_exists: false,
      propertydetailsStorage: [
        {
          id: "",
          activity: "",
          remarks: "",
          status: 0,
          employeeNumber: Number(localStorage.getItem("empId")),
          empCode: "",
        },
      ],
    };
    this.empClearanceHandler = this.empClearanceHandler.bind(this);
    this.RequiredValidation = this.RequiredValidation.bind(this);
  }
  storeUserData_fromPF = (result) => {
    this.state.Employeeclearanceform1.name =
      result.firstName + " " + result.lastName;
    this.state.Employeeclearanceform1.mobile = result.contactInfo[1].phone;
    this.state.Employeeclearanceform1.alternateMobile =
      result.contactInfo[1].alternateContact;
    this.props.dispatch(spinner(false));
  };
  store_joiningDate = (action) => {
    let tempObj_prop = this.state.Employeeclearanceform1;
    tempObj_prop["joiningDate"] = action.dateOfJoining;
    this.setState({ Employeeclearanceform1: tempObj_prop });
    this.props.dispatch(spinner(false));
  };

  componentDidMount() {
    this.props.dispatch(spinner(true));
    this.props.dispatch(employeeclearance(true));

    let date = new Date();
    let year = date.getFullYear();
    let month =
      date.getMonth() < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
    let day = date.getDate() > 10 ? date.getDate() : "0" + date.getDate();
    let currentDate = year + "-" + month + "-" + day;
    let item = this.state.Employeeclearanceform1;
    item.currentDate = currentDate;
    this.setState({ ...this.state, currentDate: currentDate });
    axios
      .get(
        "https://capv.live/portalprod/getEmployee?employeeId=" +
          localStorage.getItem("empId")
      )
      .then((response) => {
        if (response.data.result !== null) {
          let result = response.data.result;
          this.storeUserData_fromPF(result);
        }
      });
    axios
      .get(
        "https://capv.live/portalprod/getPfDetails?employeeId=" +
          localStorage.getItem("empId")
      )
      .then((response) => {
        if (response.data.result !== null) {
          let getpfdetails = response.data.result;
          this.store_joiningDate(getpfdetails);
        }
      });

    axios
      .get(
        "https://capv.live/portalprod/getClearanceInfoDetails?employeeId=" +
          localStorage.getItem("empId")
      )
      .then((response) => {
        if (
          response.data.result !== null &&
          Object.keys(response.data.result).length > 0
        ) {
          this.props.dispatch(showViewButton(true));
          this.setState({
            Employeeclearanceform1: response.data.result,
            prevEmployeeClearance_exists: true,
          });
        } else {
          this.props.dispatch(showEditButton(true));
          this.setState({ prevEmployeeClearance_exists: false });
        }
      });

    axios
      .get(
        "https://capv.live/portalprod/getAdminClearanceInfoDetails?employeeId=" +
          localStorage.getItem("empId")
      )
      .then((response) => {
        if (response.data.result.length > 0) {
          this.setState({ propertydetailsStorage: response.data.result });
          this.setState({ repetitionVal: response.data.result.length });
        }
      });

    axios
      .get(
        "https://capv.live/portalprod/getEmployeeCodeById?employeeNumber=" +
          localStorage.getItem("empId")
      )
      .then((response) => {
        if (
          response.data.result !== null &&
          response.data.result !== undefined
        ) {
          if (this.state.Employeeclearanceform1) {
            let obj = this.state.Employeeclearanceform1;

            obj.empCode = response.data.result.employeeCode;
            let propertyObj = this.state.propertydetailsStorage;
            propertyObj[0].empCode = obj.empCode;

            this.setState({ Employeeclearanceform1: obj });
            this.setState({ propertydetailsStorage: propertyObj });
            this.setState({ employeeCode: response.data.result.employeeCode });
          }
        }
      });
  }

  RequiredValidation() {
    let fields = this.state.Employeeclearanceform1;
    let errors = {};
    let formIsValid = true;

    if (fields && !fields.name) {
      formIsValid = false;
      errors.name = "Required";
    }

    if (fields && !fields.designation) {
      formIsValid = false;
      errors.designation = "Required";
    }

    if (fields && !fields.department) {
      formIsValid = false;
      errors.department = "Required";
    }

    if (fields && !fields.joiningDate) {
      formIsValid = false;
      errors.joiningDate = "Required";
    }

    if (fields && !fields.resignationDate) {
      formIsValid = false;
      errors.resignationDate = "Required";
    }

    if (fields && !fields.relievingDate) {
      formIsValid = false;
      errors.relievingDate = "Required";
    }

    if (fields && !fields.contactAddress) {
      formIsValid = false;
      errors.contactAddress = "Required";
    }

    if (fields && !fields.mobile) {
      formIsValid = false;
      errors.mobile = "Required";
    }

    if (fields && !fields.alternateMobile) {
      formIsValid = false;
      errors.alternateMobile = "Required";
    }

    if (fields && !fields.email) {
      formIsValid = false;
      errors.email = "Required";
    }

    if (fields && !fields.contactAddress) {
      formIsValid = false;
      errors.contactAddress = "Required";
    }

    if (Object.is(this.state.AreDetailsSubmitted, true)) {
      for (
        let index = 0;
        index < this.state.propertydetailsStorage.length;
        index++
      ) {
        if (!this.state.propertydetailsStorage[index].remarks) {
          formIsValid = false;
          this.toast.show({
            severity: "info",
            summary: "Info Message",
            detail: "Please fill all the fields",
            life: 3000,
          });
        }
        if (!this.state.propertydetailsStorage[index].activity) {
          formIsValid = false;
          this.toast.show({
            severity: "info",
            summary: "Info Message",
            detail: "Please fill all the fields",
            life: 3000,
          });
        }
      }
    }

    this.setState({ fields: fields });
    this.setState({ errors: errors });
    return formIsValid;
  }

  handleValidation() {
    //debugger
    let fields = this.state.Employeeclearanceform1;
    let errors = {};
    let device_fields = this.state.propertydetailsStorage;
    let formIsValid = true;

    //FirstName//
    if (fields.name !== "") {
      if (!/^[a-zA-Z, ]+$/.test(fields.name)) {
        formIsValid = false;
        errors.name = "Special characters & numbers are not allowed";
      }
    }

    if (fields.department !== "") {
      if (!/^[a-zA-Z, ]+$/.test(fields.department)) {
        formIsValid = false;
        errors.department = "Special characters & numbers are not allowed";
      }
    }

    if (fields.designation !== "") {
      if (!/^[a-zA-Z, ]+$/.test(fields.designation)) {
        formIsValid = false;
        errors.designation = "Special characters & numbers are not allowed";
      }
    }

    if (fields.email !== "") {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(fields.email)) {
        formIsValid = false;
        errors.email = "Please enter valid email";
      }
    }

    if (fields.mobile !== "") {
      if (!/^[0-9]{10}$/.test(fields.mobile)) {
        formIsValid = false;
        errors.mobile = "Mobile number must be 10 numbers";
      }
    }

    if (fields.alternateMobile !== "") {
      if (!/^[0-9]{10}$/.test(fields.alternateMobile)) {
        formIsValid = false;
        errors.alternateMobile = "Mobile number must be 10 numbers";
      }
    }
    if (fields.contactAddress !== "") {
      if (!/^[a-zA-Z0-9!\n-._ :/ , ]+$/.test(fields.contactAddress)) {
        formIsValid = false;
        errors.contactAddress = "Special characters are not allowed";
      }
    }

    for (
      let index = 0;
      index < this.state.propertydetailsStorage.length;
      index++
    ) {
      if (device_fields[index].activity !== "") {
        if (!/^[a-zA-Z0-9!\n-, ]+$/.test(device_fields[index].activity)) {
          formIsValid = false;
          errors.activity = "Special characters are not allowed";
        }
      }

      if (device_fields[index].remarks != "") {
        if (!/^[a-zA-Z0-9!\n-._ :/ , ]+$/.test(device_fields[index].remarks)) {
          formIsValid = false;
          errors.remarks = "Special characters are not allowed";
        }
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }
  empClearanceHandler_onChange = (e, field) => {
    if (this.state.Employeeclearanceform1) {
      let empChange = this.state.Employeeclearanceform1;
      empChange[field] = e.target.value;
      this.setState({ Employeeclearanceform1: empChange });
      this.handleValidation();
    }
  };
  insuranceHandler = () => {
    this.props.dispatch(insuranceid(true));
    this.props.history.push("/Insurance");
  };
  pfHandler = () => {
    this.props.dispatch(pfdataid(true));
    this.props.history.push("/Pfdata");
  };
  profileHandler = () => {
    this.props.dispatch(profileid(true));
    this.props.history.push("/Profile");
  };
  empClearanceHandler = () => {
    this.props.dispatch(employeeclearance(true));
    this.props.history.push("/Employeeclearanceform1form");
  };

  exitinterviewhandler = () => {
    this.props.dispatch(exitinterviewfunction(true));
    this.props.history.push("/ExitInterviewForm");
  };
  madeVisible = () => {
    this.setState({
      showViewForm: false,
      showEditForm: true,
    });
  };

  madeVisibleoff = () => {
    this.setState({
      showViewForm: true,
      showEditForm: false,
      errors: {},
    });
  };

  storeEmployeeclearanceform1Info = () => {
    let completeStatus = this.state.Employeeclearanceform1Object;
    axios
      .post("https://capv.live/portalprod/saveClearanceInfo", completeStatus)
      .then((response) => {
        this.setState({ Employeeclearanceform1: response.data.result });
      });
  };
  inputView = () => {
    return this.props.showView ? "Input_fields_Disable" : "Input_fields";
  };

  displayFields = (index) => {
    const { activity, remarks } = this.state.errors;
    return (
      <>
        <div className="personal_Info">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            {this.state.prevEmployeeClearance_exists === true && (
              <h6 className="Form55">Asset submission form</h6>
            )}
            <div
              style={{
                flexDirection: "row",
                alignItems: "center",
                display: "flex",
              }}
            >
              <h6
                onClick={() => {
                  this.setState({ AreDetailsSubmitted: false });
                }}
                style={{
                  fontSize: 14,
                  color: "#284caf",
                  margin: 0,
                  cursor: "pointer",
                  borderBottom: "1px solid #d7d7d7",
                }}
              >
                Employee clearance form
              </h6>
              {this.props.showEdit && (
                <div
                  className="pluswithtooltip"
                  style={{ position: "relative", width: "50px" }}
                >
                  <button
                    className="plus"
                    onClick={() => {
                      let currentState = this.state.propertydetailsStorage;
                      currentState.push({
                        activity: "",
                        remarks: "",
                        status: 0,
                        employeeNumber: Number(localStorage.getItem("empId")),
                        empCode: this.state.employeeCode,
                      });
                      this.setState({
                        propertydetailsStorage: currentState,
                      });
                    }}
                  >
                    +
                  </button>
                  <div className="plusTooltip">
                    <span className="addMore">Add Record</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {this.state.propertydetailsStorage.map((eachItem, index) => {
          return (
            <div
              className="personal_Info_fields"
              style={{
                display: "flex",
                flexWrap: "wrap",
                marginBottom: 12,
                marginLeft: 20,
                marginRight: 20,
                marginTop: 0,
              }}
            >
              <div
                className="input_modules"
                style={{ width: "80%", display: "flex", flex: 1 }}
              >
                <div
                  className="each_fiedname_sno"
                  style={{
                    width: "10%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <label className="sno_value" style={{ color: "#284caf" }}>
                    {index + 1}
                  </label>
                </div>
                <div className="each_fiedname" style={{ width: "45%" }}>
                  <div
                    className="personal_Info_label_input"
                    style={{ width: "100%" }}
                  >
                    <div
                      className="personal_Info_input"
                      style={{ width: "100%" }}
                    >
                      <input
                        className={this.inputView()}
                        type="text"
                        disabled={this.props.showView}
                        name="Property"
                        placeholder="Assets"
                        value={eachItem.activity}
                        onChange={(e) => {
                          let dummyvalue = this.state.propertydetailsStorage;
                          dummyvalue[index].activity = e.target.value;
                          this.setState({ propertydetailsStorage: dummyvalue });
                        }}
                        required
                        autocomplete="off"
                      ></input>
                    </div>
                  </div>
                </div>

                <div className="each_fiedname" style={{ width: "45%" }}>
                  <div
                    className="personal_Info_label_input"
                    style={{ width: "100%" }}
                  >
                    <div
                      className="personal_Info_input"
                      style={{ width: "100%" }}
                    >
                      <input
                        className={this.inputView()}
                        type="text"
                        disabled={this.props.showView}
                        name="Remarks"
                        placeholder="Remarks"
                        value={eachItem.remarks}
                        onChange={(e) => {
                          let dummyvalue = this.state.propertydetailsStorage;
                          dummyvalue[index].remarks = e.target.value;
                          this.setState({ propertydetailsStorage: dummyvalue });
                        }}
                        required
                        autocomplete="off"
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="btns_add_sub1"
                style={{ display: "flex", width: "20%" }}
              >
                {this.props.showEdit && (
                  <div style={{ display: "flex", width: "100%" }}>
                    <div
                      className="minuswithtooltip"
                      style={{ position: "relative" }}
                      onClick={() => {
                        this.removeEachID(eachItem.id, index);
                      }}
                    >
                      <FontAwesome
                        className="negative"
                        name="remove"
                        color="red"
                        onMouseOver={() => this.setState({ reduceHover: true })}
                        onMouseOut={() => this.setState({ reduceHover: false })}
                        size={25}
                        style={{
                          marginLeft: "50px",
                          cursor: "pointer",
                          fontWeight: 400,
                        }}
                      />

                      <div
                        className="minusTooltip"
                        style={{
                          visibility: "visible",
                        }}
                      >
                        <span className="reduceMore">Remove</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </>
    );
  };
  submitHandler_exitInterView = () => {
    this.props.dispatch(spinner(true));
    axios
      .get(
        "https://capv.live/portalprod/getAdminClearanceInfoDetails?employeeId=" +
          localStorage.getItem("empId")
      )
      .then((r) => {
        if (r.data.result.length > 0) {
          axios
            .put(
              "https://capv.live/portalprod/updateAdminClearance",
              this.state.propertydetailsStorage
            )
            .then((response) => {
              this.props.dispatch(spinner(false));
              this.toast.show({
                severity: "success",
                summary: "Success Message",
                detail: "You have updated the form successfully",
                life: 3000,
              });
              this.props.dispatch(showViewButton(true));
            });
        } else {
          axios
            .post(
              "https://capv.live/portalprod/saveAdminClearance",
              this.state.propertydetailsStorage
            )
            .then((response) => {
              this.props.dispatch(spinner(false));
              this.toast.show({
                severity: "success",
                summary: "Success Message",
                detail: "You have submitted successfully!!!",
                life: 3000,
              });

              this.props.dispatch(showViewButton(true));
            });
        }
      });
  };

  submitHandler = () => {
    if (this.RequiredValidation() && this.handleValidation()) {
      this.props.dispatch(spinner(true));
      if (this.state.prevEmployeeClearance_exists) {
        axios
          .put(
            "https://capv.live/portalprod/updateClearanceInfo",
            this.state.Employeeclearanceform1
          )
          .then((response) => {
            if (response.data.result !== null) {
              this.setState({ Employeeclearanceform1: response.data.result });
              this.props.dispatch(spinner(false));
              this.toast.show({
                severity: "success",
                summary: "Success Message",
                detail: "You have Updated the form successfully",
                life: 3000,
              });
            }
          });
      } else {
        axios
          .post(
            "https://capv.live/portalprod/saveClearanceInfo",
            this.state.Employeeclearanceform1
          )
          .then((response) => {
            response.data.result.empCode = this.state.employeeCode;
            response.data.result.relievingDate =
              response.data.result.relievingDate.split("T")[0];
            response.data.result.resignationDate =
              response.data.result.resignationDate.split("T")[0];
            response.data.result.joiningDate =
              response.data.result.joiningDate.split("T")[0];
            this.setState({
              employeeClearanceObject: [response.data.result],
              AreDetailsSubmitted: true,
              prevEmployeeClearance_exists: true,
            });
            this.props.dispatch(spinner(false));
            this.toast.show({
              severity: "success",
              summary: "Success Message",
              detail: "You have Updated the form successfully",
              life: 3000,
            });
          })
          .catch((err) => {
            this.props.dispatch(spinner(false));
            console.log(err);
          });
      }
      this.setState({ AreDetailsSubmitted: false });
    }
  };
  removeEachID = (id, index) => {
    this.props.dispatch(spinner(true));
    let findIndex = this.state.propertydetailsStorage.filter(
      (item) => item.id === id
    );
    if (findIndex !== -1) {
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(findIndex[0]),
      };
      fetch("https://capv.live/portalprod/deleteAdminClearanceInfo", requestOptions)
        .then((response) => {
          let currentObj = this.state.propertydetailsStorage;
          currentObj.splice(index, 1);
          this.setState({ propertydetailsStorage: currentObj });
          this.props.dispatch(spinner(false));
        })
        .catch((err) => {
          this.props.dispatch(spinner(false));
        });
    } else {
      let currentObj = this.state.propertydetailsStorage;
      currentObj.splice(index, 1);
      this.setState({ propertydetailsStorage: currentObj });
      this.props.dispatch(spinner(false));
    }
  };

  render() {
    const {
      name,
      department,
      designation,
      joiningDate,
      resignationDate,
      relievingDate,
      email,
      contactAddress,
      mobile,
      alternateMobile,
      chooseFile,
      currentDate,
    } = this.state.errors;
    return (
      <div className="input_field">
        <Header />
        <h6 className="Instructions_Form">
          <strong className="strong">Instructions :</strong> You worked with us
          & have contributed your share towards this organization. We appreciate
          your services & are interested in having your views towards the
          company & your colleagues, during your tenure with us. We value your
          honest, objective & unbiased views on the following issues. We assure
          you that sharing this information with others would be kept anonymous
          & used to increase our commitment towards being a fair organization.
        </h6>
        <div className="input_field">
          <Buttons />
        </div>

        <Toast ref={(el) => (this.toast = el)} />
        {this.state.AreDetailsSubmitted === false ? (
          <div className="personal_Info">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <h6 className="Form55">Employee Clearance Form</h6>
              {this.state.prevEmployeeClearance_exists === true && (
                <h6
                  onClick={() => {
                    this.setState({ AreDetailsSubmitted: true });
                  }}
                  style={{
                    fontSize: 14,
                    color: "#284caf",
                    margin: 0,
                    cursor: "pointer",
                    borderBottom: "1px solid #d7d7d7",
                  }}
                >
                  Asset submission form
                </h6>
              )}
            </div>
            <div className="personal_Info_fields">
              {/* empname */}
              <div className="personal_Info_label_input">
                <div className="input_field">
                  <div className="personal_Info_label">
                    <span>Name:</span>
                  </div>
                  <div className="personal_Info_input">
                    <input
                      className={disable}
                      type="text"
                      name="empName"
                      disabled
                      value={
                        this.state.Employeeclearanceform1 &&
                        this.state.Employeeclearanceform1.name
                      }
                      placeholder="Employee name"
                      autoComplete="off"
                    ></input>
                  </div>
                  <div
                    className="personal_Info_Error"
                    style={{
                      marginTop: "10px",
                      paddingLeft: "15px",
                      fontSize: "13px",
                    }}
                  >
                    {name && <span className="Error">{name}</span>}
                  </div>
                </div>
                <div className="personal_Info_Error_Mobile">
                  {name && <span className="Error">{name}</span>}
                </div>
              </div>
              {/* designation */}
              <div className="personal_Info_label_input">
                <div className="input_field">
                  <div className="personal_Info_label">
                    <span>Designation:</span>
                  </div>
                  <div className="personal_Info_input">
                    <input
                      className={this.inputView()}
                      type="text"
                      name="empName"
                      disabled={this.props.showView}
                      value={
                        this.state.Employeeclearanceform1 &&
                        this.state.Employeeclearanceform1.designation
                      }
                      onChange={(e) =>
                        this.empClearanceHandler_onChange(e, "designation")
                      }
                      placeholder="designation"
                      autoComplete="off"
                    ></input>
                  </div>
                </div>
                <div
                  className="personal_Info_Error"
                  style={{
                    marginTop: "10px",
                    paddingLeft: "15px",
                    fontSize: "13px",
                  }}
                >
                  {designation && <span className="Error">{designation}</span>}
                </div>
              </div>
              <div className="personal_Info_Error_Mobile">
                {designation && <span className="Error">{designation}</span>}
              </div>

              {/* department/Project: */}
              <div className="personal_Info_label_input">
                <div className="input_field">
                  <div className="personal_Info_label">
                    <span>Department/Project:</span>
                  </div>
                  <div className="personal_Info_input">
                    <input
                      className={this.inputView()}
                      type="text"
                      name="empName"
                      value={
                        this.state.Employeeclearanceform1 &&
                        this.state.Employeeclearanceform1.department
                      }
                      onChange={(e) =>
                        this.empClearanceHandler_onChange(e, "department")
                      }
                      disabled={this.props.showView}
                      placeholder="Project"
                      autoComplete="off"
                    ></input>
                  </div>
                </div>
                <div
                  className="personal_Info_Error"
                  style={{
                    marginTop: "10px",
                    paddingLeft: "15px",
                    fontSize: "13px",
                  }}
                >
                  {department && <span className="Error">{department}</span>}
                </div>

                <div className="personal_Info_Error_Mobile">
                  {department && <span className="Error">{department}</span>}
                </div>
              </div>
              {/* Joining Date: : */}
              <div className="personal_Info_label_input">
                <div className="input_field">
                  <div className="personal_Info_label">
                    <span>Joining Date:</span>
                  </div>
                  <div className="personal_Info_input">
                    <input
                      className="Input_fields_Disable"
                      type="date"
                      name="empName"
                      value={
                        this.state.Employeeclearanceform1 &&
                        this.state.Employeeclearanceform1.joiningDate.split(
                          "T"
                        )[0]
                      }
                      disabled
                      placeholder="Joining Date"
                      autoComplete="off"
                    ></input>
                  </div>
                  <div
                    className="personal_Info_Error"
                    style={{
                      marginTop: "10px",
                      paddingLeft: "15px",
                      fontSize: "13px",
                    }}
                  >
                    {joiningDate && (
                      <span className="Error">{joiningDate}</span>
                    )}
                  </div>
                </div>
                <div className="personal_Info_Error_Mobile">
                  {joiningDate && <span className="Error">{joiningDate}</span>}
                </div>
              </div>
              {/* Resignation Date: */}

              <div className="personal_Info_label_input">
                <div className="input_field">
                  <div className="personal_Info_label">
                    <span>Resignation Date:</span>
                  </div>
                  <div className="personal_Info_input">
                    <input
                      className={this.inputView()}
                      type="date"
                      name="empName"
                      value={
                        this.state.Employeeclearanceform1 &&
                        this.state.Employeeclearanceform1.resignationDate.split(
                          "T"
                        )[0]
                      }
                      onChange={(e) =>
                        this.empClearanceHandler_onChange(e, "resignationDate")
                      }
                      disabled={this.props.showView}
                      placeholder="Resignation Date"
                      autoComplete="off"
                    ></input>
                  </div>
                </div>
                <div
                  className="personal_Info_Error"
                  style={{
                    marginTop: "10px",
                    paddingLeft: "15px",
                    fontSize: "13px",
                  }}
                >
                  {resignationDate && (
                    <span className="Error">{resignationDate}</span>
                  )}
                </div>

                <div className="personal_Info_Error_Mobile">
                  {resignationDate && (
                    <span className="Error">{resignationDate}</span>
                  )}
                </div>
              </div>
              {/* Relieving Date:  */}
              <div className="personal_Info_label_input">
                <div className="input_field">
                  <div className="personal_Info_label">
                    <span>Relieving Date:</span>
                  </div>
                  <div className="personal_Info_input">
                    <input
                      className={this.inputView()}
                      type="date"
                      name="empName"
                      value={
                        this.state.Employeeclearanceform1 &&
                        this.state.Employeeclearanceform1.relievingDate.split(
                          "T"
                        )[0]
                      }
                      onChange={(e) =>
                        this.empClearanceHandler_onChange(e, "relievingDate")
                      }
                      disabled={this.props.showView}
                      placeholder="Relieving Date"
                      autoComplete="off"
                    ></input>
                  </div>
                </div>
                <div
                  className="personal_Info_Error"
                  style={{
                    marginTop: "10px",
                    paddingLeft: "15px",
                    fontSize: "13px",
                  }}
                >
                  {relievingDate && (
                    <span className="Error">{relievingDate}</span>
                  )}
                </div>

                <div className="personal_Info_Error_Mobile">
                  {relievingDate && (
                    <span className="Error">{relievingDate}</span>
                  )}
                </div>
              </div>
              <div className="furthercorrespondence">
                <h3 style={{ margin: 0 }}>
                  Address For Further Correspondence:
                </h3>
              </div>

              <div className="personal_info_fields2">
                <div className="personal_Info_label_input2">
                  <div class="input_field">
                    <div className="personal_Info_label">
                      <span>EmailID:</span>
                    </div>
                    <div className="personal_Info_input">
                      <input
                        className={this.inputView()}
                        type="text"
                        name="email"
                        disabled={this.props.showView}
                        value={
                          this.state.Employeeclearanceform1 &&
                          this.state.Employeeclearanceform1.email
                        }
                        onChange={(e) =>
                          this.empClearanceHandler_onChange(e, "email")
                        }
                        placeholder="Email"
                        autoComplete="off"
                      ></input>
                    </div>
                    <div
                      className="personal_Info_Error"
                      style={{
                        marginTop: "10px",
                        paddingLeft: "15px",
                        fontSize: "13px",
                      }}
                    >
                      {email && <span className="Error">{email}</span>}
                    </div>
                  </div>
                </div>
                <div className="personal_Info_Error_Mobile">
                  {email && <span className="Error">{email}</span>}
                </div>
                {/* Mobile */}
                {/* Alternate_Mobile */}
                <div className="personal_Info_label_input2">
                  <div class="input_field">
                    <div className="personal_Info_label">
                      <span>Mobile:</span>
                    </div>
                    <div className="personal_Info_input">
                      <input
                        className={this.inputView()}
                        type="text"
                        name="mobile"
                        disabled={this.props.showView}
                        value={
                          this.state.Employeeclearanceform1 &&
                          this.state.Employeeclearanceform1.mobile
                        }
                        onChange={(e) =>
                          this.empClearanceHandler_onChange(e, "mobile")
                        }
                        placeholder="Mobile No"
                        autoComplete="off"
                      ></input>
                    </div>
                    <div
                      className="personal_Info_Error"
                      style={{
                        marginTop: "10px",
                        paddingLeft: "15px",
                        fontSize: "13px",
                      }}
                    >
                      {mobile && <span className="Error">{mobile}</span>}
                    </div>
                  </div>
                </div>
                <div className="personal_Info_Error_Mobile">
                  {mobile && <span className="Error">{mobile}</span>}
                </div>

                {/* alternateMobile */}
                <div className="personal_Info_label_input2">
                  <div class="input_field">
                    <div className="personal_Info_label">
                      <span>Alternate Mobile No:</span>
                    </div>
                    <div className="personal_Info_input">
                      <input
                        className={this.inputView()}
                        type="text"
                        name="alternateMobile"
                        disabled={this.props.showView}
                        value={
                          this.state.Employeeclearanceform1 &&
                          this.state.Employeeclearanceform1.alternateMobile
                        }
                        onChange={(e) =>
                          this.empClearanceHandler_onChange(
                            e,
                            "alternateMobile"
                          )
                        }
                        placeholder="Alternate Mobile No"
                        autoComplete="off"
                      ></input>
                    </div>
                    <div
                      className="personal_Info_Error"
                      style={{
                        marginTop: "10px",
                        paddingLeft: "15px",
                        fontSize: "13px",
                      }}
                    >
                      {alternateMobile && (
                        <span className="Error">{alternateMobile}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="personal_Info_Error_Mobile">
                  {alternateMobile && (
                    <span className="Error">{alternateMobile}</span>
                  )}
                </div>
                {/* Contact Address */}
                <div
                  className="personal_Info_label_input3"
                  style={{ marginRight: 45 }}
                >
                  <div class="input_field">
                    <div className="personal_Info_label111">
                      <span>Contact Address:</span>
                    </div>
                    <div
                      className="personal_Info_input"
                      style={{ marginRight: "10px" }}
                    >
                      <InputTextarea
                        rows={4}
                        cols={18}
                        autoResize={true}
                        className={
                          this.props.showView
                            ? "Input_fields_textArea_disable"
                            : "Input_fields_textArea"
                        }
                        type="text"
                        style={{ marginLeft: 0 }}
                        name="contactAddress"
                        disabled={this.props.showView}
                        value={
                          this.state.Employeeclearanceform1 &&
                          this.state.Employeeclearanceform1.contactAddress
                        }
                        onChange={(e) =>
                          this.empClearanceHandler_onChange(e, "contactAddress")
                        }
                        placeholder=""
                        autoComplete="off"
                      />
                    </div>
                    <div
                      className="personal_Info_Error"
                      style={{
                        marginTop: "10px",
                        paddingLeft: "15px",
                        fontSize: "13px",
                      }}
                    >
                      {contactAddress && (
                        <span className="Error">{contactAddress}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="personal_Info_Error_Mobile">
                  {contactAddress && (
                    <span className="Error">{contactAddress}</span>
                  )}
                </div>
              </div>

              <div className="HRSign_Date">
                <div className="HRSign">
                  <div className="HR_label">
                    <span className="signature_forHr">Signature:</span>
                  </div>
                  <div className="signature_file_container">
                    <input
                      disabled={this.props.showView}
                      style={{
                        marginLeft: "5px",
                        border: this.props.showView && "none",
                      }}
                      type="file"
                    ></input>
                    {chooseFile && <span className="Error">{chooseFile}</span>}
                  </div>
                </div>
                <div className="HRDate">
                  <div className="HRDate_label">
                    <span className="signature_forHr">Date:</span>
                  </div>
                  <input
                    style={{ marginLeft: "5px", width: 300 }}
                    className="Input_fields_Disable"
                    disabled
                    type="date"
                    value={this.state.currentDate}
                  ></input>
                  {currentDate && (
                    <span style={{ marginLeft: "1%" }} className="Error">
                      {currentDate}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="input_field">{this.displayFields()}</div>
        )}

        <div className="Submit_btn_userpage_admin_otp">
          <button
            onClick={
              !Object.keys(this.state.errors).length > 0 &&
              this.state.AreDetailsSubmitted === false
                ? this.submitHandler
                : this.submitHandler_exitInterView
            }
            className={
              Object.keys(this.state.errors).length > 0
                ? "save-btn-disable"
                : "save-btn"
            }
            style={{ display: this.props.showView === true && "none" }}
          >
            Submit<i className="pi pi-send"></i>
          </button>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    empId: state.empId,
    uploadedpfdata: state.uploadedpfdata,
    profile: state.profile,
    pfdetails: state.pfdetails,
    insurance: state.insurance,
    empClearance: state.empClearance,
    exitinterview: state.exitinterview,
    empIdNotAssigned: state.empIdNotAssigned,
    showEdit: state.showEdit,
    showView: state.showView,
    enableFormForPfIns: state.enableFormForPfIns,
    btnStatus: state.btnStatus,
  };
};
export default connect(mapStateToProps)(Employeeclearance);
