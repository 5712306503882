import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import React from "react";
import bg from "../../../Assets/caprusit.mp4";
import Logo from "../../../Assets/logo.png";
import axios from "axios";
import { spinner, uploadid, uploadData } from "../../../store/actions.js";
import { connect } from "react-redux";
import Loading from "../../Containers/Loading_spinner/loadingComponent.js";
import { Toast } from "primereact/toast";

// type props = { dispatch:Function, isSpinner: boolean }

class OtpPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: {
        field1: "",
        field2: "",
        field3: "",
        field4: "",
      },
      max: [],
      error: {},
      isValid: false,
      codeAgain: false,
      isExist: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.otpPageHandler = this.otpPageHandler.bind(this);
  }

  componentDidMount() {}

  handleChange = (e, field) => {
    let requiredFields = this.state.otp;
    requiredFields[field] = e.target.value;
    this.setState({ otp: requiredFields });
    if (e.target.nextSibling !== null) {
      e.target.nextSibling.focus();
    }
    if (e.target.value === "") {
      e.target.focus();
    }
  };

  otpPageHandler = (e) => {
    e.preventDefault();
    let totalOtp = this.state.otp.field1.concat(
      this.state.otp.field2,
      this.state.otp.field3,
      this.state.otp.field4
    );
    this.props.dispatch(spinner(true));
    axios
      .post("https://capv.live/portalprod/verifyOTP", null, {
        params: {
          email: localStorage.getItem("email"),
          otp: totalOtp,
        },
      })
      .then((response) => {
        if (response.data.result.status == "success") {
          this.props.dispatch(spinner(false));
          this.props.history.push("/Form");
        }
        if (response.data.result.status == "email already exists") {
          this.props.dispatch(spinner(false));
          this.setState({ isExist: true });
          this.props.dispatch(uploadid(response.data.result.employeeId));
          localStorage.setItem("empId", response.data.result.employeeId);
          this.props.history.push("/Profile");
          this.toastTR.show({
            severity: "info",
            summary: "Info Message",
            detail: "Email already Exists",
            life: 3000,
          });
        }
        if (response.data.result.status == "invalid OTP") {
          this.props.dispatch(spinner(false));
          this.setState({ isValid: true });
        }
        return response;
      })
      .then((data) => {
        this.props.dispatch(spinner(false));
        this.toastTR.show({
          severity: "error",
          summary: "Error Message",
          detail: "Invalid OTP",
          life: 3000,
        });
      })
      .catch((err) => {
        this.props.dispatch(spinner(false));
      });
  };

  codeAgain = (e) => {
    this.setState({ isValid: false });
    e.preventDefault();
    this.setState({
      otp: {
        field1: "",
        field2: "",
        field3: "",
        field4: "",
      },
    });
    this.props.dispatch(spinner(true));
    axios
      .get("https://capv.live/portalprod/sendmail", {
        params: {
          email: localStorage.getItem("email"),
        },
      })
      .then((response) => {
        if (response.data.message == "email sent successfully") {
          this.props.dispatch(spinner(false));
          this.setState({ codeAgain: true });
          this.setState({ isValid: false });
        }
        return response.JSON;
      })
      .then((data) => {
        this.props.dispatch(spinner(false));
        this.toastTR.show({
          severity: "success",
          summary: "Success Message",
          detail: "OTP sent successfully",
          life: 3000,
        });
      })
      .catch((err) => {
        this.props.dispatch(spinner(false));
      });
  };
  userpage = () => {
    localStorage.removeItem("email");
    this.props.history.push("/");
  };
  refreshPage() {
    window.location.reload();
  }

  render() {
    return (
      <div className="HRloginpage">
        {this.props.isSpinner && <Loading />}
        <Toast ref={(el) => (this.toastTR = el)} position="top-right" />
        <video
          className="background_img"
          src={bg}
          autoPlay
          loop
          muted
          type="video/mp4"
        ></video>
        <div className="Caprus_Image_EmployeeBtn">
          <div style={{ width: "50%" }}>
            <img
              className="CaprusImage"
              onClick={this.refreshPage}
              src={Logo}
              alt="capv"
            />
          </div>
          <div className="EmployeeBtn"></div>
        </div>
        <div className="UserPage_Card">
          <div className="userPage_card_Inside">
            <div className="inside_card">
              <div>
                <form onSubmit={this.otpPageHandler} autoComplete="off">
                  <div className="Otpinput-submit">
                    <div className="otpinput">
                      <input
                        className="OTP_inputField"
                        type="number"
                        maxLength="1"
                        size="1"
                        required
                        min="0"
                        max="9"
                        value={this.state.otp.field1}
                        onChange={(e) => this.handleChange(e, "field1")}
                        onFocus={(e) => e.target.select()}
                        pattern="[0-9]{1}"
                        autocomplete="true"
                      />
                      <input
                        className="OTP_inputField"
                        name="Name1"
                        type="number"
                        maxLength="1"
                        size="1"
                        min="0"
                        max="9"
                        value={this.state.otp.field2}
                        pattern="[0-9]{1}"
                        onChange={(e) => this.handleChange(e, "field2")}
                        required
                        autocomplete="true"
                      ></input>
                      <input
                        className="OTP_inputField"
                        name="Name1"
                        type="number"
                        maxLength="1"
                        size="1"
                        min="0"
                        max="9"
                        value={this.state.otp.field3}
                        pattern="[0-9]{1}"
                        onChange={(e) => this.handleChange(e, "field3")}
                        autocomplete="true"
                        required
                      ></input>
                      <input
                        className="OTP_inputField"
                        name="Name1"
                        type="number"
                        maxLength="1"
                        size="1"
                        min="0"
                        max="9"
                        value={this.state.otp.field4}
                        pattern="[0-9]{1}"
                        onChange={(e) => this.handleChange(e, "field4")}
                        required
                        autocomplete="true"
                      ></input>
                    </div>
                    <div
                      style={{ marginTop: "10px" }}
                      className="Submit_btn_userpage_admin_otp"
                    >
                      <button className="Otp_Submit_Btn">
                        Submit <i class="pi pi-send"></i>
                      </button>
                    </div>
                  </div>
                </form>
                <div className="Otp_Modifiactions_">
                  <strong className="Didnt_recieve_OTP">
                    Didn't receive OTP?
                  </strong>
                  <a className="ResendOtp" onClick={this.codeAgain.bind(this)}>
                    Resend OTP
                  </a>
                  <a className="Email_Change" onClick={this.userpage}>
                    Change Email
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    email: state.email,
    isSpinner: state.isSpinner,
    empId: state.empId,
    //code by janya
    emailprovided: state.emailprovided,
  };
};

export default connect(mapStateToProps)(OtpPage);
