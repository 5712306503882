import React from "react";
import { connect } from "react-redux";
import "./loadingComponent.css";
import "@lottiefiles/lottie-player";

class Loading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="overrider">
        <div className="loadingImage">
          <lottie-player
            src="https://assets9.lottiefiles.com/packages/lf20_rm8ihy96.json"
            background="transparent"
            speed="1"
            style={{ width: "100px" }}
            loop
            autoplay
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isSpinner: state.isSpinner,
  };
};
export default connect(mapStateToProps)(Loading);
