import React from "react";
//karthik
//import bg from "../Assets/tea2.jfif";
import bg from "../../../Assets/caprusit.mp4";
import { connect } from "react-redux";
import { spinner, guestLogin } from "../../../store/actions.js";
import axios from "axios";
import Loading from "../../Containers/Loading_spinner/loadingComponent.js";
import Logo from "../../../Assets/logo.png";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { Toast } from "primereact/toast";

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        username: "",
        password: "",
      },
      errors: {},
      isSuccess: false,
      isUsername: false,
      isPassword: false,
      isGuest: false,
    };
    this.AdminPage = this.AdminPage.bind(this);
    this.AdminSubmit = this.AdminSubmit.bind(this);
  }

  AdminValidations = () => {
    let errors = {};
    let fields = this.state.fields;
    let formIsValid = true;

    // email Validition!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
    if (!fields.username) {
      formIsValid = false;
      errors.username = "Required";
      this.toastTR.show({
        severity: "error",
        summary: "Error Message",
        detail: "Username Required",
        life: 3000,
      });
    }
    this.setState({ errors: errors });
    return formIsValid;
  };

  passwordValidations = () => {
    let errors = {};
    let fields = this.state.fields;
    let formIsValid = true;

    if (!fields.password) {
      formIsValid = false;
      errors.password = "Required";
      this.toastTR.show({
        severity: "error",
        summary: "Error Message",
        detail: "Password Required",
        life: 3000,
      });
    }
    this.setState({ errors: errors });
    return formIsValid;
  };

  AdminPage = (e, field) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    this.AdminValidations();
    this.setState({ isUsername: false });
  };
  password_change = (e, field) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    this.passwordValidations();
    this.setState({ isUsername: false });
  };

  AdminSubmit(e) {
    e.preventDefault();
    if (this.AdminValidations() && this.passwordValidations()) {
      this.props.dispatch(spinner(true));
      axios
        .get("https://capv.live/portalprod/login", {
          params: {
            username: this.state.fields.username,
            password: this.state.fields.password,
          },
        })
        .then((response) => {
          this.props.dispatch(spinner(false));
          if (response.data.result.role == "admin") {
            this.setState({ isSuccess: true });
            localStorage.setItem("adminloggedIn", this.state.fields.username);
            this.props.history.push("/previousForms");
          }
          if (response.data.result.role == "bgv1") {
            this.setState({ isSuccess: true });
            localStorage.setItem("guestloggedIn", this.state.fields.username);
            this.props.history.push("/PreviousformsGuest");
          }
          if (response.data.result.role == "bgv2") {
            this.setState({ isSuccess: true });
            localStorage.setItem("guestloggedIn", this.state.fields.username);
            this.props.history.push("/PreviousformsGuest");
          }
          if (response.data.result.status == "failure") {
            this.props.dispatch(guestLogin(true));
            this.props.history.push("/Admin");
          }
          if (response.data.result.description == "username does not exist") {
            this.toastTR.show({
              severity: "error",
              summary: "Error Message",
              detail: "Username does not exist",
              life: 3000,
            });
          }
          if (response.data.result.description == "password mismatch") {
            this.setState({ isPassword: true });
            this.toastTR.show({
              severity: "error",
              summary: "Error Message",
              detail: "Wrong Password",
              life: 3000,
            });
          }
        });
    }
  }

  render() {
    const { username, password } = this.state.errors;
    return (
      <div className="HRloginpage">
        <Toast ref={(el) => (this.toastTR = el)} position="top-right" />
        {/* //karthik */}
        {/* <img className="background_img" src={bg} /> */}
        <video
          className="background_img"
          autoPlay
          loop
          muted
          plays-inline
          src={bg}
          type="video/mp4"
        />
        {this.props.isSpinner && <Loading />}
        {/* image and btn */}
        <div className="Caprus_Image_EmployeeBtn">
          <div style={{ width: "50%" }}>
            <img
              className="CaprusImage"
              onClick={this.refreshPage}
              src={Logo}
              alt="capv"
            />
          </div>
          <div className="EmployeeBtn">
            <button
              onClick={() => this.props.history.push("/")}
              className="Admin"
            >
              Employee
            </button>
          </div>
        </div>
        {/* image and btn ended */}
        <div className="UserPage_Card">
          <div className="userPage_card_Inside">
            <div className="inside_card">
              <span className="Emailtext">Admin Login</span>
              <div style={{ marginTop: "40px" }}>
                <form onSubmit={this.AdminSubmit}>
                  <div>
                    <input
                      className="Email_input_text"
                      type="text"
                      value={this.state.fields.username}
                      onChange={(e) => {
                        this.AdminPage(e, "username");
                      }}
                      name="username"
                      placeholder="Username"
                      autoComplete="off"
                    ></input>
                  </div>
                  <div style={{ marginTop: "25px" }}>
                    <input
                      className="Email_input_text"
                      type="password"
                      value={this.state.fields.password}
                      onChange={(e) => {
                        this.password_change(e, "password");
                      }}
                      name="password"
                      placeholder="Password"
                      autoComplete="off"
                    ></input>
                  </div>
                  <div
                    style={{ marginTop: "20px" }}
                    className="Submit_btn_userpage_admin_otp"
                  >
                    <button className="Otp_Submit_Btn">
                      Submit <i class="pi pi-send"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    email: state.email,
    data: state.data,
    isSpinner: state.isSpinner,
    isGuest: state.isGuest,
  };
};

export default connect(mapStateToProps)(Admin);
