import axios from "axios";
import React from "react";
import "./previousForms.css";
import images from "../../Assets/logo.png";
import Select from "react-select";
import "primeicons/primeicons.css";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import svg from "../../Assets/excel.svg";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Checkbox } from "primereact/checkbox";
import { statusModeled, isassigneddata, spinner } from "../../store/actions.js";
import { connect } from "react-redux";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import infinity from "../../Assets/infinity3.gif";

class previousForms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      employeeClearanceData: null,
      SaveComment: {
        id: "",
        employeeId: "",
        comment: "",
        status: "",
        makerId: "",
      },
      updatedSaveComment: {
        id: "",
        employeeId: "",
        comment: "",
        status: "",
        makerId: "",
      },
      updateLocationComany: {
        id: "",
        employeeId: "",
        company: "",
        workLocation: "",
      },
      previousUpdatedLocationComapany: null,
      previousUpdatedLocation: null,
      verifyUpdateSaveComment: null,
      verifyRoleView: null,
      submitBtnDisable: true,
      highestemployementlength: 0,
      ifAdminLogoutClicked: false,
      alreadyAssigned: false,
      statusTemplate: null,
      errors: {},
      employeecodestore: {},
      allemployeesCode: [],
      searchKey: "",
      employeePfData: [],
      employeeInsuranceData: [],
      updatedInsurancedata: null,
      updatedstatus: false,
      isCorrect: false,
      employees: [],
      notYetUpdated: false,
      updatedEmployees: [],
      currentUserRole: "",
      status: [],
      employeeId: "",
      dataHandled: false,
      employeeidStatus: "",
      empdetails: "",
      id: 1,
      excelUrl: "",
      userstatus: null,
      empcodeassign: "",
      pdfTaker: [],
      relationStatus: null,
      statuscodesubmitted: false,
      changes: "",
      isLogout: false,
      employeeStatus: null,
      failedTo: false,
      generatedCode: [],
      selectedOption: null,
      selectedUser: null,
      userstatusselection: {},
      selectedstatus: {},
      IsAssigned: true,
      files: [],
      disableButton: false,
      showdetails: "empdetails",
      fields: {},
      insurance_Id: 1,
      currentDate: "",
      insurance_view_id: 1,
      ifSidebarClicked: true,
      employeestatusValue: null,
      getComments: null,
      errors: {},
      selectedstatusUpadted: "",
      apiData: [],
      allemployeePFData: [],
      degreeOfAcceptance: [
        { id: 1, acceptance: "Strongly Agree", checked: "false" },
        { id: 2, acceptance: "Agree", checked: "false" },
        { id: 3, acceptance: "Disagree", checked: "false" },
        { id: 4, acceptance: "Strongly Disagree", checked: "false" },
      ],
      displayClearanceForm: false,
      _exporter: React.createRef(),
      displayExcelModal: false,
      financeTable: [
        {
          Sno: "1",
          itemName: "Loans/Advance",
          Remarks: "",
          signature: "",
          completed: false,
        },
        {
          Sno: "2",
          itemName: "Travel Advance",
          Remarks: "",
          signature: "",
          completed: false,
        },
        {
          Sno: "3",
          itemName: "Invoice Pending (If any)",
          Remarks: "",
          signature: "",
          completed: false,
        },
      ],

      employeeActivityInfoDetails: [],
      getAllEmpInterviewData: [],
      activityTable: [
        {
          SNo: "1",
          activityname: "",
          status: "",
          pending: true,
          completed: false,
        },

        {
          SNo: "2",
          activityname: "",
          status: "",
          pending: true,
          completed: false,
        },

        {
          SNo: "3",
          activityname: "",
          status: "",
          pending: true,
          completed: false,
        },
      ],
      getExitInterviewData: {},
      getInterviewQuestions: [],
      getExitInterviewOptions: [],
      displayExitInterviewForEmployee: false,
    };
    this.dataHandled = this.dataHandled.bind(this);
    this.dataHandled_forMobile = this.dataHandled_forMobile.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.pdfHandler = this.pdfHandler.bind(this);
    this.selectHandler = this.selectHandler.bind(this);
    this.renderFooter = this.renderFooter.bind(this);
    this.excelforAllEmployees = this.excelforAllEmployees.bind(this);
    this.excelforCurrentEmployee = this.excelforCurrentEmployee.bind(this);
  }

  componentDidMount() {
    let date = new Date();
    let year = date.getFullYear();
    let month =
      date.getMonth() < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
    let day = date.getDate() > 10 ? date.getDate() : "0" + date.getDate();
    let currentDate = year + "-" + month + "-" + day;
    this.setState({ ...this.state, currentDate: currentDate });
    if (localStorage.getItem("adminloggedIn") != null) {
      axios.get("https://capv.live/portalprod/getAllEmployees").then((response) => {
        if (response.data.result) {
          this.dataHandled(
            response.data.result[0].id,
            response.data.result[0].id
          );
          this.setState({
            employees: response.data.result,
          });
        }
      });

      axios
        .get("https://capv.live/portalprod/getAllEmployeeCodes")
        .then((response) => {
          this.setState({ allemployeesCode: response.data.result });
        });

      axios
        .get("https://capv.live/portalprod/getAllEmployeesByRole?role=profilelens")
        .then((response) => {
          this.setState({ updatedEmployees: response.data.result });
        });
      axios
        .get("https://capv.live/portalprod/getEmployeeStatus")
        .then((response) => {
          this.setState({ employeeStatus: response.data });
        });

      axios.get("https://capv.live/portalprod/getUsers").then((response) => {
        this.setState({ userstatus: response.data.result });
      });

      axios.get("https://capv.live/portalprod/getRelationData").then((response) => {
        this.setState({ relationStatus: response.data.result });
      });

      axios
        .get("https://capv.live/portalprod/getAllEmployeeExitFormDetails")
        .then((response) => {
          this.setState({ getAllEmpInterviewData: response.data.result });
        });

      axios
        .get("https://capv.live/portalprod/getAllExitFormQuestions")
        .then((response) => {
          this.setState({ getInterviewQuestions: response.data.result });
        });

      axios
        .get("https://capv.live/portalprod/getOptionsByQuestionId?questionId=1")
        .then((response) => {
          this.setState({ getExitInterviewOptions: response.data.result });
        });
    } else {
      this.props.history.push("/Admin");
    }
  }
  selectHandler = (e) => {
    if (this.state.selectedstatusUpadted == "") {
      this.setState({
        selectedstatus: e.target.value,
        userstatusselection: "",
      });
    }

    if (this.state.selectedstatusUpadted != "") {
      if (this.state.selectedstatusUpadted.code <= e.target.value.code) {
        this.setState({
          selectedstatus: e.target.value,
        });
        axios
          .get(
            "https://capv.live/portalprod/getEmployee?employeeId=" +
              this.state.employeeCode
          )
          .then((response) => {
            this.setState({
              statusTemplate: response.data.result,
              currentUserRole: response.data.result.role,
            });
          });
      } else {
        if (
          (this.state.selectedstatusUpadted.name === "Onboarded" ||
            this.state.selectedstatusUpadted.name === "Consultant") &&
          e.target.value.code >= 2 &&
          e.target.value.code <= 5
        ) {
          this.setState({ selectedstatus: e.target.value });
        } else {
          this.toastTR.show({
            severity: "warn",
            summary: "Warn Message",
            detail: "You Cannot Change",
            life: 3000,
          });
        }
      }
    }
  };
  userselectHandler = (selectedUser) => {
    this.setState({ userstatusselection: selectedUser });
  };
  selectedstatusDisplay = (result) => {
    if (result == null) {
      result = "New Employee";
    }
    if (result != null) {
      let sampleData = this.state.employeeStatus.findIndex(
        (v) => v.status === result
      );
      let sampledataLength = sampleData + 1;
      let temObject = { name: result, code: sampledataLength };
      this.setState({
        selectedstatusUpadted: temObject,
        selectedstatus: temObject,
      });
    } else if (result === null) {
    }
  };

  dataHandled = (employeeid, empdetails) => {
    this.setState({
      ...this.state,
      isCorrect: true,
      employeeId: employeeid,
      employeeNumber: employeeid,
      empdetails: empdetails,
    });

    this.setState({ showdetails: "empdetails" });
    this.setState({ displayExitInterviewForEmployee: false });
    this.setState({ displayClearanceForm: false });
    axios
      .get("https://capv.live/portalprod/getEmployee?employeeId=" + employeeid)
      .then((response) => {
        if (response.data.result.status === null) {
          response.data.result.status = "New Employee";
          this.selectedstatusDisplay(response.data.result.status);
        } else {
          this.selectedstatusDisplay(response.data.result.status);
        }

        this.setState({ statusTemplate: response.data.result });
      });
    axios
      .get(
        `https://capv.live/portalprod/getEmployeeExitFormDetails?employeeId=${employeeid}`
      )
      .then((response) => {
        if (response.data.result !== undefined) {
          this.setState({ getExitInterviewData: response.data.result });
        }
      });
    axios
      .get(
        `https://capv.live/portalprod/getEmployeeCodeById?employeeNumber=${employeeid}`
      )
      .then((response) => {
        this.setState({ employeecodestore: response.data.result });
      });

    axios
      .get(
        "https://capv.live/portalprod/getCommentsByEmployeeId?employeeId=" +
          employeeid
      )
      .then((response) => {
        if (response.data.result.length > 0) {
          this.setState({
            SaveComment: response.data.result[0],
            updatedSaveComment: response.data.result[0],
            verifyUpdateSaveComment: response.data.result[0].status,
          });
        } else {
          this.setState({
            SaveComment: {
              id: "",
              employeeId: "",
              comment: "",
              status: "",
              makerId: "",
            },

            updatedSaveComment: {
              id: "",
              employeeId: "",
              comment: "",
              status: "",
              makerId: "",
            },
          });
        }
      });
    axios
      .get(
        "https://capv.live/portalprod/getEmployeeLocation?employeeId=" + employeeid
      )
      .then((response) => {
        if (response.data.result != null) {
          this.setState({
            updateLocationComany: response.data.result,
            previousUpdatedLocationComapany: response.data.result.company,
            previousUpdatedLocation: response.data.result.workLocation,
          });
        } else {
          this.setState({
            updateLocationComany: {
              id: "",
              employeeId: "",
              company: "",
              workLocation: "",
            },
          });
        }
      });

    axios
      .get("https://capv.live/portalprod/getAllClearanceInfoDetails")
      .then((response) => {
        let dummy = response.data.result
          .reverse()
          .findIndex(
            (sample) =>
              sample.empCode === this.state.employeecodestore.employeeCode
          );

        this.setState({ employeeClearanceData: response.data.result[dummy] });
      });

    //https://capv.live/portalprod/getAdminClearanceInfoDetails?employeeId=6412

    axios
      .get("https://capv.live/portalprod/getAllAdminClearanceInfoDetails")
      .then((response) => {
        let dummy = response.data.result.filter(
          (sample) =>
            sample.empCode === this.state.employeecodestore.employeeCode
        );

        this.setState({ employeeActivityInfoDetails: dummy });
      });
  };

  dataHandled_forMobile = (employeeid, empdetails) => {
    this.setState({
      ifSidebarClicked: false,
      isCorrect: true,
      employeeId: employeeid,
      employeeNumber: employeeid,
      empdetails: empdetails,
      selectedstatus: "",
    });

    if (this.state.employeeId != "") {
      this.setState({ showdetails: "empdetails" });
    }

    axios
      .get(
        `https://capv.live/portalprod/getEmployeeCodeById?employeeNumber=${employeeid}`
      )
      .then((response) => {
        this.setState({ employeecodestore: response.data.result });
      });
  };
  closeAlert = () => {
    this.setState({ ifAdminLogoutClicked: false });
  };

  redirectToLoginPage = () => {
    localStorage.removeItem("adminloggedIn");
    this.props.history.push("/Admin");
  };

  submitHandler = () => {
    axios
      .post(
        "https://capv.live/portalprod/generateEmployeeCode?employeeNumber=" +
          this.state.employeeId
      )
      .then((response) => {
        this.setState({ generatedCode: [response.data.result] });
        this.props.dispatch(isassigneddata(false));
        setTimeout(
          function () {
            this.setState({ dataHandled: false });
            this.setState({ showdetails: "empdetails" });
            this.setState({ alreadyAssigned: false });
          }.bind(this),
          1000
        );
        if (response.data.status === "success") {
          this.setState({ statuscodesubmitted: true });
          axios
            .get(
              "https://capv.live/portalprod/getEmployeeCodeById?employeeNumber=" +
                this.state.employeeid
            )
            .then((response) => {
              this.setState({ employeecodestore: response.data.result });
            });

          axios
            .get("https://capv.live/portalprod/getAllEmployees")
            .then((response) => {
              this.setState({ employees: response.data.result });
              let previousEmployee = this.state.employees.filter(
                (g) => g.id === this.state.employeeId
              );
              this.dataHandled(previousEmployee[0].id, previousEmployee[0].id);
            });
        }
        if (
          response.data.message === "Already assigned employee id for this user"
        ) {
          this.setState({ alreadyAssigned: true });
        }
        axios
          .get(
            `https://capv.live/portalprod/getEmployeeCodeById?employeeNumber=${this.state.employeeid}`
          )
          .then((response) => {
            this.setState({ employeecodestore: response.data.result });
          });

        if (this.state.displayClearanceForm === true) {
          axios
            .put(
              "https://capv.live/portalprod/updateAdminClearance",
              this.state.employeeActivityInfoDetails
            )
            .then((response) => {
              if (response.data.result) {
                this.toastTR.show({
                  severity: "success",
                  summary: "Success Message",
                  detail: "Clearance form status Updated Successfully",
                  life: 1000,
                });

                this.setState({ displayClearanceForm: false });
              }
            });
        }
      });
    // this.setState({showdetails: "empdetails" })
  };
  searchHandler = (e) => {
    this.setState({ searchKey: e.target.value });
  };
  changedHandler = (e) => {
    this.setState({ empcodeassign: e.target.value });
  };
  submittedStatus = () => {
    this.props.dispatch(statusModeled(true));
  };
  downloadedpdf = () => {
    axios.post(
      "https://capv.live/portalprod/getEmployeePdf?employeeId=" +
        this.state.employeeId
    );
  };

  yesHandler = () => {
    this.setState({ submitBtnDisable: false });
    if (this.ValidationsForLocation()) {
      this.props.dispatch(spinner(true));
      // if (this.state.selectedstatus.name == "Onboarded") {
      //   this.setState({ dataHandled: true, currentUserRole: "" });
      // } else {
      //   this.setState({ dataHandled: false });
      // }

      if (
        this.state.selectedstatus.name === "Consultant" ||
        this.state.selectedstatus.name === "Notice period" ||
        this.state.selectedstatus.name === "Relieved"
      ) {
        this.setState({ currentUserRole: "" });
      }
      let template = this.state.statusTemplate;
      template.status = this.state.selectedstatus.name;
      // template.status = this.state.selectedstatus.name;
      if (this.state.selectedstatus.name === "Send for Verification") {
        template.role = this.state.userstatusselection.label;
      } else if (
        this.state.selectedstatus.code >= 3 &&
        this.state.selectedstatus.code < 5
      ) {
        template.role = "hradmin";
      }

      axios
        .put("https://capv.live/portalprod/updateEmployee", template)
        .then((response) => {
          if (response.data.message == "successfully updated") {
            this.props.dispatch(spinner(false));
            this.setState({
              updatedstatus: true,
              disableButton: true,
              dataHandled: true,
            });
            this.toastTR.show({
              severity: "success",
              summary: "Success Message",
              detail: "Updated Successfully",
              life: 1000,
            });
          }
          setTimeout(
            function () {
              this.setState({
                updatedstatus: false,
                disableButton: false,
                showdetails: "empdetails",
                submitBtnDisable: true,
              });
              axios
                .get("https://capv.live/portalprod/getAllEmployees")
                .then((response) => {
                  this.setState({ employees: response.data.result });
                  let previousEmployee = this.state.employees.filter(
                    (g) => g.id === this.state.employeeId
                  );
                  this.dataHandled(
                    previousEmployee[0].id,
                    previousEmployee[0].id
                  );
                });
            }.bind(this),
            3000
          );
        });
      let completeStatus = this.state.updateLocationComany;
      completeStatus.employeeId = this.state.employeeId;

      if (completeStatus.id === "") {
        axios.post("https://capv.live/portalprod/saveLocation", completeStatus);
      } else if (completeStatus.id !== "") {
        axios.put(
          "https://capv.live/portalprod/updateEmployeeLocation",
          completeStatus
        );
      }

      this.props.dispatch(statusModeled(false));

      if (this.state.SaveComment.id == "") {
        let savedComments = this.state.SaveComment;
        savedComments.employeeId = this.state.employeeId;
        savedComments.comment = this.state.selectedstatus.name;
        savedComments.makerId = this.state.employeeId;
        axios.post("https://capv.live/portalprod/saveComment", savedComments);
      }

      if (this.state.SaveComment.id != "") {
        let savedComments = this.state.SaveComment;
        axios.put(
          "https://capv.live/portalprod/updateComment?id=" + this.state.employeeId,
          savedComments
        );
      }
    } else {
      this.props.dispatch(spinner(false));
      this.toastTR.show({
        severity: "error",
        summary: "Error Message",
        detail: "Validation failed",
        life: 3000,
      });
      this.props.dispatch(statusModeled(false));
    }
  };
  noHandler = () => {
    this.props.dispatch(statusModeled(false));
  };
  userStatus = (role) => {
    if (role != null && role != "") {
      let roleUpdated = this.state.userstatus.filter((v) => v.username == role);
      let roleView = { label: role, value: roleUpdated[0].role };
      this.setState({ userstatusselection: roleView, verifyRoleView: role });
    }
  };

  displaydetails = (showid) => {
    this.props.dispatch(isassigneddata(true));
    this.setState({ showdetails: showid });
    if (showid === "exitinterview") {
      this.setState({ displayExitInterviewForEmployee: true });
      if (this.state.displayClearanceForm === true) {
        this.setState({ displayClearanceForm: false });
      }
    }
    if (showid === "clearanceform") {
      this.setState({ displayClearanceForm: true });
      if (this.state.displayExitInterviewForEmployee === true) {
        this.setState({ displayExitInterviewForEmployee: false });
      }
    }
    if (showid !== "clearanceform" && showid !== "exitinterview") {
      if (this.state.displayExitInterviewForEmployee === true) {
        this.setState({ displayExitInterviewForEmployee: false });
      }
      if (this.state.displayClearanceForm === true) {
        this.setState({ displayClearanceForm: false });
      }
    }

    axios
      .get(
        `https://capv.live/portalprod/getEmployeeCodeById?employeeNumber=${this.state.employeeId}`
      )
      .then((response) => {
        this.setState({ employeecodestore: response.data.result });
      });
    axios
      .get(
        "https://capv.live/portalprod/getEmployee?employeeId=" +
          this.state.employeeId
      )
      .then((response) => {
        this.userStatus(response.data.result.role);
      });
    axios
      .get(
        "https://capv.live/portalprod/filesByEmployeeId/" + this.state.employeeId
      )
      .then((response) => {
        this.setState({ files: response.data });
      });
    axios
      .get(
        "https://capv.live/portalprod/getPfDetails?employeeId=" +
          this.state.employeeId
      )
      .then((response) => {
        this.setState({ employeePfData: [response.data.result] });
      });
    axios
      .get(
        "https://capv.live/portalprod/getInsurance?employeeId=" +
          this.state.employeeId
      )
      .then((response) => {
        this.setState({
          employeeInsuranceData: response.data.result,
        });
      });

    axios
      .get(
        "https://capv.live/portalprod/getEmployeeCodeById?employeeNumber=" +
          this.state.employeeId
      )
      .then((response) => {
        this.setState({ employeecodestore: response.data.result });
      });
    axios
      .get(
        "https://capv.live/portalprod/getEmployee?employeeId=" +
          this.state.employeeId
      )
      .then((response) => {
        this.setState({ statusTemplate: response.data.result });
      });

    axios
      .get(
        "https://capv.live/portalprod/getAdminClearanceInfoDetails?employeeId=" +
          this.state.employeeId
      )
      .then((response) => {
        this.setState({ employeeActivityInfoDetails: response.data.result });
      });

    axios
      .get(
        "https://capv.live/portalprod/getEmployeeExitFormDetails?employeeId=" +
          this.state.employeeId
      )
      .then((response) => {
        if (response.data.result) {
          this.setState({ getExitInterviewData: response.data.result });
        }
      });
  };

  showConfirm = () => {
    this.setState({ ifAdminLogoutClicked: true });
  };

  onSidebar_click = () => {
    this.setState({ ifSidebarClicked: false });
    if (this.state.ifSidebarClicked == false) {
      this.setState({ ifSidebarClicked: true });
    }
  };
  FinanceChangeHandler = (e, id, field) => {
    let financeOnChange = this.state.financeTable;
    financeOnChange[id][field] = e.target.value;
    this.setState({ financeTable: financeOnChange });
  };

  adminClearanceHandler = (e, id, field) => {
    let onChangeVal = this.state.employeeActivityInfoDetails;

    if (field === "pending") {
      if (e.checked) {
        onChangeVal[id].status = false;
      }
    } else if (field === "completed") {
      if (e.checked) {
        onChangeVal[id].status = true;
      }
    }
    this.setState({ employeeActivityInfoDetails: onChangeVal });
  };

  renderFooter() {
    return (
      <div>
        <Button
          label="All Employees"
          icon="pi pi-users"
          onClick={this.excelforAllEmployees}
          className="p-button-help"
        />
        <Button
          label="Current Employee"
          icon="pi pi-user"
          onClick={this.excelforCurrentEmployee}
          autoFocus
        />
      </div>
    );
  }
  excelforAllEmployees = () => {
    this.setState({ displayExcelModal: false });
    let excelString = "";
    let emplen = 0;
    // axios.get("https://capv.live/portalprod/getAllEmployees").then((response) => {
    //   if(response.data.status==="success")
    //   {
    const mainFields = [
      "id",
      "aadharNumber",
      "bloodGroup",
      "date",
      "dateOfExpiry",
      "dateOfIssue",
      "dob",
      "email",
      "firstName",
      "lastName",
      "otherName",
      "passportNumber",
      "panCardNumber",
      "place",
      "role",
    ];

    const contactInfoObjKeys = [
      "address",
      "alternateContact",
      "city",
      "country",
      "emergencyContact",
      "nationality",
      "pincode",
    ];

    const contactInfoObjKeys2 = [
      "address2",
      "alternateContact2",
      "city2",
      "country2",
      "emergencyContact2",
      "nationality2",
      "pincode2",
    ];

    const educationInfoObj = [
      "degree",
      "collegeName",
      "universityName",
      "subject",
      "courseStartDate",
      "courseEndDate",
      "courseType",
      "addressOfInstitution",
      "city",
      "pincode",
      "landline1",
      "landline2",
    ];

    const employmentInfoObj = [
      "companyName",
      "companyAddress",
      "employedPlace",
      "city",
      "state",
      "pincode",
      "fromDate",
      "toDate",
      "designation",
      "remuneration",
      "employeeCode",
      "employmentNature",
      "supervisorName",
      "supervisorDesignation",
      "supervisorEmailId",
      "leavingReason",
    ];

    const employmentInfoObj2 = [
      "companyName2",
      "companyAddress2",
      "employedPlace2",
      "city2",
      "state2",
      "pincode2",
      "fromDate2",
      "toDate2",
      "designation2",
      "remuneration2",
      "employeeCode2",
      "employmentNature2",
      "supervisorName2",
      "supervisorDesignation2",
      "supervisorEmailId2",
      "leavingReason2",
    ];

    const employmentInfoObj3 = [
      "companyName3",
      "companyAddress3",
      "employedPlace3",
      "city3",
      "state3",
      "pincode3",
      "fromDate3",
      "toDate3",
      "designation3",
      "remuneration3",
      "employeeCode3",
      "employmentNature3",
      "supervisorName3",
      "supervisorDesignation3",
      "supervisorEmailId3",
      "leavingReason3",
    ];

    const employmentInfoObj4 = [
      "companyName4",
      "companyAddress4",
      "employedPlace4",
      "city4",
      "state4",
      "pincode4",
      "fromDate4",
      "toDate4",
      "designation4",
      "remuneration4",
      "employeeCode4",
      "employmentNature4",
      "supervisorName4",
      "supervisorDesignation4",
      "supervisorEmailId4",
      "leavingReason4",
    ];

    const employmentInfoObj5 = [
      "companyName5",
      "companyAddress5",
      "employedPlace5",
      "city5",
      "state5",
      "pincode5",
      "fromDate5",
      "toDate5",
      "designation5",
      "remuneration5",
      "employeeCode5",
      "employmentNature5",
      "supervisorName5",
      "supervisorDesignation5",
      "supervisorEmailId5",
      "leavingReason5",
    ];

    excelString += mainFields.join(",") + ",";
    excelString += contactInfoObjKeys.join(",") + ",";
    excelString += contactInfoObjKeys2.join(",") + ",";
    excelString += educationInfoObj.join(",") + ",";
    excelString += employmentInfoObj.join(",") + ",";
    excelString += employmentInfoObj2.join(",") + ",";
    excelString += employmentInfoObj3.join(",") + ",";
    excelString += employmentInfoObj4.join(",") + ",";
    excelString += employmentInfoObj5.join(",") + "\n";
    let highemplength = 0;
    this.state.employees.forEach((employee, index) => {
      mainFields.forEach((field) => {
        let currentemp = this.state.allemployeesCode.filter(
          (v) => v.employeeNumber === employee.id
        );

        excelString += employee[field]
          ? field === "id"
            ? currentemp.length > 0
              ? currentemp[0].code + " " + ","
              : "-" + ","
            : employee[field].toString().indexOf("T00:00:00") !== -1
            ? employee[field].toString().replaceAll("T00:00:00.000+00:00", "") +
              " " +
              ","
            : employee[field].toString() + " " + ","
          : "-" + ",";
      });

      employee.contactInfo.forEach((conactDetails) => {
        contactInfoObjKeys.forEach((conact) => {
          let data = "-";
          if (conactDetails[conact]) {
            data = conactDetails[conact].toString().replaceAll("\n", " ");
            data = data.toString().replaceAll(",", " ");
          }

          excelString += data + ",";
        });
      });

      educationInfoObj.forEach((conact) => {
        let data = "-";
        if (employee.educationInfo[conact]) {
          data = employee.educationInfo[conact]
            .toString()
            .replaceAll("\n", " ");

          data = data.toString().replaceAll(",", " ");

          if (data.toString().indexOf("T00:") !== -1) {
            data = data.toString().replaceAll("T00:00:00.000+00:00", " ");
          }
        }

        excelString += data + ",";
      });

      employee.employmentInfo.forEach((conactDetails) => {
        employmentInfoObj.forEach((conact) => {
          let data = "-";
          if (conactDetails[conact]) {
            data = conactDetails[conact].toString().replaceAll("\n", " ");

            data = data.toString().replaceAll(",", " ");

            if (data.toString().indexOf("T00:") !== -1) {
              data = data.toString().replaceAll("T00:00:00.000+00:00", " ");
            }
          }

          excelString += data + ",";
        });
      });
      if (employee.employmentInfo.length > emplen) {
        emplen = employee.employmentInfo.length;
      }

      excelString += "\n";
    });

    this.setState({ highestemployementlength: emplen }, () => {
      if (this.state.highestemployementlength === 0) {
        excelString = excelString.replace(
          employmentInfoObj.join(",") +
            "," +
            employmentInfoObj2.join(",") +
            "," +
            employmentInfoObj3.join(",") +
            "," +
            employmentInfoObj4.join(",") +
            "," +
            employmentInfoObj5.join(","),
          " "
        );
      }

      if (this.state.highestemployementlength === 1) {
        excelString = excelString.replace(
          employmentInfoObj2.join(",") +
            "," +
            employmentInfoObj3.join(",") +
            "," +
            employmentInfoObj4.join(",") +
            "," +
            employmentInfoObj5.join(","),
          ","
        );
      } else if (this.state.highestemployementlength === 2) {
        excelString = excelString.replace(
          employmentInfoObj3.join(",") +
            "," +
            employmentInfoObj4.join(",") +
            "," +
            employmentInfoObj5.join(","),
          ","
        );
      } else if (this.state.highestemployementlength === 3) {
        excelString = excelString.replace(
          employmentInfoObj4.join(",") + "," + employmentInfoObj5.join(","),
          ","
        );
      } else if (this.state.highestemployementlength === 4) {
        excelString = excelString.replace(employmentInfoObj5.join(","), ",");
      }
    });

    setTimeout(() => {
      if (excelString) {
        const blob = new Blob([excelString], { type: "text/csv" });

        let url = window.URL.createObjectURL(blob);

        this.setState({ ...this.state, excelUrl: url });

        const a = document.createElement("a");

        a.href = this.state.excelUrl;

        a.download = "EmployeesInfo.csv";

        a.click();
      }
    }, 500);
  };

  excelforCurrentEmployee = () => {
    this.setState({ displayExcelModal: false });
    let excelString = "";
    const mainFields = [
      "id",
      "aadharNumber",
      "bloodGroup",
      "date",
      "dateOfExpiry",
      "dateOfIssue",
      "dob",
      "email",
      "firstName",
      "lastName",
      "otherName",
      "passportNumber",
      "panCardNumber",
      "place",
      "role",
    ];

    const contactInfoObjKeys = [
      "address",
      "alternateContact",
      "city",
      "country",
      "emergencyContact",
      "nationality",
      "pincode",
    ];

    const contactInfoObjKeys2 = [
      "address2",
      "alternateContact2",
      "city2",
      "country2",
      "emergencyContact2",
      "nationality2",
      "pincode2",
    ];

    const educationInfoObj = [
      "degree",
      "collegeName",
      "universityName",
      "subject",
      "courseStartDate",
      "courseEndDate",
      "courseType",
      "addressOfInstitution",
      "city",
      "pincode",
      "landline1",
      "landline2",
    ];

    const employmentInfoObj = [
      "companyName",
      "companyAddress",
      "employedPlace",
      "city",
      "state",
      "pincode",
      "fromDate",
      "toDate",
      "designation",
      "remuneration",
      "employeeCode",
      "employmentNature",
      "supervisorName",
      "supervisorDesignation",
      "supervisorEmailId",
      "leavingReason",
    ];

    const employmentInfoObj2 = [
      "companyName2",
      "companyAddress2",
      "employedPlace2",
      "city2",
      "state2",
      "pincode2",
      "fromDate2",
      "toDate2",
      "designation2",
      "remuneration2",
      "employeeCode2",
      "employmentNature2",
      "supervisorName2",
      "supervisorDesignation2",
      "supervisorEmailId2",
      "leavingReason2",
    ];

    const employmentInfoObj3 = [
      "companyName3",
      "companyAddress3",
      "employedPlace3",
      "city3",
      "state3",
      "pincode3",
      "fromDate3",
      "toDate3",
      "designation3",
      "remuneration3",
      "employeeCode3",
      "employmentNature3",
      "supervisorName3",
      "supervisorDesignation3",
      "supervisorEmailId3",
      "leavingReason3",
    ];

    const employmentInfoObj4 = [
      "companyName4",
      "companyAddress4",
      "employedPlace4",
      "city4",
      "state4",
      "pincode4",
      "fromDate4",
      "toDate4",
      "designation4",
      "remuneration4",
      "employeeCode4",
      "employmentNature4",
      "supervisorName4",
      "supervisorDesignation4",
      "supervisorEmailId4",
      "leavingReason4",
    ];

    const employmentInfoObj5 = [
      "companyName5",
      "companyAddress5",
      "employedPlace5",
      "city5",
      "state5",
      "pincode5",
      "fromDate5",
      "toDate5",
      "designation5",
      "remuneration5",
      "employeeCode5",
      "employmentNature5",
      "supervisorName5",
      "supervisorDesignation5",
      "supervisorEmailId5",
      "leavingReason5",
    ];

    excelString += mainFields.join(",") + ",";
    excelString += contactInfoObjKeys.join(",") + ",";
    excelString += contactInfoObjKeys2.join(",") + ",";
    excelString += educationInfoObj.join(",") + ",";
    excelString += employmentInfoObj.join(",") + ",";
    excelString += employmentInfoObj2.join(",") + ",";
    excelString += employmentInfoObj3.join(",") + ",";
    excelString += employmentInfoObj4.join(",") + ",";
    excelString += employmentInfoObj5.join(",") + "\n";

    axios
      .get(
        "https://capv.live/portalprod/getEmployee?employeeId=" +
          this.state.employeeId
      )
      .then((response) => {
        if (response.data.status === "success") {
          let employee = response.data.result;

          mainFields.forEach((field) => {
            excelString += employee[field]
              ? field === "id"
                ? this.state.employeecodestore.employeeCode
                  ? this.state.employeecodestore.employeeCode + " " + ","
                  : "-" + ","
                : employee[field].toString().indexOf("T00:00:00") !== -1
                ? employee[field]
                    .toString()
                    .replaceAll("T00:00:00.000+00:00", "") +
                  " " +
                  ","
                : employee[field].toString() + " " + ","
              : "-" + ",";
          });

          employee.contactInfo.forEach((conactDetails) => {
            contactInfoObjKeys.forEach((conact) => {
              let data = "-";
              if (conactDetails[conact]) {
                data = conactDetails[conact].toString().replaceAll("\n", " ");

                data = data.toString().replaceAll(",", " ");
                if (data.toString().indexOf("T00:") !== -1) {
                  data = data.toString().replaceAll("T00:00:00.000+00:00", " ");
                }
              }

              excelString += data + ",";
            });
          });

          educationInfoObj.forEach((conact) => {
            let data = "-";
            if (employee.educationInfo[conact]) {
              data = employee.educationInfo[conact]
                .toString()
                .replaceAll("\n", " ");

              data = data.toString().replaceAll(",", " ");

              if (data.toString().indexOf("T00:") !== -1) {
                data = data.toString().replaceAll("T00:00:00.000+00:00", " ");
              }
            }

            excelString += data + ",";
          });

          employee.employmentInfo.forEach((conactDetails) => {
            employmentInfoObj.forEach((conact) => {
              let data = "-";
              if (conactDetails[conact]) {
                data = conactDetails[conact].toString().replaceAll("\n", " ");
                data = data.toString().replaceAll(",", " ");
                if (data.toString().indexOf("T00:") !== -1) {
                  data = data.toString().replaceAll("T00:00:00.000+00:00", " ");
                }
              }
              excelString += data + ",";
            });
          });
          if (employee.employmentInfo.length === 0) {
            excelString = excelString.replace(
              employmentInfoObj.join(",") +
                "," +
                employmentInfoObj2.join(",") +
                "," +
                employmentInfoObj3.join(",") +
                "," +
                employmentInfoObj4.join(",") +
                "," +
                employmentInfoObj5.join(","),
              " "
            );
          }

          if (employee.employmentInfo.length === 1) {
            excelString = excelString.replace(
              employmentInfoObj2.join(",") +
                "," +
                employmentInfoObj3.join(",") +
                "," +
                employmentInfoObj4.join(",") +
                "," +
                employmentInfoObj5.join(","),
              ","
            );
          } else if (employee.employmentInfo.length === 2) {
            excelString = excelString.replace(
              employmentInfoObj3.join(",") +
                "," +
                employmentInfoObj4.join(",") +
                "," +
                employmentInfoObj5.join(","),
              ","
            );
          } else if (employee.employmentInfo.length === 3) {
            excelString = excelString.replace(
              employmentInfoObj4.join(",") + "," + employmentInfoObj5.join(","),
              ","
            );
          } else if (employee.employmentInfo.length === 4) {
            excelString = excelString.replace(
              employmentInfoObj5.join(","),
              ","
            );
          }
        }

        excelString += "\n";

        // setTimeout(() => {
        if (excelString) {
          const blob = new Blob([excelString], { type: "text/csv" });

          let url = window.URL.createObjectURL(blob);

          this.setState({ ...this.state, excelUrl: url });

          const a = document.createElement("a");

          a.href = this.state.excelUrl;

          a.download = "CurrentEmployeeInfo.csv";

          a.click();
        }
        // }, 500)
      });

    this.setState({ displayExcelModal: false });
  };

  pdfHandler = () => {
    const PFdetailsObj = [
      "empCode",
      "empName",
      "fatherName",
      "maritalStatus",
      "mobile",
      "qualification",
      "dateOfJoining",
      "emailId",
      "employeeNumber",
      "dob",
      "bankAccountNumber",
      "bankName",
      "ifscCode",
      "nameAsPerBank",
      "pancardNumber",
      "nameAsPerPan",
      "aadharNumber",
      "nameAsPerAadhar",
      "gender",
      "uan",
    ];

    const insuranceObj = [
      "id",
      "empCode",
      "name",
      "relation",
      "dob",
      "age",
      "nominee",
      "employeeNumber",
      "employee",
    ];
    let excelString = "";
    if (this.state.showdetails === "empdetails") {
      this.setState({ displayExcelModal: true });
    } else if (this.state.showdetails == "pfdetails" && this.state.employeeId) {
      const getPfDetails = async () => {
        await axios
          .get(
            "https://capv.live/portalprod/getPfDetails?employeeId=" +
              this.state.employeeId
          )
          .then((response) => {
            if (response.data.status === "success") {
              if (response.data["result"] !== null) {
                excelString += PFdetailsObj.join(",") + "\n";
                PFdetailsObj.forEach((field) => {
                  let data = "-";
                  if (response.data["result"][field]) {
                    data = response.data["result"][field]
                      .toString()
                      .replaceAll("\n", " ");

                    data = data.toString().replaceAll(",", " ");
                  }

                  excelString += data + ",";
                });
              } else {
                this.toast.show({
                  severity: "error",
                  summary: "Error",
                  detail: "No Data to download..!",
                  life: 3000,
                });
                return;
              }
            } else {
              this.toast.show({
                severity: "error",
                summary: "Error",
                detail: "No Data to download..!",
                life: 3000,
              });
              return;
            }
          });
      };
      getPfDetails();
    } else if (
      this.state.showdetails === "insurancedetails" &&
      this.state.employeeId
    ) {
      const getInsurancedetails = async () => {
        await axios
          .get(
            "https://capv.live/portalprod/getInsurance?employeeId=" +
              this.state.employeeId
          )
          .then((response) => {
            this.setState({ employeeInsuranceData: response.data.result });

            if (response.data.status === "success") {
              if (response.data.result) {
                excelString += insuranceObj.join(",") + "\n";
                response.data.result.map((eachinsuree, index) => {
                  insuranceObj.forEach((eachfield) => {
                    let data = "-";
                    if (eachinsuree[eachfield]) {
                      data = eachinsuree[eachfield]
                        .toString()
                        .replaceAll("\n", " ");

                      data = data.toString().replaceAll(",", " ");
                    }
                    excelString += data + ",";
                  });
                  excelString += "\n";
                });
              } else {
                this.toast.show({
                  severity: "error",
                  summary: "Error",
                  detail: "No Insurance Data to download..!",
                  life: 3000,
                });
                return;
              }
            } else {
              this.toast.show({
                severity: "error",
                summary: "Error",
                detail: "No Insurance Data to download..!",
                life: 3000,
              });
              return;
            }
          });
      };

      getInsurancedetails();
    }

    setTimeout(() => {
      if (excelString) {
        const blob = new Blob([excelString], { type: "text/csv" });

        let url = window.URL.createObjectURL(blob);

        this.setState({ ...this.state, excelUrl: url });

        const a = document.createElement("a");

        a.href = this.state.excelUrl;

        a.download =
          this.state.showdetails == "pfdetails"
            ? "PFInfo.csv"
            : "InsuranceInfo.csv";

        a.click();
      }
    }, 500);
  };
  onChangeCompany = (e, field) => {
    let companyDetails = this.state.updateLocationComany;
    companyDetails[field] = e.target.value;
    this.setState({ updateLocationComany: companyDetails });
    this.ValidationsForLocation();
  };
  onChangelocation = (e, field) => {
    let companyLocation = this.state.updateLocationComany;
    companyLocation[field] = e.target.value;
    this.setState({ updateLocationComany: companyLocation });
    this.ValidationsForLocation();
  };
  savecommentHandler = (e, field) => {
    let commentsOnSave = this.state.SaveComment;
    commentsOnSave[field] = e.target.value;
    this.setState({ updatedSaveComment: commentsOnSave });
  };
  ValidationsForLocation = () => {
    let fields = this.state.updateLocationComany;
    let errors = {};
    let formIsValid = true;
    //company
    if (fields.company != "") {
      if (!/^[a-zA-Z, \n]{2,30}$/.test(fields.company)) {
        formIsValid = false;
        errors.company = "Special characters & numbers are not allowed";
      }
    }
    //workLocation
    if (fields.workLocation != "") {
      if (!/^[a-zA-Z]+$/.test(fields.workLocation)) {
        formIsValid = false;
        errors.workLocation = "Special characters & numbers are not allowed";
      }
    }
    this.setState({ errors: errors });
    return formIsValid;
  };
  renderEachQuestion = (activity, eachQ, ind) => {
    let dingo = activity.find((obj) => obj.id === eachQ.questionId);
    if (dingo) {
      return ind + 1 + "." + " " + dingo.question;
    }

    // this.state.getInterviewQuestions && this.state.getInterviewQuestions.find(obj => obj.id ===  eachActivity.questionId !== null &&  eachActivity.questionId).question}
  };
  render() {
    const { company, workLocation } = this.state.errors;

    let filteredemp = this.state.searchKey.length
      ? this.state.employees.filter(
          (item) =>
            (item.firstName != "" &&
              item.firstName
                .toLowerCase()
                .includes(this.state.searchKey.toLowerCase())) ||
            (item.lastName != "" &&
              item.lastName
                .toLowerCase()
                .includes(this.state.searchKey.toLowerCase())) ||
            (item.email != null &&
              item.email
                .toLowerCase()
                .includes(this.state.searchKey.toLowerCase()))
        )
      : this.state.employees;
    let filteredemployees = [];
    if (this.state.employees.length > 0) {
      filteredemployees = this.state.employees.filter(
        (emp) => emp.id === this.state.employeeId
      );
    }
    let filteredemployeespfdata = [];
    if (this.state.employeePfData[0] != null) {
      filteredemployeespfdata = this.state.employeePfData.filter(
        (emp) => emp.employeeNumber == this.state.employeeId
      );
    }

    let filteredemployeeInterviewData = {};
    let filteredemployeeFeedback = [];
    let exitInterviewQuestions = this.state.getInterviewQuestions;
    if (
      this.state.getExitInterviewData !== null ||
      this.state.getExitInterviewData !== {} ||
      this.state.getExitInterviewData !== undefined
    ) {
      filteredemployeeInterviewData = this.state.getExitInterviewData;
      filteredemployeeFeedback =
        this.state.getExitInterviewData &&
        this.state.getExitInterviewData.feedback;
      if (this.state.getInterviewQuestions.length > 0) {
        filteredemployeeFeedback &&
          filteredemployeeFeedback.sort((a,b)=> a.questionId - b.questionId).map((obj) => ({ ...obj, question: "" }));
      }
    }

    let options = this.state.employeeStatus
      ? this.state.employeeStatus.map((v) => ({
          name: v.status,
          code: v.statusCode,
        }))
      : [{ name: "Select", code: "Select" }];
    let requestOptions = this.state.userstatus
      ? this.state.userstatus.map((v) => ({
          label: v.username,
          value: v.role,
        }))
      : [{ label: "Select", value: "Select" }];
    return (
      <div className="previousFormContent">
        <ConfirmDialog
          visible={this.state.ifAdminLogoutClicked}
          onHide={() => this.setState({ ifAdminLogoutClicked: false })}
          message="Are you sure you want to proceed?"
          header="Confirmation"
          icon="pi pi-exclamation-triangle"
          accept={this.redirectToLoginPage}
          reject={() => this.closeAlert}
        />
        <Toast ref={(el) => (this.toast = el)} />
        <Toast ref={(el1) => (this.toastTR = el1)} />
        {this.props.statusModal && (
          <div className="uploadSaved1">
            <ConfirmDialog
              visible={this.props.statusModal}
              message="Are you sure you want to change?"
              header="Confirmation"
              icon="pi pi-exclamation-triangle"
              onHide={() => this.props.dispatch(statusModeled(false))}
              accept={this.yesHandler}
              reject={this.noHandler}
            />
          </div>
        )}
        <div className="headerPart_forAdimn">
          <img className="image_capv" src={images} alt="capv" />
          <div className="signOut_Btn">
            <Button
              onClick={this.showConfirm}
              className="p-button-rounded p-button-warning capp2"
            >
              <i className="pi pi-sign-out"></i>SignOut
            </Button>
          </div>
        </div>
        {this.props.isSpinner && (
          <div style={{ width: "100%", height: "100%" }}>
            <div className="LoadingCmp_">
              <div className="LoadingCmp_Image">
                <img src={infinity} alt="pop" />
              </div>
            </div>
          </div>
        )}
        {this.state.employees.length <= 0 && (
          <div style={{ width: "100%", height: "100%" }}>
            <div className="overrider">
              <div className="loadingImage">
                {/* https://cdnl.iconscout.com/lottie/premium/preview-watermark/hr-management-4920277-4093794.mp4 */}
                <lottie-player
                  src="https://assets7.lottiefiles.com/private_files/lf30_bva3n7x1.json"
                  background="transparent"
                  speed="1"
                  style={{ width: "200px" }}
                  loop
                  autoplay
                />
              </div>
            </div>
          </div>
        )}
        {this.state.employees.length > 0 && (
          <div className="Parent_forBody">
            {/* mobile view */}
            <div>
              {this.state.isCorrect && (
                <div className="employee_switchingBtns_forMobile">
                  <button
                    className={
                      this.state.showdetails == "empdetails"
                        ? "buttonactive"
                        : "button"
                    }
                    onClick={this.displaydetails.bind(this, "empdetails")}
                    type="button"
                  >
                    Employee
                  </button>
                  <button
                    className={
                      this.state.showdetails === "status"
                        ? "buttonactive"
                        : "button"
                    }
                    onClick={this.displaydetails.bind(this, "status")}
                    type="button"
                  >
                    Status
                  </button>
                  <button
                    className={
                      this.state.showdetails === "pfdetails"
                        ? "buttonactive"
                        : "button"
                    }
                    onClick={this.displaydetails.bind(this, "pfdetails")}
                    type="button"
                  >
                    PF
                  </button>
                  <button
                    className={
                      this.state.showdetails === "insurancedetails"
                        ? "buttonactive"
                        : "button"
                    }
                    onClick={this.displaydetails.bind(this, "insurancedetails")}
                    type="button"
                  >
                    Insurance
                  </button>

                  <button
                    className={
                      this.state.showdetails === "uploadeddocuments"
                        ? "buttonactive"
                        : "button"
                    }
                    onClick={this.displaydetails.bind(
                      this,
                      "uploadeddocuments"
                    )}
                    type="button"
                  >
                    Documents
                  </button>

                  {this.state.selectedstatusUpadted.name === "Onboarded" &&
                    !this.state.employeecodestore.employeeCode && (
                      <button
                        className={
                          this.state.showdetails === "empCode"
                            ? "buttonactive"
                            : "button"
                        }
                        onClick={this.displaydetails.bind(this, "empCode")}
                        type="button"
                      >
                        generate EmpId
                      </button>
                    )}
                  {filteredemployees.map((v, index) => (
                    <div style={{ display: "flex" }} key={index}>
                      {(v.status === "Relieved" ||
                        v.status === "Notice period") && (
                        <button
                          className={
                            this.state.showdetails === "Relieved"
                              ? "buttonactive"
                              : "button"
                          }
                          onClick={this.displaydetails.bind(
                            this,
                            "clearanceform"
                          )}
                          type="button"
                        >
                          Clearance Form
                        </button>
                      )}
                      <div style={{ display: "flex" }}>
                        <button
                          className={
                            this.state.showdetails === "Relieved"
                              ? "buttonactive"
                              : "button"
                          }
                          onClick={this.displaydetails.bind(this, "Relieved")}
                          type="button"
                        >
                          ExitInterview
                        </button>
                      </div>
                    </div>
                  ))}

                  {filteredemployees.map((v, index) => (
                    <div style={{ display: "flex" }} key={index}>
                      {(v.status === "Relieved" ||
                        v.status === "Notice period") && (
                        <button
                          className={
                            this.state.showdetails === "Relieved"
                              ? "buttonactive"
                              : "button"
                          }
                          onClick={this.displaydetails.bind(
                            this,
                            "clearanceform"
                          )}
                          type="button"
                        >
                          Clearance Form
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
            {/* // mobile view ended */}
            {/* sidebar for mobile */}
            {this.state.ifSidebarClicked == false && (
              <div className="sideClosed">
                <i onClick={this.onSidebar_click} className="pi pi-bars"></i>
              </div>
            )}
            {this.state.ifSidebarClicked && (
              <div className="Employee_userNames_mobileView">
                <div>
                  <i onClick={this.onSidebar_click} className="pi pi-bars"></i>

                  <h6 className="mail">Employee</h6>
                </div>

                <div className="capp6">
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <span className="p-input-icon-left ">
                      <i className="pi pi-search" />
                      <InputText
                        value={this.state.searchKey}
                        onChange={(e) => this.searchHandler(e)}
                        placeholder="Search"
                      />
                    </span>
                  </div>
                  {filteredemp.length > 0 && (
                    <ul>
                      {filteredemp.map((employeename) => {
                        return (
                          <li
                            key={employeename.id}
                            className={
                              this.state.employeeId == employeename.id
                                ? "liactive"
                                : "employeedata"
                            }
                            onClick={this.dataHandled_forMobile.bind(
                              this,
                              employeename.id
                            )}
                          >
                            {" "}
                            {employeename.firstName} {employeename.otherName}{" "}
                            {employeename.lastName}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </div>
            )}
            {/* sidebar for mobile ended */}
            {/* sidebar */}

            <div className="Employee_userNames">
              <div>
                <h6 className="mail">Employee</h6>
              </div>

              <div className="capp6">
                <div style={{ width: "100%", textAlign: "center" }}>
                  <span className="p-input-icon-left ">
                    <i className="pi pi-search" />
                    <InputText
                      value={this.state.searchKey}
                      onChange={(e) => this.searchHandler(e)}
                      placeholder="Search"
                    />
                  </span>
                </div>
                <div
                  style={{
                    overflowY: "scroll",
                    height: window.innerHeight - 160,
                    overflowX: "hidden",
                  }}
                >
                  {filteredemp.length > 0 && (
                    <ul>
                      {filteredemp.map((employeename) => {
                        return (
                          <li
                            key={employeename.id}
                            className={
                              this.state.employeeId == employeename.id
                                ? "liactive"
                                : "employeedata"
                            }
                            onClick={this.dataHandled.bind(
                              this,
                              employeename.id
                            )}
                          >
                            {" "}
                            {employeename.firstName} {employeename.otherName}{" "}
                            {employeename.lastName}{" "}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </div>
            </div>

            {/* sidebar ended */}
            {/* body */}
            <div className="employee_body">
              {/* employee_btns */}
              {this.state.isCorrect && (
                <div className="employee_switchingBtns">
                  <button
                    className={
                      this.state.showdetails == "empdetails"
                        ? "buttonactive"
                        : "button"
                    }
                    onClick={this.displaydetails.bind(this, "empdetails")}
                    type="button"
                  >
                    Employee Details
                  </button>
                  <button
                    className={
                      this.state.showdetails == "status"
                        ? "buttonactive"
                        : "button"
                    }
                    onClick={this.displaydetails.bind(this, "status")}
                    type="button"
                  >
                    Status
                  </button>
                  <button
                    className={
                      this.state.showdetails == "pfdetails"
                        ? "buttonactive"
                        : "button"
                    }
                    onClick={this.displaydetails.bind(this, "pfdetails")}
                    type="button"
                  >
                    PF Details
                  </button>
                  <button
                    className={
                      this.state.showdetails == "insurancedetails"
                        ? "buttonactive"
                        : "button"
                    }
                    onClick={this.displaydetails.bind(this, "insurancedetails")}
                    type="button"
                  >
                    Insurance Details
                  </button>
                  <button
                    className={
                      this.state.showdetails == "uploadeddocuments"
                        ? "buttonactive"
                        : "button"
                    }
                    onClick={this.displaydetails.bind(
                      this,
                      "uploadeddocuments"
                    )}
                    type="button"
                  >
                    Documents
                  </button>
                  {filteredemployees.map((v, index) => (
                    <div style={{ display: "flex" }} key={index}>
                      {(v.status === "Relieved" ||
                        v.status === "Notice period") && (
                        <button
                          className={
                            this.state.displayClearanceForm === true
                              ? "buttonactive"
                              : "button"
                          }
                          onClick={() => this.displaydetails("clearanceform")}
                          type="button"
                        >
                          Clearance Form
                        </button>
                      )}

                      {(v.status === "Relieved" ||
                        v.status === "Notice period") && (
                        <button
                          className={
                            this.state.displayExitInterviewForEmployee === true
                              ? "buttonactive"
                              : "button"
                          }
                          onClick={this.displaydetails.bind(
                            this,
                            "exitinterview"
                          )}
                          type="button"
                        >
                          Exit Interview
                        </button>
                      )}
                    </div>
                  ))}
                  {this.state.selectedstatusUpadted.name === "Onboarded" &&
                    !this.state.employeecodestore.employeeCode && (
                      <button
                        className={
                          this.state.showdetails === "empCode"
                            ? "buttonactive"
                            : "button"
                        }
                        onClick={this.displaydetails.bind(this, "empCode")}
                        type="button"
                      >
                        generate EmpId
                      </button>
                    )}
                </div>
              )}
              {/* employees_btns ended */}
              {this.state.showdetails == "uploadeddocuments" &&
                this.state.isCorrect && (
                  <div className="capp8">
                    {this.state.files.map((emp) => {
                      return (
                        <ul key={emp.id}>
                          <li>
                            <div className="Employee_Details_">
                              <div className="empdetailsinadmin_H">
                                <span className="empdetailsinadmin">
                                  {emp.fileName}
                                </span>
                              </div>
                              <div className="colonalignment">:</div>
                              <div className="empdetailsinadmin_D">
                                <a
                                  href={
                                    "https://capv.live/portalprod/files/" +
                                    emp.fileId
                                  }
                                  className="FilesD"
                                >
                                  <i className="fa fa-download"> Download</i>
                                </a>
                              </div>
                            </div>
                          </li>
                        </ul>
                      );
                    })}
                  </div>
                )}
              {this.state.showdetails === "insurancedetails" &&
                this.state.isCorrect && (
                  <div style={{ width: "100%" }}>
                    <div className="capp8">
                      {this.state.employeeInsuranceData != null &&
                        this.state.employeeInsuranceData.map((employee, i) => (
                          <div key={i}>
                            <ul>
                              {i < 1 && (
                                <div className="employee_footer_uploadExcel_1266">
                                  <Button
                                    label="Download Excel"
                                    className="p-button-rounded p-button-secondary"
                                  />
                                </div>
                              )}
                              <li>
                                <div className="empdetailsinadminHeading">
                                  <span
                                    style={{
                                      color: "rgb(3 51 228)",
                                      fontWeight: "700",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Insurance {this.state.insurance_view_id + i}
                                  </span>
                                </div>
                              </li>
                              <li>
                                <div className="Employee_Details_">
                                  <div className="empdetailsinadmin_H">
                                    <span className="empdetailsinadmin">
                                      Emp Id
                                    </span>
                                  </div>
                                  <div className="colonalignment">:</div>
                                  <div className="empdetailsinadmin_D">
                                    <span className="empdetailsinadminD">
                                      {employee.empCode}
                                    </span>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="Employee_Details_">
                                  <div className="empdetailsinadmin_H">
                                    <span className="empdetailsinadmin">
                                      Name
                                    </span>
                                  </div>
                                  <div className="colonalignment">:</div>
                                  <div className="empdetailsinadmin_D">
                                    <span className="empdetailsinadminD">
                                      {employee.name}
                                    </span>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="Employee_Details_">
                                  <div className="empdetailsinadmin_H">
                                    <span className="empdetailsinadmin">
                                      Relation
                                    </span>
                                  </div>
                                  <div className="colonalignment">:</div>
                                  <div className="empdetailsinadmin_D">
                                    <span className="empdetailsinadminD">
                                      {employee.relation}
                                    </span>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="Employee_Details_">
                                  <div className="empdetailsinadmin_H">
                                    <span className="empdetailsinadmin">
                                      Dob
                                    </span>
                                  </div>
                                  <div className="colonalignment">:</div>
                                  <div className="empdetailsinadmin_D">
                                    <span className="empdetailsinadminD">
                                      {/* {employee.dob.split("T")[0]} */}
                                      {employee.dob.split("-")[2].split("T")[0]}
                                      -{employee.dob.split("-")[1]}-
                                      {employee.dob.split("-")[0]}
                                    </span>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="Employee_Details_">
                                  <div className="empdetailsinadmin_H">
                                    <span className="empdetailsinadmin">
                                      Age
                                    </span>
                                  </div>
                                  <div className="colonalignment">:</div>
                                  <div className="empdetailsinadmin_D">
                                    <span className="empdetailsinadminD">
                                      {employee.age}
                                    </span>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="Employee_Details_">
                                  <div className="empdetailsinadmin_H">
                                    <span className="empdetailsinadmin">
                                      Nominee
                                    </span>
                                  </div>
                                  <div className="colonalignment">:</div>
                                  <div className="empdetailsinadmin_D">
                                    <span className="empdetailsinadminD">
                                      {employee.nominee}
                                    </span>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        ))}

                      {this.state.employeecodestore.employeeCode === null &&
                        this.state.employeeInsuranceData === null && (
                          <div className="NoData">
                            <h3>
                              Sorry, No Insurance details recorded as Employee
                              ID is not assigned...
                            </h3>
                          </div>
                        )}
                      {this.state.employeecodestore.employeeCode !== null &&
                        !this.state.employeeInsuranceData && (
                          <div className="NoData">
                            <h3>Sorry, No Record is Found...</h3>
                          </div>
                        )}
                    </div>
                  </div>
                )}

              {this.state.displayClearanceForm === true &&
                this.state.employeeClearanceData !== null && (
                  <div
                    className="EmployeeRelieved_Details"
                    style={{
                      display: !this.state.employeeClearanceData && "none",
                    }}
                  >
                    {/* mobile  */}

                    <div
                      className="EmpRelieved_Field_Value_mobile"
                      style={{
                        display: !this.state.employeeClearanceData && "none",
                      }}
                    >
                      <div className="EmpRelieved_Field_Mobile">
                        <span>Name</span>
                      </div>
                      <div className="EmpRelieved_Field_Colon_Mobile">
                        <span>:</span>
                      </div>
                      <div className="EmpRelieved_Field_input_Mobile">
                        <input
                          value={
                            this.state.employeeClearanceData &&
                            this.state.employeeClearanceData.name
                          }
                          className="Input_fields_Admin"
                          type="text"
                        />
                      </div>
                    </div>
                    {/* 2 */}
                    <div className="EmpRelieved_Field_Value_mobile">
                      <div className="EmpRelieved_Field_Mobile">
                        <span>Designation</span>
                      </div>
                      <div className="EmpRelieved_Field_Colon_Mobile">
                        <span>:</span>
                      </div>
                      <div className="EmpRelieved_Field_input_Mobile">
                        <input
                          value={
                            this.state.employeeClearanceData &&
                            this.state.employeeClearanceData.designation
                          }
                          className="Input_fields_Admin"
                          type="text"
                        />
                      </div>
                    </div>
                    {/* 3 */}
                    <div className="EmpRelieved_Field_Value_mobile">
                      <div className="EmpRelieved_Field_Mobile">
                        <span>Dept/Project</span>
                      </div>
                      <div className="EmpRelieved_Field_Colon_Mobile">
                        <span>:</span>
                      </div>
                      <div className="EmpRelieved_Field_input_Mobile">
                        <input
                          value={
                            this.state.employeeClearanceData &&
                            this.state.employeeClearanceData.department
                          }
                          className="Input_fields_Admin"
                          type="text"
                        />
                      </div>
                    </div>
                    {/* 4 */}
                    <div className="EmpRelieved_Field_Value_mobile">
                      <div className="EmpRelieved_Field_Mobile">
                        <span>Joining Date</span>
                      </div>
                      <div className="EmpRelieved_Field_Colon_Mobile">
                        <span>:</span>
                      </div>
                      <div className="EmpRelieved_Field_input_Mobile">
                        <input
                          value={
                            this.state.employeeClearanceData &&
                            this.state.employeeClearanceData.joiningDate.split(
                              "T"
                            )[0]
                          }
                          className="Input_fields_Admin_date"
                          type="date"
                        />
                      </div>
                    </div>
                    {/* 5 */}
                    <div className="EmpRelieved_Field_Value_mobile">
                      <div className="EmpRelieved_Field_Mobile">
                        <span>Resignation Date</span>
                      </div>
                      <div className="EmpRelieved_Field_Colon_Mobile">
                        <span>:</span>
                      </div>
                      <div className="EmpRelieved_Field_input_Mobile">
                        <input
                          value={
                            this.state.employeeClearanceData &&
                            this.state.employeeClearanceData.resignationDate.split(
                              "T"
                            )[0]
                          }
                          className="Input_fields_Admin_date"
                          type="date"
                        />
                      </div>
                    </div>
                    {/* 6 */}
                    <div className="EmpRelieved_Field_Value_mobile">
                      <div className="EmpRelieved_Field_Mobile">
                        <span>Relieving Date</span>
                      </div>
                      <div className="EmpRelieved_Field_Colon_Mobile">
                        <span>:</span>
                      </div>
                      <div className="EmpRelieved_Field_input_Mobile">
                        <input
                          value={
                            this.state.employeeClearanceData &&
                            this.state.employeeClearanceData.relievingDate.split(
                              "T"
                            )[0]
                          }
                          className="Input_fields_Admin_date"
                          type="date"
                        />
                      </div>
                    </div>
                    {/* mobile ended */}
                    <div className="EmpRelieved_Field_Value">
                      <div className="EmpRelieved_Field">
                        <span>Name:</span>
                      </div>
                      <div className="EmpRelieved_Field_input">
                        <input
                          value={
                            this.state.employeeClearanceData &&
                            this.state.employeeClearanceData.name
                          }
                          className="Input_fields_Admin"
                          type="text"
                        />
                      </div>
                      <div className="EmpRelieved_Field2">
                        <span>Designation:</span>
                      </div>
                      <div className="EmpRelieved_Field_input2">
                        <input
                          value={
                            this.state.employeeClearanceData &&
                            this.state.employeeClearanceData.designation
                          }
                          className="Input_fields_Admin"
                          type="text"
                        />
                      </div>
                      <div className="EmpRelieved_Field">
                        <span>Dept/Project:</span>
                      </div>
                      <div className="EmpRelieved_Field_input">
                        <input
                          value={
                            this.state.employeeClearanceData &&
                            this.state.employeeClearanceData.department
                          }
                          className="Input_fields_Admin"
                          type="text"
                        />
                      </div>
                      <div className="EmpRelieved_Field2">
                        <span>Joining Date:</span>
                      </div>
                      <div className="EmpRelieved_Field_input2">
                        <input
                          value={
                            this.state.employeeClearanceData &&
                            this.state.employeeClearanceData.joiningDate.split(
                              "T"
                            )[0]
                          }
                          className="Input_fields_Admin_date"
                          type="date"
                        />
                      </div>
                      <div className="EmpRelieved_Field">
                        <span>Resignation Date:</span>
                      </div>
                      <div className="EmpRelieved_Field_input">
                        <input
                          value={
                            this.state.employeeClearanceData &&
                            this.state.employeeClearanceData.resignationDate.split(
                              "T"
                            )[0]
                          }
                          className="Input_fields_Admin_date"
                          type="date"
                        />
                      </div>
                      <div className="EmpRelieved_Field2">
                        <span>Relieving Date:</span>
                      </div>
                      <div className="EmpRelieved_Field_input2">
                        <input
                          value={
                            this.state.employeeClearanceData &&
                            this.state.employeeClearanceData.relievingDate.split(
                              "T"
                            )[0]
                          }
                          className="Input_fields_Admin_date"
                          type="date"
                        />
                      </div>
                    </div>

                    {/* Clearance from Admin */}
                    <h1 className="Form55_Admin">CLEARANCE FROM ADMIN</h1>

                    <div className="activity_Heading">
                      <div className="Activity_Heading_sNo">
                        <span className="finance_H_Weight">S.No</span>
                      </div>
                      <div className="Activity_Heading_Data">
                        <span className="finance_H_Weight">
                          List of Activities
                        </span>
                      </div>
                      <div className="Activity_Heading_Data">
                        <span className="finance_H_Weight">Status</span>
                      </div>
                      <div className="Activity_Heading_Data">
                        <span className="finance_H_Weight">
                          (Pending/Completed)
                        </span>
                      </div>
                    </div>
                    {/* data finance */}

                    <div className="activity_Table">
                      {this.state.employeeActivityInfoDetails.map(
                        (eachActivity, index) => (
                          // {this.state.activityTable.map((a, index) => (
                          <div key={index} className="Finance_Heading_">
                            <div className="Finance_Heading_sNo_H">
                              <span>{index + 1}</span>
                            </div>
                            <div className="Finance_Heading_Data_H">
                              <input
                                value={eachActivity.activity}
                                onChange={(e) =>
                                  this.adminClearanceHandler(
                                    e,
                                    index,
                                    "activityname"
                                  )
                                }
                                className="Input_fields_Admin"
                              />
                            </div>
                            <div className="Finance_Heading_Data_H">
                              <input
                                value={eachActivity.remarks}
                                onChange={(e) =>
                                  this.adminClearanceHandler(e, index, "status")
                                }
                                className="Input_fields_Admin"
                              />
                            </div>
                            <div className="Finance_Heading_Data_H">
                              <Checkbox
                                inputId="completed"
                                name="activity"
                                value={eachActivity}
                                onChange={(e) =>
                                  this.adminClearanceHandler(
                                    e,
                                    index,
                                    "completed"
                                  )
                                }
                                checked={eachActivity.status === true}
                              />
                              <label
                                className="pending_status_data"
                                htmlFor="completed"
                              >
                                Completed
                              </label>
                              <Checkbox
                                inputId="pending"
                                name="activity"
                                value={eachActivity}
                                disabled={this.state.showViewForm}
                                onChange={(e) =>
                                  this.adminClearanceHandler(
                                    e,
                                    index,
                                    "pending"
                                  )
                                }
                                checked={eachActivity.status === false}
                              />
                              <label
                                className="pending_status_data"
                                htmlFor="pending"
                              >
                                Pending
                              </label>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                    {/* data finance */}
                    {/* data finance mobile view */}
                    <div className="activity_Table_Mobile">
                      {this.state.employeeActivityInfoDetails.map(
                        (a, index) => (
                          <div key={index} className="Finance_Heading_Mobile">
                            <div className="MobileView_clearance_Form">
                              <div className="Finance_Heading_sNo_H_Mobile_label">
                                <span>S.No</span>
                              </div>
                              <div className="Finance_Heading_sNo_H_Mobile_label_Colon">
                                <span>:</span>
                              </div>
                              <div className="Finance_Heading_sNo_H_Mobile">
                                <span>{index + 1}</span>
                              </div>
                            </div>
                            <div className="MobileView_clearance_Form">
                              <div className="Finance_Heading_sNo_H_Mobile_label">
                                <span>List of Activities</span>
                              </div>
                              <div className="Finance_Heading_sNo_H_Mobile_label_Colon">
                                <span>:</span>
                              </div>
                              <div className="Finance_Heading_sNo_H_Mobile">
                                <input
                                  value={a.activity}
                                  onChange={(e) =>
                                    this.adminClearanceHandler(
                                      e,
                                      index,
                                      "activityname"
                                    )
                                  }
                                  className="Input_fields_Admin"
                                />
                              </div>
                            </div>
                            <div className="MobileView_clearance_Form">
                              <div className="Finance_Heading_sNo_H_Mobile_label">
                                <span>Remarks</span>
                              </div>
                              <div className="Finance_Heading_sNo_H_Mobile_label_Colon">
                                <span>:</span>
                              </div>
                              <div className="Finance_Heading_sNo_H_Mobile">
                                <input
                                  value={a.remarks}
                                  onChange={(e) =>
                                    this.adminClearanceHandler(
                                      e,
                                      index,
                                      "status"
                                    )
                                  }
                                  className="Input_fields_Admin"
                                />
                              </div>
                            </div>
                            <div className="MobileView_clearance_Form">
                              <div className="Finance_Heading_sNo_H_Mobile_label">
                                <span> (Pending/Completed)</span>
                              </div>
                              <div className="Finance_Heading_sNo_H_Mobile_label_Colon">
                                <span>:</span>
                              </div>
                              <div style={{ display: "block", width: "50%" }}>
                                <div className="Finance_Heading_sNo_H_Mobile">
                                  <Checkbox
                                    inputId="completed"
                                    name="activity"
                                    value={a}
                                    disabled={this.state.showViewForm}
                                    onChange={(e) =>
                                      this.adminClearanceHandler(
                                        e,
                                        index,
                                        "completed"
                                      )
                                    }
                                    checked={a.status === true}
                                  />
                                  <label
                                    className="pending_status_data"
                                    htmlFor="completed"
                                  >
                                    Completed
                                  </label>
                                </div>
                                <div className="Finance_Heading_sNo_H_Mobile">
                                  <Checkbox
                                    style={{ marginTop: "2px" }}
                                    inputId="pending"
                                    name="activity"
                                    value={a}
                                    disabled={this.state.showViewForm}
                                    onChange={(e) =>
                                      this.adminClearanceHandler(
                                        e,
                                        index,
                                        "pending"
                                      )
                                    }
                                    checked={a.status === false}
                                  />
                                  <label
                                    className="pending_status_data"
                                    htmlFor="pending"
                                  >
                                    Pending
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>

                    {/* data finance mobile view ended */}

                    {/* clearance from finance */}
                    <h1 className="Form55_Admin">CLEARANCE FROM FINANCE</h1>
                    <div className="Finance_Heading">
                      <div className="Finance_Heading_sNo">
                        <span className="finance_H_Weight">S.No</span>
                      </div>
                      <div className="Finance_Heading_Data">
                        <span className="finance_H_Weight">Item Name</span>
                      </div>
                      <div className="Finance_Heading_Data">
                        <span className="finance_H_Weight">Remarks</span>
                      </div>
                      <div className="Finance_Heading_Data">
                        <span className="finance_H_Weight">
                          (Pending/Completed)
                        </span>
                      </div>
                    </div>
                    {/* clear finance */}
                    <div className="finance_Table">
                      {this.state.financeTable.map((v, index) => (
                        <div key={v.Sno} className="Finance_Heading_">
                          <div className="Finance_Heading_sNo_H">
                            <span>{v.Sno}</span>
                          </div>
                          <div className="Finance_Heading_Data_H">
                            <span>{v.itemName}</span>
                          </div>

                          <div className="Finance_Heading_Data_H">
                            <input
                              value={" "}
                              onChange={(e) =>
                                this.adminClearanceHandler(e, index, "status")
                              }
                              className="Input_fields_Admin"
                            />
                          </div>
                          <div className="Finance_Heading_Data_H">
                            <Checkbox
                              inputId="completed"
                              name="activity"
                              value={false}
                              disabled={this.state.showViewForm}
                              checked={v.completed}
                              onChange={() => {
                                v.completed = true;
                                this.setState({
                                  financeTable: this.state.financeTable,
                                });
                              }}
                            />
                            <label
                              className="pending_status_data"
                              htmlFor="completed"
                            >
                              Completed
                            </label>
                            <Checkbox
                              inputId="pending"
                              name="activity"
                              value={true}
                              disabled={this.state.showViewForm}
                              checked={v.completed === false}
                              onChange={() => {
                                v.completed = false;
                                this.setState({
                                  financeTable: this.state.financeTable,
                                });
                              }}
                            />
                            <label
                              className="pending_status_data"
                              htmlFor="pending"
                            >
                              Pending
                            </label>
                          </div>
                        </div>
                      ))}
                      {/* clear finance */}
                      {/* mobile view finanve  */}
                      <div className="activity_Table_Mobile">
                        {this.state.financeTable.map((v, index) => (
                          <div key={v.SNo} className="Finance_Heading_Mobile">
                            <div className="MobileView_clearance_Form">
                              <div className="Finance_Heading_sNo_H_Mobile_label">
                                <span>S.No</span>
                              </div>
                              <div className="Finance_Heading_sNo_H_Mobile_label_Colon">
                                <span>:</span>
                              </div>
                              <div className="Finance_Heading_sNo_H_Mobile">
                                <span>{v.Sno}</span>
                              </div>
                            </div>
                            <div className="MobileView_clearance_Form">
                              <div className="Finance_Heading_sNo_H_Mobile_label">
                                <span>Item Name</span>
                              </div>
                              <div className="Finance_Heading_sNo_H_Mobile_label_Colon">
                                <span>:</span>
                              </div>
                              <div className="Finance_Heading_sNo_H_Mobile">
                                <span>{v.itemName}</span>
                              </div>
                            </div>
                            <div className="MobileView_clearance_Form">
                              <div className="Finance_Heading_sNo_H_Mobile_label">
                                <span>Remarks</span>
                              </div>
                              <div className="Finance_Heading_sNo_H_Mobile_label_Colon">
                                <span>:</span>
                              </div>
                              <div className="Finance_Heading_sNo_H_Mobile">
                                <input
                                  value={v.Remarks}
                                  onChange={(e) =>
                                    this.FinanceChangeHandler(
                                      e,
                                      index,
                                      "Remarks"
                                    )
                                  }
                                  className="Input_fields_Admin"
                                />
                              </div>
                            </div>
                            <div className="MobileView_clearance_Form">
                              <div className="Finance_Heading_sNo_H_Mobile_label">
                                <span>(Pending/Completed)</span>
                              </div>
                              <div className="Finance_Heading_sNo_H_Mobile_label_Colon">
                                <span>:</span>
                              </div>
                              <div className="Finance_Heading_sNo_H_Mobile">
                                <input
                                  value={v.signature}
                                  onChange={(e) =>
                                    this.FinanceChangeHandler(
                                      e,
                                      index,
                                      "signature"
                                    )
                                  }
                                  className="Input_fields_Admin"
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      {/* mobile view finance ended */}
                    </div>

                    <div className="HRSign_Date">
                      <div className="HRSign">
                        <span className="signature_forHr">Signature:</span>
                        <input
                          style={{ marginLeft: "5px" }}
                          type="file"
                        ></input>
                        {/* {chooseFile && <span className="Error">{chooseFile}</span>} */}
                      </div>
                      <div className="HRDate">
                        <span className="signature_forHr">Date:</span>
                        <input
                          style={{ marginLeft: "5px" }}
                          className="Input_fields_Admin_date"
                          type="date"
                          min={this.state.currentdate}
                          max={this.state.currentdate}
                          value={this.state.currentDate}
                        ></input>
                      </div>
                    </div>
                    <div className="Submit_btn_userpage_admin_otp">
                      <button onClick={this.submitHandler} className="save-btn">
                        Submit<i className="pi pi-send"></i>
                      </button>
                    </div>
                  </div>
                )}

              {this.state.displayClearanceForm === true &&
                !this.state.employeeClearanceData && (
                  <div
                    className="NoData"
                   
                  >
                    <h3>Sorry, No Record is submitted by the Employee...</h3>
                  </div>
                )}

              {this.state.displayExitInterviewForEmployee === true && (
                <div className="EmployeeRelieved_Details">
                  {(!filteredemployeeInterviewData ||
                    filteredemployeeInterviewData === undefined ||
                    this.state.getExitInterviewData === {}) && (
                    <div className="NoData">
                      <h3>
                        Sorry, No Record is submitted by the current Employee,
                        you are searching for...
                      </h3>
                    </div>
                  )}
                  {(filteredemployeeInterviewData ||
                    filteredemployeeInterviewData !== undefined) && (
                    <div
                      className="personal_Info_fields_ExitInterview"
                      style={{
                        display: !this.state.getExitInterviewData && "none",
                      }}
                    >
                      <div className="personal_Info_label_input">
                        <div
                          className="personal_Info_label11"
                          style={{ marginLeft: "32px" }}
                        >
                          <span>Mr/Ms:</span>
                        </div>
                        <div className="personal_Info_input">
                          <input
                            className="Input_fields"
                            type="text"
                            placeholder="Employee name"
                            value={
                              this.state.getExitInterviewData &&
                              this.state.getExitInterviewData.name
                            }
                            autoComplete="off"
                          ></input>
                        </div>
                      </div>

                      <div className="personal_Info_label_input">
                        <div
                          className="personal_Info_label11"
                          style={{ marginLeft: "32px" }}
                        >
                          <span>Designation:</span>
                        </div>
                        <div className="personal_Info_input">
                          <input
                            className="Input_fields"
                            type="text"
                            placeholder="Designation"
                            value={
                              this.state.getExitInterviewData &&
                              this.state.getExitInterviewData.designation
                            }
                            autoComplete="off"
                          ></input>
                        </div>
                      </div>

                      <div className="personal_Info_label_input">
                        <div
                          className="personal_Info_label11"
                          style={{ marginLeft: "32px" }}
                        >
                          <span>Reporting:</span>
                        </div>
                        <div className="personal_Info_input">
                          <input
                            className="Input_fields"
                            type="text"
                            disabled={this.props.showView}
                            placeholder="Reporting"
                            value={
                              this.state.getExitInterviewData &&
                              this.state.getExitInterviewData.reportingManager
                            }
                          ></input>
                        </div>
                      </div>

                      <div className="personal_Info_label_input">
                        <div
                          className="personal_Info_label11"
                          style={{ marginLeft: "32px" }}
                        >
                          <span>Id No:</span>
                        </div>
                        <div className="personal_Info_input">
                          <input
                            className="Input_fields"
                            type="text"
                            placeholder="Id No"
                            value={
                              this.state.getExitInterviewData &&
                              this.state.getExitInterviewData.empCode
                            }
                          ></input>
                        </div>
                      </div>

                      <div className="personal_Info_label_input">
                        <div
                          className="personal_Info_label11"
                          style={{ marginLeft: "32px" }}
                        >
                          <span>Department:</span>
                        </div>
                        <div className="personal_Info_input">
                          <input
                            className="Input_fields"
                            type="text"
                            placeholder="Id No"
                            value={
                              this.state.getExitInterviewData &&
                              this.state.getExitInterviewData.department
                            }
                          ></input>
                        </div>
                      </div>

                      <div className="personal_Info_label_input">
                        <div
                          className="personal_Info_label11"
                          style={{ marginLeft: "32px" }}
                        >
                          <span>Date of Joining:</span>
                        </div>
                        <div className="personal_Info_input">
                          <input
                            className="Input_fields"
                            type="text"
                            placeholder="Relieving Date"
                            value={
                              this.state.getExitInterviewData &&
                              this.state.getExitInterviewData.joiningDate &&
                              this.state.getExitInterviewData.joiningDate.split(
                                "T"
                              )[0]
                            }
                          ></input>
                        </div>
                      </div>

                      <div className="personal_Info_label_input">
                        <div
                          className="personal_Info_label11"
                          style={{ marginLeft: "32px" }}
                        >
                          <span>Relieving Date:</span>
                        </div>
                        <div className="personal_Info_input">
                          <input
                            className="Input_fields"
                            type="text"
                            placeholder="Id No"
                            value={
                              this.state.getExitInterviewData &&
                              this.state.getExitInterviewData.relievingDate
                            }
                          ></input>
                        </div>
                      </div>

                      {/* <div className="EmpRelieved_Field_Value"> */}
                    </div>
                  )}

                  <h1
                    className="Form55_Admin"
                    style={{
                      display: !this.state.getExitInterviewData && "none",
                    }}
                  >
                    {" "}
                    EMPLOYEE FEEDBACK{" "}
                  </h1>

                  <div
                    className="activity_Table"
                    style={{
                      display: !this.state.getExitInterviewData && "none",
                    }}
                  >
                    {filteredemployeeFeedback &&
                      filteredemployeeFeedback.map((eachActivity, index) => (
                        <div className="Principle_Reason">
                          <span className="Question">
                            {this.renderEachQuestion(
                              this.state.getInterviewQuestions,
                              eachActivity,
                              index
                            )}
                          </span>

                          <div className="leaving_Info_fields">
                            {eachActivity.questionType === "checkbox" &&
                              eachActivity.questionId < 6 &&
                              this.state.getExitInterviewOptions.map(
                                (employmentoption) => {
                                  return (
                                    <div
                                      key={employmentoption.id}
                                      className="p-field-checkbox"
                                    >
                                      <div>
                                        <Checkbox
                                          name="employmentoption"
                                          inputId={employmentoption.id}
                                          disabled={this.props.showView}
                                          value={employmentoption}
                                          checked={
                                            eachActivity.optionId ===
                                            employmentoption.id
                                          }
                                        />
                                        <label
                                          className="label_checkbox"
                                          htmlFor={employmentoption.id}
                                        >
                                          {employmentoption.option}
                                        </label>
                                      </div>
                                    </div>
                                  );
                                }
                              )}

                            {eachActivity.questionType === "checkbox" &&
                              eachActivity.questionId >=6 &&
                              this.state.degreeOfAcceptance.map(
                                (employmentoption) => {
                                  return (
                                    <div
                                      key={employmentoption.id}
                                      className="p-field-checkbox"
                                    >
                                      <div>
                                        <Checkbox
                                          name="employmentoption"
                                          inputId={employmentoption.id}
                                          disabled={this.props.showView}
                                          value={employmentoption}
                                          checked={
                                            eachActivity.optionId ===
                                            employmentoption.id
                                          }
                                        />
                                        <label
                                          className="label_checkbox"
                                          htmlFor={employmentoption.id}
                                        >
                                          {employmentoption.acceptance}
                                        </label>
                                      </div>
                                    </div>
                                  );
                                }
                              )}

                            {eachActivity.questionType === "text" && (
                              <div
                                key={eachActivity.questionId}
                                className="p-field-checkbox"
                              >
                                <div>
                                  <label
                                    className="label_checkbox"
                                    htmlFor={eachActivity.questionId}
                                  >
                                    {eachActivity.answer}
                                  </label>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}

              {this.state.showdetails === "empdetails" && this.state.isCorrect && (
                <div className="redux">
                  <div className="capp8">
                    {filteredemployees != null &&
                      filteredemployees.map((empDetails) => {
                        return (
                          <ul key={empDetails.id}>
                            <li>
                              {this.state.isCorrect && (
                                <div className="employee_footer_uploadExcel_1266">
                                  {/* <img src={svg} alt="svh" /> */}
                                  <img
                                    alt="svh"
                                    src={svg}
                                    data-pr-tooltip="Download Excel"
                                  />
                                </div>
                              )}
                              {/* <div className="empdetailsinadminHeading">
                              <span
                                style={{
                                  color: "rgb(3 51 228)",
                                  fontWeight: "700",
                                  fontSize: "15px",
                                }}
                              >
                                Generated Code
                              </span>
                            </div> */}
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Employee Id
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <div>
                                    {this.state.employeecodestore
                                      .employeeCode == null ? (
                                      <div>
                                        <span className="empdetailsinadminD">
                                          Not Assigned
                                        </span>
                                      </div>
                                    ) : (
                                      <div>
                                        <span className="empdetailsinadminD">
                                          {
                                            this.state.employeecodestore
                                              .employeeCode
                                          }
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="empdetailsinadminHeading">
                                <span
                                  style={{
                                    color: "rgb(3 51 228)",
                                    fontWeight: "700",
                                    fontSize: "15px",
                                  }}
                                >
                                  Status
                                </span>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Role
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>

                                {empDetails.role == "" ||
                                empDetails.role == null ? (
                                  <div className="empdetailsinadmin_D">
                                    <span className="empdetailsinadminD">
                                      Not Updated
                                    </span>
                                  </div>
                                ) : (
                                  <div className="empdetailsinadmin_D">
                                    <span className="empdetailsinadminD">
                                      {empDetails.role}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Status
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>

                                <div className="empdetailsinadmin_D">
                                  {empDetails.status == null ? (
                                    <div>
                                      <span>New Employee</span>
                                    </div>
                                  ) : (
                                    <div>
                                      <span>{empDetails.status}</span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="empdetailsinadminHeading">
                                <span
                                  style={{
                                    color: "rgb(3 51 228)",
                                    fontWeight: "700",
                                    fontSize: "15px",
                                  }}
                                >
                                  Personal Info
                                </span>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    First Name
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {empDetails.firstName}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Last Name
                                  </span>
                                </div>

                                <div className="colonalignment">:</div>

                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {empDetails.lastName}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Middle Name
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {empDetails.otherName}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Gender
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {empDetails.gender}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">Dob</span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {/* {empDetails.dob != null &&
                                      empDetails.dob.split("T")[0]} */}
                                    {empDetails.dob != null &&
                                      empDetails.dob
                                        .split("-")[2]
                                        .split("T")[0]}
                                    -
                                    {empDetails.dob != null &&
                                      empDetails.dob.split("-")[1]}
                                    -
                                    {empDetails.dob != null &&
                                      empDetails.dob.split("-")[0]}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Email
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {empDetails.email}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Blood Group
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>

                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {empDetails.bloodGroup}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Passport Number
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {empDetails.passportNumber}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Date Of Issue
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {/* {empDetails.dateOfIssue != null &&
                                      empDetails.dateOfIssue.split("T")[0]} */}
                                    {empDetails.dateOfIssue != null &&
                                      empDetails.dateOfIssue
                                        .split("-")[2]
                                        .split("T")[0]}
                                    -
                                    {empDetails.dateOfIssue != null &&
                                      empDetails.dob.split("-")[1]}
                                    -
                                    {empDetails.dateOfIssue != null &&
                                      empDetails.dateOfIssue.split("-")[0]}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Date Of Expiry
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {/* {empDetails.dateOfExpiry != null &&
                                      empDetails.dateOfExpiry.split("T")[0]} */}
                                    {empDetails.dateOfExpiry != null &&
                                      empDetails.dateOfExpiry
                                        .split("-")[2]
                                        .split("T")[0]}
                                    -
                                    {empDetails.dateOfExpiry != null &&
                                      empDetails.dob.split("-")[1]}
                                    -
                                    {empDetails.dateOfExpiry != null &&
                                      empDetails.dateOfExpiry.split("-")[0]}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Pan Card Number
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {empDetails.panCardNumber}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Aadhar Number
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {empDetails.aadharNumber}
                                  </span>
                                </div>
                              </div>
                            </li>
                            {empDetails.contactInfo
                              .sort((a, b) => a.id - b.id)
                              .map((s, index) => (
                                <div key={index}>
                                  <br />
                                  <li>
                                    <div className="empdetailsinadminHeading">
                                      <span
                                        style={{
                                          color: "rgb(3 51 228)",
                                          fontWeight: "700",
                                          fontSize: "15px",
                                        }}
                                      >
                                        {index == 0
                                          ? "Present Address"
                                          : "Permanent Address"}
                                      </span>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Address
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {s.address}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          City
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {s.city}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Pincode
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {s.pincode}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          State
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {s.state}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Country
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {s.country}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Nationality
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {s.nationality}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Phone
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {s.phone}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Emergency Contact
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {s.emergencyContact}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Alternate Contact
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {s.alternateContact}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                </div>
                              ))}
                            <br />
                            <div>
                              <li>
                                <div className="empdetailsinadminHeading">
                                  <span
                                    style={{
                                      color: "rgb(3 51 228)",
                                      fontWeight: "700",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Education Info
                                  </span>
                                </div>
                              </li>
                              {filteredemployees.educationInfo == null && (
                                <div>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Degree
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {empDetails.educationInfo.degree}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          College Name
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {empDetails.educationInfo.collegeName}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          University Name
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {
                                            empDetails.educationInfo
                                              .universityName
                                          }
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Subject
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {empDetails.educationInfo.subject}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Course Start Date
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {/* {empDetails.educationInfo
                                            .courseStartDate != null &&
                                            empDetails.educationInfo.courseStartDate.split(
                                              "T"
                                            )[0]} */}
                                          {empDetails.educationInfo
                                            .courseStartDate != null &&
                                            empDetails.educationInfo.courseStartDate
                                              .split("-")[2]
                                              .split("T")[0]}
                                          -
                                          {empDetails.educationInfo
                                            .courseStartDate != null &&
                                            empDetails.educationInfo.courseStartDate.split(
                                              "-"
                                            )[1]}
                                          -
                                          {empDetails.educationInfo
                                            .courseStartDate != null &&
                                            empDetails.educationInfo.courseStartDate.split(
                                              "-"
                                            )[0]}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Course End Date
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {/* {empDetails.educationInfo
                                            .courseEndDate != null &&
                                            empDetails.educationInfo.courseEndDate.split(
                                              "T"
                                            )[0]} */}
                                          {empDetails.educationInfo
                                            .courseEndDate != null &&
                                            empDetails.educationInfo.courseEndDate
                                              .split("-")[2]
                                              .split("T")[0]}
                                          -
                                          {empDetails.educationInfo
                                            .courseEndDate != null &&
                                            empDetails.educationInfo.courseEndDate.split(
                                              "-"
                                            )[1]}
                                          -
                                          {empDetails.educationInfo
                                            .courseEndDate != null &&
                                            empDetails.educationInfo.courseEndDate.split(
                                              "-"
                                            )[0]}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Course Type
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {empDetails.educationInfo.courseType}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Address Of Institution
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {
                                            empDetails.educationInfo
                                              .addressOfInstitution
                                          }
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          City
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {empDetails.educationInfo.city}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          PinCode
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {empDetails.educationInfo.pinCode}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Landline1
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {empDetails.educationInfo.landline1}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Landline2
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {empDetails.educationInfo.landline2}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                </div>
                              )}
                              <br />
                            </div>
                            {empDetails.employmentInfo.length > 0 &&
                              empDetails.employmentInfo.map((el) => (
                                <div key={el.id}>
                                  <li>
                                    <div className="empdetailsinadminHeading">
                                      <span
                                        style={{
                                          color: "rgb(3 51 228)",
                                          fontWeight: "700",
                                          fontSize: "15px",
                                        }}
                                      >
                                        Experience
                                      </span>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Company Name
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {el.companyName}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Employed Place
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {el.employedPlace}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Company Address
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {el.companyAddress}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          City
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {el.city}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          State
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {el.state}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Pincode
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {el.pincode}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          From Date
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {/* {el.fromDate != null &&
                                            el.fromDate.split("T")[0]} */}
                                          {el.fromDate != null &&
                                            el.fromDate
                                              .split("-")[2]
                                              .split("T")[0]}
                                          -
                                          {el.fromDate != null &&
                                            el.fromDate.split("-")[1]}
                                          -
                                          {el.fromDate != null &&
                                            el.fromDate.split("-")[0]}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          To Date
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {/* {el.toDate != null &&
                                            el.toDate.split("T")[0]} */}
                                          {el.toDate != null &&
                                            el.toDate
                                              .split("-")[2]
                                              .split("T")[0]}
                                          -
                                          {el.toDate != null &&
                                            el.toDate.split("-")[1]}
                                          -
                                          {el.toDate != null &&
                                            el.toDate.split("-")[0]}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Designation
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {el.designation}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Remuneration
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {el.remuneration}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Employee Code
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {el.employeeCode}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Employment Nature
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {el.employmentNature}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Supervisor Name
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {el.supervisorName}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Supervisor Designation
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {el.supervisorDesignation}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Superrvisor EmailId
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {el.supervisorEmailId}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="Employee_Details_">
                                      <div className="empdetailsinadmin_H">
                                        <span className="empdetailsinadmin">
                                          Leaving Reason
                                        </span>
                                      </div>
                                      <div className="colonalignment">:</div>
                                      <div className="empdetailsinadmin_D">
                                        <span className="empdetailsinadminD">
                                          {el.leavingReason}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                </div>
                              ))}
                          </ul>
                        );
                      })}
                  </div>
                </div>
              )}
              {this.state.showdetails == "pfdetails" && this.state.isCorrect && (
                <div className="capp8">
                  <ul>
                    {filteredemployeespfdata.nominee != "" &&
                      filteredemployeespfdata != null &&
                      filteredemployeespfdata.map((employee) => {
                        return (
                          <div key={employee.id}>
                            <li>
                              {this.state.isCorrect && (
                                <div className="employee_footer_uploadExcel_1266">
                                  {/* <img src={svg} alt="svh" /> */}
                                  <img
                                    alt="svh"
                                    src={svg}
                                    data-pr-tooltip="Download Excel"
                                  />
                                </div>
                              )}
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Emp Id
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {employee.empCode}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Emp Name:
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {employee.empName}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Father Name
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {employee.fatherName}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Marital Status
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {employee.maritalStatus}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Mobile
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {employee.mobile}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Qualification
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {employee.qualification}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Date Of Joining
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {/* {employee.dateOfJoining != null &&
                                      employee.dateOfJoining.split("T")[0]} */}
                                    {employee.dateOfJoining != null &&
                                      employee.dateOfJoining
                                        .split("-")[2]
                                        .split("T")[0]}
                                    -
                                    {employee.dateOfJoining != null &&
                                      employee.dateOfJoining.split("-")[1]}
                                    -
                                    {employee.dateOfJoining != null &&
                                      employee.dateOfJoining.split("-")[0]}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Email Id
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {employee.emailId}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">Dob</span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {/* {employee.dob != null &&
                                      employee.dob.split("T")[0]} */}
                                    {employee.dob != null &&
                                      employee.dob.split("-")[2].split("T")[0]}
                                    -
                                    {employee.dob != null &&
                                      employee.dob.split("-")[1]}
                                    -
                                    {employee.dob != null &&
                                      employee.dob.split("-")[0]}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Bank Account Number
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {employee.bankAccountNumber}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Bank Name
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {employee.bankName}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Ifsc Code
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {employee.ifscCode}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Name As Per Bank
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {employee.nameAsPerBank}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Pan card Number
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {employee.pancardNumber}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Name As Per Pan
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {employee.nameAsPerPan}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Aadhar Number
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {employee.aadharNumber}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Name As Per Aadhar
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {employee.nameAsPerAadhar}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">
                                    Gender
                                  </span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {employee.gender}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="Employee_Details_">
                                <div className="empdetailsinadmin_H">
                                  <span className="empdetailsinadmin">Uan</span>
                                </div>
                                <div className="colonalignment">:</div>
                                <div className="empdetailsinadmin_D">
                                  <span className="empdetailsinadminD">
                                    {employee.uan}
                                  </span>
                                </div>
                              </div>
                            </li>
                          </div>
                        );
                      })}
                  </ul>
                  {this.state.employeecodestore.employeeCode != null &&
                    this.state.employeePfData[0] == null && (
                      <div className="NoData">
                        <h3>Sorry, No Record is Found...</h3>
                      </div>
                    )}
                  {this.state.employeecodestore.employeeCode == null &&
                    this.state.employeePfData[0] == null && (
                      <div className="NoData">
                        <h3>
                          Sorry, No PF details recorded as Employee ID is not
                          assigned...
                        </h3>
                      </div>
                    )}
                </div>
              )}
              {this.state.showdetails == "empCode" && this.state.isCorrect && (
                <div className="assign_employeeCodeStatus">
                  {this.state.alreadyAssigned && (
                    <span className="empcodeassign2">
                      Already assigned employee id for this user
                    </span>
                  )}
                  {this.state.statuscodesubmitted &&
                    this.state.generatedCode.map((v) => {
                      return (
                        <div key={v.id}>
                          <span className="empcodeassign2">
                            Assigned EmployeCode is {v.code}
                          </span>
                        </div>
                      );
                    })}
                  {this.state.failedTo && (
                    <h6 style={{ color: "red" }} className="no">
                      Failed To Assign
                    </h6>
                  )}
                  <br />
                  {this.props.IsAssigned && (
                    <div className="uploadSaved4">
                      <ConfirmDialog
                        visible={this.props.IsAssigned}
                        message="Are you sure you want to assign?"
                        header="Confirmation"
                        icon="pi pi-exclamation-triangle"
                        accept={this.submitHandler.bind(this)}
                        reject={() =>
                          this.props.dispatch(isassigneddata(false))
                        }
                      />
                    </div>
                  )}
                </div>
              )}
              {this.state.showdetails == "status" && this.state.isCorrect && (
                <div>
                  <div className="personal_Info_label_input_Admin">
                    <div className="personal_Info_label_Admin">
                      <span>Status</span>
                    </div>
                    <div className="personal_Info_label_Admin_Colon">
                      <span>:</span>
                    </div>
                    <div className="personal_Info_input">
                      <Dropdown
                        value={this.state.selectedstatus}
                        options={options}
                        onChange={this.selectHandler}
                        optionLabel="name"
                        autoFocus
                        placeholder="Select any status"
                      />
                    </div>
                  </div>
                  {this.state.selectedstatus.name == "Send for Verification" &&
                    this.state.showdetails == "status" && (
                      <>
                        <div className="personal_Info_label_input_Admin">
                          <div className="personal_Info_label_Admin">
                            <span>Role</span>
                          </div>
                          <div className="personal_Info_label_Admin_Colon">
                            <span>:</span>
                          </div>
                          <div className="personal_Info_input">
                            <Select
                              className="selecthandler2"
                              options={requestOptions}
                              value={this.state.userstatusselection}
                              onChange={this.userselectHandler}
                            ></Select>
                          </div>
                        </div>
                      </>
                    )}
                  {(this.state.selectedstatusUpadted == "Onboarded" ||
                    this.state.selectedstatusUpadted == "Consultant" ||
                    this.state.selectedstatus.name == "Consultant" ||
                    this.state.selectedstatus.name == "Onboarded") && (
                    <>
                      <div className="personal_Info_label_input_Admin">
                        <div className="personal_Info_label_Admin">
                          <span>Client</span>
                        </div>
                        <div className="personal_Info_label_Admin_Colon">
                          <span>:</span>
                        </div>
                        <div className="personal_Info_input">
                          <input
                            className="Input_fields_Admin"
                            type="text"
                            placeholder="Name"
                            value={this.state.updateLocationComany.company}
                            onChange={(e) => this.onChangeCompany(e, "company")}
                          ></input>
                          <div
                            className="personal_Info_Error_Admin"
                            style={{ width: "100%" }}
                          >
                            {company && (
                              <span className="Error">{company}</span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="personal_Info_Error_Mobile_Admin">
                        {company && <span className="Error">{company}</span>}
                      </div>
                      <div className="personal_Info_label_input_Admin">
                        <div className="personal_Info_label_Admin">
                          <span>Work Location</span>
                        </div>
                        <div className="personal_Info_label_Admin_Colon">
                          <span>:</span>
                        </div>
                        <div className="personal_Info_input">
                          <input
                            className="Input_fields_Admin"
                            type="text"
                            placeholder="Location"
                            value={this.state.updateLocationComany.workLocation}
                            onChange={(e) =>
                              this.onChangelocation(e, "workLocation")
                            }
                          ></input>
                          <div
                            className="personal_Info_Error_Admin"
                            style={{ width: "100%" }}
                          >
                            {workLocation && (
                              <span className="Error">{workLocation}</span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="personal_Info_Error_Mobile_Admin">
                        {workLocation && (
                          <span className="Error">{workLocation}</span>
                        )}
                      </div>
                    </>
                  )}
                  <div className="personal_Info_label_input_Admin">
                    <div className="personal_Info_label_Admin">
                      <span>Comment</span>
                    </div>
                    <div className="personal_Info_label_Admin_Colon">
                      <span>:</span>
                    </div>
                    <div className="personal_Info_input">
                      <InputTextarea
                        placeholder="Add a description...."
                        autoResize={true}
                        value={this.state.SaveComment.status}
                        onChange={(e) => this.savecommentHandler(e, "status")}
                        rows={6}
                        cols={40}
                        autoResize
                      />
                    </div>
                    <div className="personal_Info_input_480">
                      <InputTextarea
                        placeholder="Add a description...."
                        autoResize={true}
                        value={this.state.SaveComment.status}
                        onChange={(e) => this.savecommentHandler(e, "status")}
                        rows={6}
                        cols={30}
                        autoResize
                      />
                    </div>
                  </div>
                </div>
              )}
              {this.state.showdetails == "status" && this.state.isCorrect && (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  {this.state.updatedstatus && (
                    <Toast
                      ref={(el) => (this.toastTR = el)}
                      position="top-right"
                    />
                  )}
                </div>
              )}
              {this.state.showdetails == "status" && (
                <div className="Status_selection">
                  {this.state.submitBtnDisable &&
                    (this.state.previousUpdatedLocationComapany !==
                      this.state.updateLocationComany.company ||
                      this.state.previousUpdatedLocation !==
                        this.state.updateLocationComany.workLocation ||
                      this.state.selectedstatus.name !==
                        this.state.selectedstatusUpadted.name ||
                      this.state.verifyUpdateSaveComment !==
                        this.state.SaveComment.status ||
                      this.state.verifyRoleView !==
                        this.state.userstatusselection.label) && (
                      <button
                        className={
                          this.state.selectedstatus.name ==
                          "Send for Verification"
                            ? "buttonempcodeSubmit"
                            : "buttonempcodeSubmit1"
                        }
                        onClick={this.submittedStatus}
                        type="button"
                      >
                        Submit
                      </button>
                    )}
                </div>
              )}
            </div>
            {this.state.showdetails == "Relieved" ||
              this.state.showdetails == "uploadeddocuments" ||
              this.state.showdetails == "status" || (
                <div className="employee_footer_uploadExcel">
                  <Tooltip
                    target=".download_excel"
                    mouseTrack
                    position="left"
                    mouseTrackRight={16}
                  />
                  <img
                    className="download_excel"
                    onClick={this.pdfHandler}
                    alt="svh"
                    src={svg}
                    data-pr-tooltip="download data"
                  />

                  <Dialog
                    header="Header"
                    visible={this.state.displayExcelModal}
                    style={{ width: "50vw" }}
                    footer={this.renderFooter}
                    onHide={() => this.setState({ displayExcelModal: false })}
                  >
                    <p className="p-m-0">
                      {" "}
                      Please select any of the following options
                    </p>
                  </Dialog>
                </div>
              )}
          </div>
        )}
        {/* {this.state.employees.length <= 0 && (
          <h1 style={{ textAlign: "center" }}>No Data Found....</h1>
        )} */}
        ;
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isGuest: state.isGuest,
    statusModal: state.statusModal,
    IsAssigned: state.IsAssigned,
    admin_loggedOut: state.admin_loggedOut,
    isSpinner: state.isSpinner,
  };
};
export default connect(mapStateToProps)(previousForms);
