import {
  EMAILUPLOAD,
  EMAILDATA,
  LINEARPROGRESS,
  ALWAYSASSIGNED,
  ADMINLOGOUT,
  EMPLOYEELOGOUT,
  ADDMOREEXPIRIENCE,
  LOADING,
  SELECTEDVALUE,
  ALWAYSEXIST,
  EXPIRIENCEEXIST,
  INSURANCESUCCESS,
  STATUSMODELER,
  HEADER11,
  GUESTSTATUSMODELER,
  EMAILID,
  SAVE,
  PFDATASUCCESS,
  ISGUEST,
  PROFILE,
  PFDETAILS,
  INSURANCE,
  DASHBOARD,
  PFDATAID,
  ISPDDONE,
  ISLOOGEDOUT,
  ISADMINLOOGEDOUT,
  EMPLOYEEDATA,
  ISINSURANCEOUTTED,
  EMPLOYEE_CLEARANCE,
  EXIT_INTERVIEW,
  UPLOADED_DOCUMENTS,
  IFEMPLOYEEIDNOTGENERATED,
  SHOWEDITFORM,
  SHOWVIEWFORM,
  SHOWPF,
  BUTTONSTATUS,
  //fresh code by Janya for modifying sake
  SETEMAIL,
  CREATEMPLOYEEPROFILEDATA,
  CREATEMPLOYEEPFDATA,
  CREATEMPLOYEEINSURANCEDATA,
  SETEMPLOYEECLEARANCEFORM,
} from "./actionTypes.js";

export function showButtonStatus(btnStatus) {
  return {
    type: BUTTONSTATUS,
    btnStatus,
  };
}
export function showFormPfIns(enableFormForPfIns) {
  return {
    type: SHOWPF,
    enableFormForPfIns,
  };
}
export function showEditButton(showEdit) {
  return {
    type: SHOWEDITFORM,
    showEdit,
  };
}

export function showViewButton(showView) {
  return {
    type: SHOWVIEWFORM,
    showView,
  };
}

export function ifEmpIdNotGenerated(empIdNotAssigned) {
  return {
    type: IFEMPLOYEEIDNOTGENERATED,
    empIdNotAssigned,
  };
}
export function previouslyUploadedDocuments(empDocuments) {
  return {
    type: UPLOADED_DOCUMENTS,
    empDocuments,
  };
}
export function employeeclearance(empClearance) {
  return {
    type: EMPLOYEE_CLEARANCE,
    empClearance,
  };
}

export function exitinterviewfunction(exitinterview) {
  return {
    type: EXIT_INTERVIEW,
    exitinterview,
  };
}

export function employeedetails(employeeData) {
  return {
    type: EMPLOYEEDATA,
    employeeData,
  };
}

export function uploadData(email) {
  return {
    type: EMAILUPLOAD,
    email,
  };
}
export function LinearProgressSpinner(linearProgress) {
  return {
    type: LINEARPROGRESS,
    linearProgress,
  };
}
export function employeelogout(employee_logout) {
  return {
    type: EMPLOYEELOGOUT,
    employee_logout,
  };
}
export function adminLogout(admin_loggedOut) {
  return {
    type: ADMINLOGOUT,
    admin_loggedOut,
  };
}
export function insurancewassuccess(insurancemay) {
  return {
    type: INSURANCESUCCESS,
    insurancemay,
  };
}

export function uploadEmail(data) {
  //(this.props.empId);
  return {
    type: EMAILDATA,
    data,
  };
}
export function spinner(isSpinner) {
  return {
    type: LOADING,
    isSpinner,
  };
}
export function uploadid(empId) {
  return {
    type: EMAILID,
    empId,
  };
}
export function profileid(profile) {
  return {
    type: PROFILE,
    profile,
  };
}
export function insuranceid(insurance) {
  return {
    type: INSURANCE,
    insurance,
  };
}
export function pfdataid(pfdetails) {
  return {
    type: PFDETAILS,
    pfdetails,
  };
}
export function saved(save) {
  return {
    type: SAVE,
    save,
  };
}
export function dashboard(isDashboard) {
  return {
    type: DASHBOARD,
    isDashboard,
  };
}
export function pfdataemployee(insuranceemployeeId) {
  return {
    type: PFDATAID,
    insuranceemployeeId,
  };
}
export function loggingOut(isLoggedOut) {
  return {
    type: ISLOOGEDOUT,
    isLoggedOut,
  };
}
export function loggingOutAdmin(isLoggedAdminOut) {
  return {
    type: ISADMINLOOGEDOUT,
    isLoggedAdminOut,
  };
}
export function insuranceOutAdmin(isInsuranceOut) {
  return {
    type: ISINSURANCEOUTTED,
    isInsuranceOut,
  };
}
export function isPfSubmitted(pfLogout) {
  return {
    type: ISPDDONE,
    pfLogout,
  };
}
export function header112(header111) {
  return {
    type: HEADER11,
    header111,
  };
}
export function guestLogin(isGuest) {
  return {
    type: ISGUEST,
    isGuest,
  };
}
export function savedSuceessfully(uploadedpfdata) {
  return {
    type: PFDATASUCCESS,
    uploadedpfdata,
  };
}
export function statusModeled(statusModal) {
  return {
    type: STATUSMODELER,
    statusModal,
  };
}
export function gueststatusModeled(gueststatusModal) {
  return {
    type: GUESTSTATUSMODELER,
    gueststatusModal,
  };
}
export function isexpirienceThere(isThere) {
  return {
    type: EXPIRIENCEEXIST,
    isThere,
  };
}
export function isSelectedValue(selectedValue) {
  return {
    type: SELECTEDVALUE,
    selectedValue,
  };
}
export function isAddExpirience(AddedValue) {
  return {
    type: ADDMOREEXPIRIENCE,
    AddedValue,
  };
}

export function alwaysThere(always) {
  return {
    type: ALWAYSEXIST,
    always,
  };
}
export function isassigneddata(IsAssigned) {
  return {
    type: ALWAYSASSIGNED,
    IsAssigned,
  };
}

export function setEmail(emailprovided) {
  return {
    type: SETEMAIL,
    emailprovided,
  }
}

export function createEmployeeProfileData(EmpProfileData) {
  return {
    type: CREATEMPLOYEEPROFILEDATA,
    EmpProfileData,
  }
}

export function createEmployeePFData(EmpPFData) {
  return {
    type: CREATEMPLOYEEPFDATA,
    EmpPFData,
  }
}

export function createEmployeeInsuranceData(EmpInsuranceData) {
  return {
    type: CREATEMPLOYEEINSURANCEDATA,
    EmpInsuranceData,
  }
}

export function setEmployeeClearanceForm(EmpClearanceForm) {
  return {
    type: SETEMPLOYEECLEARANCEFORM,
    EmpClearanceForm,
}
}

export function setExitInterviewForm(EmpExitInterviewForm) {
  return {
    type: SETEMPLOYEECLEARANCEFORM,
    EmpExitInterviewForm,
}

}

