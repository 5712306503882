import React, { Component } from "react";
import Header from "../../Header/Header.js";
import { connect } from "react-redux";
import "./ExitInterviewForm.css";
import { InputTextarea } from "primereact/inputtextarea";
import { Checkbox } from "primereact/checkbox";
import {
  insuranceid,
  pfdataid,
  profileid,
  employeeclearance,
  exitinterviewfunction,
  previouslyUploadedDocuments,
  showViewButton,
} from "../../../store/actions.js";
import axios from "axios";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import Buttons from "../../Containers/Buttons_tab/buttons.js";
import { Toast } from "primereact/toast";

export class ExitInterviewForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ExitInterview: {
        id: 0,
        name: "",
        empCode: "",
        employeeNumber: Number(localStorage.getItem("empId")),
        relievingDate: "",
        designation: "",
        department: "",
        joiningDate: "",
        reportingManager: "",
        feedback: [],
        comments: "",
      },
      qid: 6,

      errors: {},
      getPFData: {},
      empCode: "",
      showViewForm: true,
      Leavingreason2: [
        { id: 1, name: "Health_Concern" },
        { id: 2, name: "Continue_Education" },
        { id: 3, name: "Personal_reason" },
        { id: 4, name: "Relocating_residence" },
        { id: 5, name: "Marriage" },
        { id: 6, name: "Performance_related" },
        { id: 7, name: "To_Accept_other_employment" },
      ],
      Leavingreason: [],
      Otherleavingreason: [],
      storeAttemptedOnes: [],
      questionRetrieved: [],
      employeeFeedback: [],
      selectedLeavingReason: [],
      Otheremploymentfields: [
        { oeid: 1, ename: "career advancement" },
        { oeid: 2, ename: "distance to from work" },
        { oeid: 3, ename: "work schedule" },
        { oeid: 4, ename: "Better benefits" },
        { oeid: 5, ename: "Better pay" },
        { oeid: 6, ename: "career change" },
        { oeid: 7, ename: "termination" },
      ],
      degreeOfAcceptance: [
        { id: 1, acceptance: "Strongly Agree", checked: "false" },
        { id: 2, acceptance: "Agree", checked: "false" },
        { id: 3, acceptance: "Disagree", checked: "false" },
        { id: 4, acceptance: "Strongly Disagree", checked: "false" },
      ],
      selectedOtherEmployment: [],
      selectedRating: [],
      content: [
        {
          id: 1,
          content_Q: "1.I had regular feedback on my performance",
          Strongly_Agree: "",
          Agree: "",
          Disagree: "",
          strongle_Disagree: "",
          rating: "",
        },

        {
          id: 2,
          content_Q: "2. The policies and welfare benefits were fair",
          Strongly_Agree: "",
          Agree: "",
          Disagree: "",
          strongle_Disagree: "",
          rating: "",
        },

        {
          id: 3,
          content_Q: "3. I am paid fairly for the work I do",
          Strongly_Agree: "",
          Agree: "",
          Disagree: "",
          strongle_Disagree: "",
          rating: "",
        },

        {
          id: 4,
          content_Q: "4. There is scope for personal development & growth",
          Strongly_Agree: "",
          Agree: "",
          Disagree: "",
          strongle_Disagree: "",
          rating: "",
        },

        {
          id: 5,
          content_Q: "5. I had a good working relationship with my supervisor",
          Strongly_Agree: "",
          Agree: "",
          Disagree: "",
          strongle_Disagree: "",
          rating: "",
        },

        {
          id: 6,
          content_Q: "6. I had a good working relationship with my Co-workers",
          Strongly_Agree: "",
          Agree: "",
          Disagree: "",
          strongle_Disagree: "",
          rating: "",
        },

        {
          id: 7,
          content_Q: "7. Training & job development met expectations",
          Strongly_Agree: "",
          Agree: "",
          Disagree: "",
          strongle_Disagree: "",
          rating: "",
        },

        {
          id: 8,
          content_Q: "8. Working conditions met expectations",
          Strongly_Agree: "",
          Agree: "",
          Disagree: "",
          strongle_Disagree: "",
          rating: "",
        },

        {
          id: 9,
          content_Q: "9. The work culture in the Organization was good",
          Strongly_Agree: "",
          Agree: "",
          Disagree: "",
          strongle_Disagree: "",
          rating: "",
        },

        {
          id: 10,
          content_Q: "10. Overall I was satisfied with the Job",
          Strongly_Agree: "",
          Agree: "",
          Disagree: "",
          strongle_Disagree: "",
          rating: "",
          empCode: "",
        },
      ],

      EmpData: [],
      pfData: [],
    };
    this.onLeavingReason = this.onLeavingReason.bind(this);
    this.onSelectRating = this.onSelectRating.bind(this);
    this.checkBoxQA = this.checkBoxQA.bind(this);
    this.onOtherEmploymentIssue = this.onOtherEmploymentIssue.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(exitinterviewfunction(true));
    this.props.dispatch(showViewButton(true));
    axios
      .get("https://capv.live/portalprod/getAllExitFormQuestions")
      .then((response) => {
        let random = response.data.result;
        let random2 = random.map((obj) => ({
          questionId: obj.id,
          questionType: obj.type,
          employeeNumber: Number(localStorage.getItem("empId")),
          optionId: "",
          answer: "",
        }));
        let employeeInterview = this.state.ExitInterview;
        employeeInterview.feedback = random2;

        this.setState({ employeeFeedback: random2 });
        let random1 = response.data.result
          .filter((each) => each.id < 6)
          .concat(
            response.data.result
              .filter((each) => each.id >= 6 && each.type !== "text")
              .map(
                (eachObj) =>
                  (eachObj.acceptObj = [
                    { id: 1, acceptance: "Strongly Agree", checked: "false" },
                    { id: 2, acceptance: "Agree", checked: "false" },
                    { id: 3, acceptance: "Disagree", checked: "false" },
                    {
                      id: 4,
                      acceptance: "Strongly Disagree",
                      checked: "false",
                    },
                  ])
              )
          );
        let getforanswers =
          this.state.ExitInterview && this.state.ExitInterview.feedback;
        let random3 = random.map((obj) => {
          if (getforanswers.find((ob) => ob.questionId === obj.id)) {
            return {
              ...obj,
              answer: getforanswers.find((ob) => ob.questionId === obj.id)
                .answer,
            };
          } else {
            return { ...obj, answer: "" };
          }
        });
        this.setState({ questionRetrieved: random3 });
      });

    axios
      .get(
        "https://capv.live/portalprod/getEmployeeExitFormDetails?employeeId=" +
          localStorage.getItem("empId")
      )
      .then((response) => {
        if (response.data.result) {
          let findit = response.data.result;
          findit.feedback
            .filter(
              (each) => each.questionId <= 5 && each.questionType === "checkbox"
            )
            .map((each1, index) => {
              each1.employeeNumber = Number(localStorage.getItem("empId"));
              if (this.state.employeeFeedback.length > 0) {
                let ind = this.state.employeeFeedback.findIndex(
                  (j) => j.questionId === each1.questionId
                );
                let dummyobj = this.state.employeeFeedback;
                dummyobj[ind].id = each1.id;
                dummyobj[ind].questionType = each1.questionType;
                dummyobj[ind].optionId = each1.optionId;
                dummyobj[ind].answer = each1.answer;

                this.setState({ employeeFeedback: dummyobj });
              }
            });
          if (this.state.employeeFeedback.length > 0) {
            findit.feedback
              .filter((each) => each.questionType === "text")
              .map((each1) => {
                let ind = this.state.employeeFeedback.findIndex(
                  (j) => j.questionId === each1.questionId
                );
                let dummyobj = this.state.employeeFeedback;
                dummyobj[ind].id = each1.id;
                dummyobj[ind].questionType = each1.questionType;
                dummyobj[ind].optionId = each1.optionId;
                dummyobj[ind].answer = each1.answer;

                this.setState({ employeeFeedback: dummyobj });
              });
          }

          findit.feedback
            .filter(
              (each) => each.questionId >= 6 && each.questionType === "checkbox"
            )
            .map((each1, index) => {
              each1.employeeNumber = Number(localStorage.getItem("empId"));
              let ind = this.state.employeeFeedback.findIndex(
                (j) => j.questionId === each1.questionId
              );
              if (ind !== -1) {
                let dummyobj = this.state.employeeFeedback;
                dummyobj[ind].id = each1.id;
                dummyobj[ind].questionType = each1.questionType;
                dummyobj[ind].optionId = each1.optionId;
                dummyobj[ind].answer = each1.answer;
                this.setState({ employeeFeedback: dummyobj });
              }
            });

          findit.feedback = this.state.employeeFeedback;

          this.setState({ ExitInterview: findit });
        } else {
          let storeFeedback = this.state.employeeFeedback;
          let findit = this.state.ExitInterview;
          findit.feedback = this.state.employeeFeedback;

          this.setState({ ExitInterview: findit });
        }
      });

    axios
      .get(
        "https://capv.live/portalprod/getEmployeeCodeById?employeeNumber=" +
          localStorage.getItem("empId")
      )
      .then((response) => {
        if (response.data.result) {
          this.setState({ empCode: response.data.result.employeeCode });
          let personExitForm = this.state.ExitInterview;

          personExitForm.empCode = Number(this.state.empCode);
          this.setState({ ExitInterview: personExitForm });
        }
      });

    axios
      .get(
        "https://capv.live/portalprod/getPfDetails?employeeId=" +
          localStorage.getItem("empId")
      )
      .then((response) => {
        if (response.data.result) {
          this.setState({ getPFData: response.data.result });
          let personExitForm = this.state.ExitInterview;
          personExitForm.joiningDate = response.data.result.dateOfJoining;
          this.setState({ ExitInterview: personExitForm });
        }
      });

    axios
      .get(
        "https://capv.live/portalprod/getClearanceInfoDetails?employeeId=" +
          localStorage.getItem("empId")
      )
      .then((response) => {
        let personExitForm = this.state.ExitInterview;
        if (response.data.result !== null) {
          personExitForm.relievingDate = response.data.result.relievingDate;
          personExitForm.joiningDate = response.data.result.joiningDate;
          personExitForm.name = response.data.result.name;
          personExitForm.designation = response.data.result.designation;
          personExitForm.department = response.data.result.department;
        }
        this.setState({ ExitInterview: personExitForm });
      });
    axios
      .get(
        "https://capv.live/portalprod/getEmployee?employeeId=" +
          localStorage.getItem("empId")
      )
      .then((response) => {
        this.setState({ EmpData: response.data.result });
        let personForm = this.state.ExitInterview;
        personForm.name =
          this.state.EmpData.firstName + " " + this.state.EmpData.lastName;

        this.setState({ ExitInterview: personForm });
      });

    axios
      .get("https://capv.live/portalprod/getOptionsByQuestionId?questionId=1")
      .then((response) => {
        this.setState({ Leavingreason: response.data.result });
      });

    axios
      .get("https://capv.live/portalprod/getOptionsByQuestionId?questionId=2")
      .then((response) => {
        if (response.data.result) {
          this.setState({ Otherleavingreason: response.data.result });
        }
      });
    this.trimEmptySpace();
  }
  trimEmptySpace = () => {
    let tempObj = this.state.ExitInterview;
    for (let index = 0; index < tempObj.feedback.length; index++) {
      tempObj.feedback[index].answer.trim();
    }
    this.setState({ ExitInterview: tempObj });
  };

  onLeavingReason(e, queId) {
    let selectedCategories = this.state.employeeFeedback;

    this.checkBoxQA(e, queId, selectedCategories);
    this.setState({ selectedLeavingReason: selectedCategories });
  }

  onOtherEmploymentIssue(e) {
    let otherEmployment = [this.state.selectedOtherEmployment];

    if (e.checked) {
      otherEmployment.push(e.value);
    } else {
      for (let i = 0; i < otherEmployment.length; i++) {
        const selectedCategory = otherEmployment[i];

        if (selectedCategory.option === e.value.option) {
          otherEmployment.splice(i, 1);
          break;
        }
      }
    }

    this.setState({ selectedOtherEmployment: otherEmployment });
  }

  exitInterViewHandler_Onchange = (field, e) => {
    let empChange = this.state.ExitInterview;
    empChange[field] = e.target.value;
    if (
      field === "Employee_Name" ||
      field === "Id_No" ||
      (field === "Mobile_No" && field === "Alternate_Mobile_No")
    ) {
    }
    this.setState({ Employeeclearance: empChange });
    this.handleValidation();
  };

  madeVisible = () => {
    this.setState({ ...this.state, showViewForm: false, showEditForm: true });
  };

  madeVisibleoff = () => {
    this.setState({ ...this.state, showViewForm: true, showEditForm: false });
  };
  validationForExitInterviewForm = () => {
    let exitInterviewValues = this.state.ExitInterview;
    let formIsValid = true;

    if (!exitInterviewValues.reportingManager) {
      formIsValid = false;
      this.toast.show({
        severity: "error",
        summary: "Error Message",
        detail: "Please fill reporting field",
        life: 3000,
      });
    }
    for (let index = 0; index < exitInterviewValues.feedback.length; index++) {
      if (exitInterviewValues.feedback[index].answer === " ") {
        formIsValid = false;
        this.toast.show({
          severity: "error",
          summary: "Error Message",
          detail: `Please fill question ${index + 1}`,
          life: 3000,
        });
      }
    }
    return formIsValid;
  };

  submitExitInterViewForm = () => {
    if (this.validationForExitInterviewForm()) {
      if (this.state.ExitInterview.id !== 0) {
        axios
          .put(
            "https://capv.live/portalprod/updateEmployeeExitForm",
            this.state.ExitInterview
          )
          .then((response) => {
            if (response.data.result !== null) {
              this.toast.show({
                severity: "success",
                summary: "Success Message",
                detail: "You have updated the form successfully",
                life: 3000,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            this.toast.show({
              severity: "info",
              summary: "Info Message",
              detail: "Internal server error",
              life: 3000,
            });
          });
      } else {
        axios
          .post(
            "https://capv.live/portalprod/saveEmployeeExitForm",
            this.state.ExitInterview
          )
          .then((response) => {
            if (response.data.result !== null) {
              this.toast.show({
                severity: "success",
                summary: "Success Message",
                detail: "You have saved the form successfully",
                life: 3000,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            this.toast.show({
              severity: "info",
              summary: "Info Message",
              detail: "Internal server error",
              life: 3000,
            });
          });
      }
      this.props.dispatch(showViewButton(true));
    }
  };

  descriptivehandler = (queId, queType, e) => {
    let tempobject = this.state.ExitInterview;
    let index = tempobject.feedback.findIndex(
      (item) => item.questionId === queId
    );

    if (index >= 0) {
      tempobject.feedback[index].optionId = 0;
      tempobject.feedback[index].answer = e.target.value;

      this.setState({
        ExitInterview: tempobject,
        refresh: !this.state.refresh,
      });
    }
  };

  checkBoxQA = (event, id, item) => {
    let exitForm = this.state.ExitInterview;
    let tempObj = {};
    if (event.checked) {
      let index = this.state.employeeFeedback.findIndex(
        (obj) => obj.questionId === id
      );
      let i = exitForm.feedback.findIndex((obj) => obj.questionId === id);
      if (i >= 0) {
        exitForm.feedback[i].employeeNumber = Number(
          localStorage.getItem("empId")
        );
        exitForm.feedback[i].optionId = event.value.id;
        exitForm.feedback[i].questionType = "checkbox";
        if (exitForm.feedback[i].questionId >= 6)
          exitForm.feedback[i].answer = event.value.acceptance;
        else exitForm.feedback[i].answer = event.value.option;

        this.setState({ employeeFeedback: item });
        this.setState({ ExitInterview: exitForm });
      }
    } else {
      let i = item.findIndex((item) => item.questionId === id);
      exitForm.feedback[i].employeeNumber = Number(
        localStorage.getItem("empId")
      );
      exitForm.feedback[i].optionId = 0;
      exitForm.feedback[i].questionType = "checkbox";
      if (id >= 6) exitForm.feedback[i].answer = "";
      else exitForm.feedback[i].answer = "";

      this.setState({ ExitInterview: exitForm });
    }
    this.setState({
      refresh: !this.state.refresh,
    });
  };

  onSelectRating = (e, stateId) => {
    let onSelect = this.state.employeeFeedback;
    this.checkBoxQA(e, stateId, onSelect);
  };
  CheckedHandlerOnChangeAgree = (e, id) => {
    let checkedBox = this.state.content;
    checkedBox[id].Agree = "Agree";
    checkedBox[id].Strongly_Agree = "";
    checkedBox[id].strongly_Disagree = "";
    checkedBox[id].Disagree = "";
    this.setState({ content: checkedBox });
  };
  CheckedHandler_onChange_Disagree = (e, id) => {
    let checkedBox = this.state.content;
    checkedBox[id].Disagree = "Disagree";
    checkedBox[id].Agree = "=";
    checkedBox[id].Strongly_Agree = "";
    checkedBox[id].strongly_Disagree = "";
    this.setState({ content: checkedBox });
  };
  CheckedHandleronChangeStronglyDisagree = (e, id) => {
    let checkedBox = this.state.content;
    checkedBox[id].strongly_Disagree = "strongly Disagree";
    checkedBox[id].Disagree = "";
    checkedBox[id].Agree = "=";
    checkedBox[id].Strongly_Agree = "";
    this.setState({ content: checkedBox });
  };
  inputView = () => {
    return this.props.showView ? "Input_fields_Disable" : "Input_fields";
  };
  retrunQID = () => {
    return this.state.qid + 1;
  };

  render() {
    const {
      Employee_Name,
      Designation,
      Reporting,
      Id_No,
      Dept,
      lastworkingday,
      location,
      Date_Of_Joining,
      Dept_project_name,
      Relieving_Date,
    } = this.state.errors;

    return (
      <div>
        <Header />
        <h6 className="Instructions_Form">
          <strong className="strong">Instructions :</strong> You worked with us
          & have contributed your share towards this organization. We appreciate
          your services & are interested in having your views towards the
          company & your colleagues, during your tenure with us. We value your
          honest, objective & unbiased views on the following issues. We assure
          you that sharing this information with others would be kept anonymous
          & used to increase our commitment towards being a fair organization.
        </h6>
        <div>
          <Buttons />
        </div>
        <div className="personal_Info">
          <h6 className="Form55">Exit Interview Form</h6>

          <div style={{ padding: 10 }}>
            <div className="personal_Info_fields_ExitInterview">
              {/* mr/ms */}
              <div className="personal_Info_label_input">
                <div
                  className="personal_Info_label11"
                  style={{ marginLeft: "32px" }}
                >
                  <span>Mr/Ms:</span>
                </div>
                <div className="personal_Info_input">
                  <input
                    className="Input_fields_Disable"
                    type="text"
                    disabled
                    placeholder="Employee name"
                    value={this.state.ExitInterview.name}
                    autoComplete="off"
                  ></input>
                </div>
                <div className="personal_Info_Error">
                  {Employee_Name && (
                    <span className="Error">{Employee_Name}</span>
                  )}
                </div>
              </div>
              <div className="personal_Info_Error_Mobile">
                {Employee_Name && (
                  <span className="Error">{Employee_Name}</span>
                )}
              </div>
              {/* designation */}
              <div className="personal_Info_label_input">
                <div
                  className="personal_Info_label11"
                  style={{ marginLeft: "32px" }}
                >
                  <span>Designation:</span>
                </div>
                <div className="personal_Info_input">
                  <input
                    className="Input_fields_Disable"
                    type="text"
                    disabled
                    placeholder="Designation"
                    value={this.state.ExitInterview.designation}
                    autoComplete="off"
                  ></input>
                </div>

                <div className="personal_Info_Error">
                  {Designation && <span className="Error">{Designation}</span>}
                </div>
              </div>
              <div className="personal_Info_Error_Mobile">
                {Designation && <span className="Error">{Designation}</span>}
              </div>
              <div className="personal_Info_label_input">
                <div
                  className="personal_Info_label11"
                  style={{ marginLeft: "32px" }}
                >
                  <span>Reporting:</span>
                </div>
                <div className="personal_Info_input">
                  <input
                    className={this.inputView()}
                    type="text"
                    disabled={this.props.showView}
                    placeholder="Reporting"
                    value={this.state.ExitInterview.reportingManager}
                    onChange={(e) =>
                      this.exitInterViewHandler_Onchange("reportingManager", e)
                    }
                    autoComplete="off"
                  ></input>
                </div>

                <div className="personal_Info_Error">
                  {Reporting && <span className="Error">{Reporting}</span>}
                </div>
              </div>
              <div className="personal_Info_Error_Mobile">
                {Reporting && <span className="Error">{Reporting}</span>}
              </div>
              {/* Id No: */}
              <div className="personal_Info_label_input">
                <div
                  className="personal_Info_label11"
                  style={{ marginLeft: "32px" }}
                >
                  <span>Id No:</span>
                </div>
                <div className="personal_Info_input">
                  <input
                    className="Input_fields_Disable"
                    type="text"
                    disabled
                    placeholder="Id No"
                    value={this.state.empCode}
                    autoComplete="off"
                  ></input>
                </div>
                <div className="personal_Info_Error">
                  {Id_No && <span className="Error">{Id_No}</span>}
                </div>
              </div>
              <div className="personal_Info_Error_Mobile">
                {Id_No && <span className="Error">{Id_No}</span>}
              </div>
              {/* Dept: */}
              <div className="personal_Info_label_input">
                <div
                  className="personal_Info_label11"
                  style={{ marginLeft: "32px" }}
                >
                  <span>Department:</span>
                </div>
                <div className="personal_Info_input">
                  <input
                    className="Input_fields_Disable"
                    type="text"
                    disabled
                    placeholder="Department"
                    value={this.state.ExitInterview.department}
                    onChange={(e) =>
                      this.exitInterViewHandler_Onchange("department", e)
                    }
                    autoComplete="off"
                  ></input>
                </div>

                <div className="personal_Info_Error">
                  {Dept && <span className="Error">{Dept}</span>}
                </div>
              </div>
              <div className="personal_Info_Error_Mobile">
                {Dept && <span className="Error">{Dept}</span>}
              </div>

              <div className="personal_Info_label_input">
                <div
                  className="personal_Info_label11"
                  style={{ marginLeft: "32px" }}
                >
                  <span>Date Of Joining:</span>
                </div>
                <div className="personal_Info_input">
                  <input
                    className="Input_fields_Disable"
                    type="date"
                    disabled
                    placeholder="Date Of Joining"
                    value={this.state.ExitInterview.joiningDate.split("T")[0]}
                    autoComplete="off"
                  ></input>
                </div>

                <div className="personal_Info_Error">
                  {Date_Of_Joining && (
                    <span className="Error">{Date_Of_Joining}</span>
                  )}
                </div>
              </div>
              <div className="personal_Info_Error_Mobile">
                {Date_Of_Joining && (
                  <span className="Error">{Date_Of_Joining}</span>
                )}
              </div>

              <div className="personal_Info_label_input">
                <div
                  className="personal_Info_label11"
                  style={{ marginLeft: "32px" }}
                >
                  <span> Relieving Date:</span>
                </div>
                <div className="personal_Info_input">
                  <input
                    className="Input_fields_Disable"
                    type="text"
                    disabled
                    placeholder="Relieving Date"
                    value={
                      this.state.ExitInterview.relievingDate !== null &&
                      this.state.ExitInterview.relievingDate.split("T")[0]
                    }
                    autoComplete="off"
                  ></input>
                </div>
                <div className="personal_Info_Error">
                  {Relieving_Date && (
                    <span className="Error">{Relieving_Date}</span>
                  )}
                </div>
              </div>

              {this.state.questionRetrieved
                .filter((item) => item.id < 6)
                .map((eachQue, id) => (
                  <div className="Principle_Reason" key={eachQue.id}>
                    <span
                      className={
                        this.props.showEdit ? "Question" : "QuestionDisabled"
                      }
                    >
                      {JSON.stringify(eachQue.id)}.{" "}
                      {eachQue.question.toString()}
                    </span>

                    <div className="leaving_Info_fields">
                      {eachQue.type === "checkbox" &&
                        eachQue.id === 1 &&
                        this.state.Leavingreason.map((employmentoption) => {
                          return (
                            <div
                              key={employmentoption.id}
                              className="p-field-checkbox"
                            >
                              <div>
                                <Checkbox
                                  name="employmentoption"
                                  inputId={employmentoption.id}
                                  disabled={this.props.showView}
                                  value={employmentoption}
                                  onChange={(e) =>
                                    this.onLeavingReason(e, eachQue.id)
                                  }
                                  checked={
                                    this.state.employeeFeedback.filter(
                                      (obj) =>
                                        obj.questionId === eachQue.id &&
                                        obj.optionId === employmentoption.id
                                    ).length > 0
                                  }
                                />
                                <label
                                  className="label_checkbox"
                                  htmlFor={employmentoption.id}
                                >
                                  {employmentoption.option}
                                </label>
                              </div>
                            </div>
                          );
                        })}

                      {eachQue.type === "checkbox" &&
                        eachQue.id === 2 &&
                        this.state.Otherleavingreason.map(
                          (employmentoption) => {
                            return (
                              <div
                                key={employmentoption.id}
                                className="p-field-checkbox"
                              >
                                <div>
                                  <Checkbox
                                    name="employmentoption"
                                    inputId={employmentoption.id}
                                    disabled={this.props.showView}
                                    value={employmentoption}
                                    onChange={(e) =>
                                      this.onLeavingReason(e, eachQue.id)
                                    }
                                    checked={
                                      this.state.employeeFeedback.filter(
                                        (obj) =>
                                          obj.questionId === eachQue.id &&
                                          obj.optionId === employmentoption.id
                                      ).length > 0
                                    }
                                  />
                                  <label
                                    className="label_checkbox"
                                    htmlFor={employmentoption.id}
                                  >
                                    {employmentoption.option}
                                  </label>
                                </div>
                              </div>
                            );
                          }
                        )}

                      {eachQue.type === "text" && (
                        <div>
                          <InputTextarea
                            id={eachQue.id}
                            className={
                              this.props.showView
                                ? "Input_fields_textArea_disable"
                                : "Input_fields_textArea"
                            }
                            rows={5}
                            cols={200}
                            disabled={!this.props.showEdit}
                            value={
                              this.state.ExitInterview &&
                              this.state.ExitInterview.feedback.find(
                                (ech) => ech.questionId === eachQue.id
                              ).answer
                            }
                            onChange={(e) =>
                              this.descriptivehandler(
                                eachQue.id,
                                eachQue.type,
                                e
                              )
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ))}

              <div className="Principle_Reason">
                <span
                  className={
                    this.props.showEdit ? "Question" : "QuestionDisabled"
                  }
                >
                  Please complete the following :(please tick)
                </span>
                <div className="tasks_Acceptance_H">
                  <div className="task_Name">
                    <span className="Task_Q"></span>
                  </div>
                  <div className="checkboxContainer">
                    <div className="multiple_checks1">
                      <div>
                        <span>Strongly Agree(4)</span>
                      </div>
                      <div>
                        <span>Agree(3)</span>
                      </div>
                      <div>
                        <span>Disagree(2)</span>
                      </div>
                      <div>
                        <span>Strongly Disagree(1)</span>
                      </div>
                    </div>
                  </div>
                </div>

                {this.state.questionRetrieved
                  .filter((item) => item.id >= 6)
                  .map((field) => (
                    <div
                      key={field.id}
                      className="tasks_Acceptance"
                      style={{
                        flexDirection: field.type === "text" && "column",
                      }}
                    >
                      <div
                        className="task_Name"
                        style={{
                          width: field.type === "text" && "100%",
                          marginBottom: "10px",
                        }}
                      >
                        <span className="Task_Q">
                          {field.id + " . " + field.question}
                        </span>
                      </div>
                      {field.type === "checkbox" && (
                        <div className="checkboxContainer">
                          <div className="multiple_checks1">
                            {field.hasOwnProperty("acceptObj") &&
                              this.state.degreeOfAcceptance.map((eachOne) => (
                                <div key={eachOne.id}>
                                  <Checkbox
                                    disabled={this.props.showView}
                                    inputId={eachOne.id}
                                    value={eachOne}
                                    checked={
                                      this.state.employeeFeedback.filter(
                                        (each1) =>
                                          each1.questionId === field.id &&
                                          each1.answer === eachOne.acceptance
                                      ).length > 0
                                    }
                                    onChange={
                                      (e) => this.onSelectRating(e, field.id)

                                      //eachOne.Que = field.question && this.CheckedHandleronChangeStrongleAgree(e, eachOne.id, field.question)
                                    }
                                  />
                                </div>
                              ))}
                          </div>
                        </div>
                      )}
                      {field.type === "text" && (
                        <div Key={field.id}>
                          <InputTextarea
                            rows={5}
                            cols={200}
                            className={
                              this.props.showView
                                ? "Input_fields_textArea_disable"
                                : "Input_fields_textArea"
                            }
                            disabled={this.props.showView}
                            value={this.state.ExitInterview.comments}
                            onChange={(e) => {
                              let d = this.state.ExitInterview;
                              d.comments = e.target.value;
                              d.feedback[15].answer = e.target.value;
                              this.setState({ ExitInterview: d });
                            }}
                          />
                        </div>
                      )}
                      {/* mobileview */}

                      <div className="multiple_checks_MobileView">
                        {field.type === "checkbox" &&
                          this.state.degreeOfAcceptance.map((eachOne) => (
                            <div className="mobile_Checkbox">
                              <Checkbox
                                disabled={this.props.showView}
                                value={eachOne.checked}
                                checked={eachOne.checked}
                                onClick={(e) =>
                                  this.CheckedHandleronChangeStrongleAgree(
                                    e,
                                    eachOne.id
                                  )
                                }
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="Submit_btn_userpage_admin_otp">
              {this.props.showView === false && (
                <button
                  onClick={this.submitExitInterViewForm}
                  style={{ marginTop: "12px" }}
                  className="save-btn"
                  disabled={this.state.isWaitingOnceSubmitted}
                >
                  Submit <i className="pi pi-send"></i>
                </button>
              )}
            </div>
          </div>
          <Toast ref={(el) => (this.toast = el)} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    empId: state.empId,
    uploadedpfdata: state.uploadedpfdata,
    profile: state.profile,
    pfdetails: state.pfdetails,
    insurance: state.insurance,
    empClearance: state.empClearance,
    empDocuments: state.empDocuments,
    exitinterview: state.exitinterview,
    empIdNotAssigned: state.empIdNotAssigned,
    showEdit: state.showEdit,
    showView: state.showView,
    enableFormForPfIns: state.enableFormForPfIns,
    btnStatus: state.btnStatus,
  };
};
export default connect(mapStateToProps)(ExitInterviewForm);
