// import { flushSync } from "react-dom";
import {
  EMAILUPLOAD,
  ADMINLOGOUT,
  EMPLOYEELOGOUT,
  EMAILDATA,
  ADDMOREEXPIRIENCE,
  ALWAYSASSIGNED,
  ALWAYSEXIST,
  LINEARPROGRESS,
  SELECTEDVALUE,
  INSURANCESUCCESS,
  LOADING,
  HEADER11,
  STATUSMODELER,
  GUESTSTATUSMODELER,
  EMAILID,
  EXPIRIENCEEXIST,
  ISGUEST,
  PFDATASUCCESS,
  INSURANCE,
  PFDETAILS,
  PROFILE,
  ISPDDONE,
  SAVE,
  DASHBOARD,
  PFDATAID,
  ISINSURANCEOUTTED,
  ISLOOGEDOUT,
  ISADMINLOOGEDOUT,
  EMPLOYEEDATA,
  EMPLOYEE_CLEARANCE,
  EXIT_INTERVIEW,
  UPLOADED_DOCUMENTS,
  IFEMPLOYEEIDNOTGENERATED,
  SHOWEDITFORM,
  SHOWVIEWFORM,
  SHOWPF,
  BUTTONSTATUS,
  SETEMAIL,
  CREATEMPLOYEEPROFILEDATA,
  CREATEMPLOYEEPFDATA,
  CREATEMPLOYEEINSURANCEDATA,
  SETEMPLOYEECLEARANCEFORM,
  SETEXITINTERVIEWFORM,
} from "./actionTypes.js";

const initialState = {
  email: "",
  data: [],
  isSpinner: false,
  insuranceemployeeId: "",
  empId: "",
  profile: false,
  pfdetails: false,
  isInsuranceOut: true,
  save: false,
  pfLogout: true,
  isLoggedOut: true,
  isDashboard: false,
  header111: true,
  isLoggedAdminOut: true,
  isGuest: false,
  employee_logout: false,
  IsAssigned: true,
  uploadedpfdata: false,
  statusModal: false,
  gueststatusModal: false,
  insurancemay: true,
  isThere: false,
  linearProgress: false,
  selectedValue: "",
  always: false,
  AddedValue: "",
  employeeData: {},
  admin_loggedOut: false,
  empClearance: false,
  exitinterview: false,
  empDocuments: false,
  empIdNotAssigned: true,
  showEdit: true,
  showView: false,
  enableFormForPfIns: false,
  btnStatus: "",

  email: "",
  EmpData: [],
  EmpPFData: [],
  EmpInsuranceData: [],
  EmpClearanceForm: [],
  EmpExitInterviewForm: [],
  employeeId: "",
};

export const uploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case BUTTONSTATUS:
      return {
        ...state,
        btnStatus: action.btnStatus,
      };
    case SHOWPF:
      return {
        ...state,
        enableFormForPfIns: action.enableFormForPfIns,
      };
    case SHOWEDITFORM:
      return {
        ...state,
        showEdit: action.showEdit,
        showView: false,
      };
    case SHOWVIEWFORM:
      return {
        ...state,
        showView: action.showView,
        showEdit: false,
      };
    case IFEMPLOYEEIDNOTGENERATED:
      return {
        ...state,
        empIdNotAssigned: action.empIdNotAssigned,
      };
    case EMPLOYEEDATA:
      return {
        ...state,
        employeeData: action.employeeData,
      };
    case EMPLOYEELOGOUT:
      return {
        ...state,
        employee_logout: action.employee_logout,
      };
    case LINEARPROGRESS:
      return {
        ...state,
        linearProgress: action.linearProgress,
      };
    case ADMINLOGOUT:
      return {
        ...state,
        admin_loggedOut: action.admin_loggedOut,
      };
    case EMAILUPLOAD:
      return {
        ...state,
        email: action.email,
      };
    case SELECTEDVALUE:
      return {
        ...state,
        selectedValue: action.selectedValue,
      };
    case ADDMOREEXPIRIENCE:
      return {
        ...state,
        AddedValue: action.AddedValue,
      };
    case ALWAYSASSIGNED:
      return {
        ...state,
        IsAssigned: action.IsAssigned,
      };
    case ALWAYSEXIST:
      return {
        ...state,
        always: action.always,
      };
    case EXPIRIENCEEXIST:
      return {
        ...state,
        isThere: action.isThere,
      };
    case INSURANCESUCCESS:
      return {
        ...state,
        insurancemay: action.insurancemay,
      };
    case EMAILDATA:
      return {
        ...state,
        data: action.data,
      };
    case LOADING:
      return {
        ...state,
        isSpinner: action.isSpinner,
      };
    case EMAILID:
      return {
        ...state,
        empId: action.empId,
      };
    case UPLOADED_DOCUMENTS:
      return {
        ...state,
        empDocuments: action.empDocuments,
        profile: false,
        insurance: false,
        pfdetails: false,
        empClearance: false,
        exitinterview: false,
        btnStatus: "uploaded documents",
      };
    case PROFILE:
      return {
        ...state,
        profile: action.profile,
        insurance: false,
        pfdetails: false,
        empClearance: false,
        exitinterview: false,
        empDocuments: false,
        btnStatus: "profile",
      };
    case PFDETAILS:
      return {
        ...state,
        pfdetails: action.pfdetails,
        profile: false,
        insurance: false,
        empClearance: false,
        exitinterview: false,
        empDocuments: false,
        btnStatus: "pfdetails",
      };
    case SAVE:
      return {
        ...state,
        save: action.save,
      };
    case INSURANCE:
      return {
        ...state,
        insurance: action.insurance,
        profile: false,
        pfdetails: false,
        empClearance: false,
        exitinterview: false,
        empDocuments: false,
        btnStatus: "insurance",
      };
    case EMPLOYEE_CLEARANCE:
      return {
        ...state,
        empClearance: action.empClearance,
        profile: false,
        pfdetails: false,
        insurance: false,
        exitinterview: false,
        empDocuments: false,
        btnStatus: "employee clearance",
      };

    case EXIT_INTERVIEW:
      return {
        ...state,
        exitinterview: action.exitinterview,
        profile: false,
        pfdetails: false,
        insurance: false,
        empClearance: false,
        empDocuments: false,
        btnStatus: "exit interview",
      };

    case DASHBOARD:
      return {
        ...state,
        isDashboard: action.isDashboard,
      };
    case PFDATAID:
      return {
        ...state,
        insuranceemployeeId: action.insuranceemployeeId,
      };
    case HEADER11:
      return {
        ...state,
        header111: action.header111,
      };
    case ISINSURANCEOUTTED:
      return {
        ...state,
        isInsuranceOut: action.isInsuranceOut,
      };
    case ISLOOGEDOUT:
      return {
        ...state,
        isLoggedOut: action.isLoggedOut,
      };
    case ISADMINLOOGEDOUT:
      return {
        ...state,
        isLoggedAdminOut: action.isLoggedAdminOut,
      };
    case ISPDDONE:
      return {
        ...state,
        pfLogout: action.pfLogout,
      };
    case ISGUEST:
      return {
        ...state,
        isGuest: action.isGuest,
      };
    case PFDATASUCCESS:
      return {
        ...state,
        uploadedpfdata: action.uploadedpfdata,
      };
    case STATUSMODELER:
      return {
        ...state,
        statusModal: action.statusModal,
      };
    case GUESTSTATUSMODELER:
      return {
        ...state,
        gueststatusModal: action.gueststatusModal,
      };

    case SETEMAIL:
      return {
        ...state,
        emailprovided: action.emailprovided,
      };

    case CREATEMPLOYEEPROFILEDATA:
      return {
        ...state,
        EmpData: action.EmpData,
      };
    case CREATEMPLOYEEPFDATA:
      return {
        ...state,
        EmpPFData: action.EmpPFData,
      };
    case CREATEMPLOYEEINSURANCEDATA:
      return {
        ...state,
        EmpInsuranceData: action.EmpInsuranceData,
      };
    case SETEMPLOYEECLEARANCEFORM:
      return {
        ...state,
        EmpClearanceForm: action.EmpClearanceForm,
      };

    case SETEXITINTERVIEWFORM:
      return {
        ...state,
        EmpExitInterviewForm: action.EmpExitInteviewForm,
      };

    default:
      return state;
  }
};
export default uploadReducer;
