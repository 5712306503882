import React, { Component } from "react";
import { connect } from "react-redux";
import "../../Employeee/Pf/Pfdata.css";
import {
  insuranceid,
  pfdataid,
  profileid,
  employeeclearance,
  exitinterviewfunction,
  previouslyUploadedDocuments,
  ifEmpIdNotGenerated,
  showEditButton,
  showViewButton,
  spinner,
  showFormPfIns,
  showButtonStatus,
} from "../../../store/actions.js";
import axios from "axios";
import { withRouter } from "react-router";

class buttons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      EmpData: [],
    };
  }

  componentDidMount() {
    this.props.dispatch(spinner(true));
    if (localStorage.getItem("empId") != null) {
      axios
        .get(
          "https://capv.live/portalprod/getEmployee?employeeId=" +
            localStorage.getItem("empId")
        )
        .then((response) => {
          this.setState({ EmpData: response.data.result });
        });
      this.props.dispatch(pfdataid(true));
      this.props.dispatch(spinner(false));
      axios
        .get(
          "https://capv.live/portalprod/getEmployeeCodeById?employeeNumber=" +
            localStorage.getItem("empId")
        )
        .then((response) => {
          if (response.data.result.employeeCode == null) {
            this.props.dispatch(ifEmpIdNotGenerated(true));
            this.props.dispatch(showFormPfIns(false));
          } else {
            this.props.dispatch(ifEmpIdNotGenerated(false));
            this.props.dispatch(showFormPfIns(true));
          }
        });
    } else {
      this.props.dispatch(spinner(false));
      this.props.history.push("/");
    }
  }
  insuranceHandler = () => {
    this.props.dispatch(insuranceid(true));
    this.props.history.push("/Insurance");
  };
  pfHandler = () => {
    this.props.dispatch(pfdataid(true));
    this.props.history.push("/Pfdata");
  };
  profileHandler = () => {
    this.props.dispatch(profileid(true));
    this.props.history.push("/Profile");
  };
  empClearanceHandler = () => {
    this.props.dispatch(employeeclearance(true));
    this.props.history.push("/Employeeclearanceform");
  };

  exitinterviewhandler = () => {
    this.props.dispatch(exitinterviewfunction(true));
    this.props.history.push("/ExitInterviewForm");
  };
  empDocumentsHandler = () => {
    this.props.dispatch(previouslyUploadedDocuments(true));
    this.props.history.push("/PreviouslyUploadedDocuments");
  };
  madeVisible = () => {
    this.props.dispatch(showEditButton(true));
  };
  madeVisibleoff = () => {
    this.props.dispatch(showViewButton(true));
  };
  render() {
    return (
      <>
        <div className="Instructions">
          {/* mobileview */}
          <div className="Insurance_Profile_Pfdetails_BTNs_forMobile">
            <button
              className={this.props.profile ? "menubuttonactive" : "menubutton"}
              onClick={this.profileHandler}
            >
              PROFILE
            </button>
            <button
              className={
                this.props.empDocuments ? "menubuttonactive" : "menubutton"
              }
              onClick={this.empDocumentsHandler}
            >
              DOCUMENTS
            </button>
            <button
              className={
                this.props.pfdetails ? "menubuttonactive" : "menubutton"
              }
              onClick={this.pfHandler}
            >
              PF DETAILS
            </button>
            <button
              className={
                this.props.insurance ? "menubuttonactive" : "menubutton"
              }
              onClick={this.insuranceHandler}
            >
              INSURANCE
            </button>

            {this.state.EmpData.status === "Relieved" && (
              <button
                className={
                  this.props.empClearance ? "menubuttonactive" : "menubutton"
                }
                onClick={this.empClearanceHandler}
              >
                CLEARANCE FORM
              </button>
            )}
            {(this.state.EmpData.status === "Notice period" ||
              this.state.EmpData.status === "Relieved") && (
              <button
                className={
                  this.props.exitinterview ? "menubuttonactive" : "menubutton"
                }
                onClick={this.exitinterviewhandler}
              >
                EXIT INTERVIEW FORM
              </button>
            )}
          </div>
          {/* mobileview ended */}
        </div>
        <div className="ifnotGenerated_btns">
          {this.props.btnStatus !== "uploaded documents" &&
            this.props.btnStatus !== "profile" &&
            this.props.btnStatus !== "employee clearance" && (
              <>
                {this.props.empIdNotAssigned ? (
                  <div className="ifnotGenerated">
                    <h6 className="employeeId_Text">
                      Employee Id :
                      <span className="employeeIdea_notyetgenerated_text">
                        Not Yet Generated
                      </span>
                    </h6>
                  </div>
                ) : (
                  <div className="EDITVIEW">
                    <button
                      className={
                        this.props.showEdit
                          ? "view-edit-btn active-btn"
                          : "view-edit-btn"
                      }
                      onClick={this.madeVisible}
                    >
                      EDIT
                    </button>
                    <button
                      className={
                        this.props.showView
                          ? "view-edit-btn active-btn"
                          : "view-edit-btn"
                      }
                      onClick={this.madeVisibleoff}
                    >
                      VIEW
                    </button>
                  </div>
                )}
              </>
            )}
          {(this.props.btnStatus == "profile" ||
            this.props.btnStatus == "employee clearance") && (
            <div className="EDITVIEW">
              <button
                className={
                  this.props.showEdit
                    ? "view-edit-btn active-btn"
                    : "view-edit-btn"
                }
                onClick={this.madeVisible}
              >
                EDIT
              </button>
              <button
                className={
                  this.props.showView
                    ? "view-edit-btn active-btn"
                    : "view-edit-btn"
                }
                onClick={this.madeVisibleoff}
              >
                VIEW
              </button>
            </div>
          )}
          <div
            className={
              this.props.btnStatus == "uploaded documents"
                ? "Insurance_Profile_Pfdetails_BTNs_disabling_editView"
                : "Insurance_Profile_Pfdetails_BTNs"
            }
          >
            <button
              className={this.props.profile ? "menubuttonactive" : "menubutton"}
              onClick={this.profileHandler}
            >
              PROFILE
            </button>
            <button
              className={
                this.props.empDocuments ? "menubuttonactive" : "menubutton"
              }
              onClick={this.empDocumentsHandler}
            >
              DOCUMENTS
            </button>
            <button
              className={
                this.props.pfdetails ? "menubuttonactive" : "menubutton"
              }
              onClick={this.pfHandler}
            >
              PF DETAILS
            </button>
            <button
              className={
                this.props.insurance ? "menubuttonactive" : "menubutton"
              }
              onClick={this.insuranceHandler}
            >
              INSURANCE
            </button>
            {(this.state.EmpData.status === "Notice period" ||
              this.state.EmpData.status === "Relieved") && (
              <button
                className={
                  this.props.empClearance ? "menubuttonactive" : "menubutton"
                }
                onClick={this.empClearanceHandler}
              >
                CLEARANCE FORM
              </button>
            )}
            {(this.state.EmpData.status === "Notice period" ||
              this.state.EmpData.status === "Relieved") && (
              <button
                className={
                  this.props.exitinterview ? "menubuttonactive" : "menubutton"
                }
                onClick={this.exitinterviewhandler}
              >
                EXIT INTERVIEW FORM
              </button>
            )}
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    empId: state.empId,
    uploadedpfdata: state.uploadedpfdata,
    profile: state.profile,
    pfdetails: state.pfdetails,
    insurance: state.insurance,
    empClearance: state.empClearance,
    exitinterview: state.exitinterview,
    empDocuments: state.empDocuments,
    empIdNotAssigned: state.empIdNotAssigned,
    showEdit: state.showEdit,
    showView: state.showView,
    enableFormForPfIns: state.enableFormForPfIns,
    btnStatus: state.btnStatus,
  };
};

export default connect(mapStateToProps)(withRouter(buttons));
