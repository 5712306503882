import React, { Component } from "react";
import "./Profile.css";
import Header from "../../Header/Header.js";
import axios from "axios";
import { connect } from "react-redux";
import {
  createEmployeeProfileData,
  saved,
  insuranceid,
  pfdataid,
  profileid,
  isexpirienceThere,
  employeeclearance,
  exitinterviewfunction,
  previouslyUploadedDocuments,
  spinner,
  showViewButton,
  showFormPfIns,
  showButtonStatus,
} from "../../../store/actions.js";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { Toast } from "primereact/toast";
import { InputTextarea } from "primereact/inputtextarea";
import { InputSwitch } from "primereact/inputswitch";
import Buttons from "../../Containers/Buttons_tab/buttons.js";
import Relieved from "../Relieved/Relieved.js";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile1: {},
      isFetched: false,
      previousData: {},
      isAlwaysFetched: true,
      isAlwaysSuccess: false,
      errors: {},
      makesVisible: false,
      makesVisibleoff: false,
      showEditForm: false,
      showViewForm: true,
      currentdate: "",
      isFailed: false,
      confirmModal: false,
      checked1: false,
      address: {},
      // address: {
      //   address: "",
      //   city: "",
      //   pincode: "",
      //   state: "",
      //   country: "",
      //   nationality: "",
      //   phone: "",
      //   email: "",
      //   emergencyContact: "",
      //   alternateContact: "",
      // },
    };
    this.profileChanger = this.profileChanger.bind(this);
    this.profileMaker = this.profileMaker.bind(this);
    this.contactinfoChanger = this.contactinfoChanger.bind(this);
    this.educationInfoChanger = this.educationInfoChanger.bind(this);
    this.employementInfoChanger = this.employementInfoChanger.bind(this);
    this.changeExperienceDetails = this.changeExperienceDetails.bind(this);
    this.AddExp = this.AddExp.bind(this);
    this.RemoveExp = this.RemoveExp.bind(this);
  }
  profileMaker = (e) => {
    e.preventDefault();
    if (this.RequiredValidation() && this.handleValidation()) {
      let empD = this.state.profile1;
      this.props.dispatch(createEmployeeProfileData(empD));

      this.props.dispatch(spinner(true));
      this.setState({ isFailed: false });
      const request = {
        method: "PUT",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(this.state.profile1),
      };
      fetch("https://capv.live/portalprod/updateEmployee", request).then(
        (response) => {
          if (response.ok) {
            this.props.dispatch(spinner(false));
            this.setState({ isAlwaysSuccess: true });
            this.props.dispatch(saved(false));
            this.setState({ profile1: "" });
            this.toastBC.show({
              severity: "success",
              summary: "Success Message",
              detail: "Saved Successfully",
              life: 3000,
            });

            axios
              .get(
                "https://capv.live/portalprod/getEmployee?employeeId=" +
                  localStorage.getItem("empId")
              )
              .then((response) => {
                this.props.dispatch(showViewButton(true));
                this.setState({
                  profile1: response.data.result,
                  address: response.data.result.contactInfo[1],
                });
              });
            setTimeout(
              function () {
                this.setState({
                  isAlwaysSuccess: false,
                });
              }.bind(this),
              1000
            );
          }
        }
      );
    } else {
      this.setState({ isFailed: true, isAlwaysSuccess: false });
      this.props.dispatch(spinner(false));
      this.props.dispatch(saved(false));
      this.toastBC.show({
        severity: "error",
        summary: "Error Message",
        detail: "Incorrect Data",
        life: 3000,
      });
    }
  };
  componentDidMount() {
    this.props.dispatch(spinner(true));
    this.props.dispatch(profileid(true));
    this.props.dispatch(showViewButton(true));
    if (localStorage.getItem("empId") != null) {
      axios
        .get(
          "https://capv.live/portalprod/filesByEmployeeId/" +
            localStorage.getItem("empId")
        )
        .then((response) => {
          this.props.dispatch(spinner(false));
          this.setState({ files: response.data });
          if (response.data.length == 0) {
            this.props.history.push("/UploadDocuments");
          }
        });
      axios
        .get(
          "https://capv.live/portalprod/getEmployee?employeeId=" +
            localStorage.getItem("empId")
        )
        .then((response) => {
          this.props.dispatch(spinner(false));
          this.setState({ profile1: response.data.result });
          this.setState({ address: response.data.result.contactInfo[1] });
        });
      this.props.dispatch(profileid(true));
    } else {
      this.props.dispatch(spinner(false));
      this.props.history.push("/");
    }
    let date = new Date();
    let year = date.getFullYear();
    let month =
      date.getMonth() < 10 ? date.getMonth() + 1 : "0" + date.getMonth();
    let day = date.getDate() > 10 ? date.getDate() : date.getDate();
    let currentdate = year + "-" + month + "-" + day;
    this.setState({ ...this.state, currentdate: currentdate });
  }
  RequiredValidation() {
    //debugger
    let fields = this.state.profile1;
    let errors = {};
    let formIsValid = true;

    //checked1
    //   if(!this.props.selectedValue) {
    //     formIsValid = false;
    //     errors.selected = 'Mandatory'
    // }
    //FirstName//
    if (!fields.firstName) {
      formIsValid = false;
      errors.firstName = "Required";
    }

    //LastName//
    // if (!fields.lastName) {
    //   formIsValid = false;
    //   errors.lastName = "Required";
    // }

    //OtherName//
    if (!fields.otherName) {
      formIsValid = false;
      errors.otherName = "Required";
    }

    //Gender//
    if (!fields.gender) {
      formIsValid = false;
      errors.gender = "Required";
    }

    if (!fields["dob"]) {
      formIsValid = false;
      errors["dob"] = "Required";
    }

    //bloodGroup//
    if (!fields.bloodGroup) {
      formIsValid = false;
      errors.bloodGroup = "Required";
    }

    //passportNumber//
    // if (!fields.passportNumber) {
    //   formIsValid = false;
    //   errors.passportNumber = "Required";
    // }

    //dateOfIssue//
    // if (!fields["dateOfIssue"]) {
    //   formIsValid = false;
    //   errors["dateOfIssue"] = "Required";
    // }

    //dateOfExpiry//
    // if (!fields["dateOfExpiry"]) {
    //   formIsValid = false;
    //   errors["dateOfExpiry"] = "Required";
    // }

    //PanCardNumber//
    if (!fields.panCardNumber) {
      formIsValid = false;
      errors.panCardNumber = "Required";
    }

    if (!fields.aadharNumber) {
      formIsValid = false;
      errors.aadharNumber = "Required";
    }

    //address//
    if (!fields.contactInfo[0].address) {
      formIsValid = false;
      errors.address = "Required";
    }
    // //address//
    if (!fields.contactInfo[1].address) {
      formIsValid = false;
      errors.address1 = "Required";
    }

    //City//
    if (!fields.contactInfo[0].city) {
      formIsValid = false;
      errors.city = "Required";
    }

    //City//
    if (!fields.contactInfo[1].city) {
      formIsValid = false;
      errors.citty = "Required";
    }

    //Pincode//
    if (!fields.contactInfo[0].pincode) {
      formIsValid = false;
      errors.contactInfopincode = "Required";
    }

    //Pincode//
    if (!fields.contactInfo[1].pincode) {
      formIsValid = false;
      errors.contactInfopincode1 = "Required";
    }

    //State//
    if (!fields.contactInfo[0].state) {
      formIsValid = false;
      errors.state = "Required";
    }

    //State//
    if (!fields.contactInfo[1].state) {
      formIsValid = false;
      errors.state1 = "Required";
    }

    //Country//
    if (!fields.contactInfo[0].country) {
      formIsValid = false;
      errors.country = "Required";
    }

    //Country//
    if (!fields.contactInfo[1].country) {
      formIsValid = false;
      errors.country1 = "Required";
    }

    //Nationality//
    if (!fields.contactInfo[0].nationality) {
      formIsValid = false;
      errors.nationality = "Required";
    }

    //Nationality//
    if (!fields.contactInfo[1].nationality) {
      formIsValid = false;
      errors.nationality1 = "Required";
    }

    //Phone//
    if (!fields.contactInfo[0].phone) {
      formIsValid = false;
      errors.phone = "Required";
    }

    //Phone//
    if (!fields.contactInfo[1].phone) {
      formIsValid = false;
      errors.phone1 = "Required";
    }

    //EmergencyContact//

    if (!fields.contactInfo[0].emergencyContact) {
      formIsValid = false;
      errors.emergencyContact = "Required";
    }

    //EmergencyContact//
    if (!fields.contactInfo[1].emergencyContact) {
      formIsValid = false;
      errors.emergencyContact1 = "Required";
    }

    //AlternateContact//
    if (!fields.contactInfo[0].alternateContact) {
      formIsValid = false;
      errors.alternateContact = "Required";
    }

    //AlternateContact//
    if (!fields.contactInfo[1].alternateContact) {
      formIsValid = false;
      errors.alternateContact1 = "Required";
    }

    // Degree
    if (!fields.educationInfo.degree) {
      formIsValid = false;
      errors.degree = "Required";
    }

    //CollegeName//
    if (!fields.educationInfo.collegeName) {
      formIsValid = false;
      errors.collegeName = "Required";
    }

    //UniversityName//
    if (!fields.educationInfo.universityName) {
      formIsValid = false;
      errors.universityName = "Required";
    }

    //Subject//
    if (!fields.educationInfo.subject) {
      formIsValid = false;
      errors.subject = "Required";
    }
    //CourseStartDate//
    if (!fields.educationInfo["courseStartDate"]) {
      formIsValid = false;
      errors["courseStartDate"] = "Required";
    }

    //CourseEndDate//
    if (!fields.educationInfo["courseEndDate"]) {
      formIsValid = false;
      errors["courseEndDate"] = "Required";
    }

    //CourseType//
    if (!fields.educationInfo.courseType) {
      formIsValid = false;
      errors.courseType = "Required";
    }

    //AddressOfInstitution//
    if (!fields.educationInfo.addressOfInstitution) {
      formIsValid = false;
      errors.addressOfInstitution = "Required";
    }

    //City//
    if (!fields.educationInfo.city) {
      formIsValid = false;
      errors.cityeducatio = "Required";
    }

    //PinCode//
    if (!fields.educationInfo.pinCode) {
      formIsValid = false;
      errors.educationInfopinCode = "Required";
    }
    //Landline1//
    // if (!fields.educationInfo.landline1) {
    //   formIsValid = false;
    //   errors.landline1 = "Required";
    // }

    //Landline2//
    // if (!fields.educationInfo.landline2) {
    //   formIsValid = false;
    //   errors.landline2 = "Required";
    // }

    for (let i = 0; i < this.state.profile1.employmentInfo.length; i++) {
      //company Name//

      if (!fields.employmentInfo[i].companyName) {
        formIsValid = false;

        if (i == 0) {
          errors.companyName = "Required";
        }
        if (i == 1) {
          errors.exp1_companyName = "Required";
        }
        if (i == 2) {
          errors.exp2_companyName = "Required";
        }
        if (i == 3) {
          errors.exp3_companyName = "Required";
        }
        if (i == 4) {
          errors.exp4_companyName = "Required";
        }
      }

      //employedPlace
      if (!fields.employmentInfo[i].employedPlace) {
        formIsValid = false;

        if (i == 0) {
          errors.employedPlace = "Required";
        }
        if (i == 1) {
          errors.exp1_employedPlace = "Required";
        }
        if (i == 2) {
          errors.exp2_employedPlace = "Required";
        }
        if (i == 3) {
          errors.exp3_employedPlace = "Required";
        }
        if (i == 4) {
          errors.exp4_employedPlace = "Required";
        }
      }

      //company Address//
      if (!fields.employmentInfo[i].companyAddress) {
        formIsValid = false;

        if (i == 0) {
          errors.companyAddress = "Required";
        }
        if (i == 1) {
          errors.exp1_companyAddress = "Required";
        }
        if (i == 2) {
          errors.exp2_companyAddress = "Required";
        }
        if (i == 3) {
          errors.exp3_companyAddress = "Required";
        }
        if (i == 4) {
          errors.exp4_companyAddress = "Required";
        }
      }

      ////city

      if (!fields.employmentInfo[i].city) {
        formIsValid = false;

        if (i == 0) {
          errors.cityemployment = "Required";
        }
        if (i == 1) {
          errors.exp1_cityemployment = "Required";
        }
        if (i == 2) {
          errors.exp2_cityemployment = "Required";
        }
        if (i == 3) {
          errors.exp3_cityemployment = "Required";
        }
        if (i == 4) {
          errors.exp4_cityemployment = "Required";
        }
      }

      ////state

      if (!fields.employmentInfo[i].state) {
        formIsValid = false;

        if (i == 0) {
          errors.stateemployment = "Required";
        }
        if (i == 1) {
          errors.exp1_stateemployment = "Required";
        }
        if (i == 2) {
          errors.exp2_stateemployment = "Required";
        }
        if (i == 3) {
          errors.exp3_stateemployment = "Required";
        }
        if (i == 4) {
          errors.exp4_stateemployment = "Required";
        }
      }
      ////pincode

      if (!fields.employmentInfo[i].pincode) {
        formIsValid = false;

        if (i == 0) {
          errors.pincodeemployement = "Required";
        }
        if (i == 1) {
          errors.exp1_pincodeemployement = "Required";
        }
        if (i == 2) {
          errors.exp2_pincodeemployement = "Required";
        }
        if (i == 3) {
          errors.exp3_pincodeemployement = "Required";
        }
        if (i == 4) {
          errors.exp4_pincodeemployement = "Required";
        }
      }

      //fromDate
      if (!fields.employmentInfo[i].fromDate) {
        formIsValid = false;

        if (i == 0) {
          errors.fromDate = "Required";
        }
        if (i == 1) {
          errors.exp1_fromDate = "Required";
        }
        if (i == 2) {
          errors.exp2_fromDate = "Required";
        }
        if (i == 3) {
          errors.exp3_fromDate = "Required";
        }
        if (i == 4) {
          errors.exp4_fromDate = "Required";
        }
      }

      //toDate
      if (!fields.employmentInfo[i].toDate) {
        formIsValid = false;
        if (i == 0) {
          errors.toDate = "Required";
        }
        if (i == 1) {
          errors.exp1_toDate = "Required";
        }
        if (i == 2) {
          errors.exp2_toDate = "Required";
        }
        if (i == 3) {
          errors.exp3_toDate = "Required";
        }
        if (i == 4) {
          errors.exp4_toDate = "Required";
        }
      }

      //designation
      if (!fields.employmentInfo[i].designation) {
        formIsValid = false;

        if (i == 0) {
          errors.designation = "Required";
        }
        if (i == 1) {
          errors.exp1_designation = "Required";
        }
        if (i == 2) {
          errors.exp2_designation = "Required";
        }
        if (i == 3) {
          errors.exp3_designation = "Required";
        }
        if (i == 4) {
          errors.exp4_designation = "Required";
        }
      }

      //remuneration

      if (!fields.employmentInfo[i].remuneration) {
        formIsValid = false;

        if (i == 0) {
          errors.remuneration = "Required";
        }
        if (i == 1) {
          errors.exp1_remuneration = "Required";
        }
        if (i == 2) {
          errors.exp2_remuneration = "Required";
        }
        if (i == 3) {
          errors.exp3_remuneration = "Required";
        }
        if (i == 4) {
          errors.exp4_remuneration = "Required";
        }
      }
      //supervisorEmailId

      if (!fields.employmentInfo[i].supervisorEmailId) {
        formIsValid = false;

        if (i == 0) {
          errors.supervisorEmailId = "Required";
        }
        if (i == 1) {
          errors.exp1_supervisorEmailId = "Required";
        }
        if (i == 2) {
          errors.exp2_supervisorEmailId = "Required";
        }
        if (i == 3) {
          errors.exp3_supervisorEmailId = "Required";
        }
        if (i == 4) {
          errors.exp4_supervisorEmailId = "Required";
        }
      }

      //employeeCode

      if (!fields.employmentInfo[i].employeeCode) {
        formIsValid = false;

        if (i == 0) {
          errors.employeeCode = "Required";
        }
        if (i == 1) {
          errors.exp1_employeeCode = "Required";
        }
        if (i == 2) {
          errors.exp2_employeeCode = "Required";
        }
        if (i == 3) {
          errors.exp3_employeeCode = "Required";
        }
        if (i == 4) {
          errors.exp4_employeeCode = "Required";
        }
      }

      //employmentNature

      if (!fields.employmentInfo[i].employmentNature) {
        formIsValid = false;

        if (i == 0) {
          errors.employmentNature = "Required";
        }
        if (i == 1) {
          errors.exp1_employmentNature = "Required";
        }
        if (i == 2) {
          errors.exp2_employmentNature = "Required";
        }
        if (i == 3) {
          errors.exp3_employmentNature = "Required";
        }
        if (i == 4) {
          errors.exp4_employmentNature = "Required";
        }
      }

      //supervisorName

      if (!fields.employmentInfo[i].supervisorName) {
        formIsValid = false;

        if (i == 0) {
          errors.supervisorName = "Required";
        }
        if (i == 1) {
          errors.exp1_supervisorName = "Required";
        }
        if (i == 2) {
          errors.exp2_supervisorName = "Required";
        }
        if (i == 3) {
          errors.exp3_supervisorName = "Required";
        }
        if (i == 4) {
          errors.exp4_supervisorName = "Required";
        }
      }

      //supervisorDesignation

      if (!fields.employmentInfo[i].supervisorDesignation) {
        formIsValid = false;

        if (i == 0) {
          errors.supervisorDesignation = "Required";
        }
        if (i == 1) {
          errors.exp1_supervisorDesignation = "Required";
        }
        if (i == 2) {
          errors.exp2_supervisorDesignation = "Required";
        }
        if (i == 3) {
          errors.exp3_supervisorDesignation = "Required";
        }
        if (i == 4) {
          errors.exp4_supervisorDesignation = "Required";
        }
      }

      //leavingReason

      if (!fields.employmentInfo[i].leavingReason) {
        formIsValid = false;

        if (i == 0) {
          errors.leavingReason = "Required";
        }
        if (i == 1) {
          errors.exp1_leavingReason = "Required";
        }
        if (i == 2) {
          errors.exp2_leavingReason = "Required";
        }
        if (i == 3) {
          errors.exp3_leavingReason = "Required";
        }
        if (i == 4) {
          errors.exp4_leavingReason = "Required";
        }
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleValidation() {
    //debugger
    let fields = this.state.profile1;
    let errors = {};
    let formIsValid = true;

    //FirstName//
    if (fields.firstName != "") {
      if (!/^[a-zA-Z, ]{3,30}$/.test(fields.firstName)) {
        formIsValid = false;
        errors.firstName =
          "Special characters & numbers are not allowed Min 3 and Max 30 characters.";
      }
    }

    //place//
    // if (fields.place != "") {
    //   if (!/^[a-zA-Z]+$/.test(fields.place)) {
    //     formIsValid = false;
    //     errors.place = "Special characters & numbers are not allowed";
    //   }
    // }

    //LastName//

    // if (fields.lastName != "") {
    //   if (!/^[a-zA-Z]+$/.test(fields.lastName)) {
    //     formIsValid = false;
    //     errors.lastName = "Special characters & numbers are not allowed";
    //   }
    // }

    //OtherName//

    if (fields.otherName != "") {
      if (!/^[a-zA-Z]+$/.test(fields.otherName)) {
        formIsValid = false;
        errors.otherName = "Special characters & numbers are not allowed";
      }
    }

    //bloodGroup//

    if (fields.bloodGroup != "") {
      if (!/^(A|B|AB|O)[+-]$/i.test(fields.bloodGroup)) {
        formIsValid = false;
        errors.bloodGroup = "Enter valid blood group";
      }
    }

    //passportNumber//

    if (fields.passportNumber != "") {
      if (!/^[A-Za-z]{1}[0-9]{7}$/.test(fields.passportNumber)) {
        formIsValid = false;
        errors.passportNumber = "Enter valid passport number";
      }
    }
    // PanCardNumber; //
    if (fields.panCardNumber != "") {
      if (!/[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/.test(fields.panCardNumber)) {
        formIsValid = false;
        errors.panCardNumber = "Enter valid Pancard number";
      }
    }

    if (fields.aadharNumber != "") {
      if (!/^[0-9]{12}$/.test(fields.aadharNumber)) {
        formIsValid = false;
        errors.aadharNumber = "Aadhaar number must be 12 digits";
      }
    }

    //address//
    if (fields.contactInfo[0].address != "") {
      if (
        !/^[a-zA-Z0-9!\n-._ :/ , ]{3,100}$/.test(fields.contactInfo[0].address)
      ) {
        formIsValid = false;
        errors.address =
          "Special characters are not allowed. Max 100 characters are allowed";
      }
    }
    // //address//
    if (fields.contactInfo[1].address != "") {
      if (
        !/^[a-zA-Z0-9!\n-._ :/ , ]{3,100}$/.test(fields.contactInfo[1].address)
      ) {
        formIsValid = false;
        errors.address1 =
          "Special characters are not allowed. Max 100 characters are allowed";
      }
    }

    //City//
    if (fields.contactInfo[0].city != "") {
      if (!/^[a-zA-Z]{3,30}$/.test(fields.contactInfo[0].city)) {
        formIsValid = false;
        errors.city =
          "Special characters & numbers are not allowed. Min 3 and Max 30 characters";
      }
    }

    //City//
    if (fields.contactInfo[1].city != "") {
      if (!/^[a-zA-Z]{3,30}$/.test(fields.contactInfo[1].city)) {
        formIsValid = false;
        errors.citty =
          "Special characters & numbers are not allowed, Min 3 and Max 30 characters are allowed";
      }
    }

    //Pincode//
    if (fields.contactInfo[0].pincode != "") {
      if (!/^[0-9]{6}$/.test(fields.contactInfo[0].pincode)) {
        formIsValid = false;
        errors.contactInfopincode = "Pincode must be 6 numbers";
      }
    }

    //Pincode//
    if (fields.contactInfo[1].pincode != "") {
      if (!/^[0-9]{6}$/.test(fields.contactInfo[1].pincode)) {
        formIsValid = false;
        errors.contactInfopincode1 = "Pincode must be 6 numbers";
      }
    }

    //State//
    if (fields.contactInfo[0].state != "") {
      if (!/^[a-zA-Z ,]{3,100}$/.test(fields.contactInfo[0].state)) {
        formIsValid = false;
        errors.state =
          "Special characters & numbers are not allowed. Max upto 100 characters are allowed";
      }
    }

    //State//
    if (fields.contactInfo[1].state != "") {
      if (!/^[a-zA-Z ,]{3,100}$/.test(fields.contactInfo[1].state)) {
        formIsValid = false;
        errors.state1 =
          "Special characters & numbers are not allowed, Min 3 and Max 100 characters are allowed";
      }
    }
    //Country//
    if (fields.contactInfo[0].country != "") {
      if (!/^[a-zA-Z ,]+$/.test(fields.contactInfo[0].country)) {
        formIsValid = false;
        errors.country = "Special characters & numbers are not allowed";
      }
    }

    //Country//
    if (fields.contactInfo[1].country != "") {
      if (!/^[a-zA-Z ,]+$/.test(fields.contactInfo[1].country)) {
        formIsValid = false;
        errors.country1 = "Special characters & numbers are not allowed";
      }
    }

    if (fields.contactInfo[0].nationality != "") {
      if (!/^[a-zA-Z]{3,30}$/.test(fields.contactInfo[0].nationality)) {
        formIsValid = false;
        errors.nationality =
          "Special characters & numbers are not allowed, Min 3 and Max 30 characters are allowed";
      }
    }

    //Nationality//
    if (fields.contactInfo[1].nationality != "") {
      if (!/^[a-zA-Z]{3,30}$/.test(fields.contactInfo[1].nationality)) {
        formIsValid = false;
        errors.nationality1 =
          "Special characters & numbers are not allowed. Min 3 and Max 30 letters are allowed";
      }
    }

    //Phone//

    if (fields.contactInfo[0].phone != "") {
      if (!/^[0-9]{10}$/.test(fields.contactInfo[0].phone)) {
        formIsValid = false;
        errors.phone = "Mobile number must be 10 numbers";
      }
    }

    //Phone//

    if (fields.contactInfo[1].phone != "") {
      if (!/^[0-9]{10}$/.test(fields.contactInfo[1].phone)) {
        formIsValid = false;
        errors.phone1 = "Mobile number must be 10 numbers";
      }
    }

    //EmergencyContact//

    if (fields.contactInfo[0].emergencyContact != "") {
      if (!/^[0-9]{10}$/.test(fields.contactInfo[0].emergencyContact)) {
        formIsValid = false;
        errors.emergencyContact = "Mobile number must be 10 numbers";
      }
    }

    //EmergencyContact//
    if (fields.contactInfo[1].emergencyContact != "") {
      if (!/^[0-9]{10}$/.test(fields.contactInfo[1].emergencyContact)) {
        formIsValid = false;
        errors.emergencyContact1 = "Mobile number must be 10 numbers";
      }
    }

    //AlternateContact//
    if (fields.contactInfo[0].alternateContact != "") {
      if (!/^[0-9]{10}$/.test(fields.contactInfo[0].alternateContact)) {
        formIsValid = false;
        errors.alternateContact = "Mobile number must be 10 numbers";
      }
    }

    //AlternateContact//
    if (fields.contactInfo[1].alternateContact != "") {
      if (!/^[0-9]{10}$/.test(fields.contactInfo[1].alternateContact)) {
        formIsValid = false;
        errors.alternateContact1 = "Mobile number must be 10 numbers";
      }
    }

    //Degree//
    if (fields.educationInfo.degree != "") {
      if (!/^[a-zA-Z-,"." ,]{3,30}$/.test(fields.educationInfo.degree)) {
        formIsValid = false;
        errors.degree =
          "Special characters & numbers are not allowed. Min 3 and Max 30 characters are allowed";
      }
    }

    //CollegeName//
    if (fields.educationInfo.collegeName != "") {
      if (!/^[a-zA-Z, ]{3,30}$/.test(fields.educationInfo.collegeName)) {
        formIsValid = false;
        errors.collegeName =
          "Special characters & numbers are not allowed. Min 3 and Max 30 characters are allowed";
      }
    }

    //UniversityName//
    if (fields.educationInfo.universityName != "") {
      if (!/^[a-zA-Z, ]{3,40}$/.test(fields.educationInfo.universityName)) {
        formIsValid = false;
        errors.universityName =
          "Special characters & numbers are not allowed. Min 3 and Max 40 characters are allowed";
      }
    }

    //Subject//
    if (fields.educationInfo.subject != "") {
      if (!/^[a-zA-Z, ]{3,30}$/.test(fields.educationInfo.subject)) {
        formIsValid = false;
        errors.subject =
          "Special characters & numbers are not allowed. Min 3 and Max 30 characters are allowed";
      }
    }
    //CourseStartDate//

    //CourseType//
    if (fields.educationInfo.courseType != "") {
      if (!/^[a-zA-Z, ]+$/.test(fields.educationInfo.courseType)) {
        formIsValid = false;
        errors.courseType = "Special characters & numbers are not allowed";
      }
    }

    if (fields.educationInfo.addressOfInstitution != "") {
      if (
        !/^[a-zA-Z0-9!\n-._ :/ , ]{3,150}$/.test(
          fields.educationInfo.addressOfInstitution
        )
      ) {
        formIsValid = false;
        errors.addressOfInstitution =
          "Special characters are not allowed and Max upto 150 characters are allowed";
      }
    }

    //City//
    if (fields.educationInfo.city != "") {
      if (!/^[a-zA-Z]{3,30}$/.test(fields.educationInfo.city)) {
        formIsValid = false;
        errors.cityeducatio =
          "Special characters are not allowed and Max upto 150 characters are allowed";
      }
    }

    //PinCode//
    if (fields.educationInfo.pinCode != "") {
      if (!/^[0-9]{6}$/.test(fields.educationInfo.pinCode)) {
        formIsValid = false;
        errors.educationInfopinCode = "Pincode must be 6 numbers";
      }
    }

    //Landline1//
    if (fields.educationInfo.landline1 != null) {
      if (!/^[0-9]{8,11}$/.test(fields.educationInfo.landline1)) {
        formIsValid = false;
        errors.landline1 = "Please enter valid landline number";
      }
    }

    //Landline2//
    if (fields.educationInfo.landline2 != null) {
      if (!/^[0-9]{8,11}$/.test(fields.educationInfo.landline2)) {
        formIsValid = false;
        errors.landline2 = "Please enter valid landline number";
      }
    }

    for (let i = 0; i < this.state.profile1.employmentInfo.length; i++) {
      //company Name//

      if (fields.employmentInfo[i].companyName != "") {
        if (
          !/^[a-zA-Z0-9 ]{3,30}$/.test(fields.employmentInfo[i].companyName)
        ) {
          formIsValid = false;

          if (i == 0) {
            errors.companyName =
              "Special characters are not allowed. Min 3 and Max 30 characters are allowed";
          }
          if (i == 1) {
            errors.exp1_companyName =
              "Special characters are not allowed. Min 3 and Max 30 characters are allowed";
          }
          if (i == 2) {
            errors.exp2_companyName =
              "Special characters are not allowed. Min 3 and Max 30 characters are allowed";
          }
          if (i == 3) {
            errors.exp3_companyName =
              "Special characters are not allowed. Min 3 and Max 30 characters are allowed";
          }
          if (i == 4) {
            errors.exp4_companyName =
              "Special characters are not allowed. Min 3 and Max 30 characters are allowed";
          }
        }
      }

      //employedPlace
      if (fields.employmentInfo[i].employedPlace != "") {
        if (
          !/^[a-zA-Z, ]{3,30}$/.test(fields.employmentInfo[i].employedPlace)
        ) {
          formIsValid = false;

          if (i == 0) {
            errors.employedPlace =
              "Special characters are not allowed and Max upto 30 characters are allowed";
          }
          if (i == 1) {
            errors.exp1_employedPlace =
              "Special characters are not allowed and Max upto 150 characters are allowed";
          }
          if (i == 2) {
            errors.exp2_employedPlace =
              "Special characters are not allowed and Max upto 150 characters are allowed";
          }
          if (i == 3) {
            errors.exp3_employedPlace =
              "Special characters are not allowed and Max upto 150 characters are allowed";
          }
          if (i == 4) {
            errors.exp4_employedPlace =
              "Special characters are not allowed and Max upto 150 characters are allowed";
          }
        }
      }

      if (fields.employmentInfo[i].companyAddress != "") {
        if (
          !/^[a-zA-Z0-9!\n-._ :/ , ]{3,150}$/.test(
            fields.employmentInfo[i].companyAddress
          )
        ) {
          formIsValid = false;

          if (i == 0) {
            errors.companyAddress =
              "Special characters are not allowed and Max upto 150 characters are allowed";
          }
          if (i == 1) {
            errors.exp1_companyAddress =
              "Special characters are not allowed and Max upto 150 characters are allowed";
          }
          if (i == 2) {
            errors.exp2_companyAddress =
              "Special characters are not allowed and Max upto 150 characters are allowed";
          }
          if (i == 3) {
            errors.exp3_companyAddress =
              "Special characters are not allowed and Max upto 150 characters are allowed";
          }
          if (i == 4) {
            errors.exp4_companyAddress =
              "Special characters are not allowed and Max upto 150 characters are allowed";
          }
        }
      }

      ////city

      if (fields.employmentInfo[i].city != "") {
        if (!/^[a-zA-Z, ]{3,30}$/.test(fields.employmentInfo[i].city)) {
          formIsValid = false;
          if (i == 0) {
            errors.cityemployment =
              "Special characters are not allowed and Max upto 30 characters are allowed";
          }
          if (i == 1) {
            errors.exp1_cityemployment =
              "Special characters are not allowed and Max upto 30 characters are allowed";
          }
          if (i == 2) {
            errors.exp2_cityemployment =
              "Special characters are not allowed and Max upto 30 characters are allowed";
          }
          if (i == 3) {
            errors.exp3_cityemployment =
              "Special characters are not allowed and Max upto 30 characters are allowed";
          }
          if (i == 4) {
            errors.exp4_cityemployment =
              "Special characters are not allowed and Max upto 30 characters are allowed";
          }
        }
      }

      //company Address//

      ////state
      if (fields.employmentInfo[i].state != "") {
        if (!/^[a-zA-Z, ]{3,30}$/.test(fields.employmentInfo[i].state)) {
          formIsValid = false;
          if (i == 0) {
            errors.stateemployment =
              "Special characters & numbers are not allowed, Min 3 and Max 30 characters are allowed";
          }
          if (i == 1) {
            errors.exp1_stateemployment = "Special characters are not allowed";
          }
          if (i == 2) {
            errors.exp2_stateemployment = "Special characters are not allowed";
          }
          if (i == 3) {
            errors.exp3_stateemployment = "Special characters are not allowed";
          }
          if (i == 4) {
            errors.exp4_stateemployment = "Special characters are not allowed";
          }
        }
      }

      ////pincode

      if (fields.employmentInfo[i].pincode != "") {
        if (!/^[0-9]{6}$/.test(fields.employmentInfo[i].pincode)) {
          formIsValid = false;

          if (i == 0) {
            errors.pincodeemployement = "Pincode must be 6 numbers";
          }
          if (i == 1) {
            errors.exp1_pincodeemployement = "Pincode must be 6 numbers";
          }
          if (i == 2) {
            errors.exp2_pincodeemployement = "Pincode must be 6 numbers";
          }
          if (i == 3) {
            errors.exp3_pincodeemployement = "Pincode must be 6 numbers";
          }
          if (i == 4) {
            errors.exp4_pincodeemployement = "Pincode must be 6 numbers";
          }
        }
      }

      //fromDate

      //designation
      if (fields.employmentInfo[i].designation != "") {
        if (!/^[a-zA-Z, ]{3,30}$/.test(fields.employmentInfo[i].designation)) {
          formIsValid = false;

          if (i == 0) {
            errors.designation =
              "Special characters & numbers are not allowed. Min 3 and Max 30 characters are allowed";
          }
          if (i == 1) {
            errors.exp1_designation =
              "Special characters & numbers are not allowed. Min 3 and Max 30 characters are allowed";
          }
          if (i == 2) {
            errors.exp2_designation =
              "Special characters & numbers are not allowed. Min 3 and Max 30 characters are allowed";
          }
          if (i == 3) {
            errors.exp3_designation =
              "Special characters & numbers are not allowed. Min 3 and Max 30 characters are allowed";
          }
          if (i == 4) {
            errors.exp4_designation =
              "Special characters & numbers are not allowed. Min 3 and Max 30 characters are allowed";
          }
        }
      }

      if (fields.employmentInfo[i].remuneration != "") {
        if (!/^[0-9]{6,8}$/.test(fields.employmentInfo[i].remuneration)) {
          formIsValid = false;
          if (i == 0) {
            errors.remuneration = "Enter 6 to 8 digits";
          }
          if (i == 1) {
            errors.exp1_remuneration = "Enter 6 to 8 digits";
          }
          if (i == 2) {
            errors.exp2_remuneration = "Enter 6 to 8 digits";
          }
          if (i == 3) {
            errors.exp3_remuneration = "Enter 6 to 8 digits";
          }
          if (i == 4) {
            errors.exp4_remuneration = "Enter 6 to 8 digits";
          }
        }
      }
      //supervisorEmailId

      if (fields.employmentInfo[i].supervisorEmailId != "") {
        if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
            fields.employmentInfo[i].supervisorEmailId
          )
        ) {
          formIsValid = false;
          if (i == 0) {
            errors.supervisorEmailId = "Please enter valid email";
          }
          if (i == 1) {
            errors.exp1_supervisorEmailId = "Please enter valid email";
          }
          if (i == 2) {
            errors.exp2_supervisorEmailId = "Please enter valid email";
          }
          if (i == 3) {
            errors.exp3_supervisorEmailId = "Please enter valid email";
          }
          if (i == 4) {
            errors.exp4_supervisorEmailId = "Please enter valid email";
          }
        }
      }

      //employeeCode

      if (fields.employmentInfo[i].employeeCode != "") {
        if (
          !/^[a-zA-Z0-9, ]{3,20}$/.test(fields.employmentInfo[i].employeeCode)
        ) {
          formIsValid = false;
          if (i == 0) {
            errors.employeeCode =
              "Special characters & numbers are not allowed, Min 3 and Max 30 characters are allowed";
          }
          if (i == 1) {
            errors.exp1_employeeCode =
              "Special characters & numbers are not allowed, Min 3 and Max 30 characters are allowed";
          }
          if (i == 2) {
            errors.exp2_employeeCode =
              "Special characters & numbers are not allowed, Min 3 and Max 30 characters are allowed";
          }
          if (i == 3) {
            errors.exp3_employeeCode =
              "Special characters & numbers are not allowed, Min 3 and Max 30 characters are allowed";
          }
          if (i == 4) {
            errors.exp4_employeeCode =
              "Special characters & numbers are not allowed, Min 3 and Max 30 characters are allowed";
          }
        }
      }

      //employmentNature
      if (fields.employmentInfo[i].employmentNature != "") {
        if (
          !/^[a-zA-Z, ]{3,30}$/.test(fields.employmentInfo[i].employmentNature)
        ) {
          formIsValid = false;
          if (i == 0) {
            errors.employmentNature =
              "Special characters & numbers are not allowed, Min 3 and Max 30 characters are allowed";
          }
          if (i == 1) {
            errors.exp1_employmentNature =
              "Special characters & numbers are not allowed, Min 3 and Max 30 characters are allowed";
          }
          if (i == 2) {
            errors.exp2_employmentNature =
              "Special characters & numbers are not allowed, Min 3 and Max 30 characters are allowed";
          }
          if (i == 3) {
            errors.exp3_employmentNature =
              "Special characters & numbers are not allowed, Min 3 and Max 30 characters are allowed";
          }
          if (i == 4) {
            errors.exp4_employmentNature =
              "Special characters & numbers are not allowed, Min 3 and Max 30 characters are allowed";
          }
        }
      }

      //supervisorName
      if (fields.employmentInfo[i].supervisorName != "") {
        if (
          !/^[a-zA-Z, ]{3,30}$/.test(fields.employmentInfo[i].supervisorName)
        ) {
          formIsValid = false;
          if (i == 0) {
            errors.supervisorName =
              "Special characters & numbers are not allowed. Min 3 and Max 30 characters are allowed";
          }
          if (i == 1) {
            errors.exp1_supervisorName =
              "Special characters & numbers are not allowed. Min 3 and Max 30 characters are allowed";
          }
          if (i == 2) {
            errors.exp2_supervisorName =
              "Special characters & numbers are not allowed. Min 3 and Max 30 characters are allowed";
          }
          if (i == 3) {
            errors.exp3_supervisorName =
              "Special characters & numbers are not allowed. Min 3 and Max 30 characters are allowed";
          }
          if (i == 4) {
            errors.exp4_supervisorName =
              "Special characters & numbers are not allowed. Min 3 and Max 30 characters are allowed";
          }
        }
      }

      //supervisorDesignation

      if (fields.employmentInfo[i].supervisorDesignation != "") {
        if (
          !/^[a-zA-Z, ]{3,30}$/.test(
            fields.employmentInfo[i].supervisorDesignation
          )
        ) {
          formIsValid = false;
          if (i == 0) {
            errors.supervisorDesignation =
              "Special characters & numbers are not allowed. Min 3 and Max 30 characters are allowed";
          }
          if (i == 1) {
            errors.exp1_supervisorDesignation =
              "Special characters & numbers are not allowed. Min 3 and Max 30 characters are allowed";
          }
          if (i == 2) {
            errors.exp2_supervisorDesignation =
              "Special characters & numbers are not allowed. Min 3 and Max 30 characters are allowed";
          }
          if (i == 3) {
            errors.exp3_supervisorDesignation =
              "Special characters & numbers are not allowed. Min 3 and Max 30 characters are allowed";
          }
          if (i == 4) {
            errors.exp4_supervisorDesignation =
              "Special characters & numbers are not allowed. Min 3 and Max 30 characters are allowed";
          }
        }
      }

      //leavingReason

      if (fields.employmentInfo[i].leavingReason != "") {
        if (
          !/^[a-zA-Z0-9!\n-._ :/ , ]{10,300}$/.test(
            fields.employmentInfo[i].leavingReason
          )
        ) {
          formIsValid = false;
          if (i == 0) {
            errors.leavingReason =
              "Special characters & numbers are not allowed. Min 10 and Max 100 characters are allowed";
          }
          if (i == 1) {
            errors.exp1_leavingReason =
              "Special characters & numbers are not allowed. Min 10 and Max 100 characters are allowed";
          }
          if (i == 2) {
            errors.exp2_leavingReason =
              "Special characters & numbers are not allowed. Min 10 and Max 100 characters are allowed";
          }
          if (i == 3) {
            errors.exp3_leavingReason =
              "Special characters & numbers are not allowed. Min 10 and Max 100 characters are allowed";
          }
          if (i == 4) {
            errors.exp4_leavingReason =
              "Special characters & numbers are not allowed. Min 10 and Max 100 characters are allowed";
          }
        }
      }
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  checkedAddress = (e) => {
    let addressTemplate = this.state.profile1;
    this.setState({ [e.target.name]: !this.state.checked1 });
    if (this.state.checked1 === false) {
      addressTemplate.contactInfo[1] = addressTemplate.contactInfo[0];
      this.setState({
        profile1: addressTemplate,
      });
    } else {
      addressTemplate.contactInfo[1] = this.state.address;
      this.setState({
        profile1: addressTemplate,
      });
    }
    this.handleValidation();
  };

  profileChanger = (e, element) => {
    let dummyProfile2 = this.state.profile1;
    if (dummyProfile2 !== "") {
      this.setState({ isAlwaysFetched: false });
      this.props.dispatch(saved(true));
      dummyProfile2[element] = e.target.value;
      this.setState({ profile1: dummyProfile2 });
    } else {
      this.setState({ isAlwaysFetched: true });
    }
    this.handleValidation();
  };
  contactinfoChanger = (e, field, index) => {
    let dummyProfile3 = this.state.profile1;
    dummyProfile3.contactInfo[index][field] = e.target.value;
    this.setState({ profile1: dummyProfile3 });

    this.handleValidation();
  };

  educationInfoChanger = (e, field) => {
    let dummyProfile3 = this.state.profile1;
    if (dummyProfile3 !== "") {
      this.props.dispatch(saved(true));
      dummyProfile3.educationInfo[field] = e.target.value;
      this.setState({ profile1: dummyProfile3 });
    }
    this.handleValidation();
  };

  changeExperienceDetails = (e) => {
    if (e.target.value == "Experience") {
      this.props.dispatch(isexpirienceThere(true));

      let employmentInfo_CreateNew = this.state.profile1.employmentInfo;
      if (employmentInfo_CreateNew.length < 5) {
        employmentInfo_CreateNew.push({
          companyName: "",
          employedPlace: "",
          companyAddress: "",
          city: "",
          state: "",
          pincode: "",
          fromDate: "",
          toDate: "",
          designation: "",
          remuneration: "",
          employeeCode: "",
          employmentNature: "",
          supervisorName: "",
          supervisorDesignation: "",
          supervisorEmailId: "",
          leavingReason: "",
        });
      }
      this.setState({ employmentInfo: employmentInfo_CreateNew });
    }
  };

  AddExp = (e) => {
    e.preventDefault();
    if (this.state.profile1.employmentInfo.length < 5) {
      let employmentInfo_CreateNew = this.state.profile1.employmentInfo;
      employmentInfo_CreateNew.push({
        companyName: "",
        employedPlace: "",
        companyAddress: "",
        city: "",
        state: "",
        pincode: "",
        fromDate: "",
        toDate: "",
        designation: "",
        remuneration: "",
        employeeCode: "",
        employmentNature: "",
        supervisorName: "",
        supervisorDesignation: "",
        supervisorEmailId: "",
        leavingReason: "",
      });

      this.setState({ employmentInfo: employmentInfo_CreateNew });
    }
  };

  RemoveExp = (index, e) => {
    e.preventDefault();
    let expToDelete = this.state.profile1.employmentInfo;
    expToDelete.splice(index, 1);
    this.setState({ employementInfo: expToDelete });
  };

  employementInfoChanger = (e, field, index) => {
    let dummyProfile3 = this.state.profile1;
    if (dummyProfile3 !== "") {
      this.props.dispatch(saved(true));
      dummyProfile3.employmentInfo[index][field] = e.target.value;
      this.setState({ profile1: dummyProfile3 });
    }
    this.handleValidation();
  };

  madeVisible = () => {
    this.setState({ ...this.state, showViewForm: false, showEditForm: true });
  };

  madeVisibleoff = () => {
    this.setState({ ...this.state, showViewForm: true, showEditForm: false });
  };
  insuranceHandler = () => {
    this.props.dispatch(insuranceid(true));
    this.props.history.push("/Insurance");
  };
  pfHandler = () => {
    this.props.dispatch(pfdataid(true));
    this.props.history.push("/Pfdata");
  };
  profileHandler = () => {
    this.props.dispatch(profileid(true));
    this.props.history.push("/Profile");
  };
  empClearanceHandler = () => {
    this.props.dispatch(employeeclearance(true));
    this.props.history.push("/Employeeclearanceform");
  };

  exitinterviewhandler = () => {
    this.props.dispatch(exitinterviewfunction(true));
    this.props.history.push("/ExitInterviewForm");
  };
  empDocumentsHandler = () => {
    this.props.dispatch(previouslyUploadedDocuments(true));
    this.props.history.push("/PreviouslyUploadedDocuments");
  };
  inputView = () => {
    return this.props.showView ? "Input_fields_Disable" : "Input_fields";
  };
  render() {
    const {
      place,
      companyName1,
      selected,
      date,
      address1,
      firstName,
      lastName,
      companyName,
      fromDate,
      toDate,
      cityemployment,
      stateemployment,
      designation,
      remuneration,
      employeeCode,
      pincodeemployement,
      employmentNature,
      supervisorName,
      supervisorDesignation,
      supervisorEmailId,
      leavingReason,
      employedPlace,
      companyAddress,
      otherName,
      gender,
      dob,
      bloodGroup,
      checked,
      passportNumber,
      dateOfIssue,
      dateOfExpiry,
      aadharNumber,
      panCardNumber,
      address,
      city,
      contactInfopincode,
      state,
      country,
      cityeducatio,
      nationality,
      phone,
      emergencyContact,
      alternateContact,
      degree,
      collegeName,
      universityName,
      subject,
      courseStartDate,
      courseEndDate,
      courseType,
      addressOfInstitution,
      citty,
      educationInfopinCode,
      landline1,
      landline2,
      alternateContact1,
      contactInfopincode1,
      country1,
      state1,
      nationality1,
      phone1,
      emergencyContact1,
      leavingReason1,
      supervisorEmailId1,
      supervisorName1,
      employmentNature1,
      employeeCode1,
      remuneration1,
      designation1,
      supervisorDesignation1,
      toDate1,
      employedPlace1,
      companyAddress1,
      cityemployment1,
      stateemployment1,
      fromDate1,
      pincodeemployement1,
      exp1_companyName,
      exp2_companyName,
      exp3_companyName,
      exp4_companyName,
      exp1_employedPlace,
      exp2_employedPlace,
      exp3_employedPlace,
      exp4_employedPlace,
      exp1_companyAddress,
      exp2_companyAddress,
      exp3_companyAddress,
      exp4_companyAddress,
      exp1_cityemployment,
      exp2_cityemployment,
      exp3_cityemployment,
      exp4_cityemployment,
      exp1_stateemployment,
      exp2_stateemployment,
      exp3_stateemployment,
      exp4_stateemployment,
      exp1_pincodeemployement,
      exp2_pincodeemployement,
      exp3_pincodeemployement,
      exp4_pincodeemployement,
      exp1_designation,
      exp2_designation,
      exp3_designation,
      exp4_designation,
      exp1_remuneration,
      exp2_remuneration,
      exp3_remuneration,
      exp4_remuneration,
      exp1_supervisorEmailId,
      exp2_supervisorEmailId,
      exp3_supervisorEmailId,
      exp4_supervisorEmailId,
      exp1_employeeCode,
      exp2_employeeCode,
      exp3_employeeCode,
      exp4_employeeCode,
      exp1_employmentNature,
      exp2_employmentNature,
      exp3_employmentNature,
      exp4_employmentNature,
      exp1_supervisorName,
      exp2_supervisorName,
      exp3_supervisorName,
      exp4_supervisorName,
      exp1_supervisorDesignation,
      exp2_supervisorDesignation,
      exp3_supervisorDesignation,
      exp4_supervisorDesignation,
      exp1_leavingReason,
      exp2_leavingReason,
      exp3_leavingReason,
      exp4_leavingReason,
      exp1_fromDate,
      exp2_fromDate,
      exp3_fromDate,
      exp4_fromDate,
      exp1_toDate,
      exp2_toDate,
      exp3_toDate,
      exp4_toDate,
    } = this.state.errors;
    //(this.props.empClearance);
    return (
      <div>
        <Header />
        {this.state.profile1.status === "Relieved" ? (
          <Relieved />
        ) : (
          <>
            <h6 className="Instructions_Form">
              <strong className="strong">Instructions :</strong> Please provide
              all the information requested in this form. Incomplete Candidate
              Declaration Forms (CDFs) will be returned. All supporting
              documents must accompany this form. Photocopies must be legible.
              We have included a checklist to assist you to complete your
              application comprehensively
            </h6>
            <div>
              <Buttons />
            </div>
            <div>
              <form
                className="Submit_btn_"
                id="myForm"
                onSubmit={this.profileMaker}
              >
                <div className="personal_Info">
                  <h1 className="Form55">Personal Information</h1>
                  <div
                    className="personal_Info_fields"
                    style={{ display: "flex", flexWrap: "wrap" }}
                  >
                    <div className="personal_Info_label_input">
                      <div className="personal_Info_label">
                        <span>First Name:</span>
                      </div>
                      <div className="personal_Info_input">
                        <input
                          className={this.inputView()}
                          disabled={this.props.showView}
                          name="first Name"
                          placeholder="First Name"
                          value={this.state.profile1.firstName}
                          onChange={(e) => this.profileChanger(e, "firstName")}
                        ></input>
                      </div>
                      <div className="personal_Info_Error">
                        {firstName && (
                          <span className="Error">{firstName}</span>
                        )}
                      </div>
                    </div>
                    <div className="personal_Info_Error_Mobile">
                      {firstName && <span className="Error">{firstName}</span>}
                    </div>
                    <div className="personal_Info_label_input">
                      <div className="personal_Info_label">
                        <span>Middle Name (optional):</span>
                      </div>
                      <div className="personal_Info_input">
                        <input
                          className={this.inputView()}
                          disabled={this.props.showView}
                          name="first Name"
                          placeholder="Middle Name"
                          value={this.state.profile1.lastName}
                          onChange={(e) => this.profileChanger(e, "lastName")}
                        ></input>
                      </div>
                      <div className="personal_Info_Error">
                        {lastName && <span className="Error">{lastName}</span>}
                      </div>
                    </div>
                    <div className="personal_Info_Error_Mobile">
                      {lastName && <span className="Error">{lastName}</span>}
                    </div>

                    <div className="personal_Info_label_input">
                      <div className="personal_Info_label">
                        <span>Last Name:</span>
                      </div>
                      <div className="personal_Info_input">
                        <input
                          className={this.inputView()}
                          disabled={this.props.showView}
                          name="otherName"
                          placeholder="Last Name"
                          value={this.state.profile1.otherName}
                          onChange={(e) => this.profileChanger(e, "otherName")}
                        ></input>
                      </div>
                      <div className="personal_Info_Error">
                        {otherName && (
                          <span className="Error">{otherName}</span>
                        )}
                      </div>
                    </div>
                    <div className="personal_Info_Error_Mobile">
                      {otherName && <span className="Error">{otherName}</span>}
                    </div>

                    <div className="personal_Info_label_input">
                      <div className="personal_Info_label">
                        <span>Gender:</span>
                      </div>
                      <div className="personal_Info_input">
                        <select
                          className={this.inputView()}
                          name="gender"
                          disabled={this.props.showView}
                          value={this.state.profile1.gender}
                          onChange={(e) => this.profileChanger(e, "gender")}
                        >
                          <option value="" disabled>
                            Select Gender
                          </option>
                          <option value="Male">Male</option>
                          <option value="FeMale">Female</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                      <div className="personal_Info_Error">
                        {gender && <span className="Error">{gender}</span>}
                      </div>
                    </div>
                    <div className="personal_Info_Error_Mobile">
                      {gender && <span className="Error">{gender}</span>}
                    </div>

                    <div className="personal_Info_label_input">
                      <div className="personal_Info_label">
                        <span>Date Of Birth:</span>
                      </div>
                      <div className="personal_Info_input">
                        <input
                          className={this.inputView()}
                          disabled={this.props.showView}
                          name="Dob"
                          min="1930-01-01"
                          max="2000-12-31"
                          type="date"
                          value={this.state.profile1.dob?.split("T")[0]}
                          placeholder="DD/MM/YYYY"
                          onChange={(e) => this.profileChanger(e, "dob")}
                        ></input>
                      </div>
                      <div className="personal_Info_Error">
                        {dob && <span className="Error">{dob}</span>}
                      </div>
                    </div>
                    <div className="personal_Info_Error_Mobile">
                      {dob && <span className="Error">{dob}</span>}
                    </div>

                    <div className="personal_Info_label_input">
                      <div className="personal_Info_label">
                        <span>BloodGroup:</span>
                      </div>
                      <div className="personal_Info_input">
                        <input
                          className={this.inputView()}
                          name="bloodGroup"
                          disabled={this.props.showView}
                          value={this.state.profile1.bloodGroup}
                          placeholder="ex:A+"
                          onChange={(e) => this.profileChanger(e, "bloodGroup")}
                        ></input>
                      </div>
                      <div className="personal_Info_Error">
                        {bloodGroup && (
                          <span className="Error">{bloodGroup}</span>
                        )}
                      </div>
                    </div>
                    <div className="personal_Info_Error_Mobile">
                      {bloodGroup && (
                        <span className="Error">{bloodGroup}</span>
                      )}
                    </div>

                    <div className="personal_Info_label_input">
                      <div className="personal_Info_label">
                        <span> Passport Number (optional):</span>
                      </div>
                      <div className="personal_Info_input">
                        <input
                          className={this.inputView()}
                          disabled={this.props.showView}
                          name="passportNumber"
                          value={this.state.profile1.passportNumber}
                          placeholder="Passport"
                          onChange={(e) =>
                            this.profileChanger(e, "passportNumber")
                          }
                        ></input>
                      </div>
                      <div className="personal_Info_Error">
                        {passportNumber && (
                          <span className="Error">{passportNumber}</span>
                        )}
                      </div>
                      <div className="personal_Info_Error_Mobile">
                        {passportNumber && (
                          <span className="Error">{passportNumber}</span>
                        )}
                      </div>
                    </div>

                    <div className="personal_Info_label_input">
                      {/* <div className="personal_Info_input"> */}
                      <div className="personal_Info_label">
                        <span>Date Of Issue (optional):</span>
                      </div>
                      <div className="personal_Info_input">
                        <input
                          className={this.inputView()}
                          name="dateOfIssue"
                          max={this.state.currentdate}
                          disabled={this.props.showView}
                          value={this.state.profile1.dateOfIssue?.split("T")[0]}
                          placeholder="DD/MM/YYYY"
                          min="1930-01-01"
                          // max="2000-12-31"
                          type="date"
                          onChange={(e) =>
                            this.profileChanger(e, "dateOfIssue")
                          }
                        ></input>
                      </div>
                      <div className="personal_Info_Error">
                        {dateOfIssue && (
                          <span className="Error">{dateOfIssue}</span>
                        )}
                      </div>
                    </div>
                    <div className="personal_Info_Error_Mobile">
                      {dateOfIssue && (
                        <span className="Error">{dateOfIssue}</span>
                      )}
                    </div>

                    <div className="personal_Info_label_input">
                      <div className="personal_Info_label">
                        <span>Date Of Expiry (optional):</span>
                      </div>
                      <div className="personal_Info_input">
                        <input
                          type="date"
                          className={this.inputView()}
                          max="2040-12-31"
                          min={this.state.profile1.dateOfIssue}
                          disabled={this.props.showView}
                          value={
                            this.state.profile1.dateOfExpiry?.split("T")[0]
                          }
                          name="dateOfExpiry"
                          placeholder="DD/MM/YYYY"
                          onChange={(e) =>
                            this.profileChanger(e, "dateOfExpiry")
                          }
                        ></input>
                      </div>
                      <div className="personal_Info_Error">
                        {dateOfExpiry && (
                          <span className="Error">{dateOfExpiry}</span>
                        )}
                      </div>
                    </div>
                    <div className="personal_Info_Error_Mobile">
                      {dateOfExpiry && (
                        <span className="Error">{dateOfExpiry}</span>
                      )}
                    </div>

                    <div className="personal_Info_label_input">
                      <div className="personal_Info_label">
                        <span>PAN/Social Security Number:</span>
                      </div>
                      <div className="personal_Info_input">
                        <input
                          className={this.inputView()}
                          disabled={this.props.showView}
                          name="PanCardNumber"
                          value={this.state.profile1.panCardNumber}
                          placeholder="PanCard"
                          style={{ textTransform: "uppercase" }}
                          onChange={(e) =>
                            this.profileChanger(e, "panCardNumber")
                          }
                        ></input>
                      </div>
                      <div className="personal_Info_Error">
                        {panCardNumber && (
                          <span className="Error">{panCardNumber}</span>
                        )}
                      </div>
                    </div>
                    <div className="personal_Info_Error_Mobile">
                      {panCardNumber && (
                        <span className="Error">{panCardNumber}</span>
                      )}
                    </div>

                    <div className="personal_Info_label_input">
                      <div className="personal_Info_label">
                        <span>AADHAR Number:</span>
                      </div>
                      <div className="personal_Info_input">
                        <input
                          className={this.inputView()}
                          disabled={this.props.showView}
                          name="aadharNumber"
                          value={this.state.profile1.aadharNumber}
                          placeholder="Aadhar Number"
                          onChange={(e) =>
                            this.profileChanger(e, "aadharNumber")
                          }
                        ></input>
                      </div>
                      <div className="personal_Info_Error">
                        {aadharNumber && (
                          <span className="Error">{aadharNumber}</span>
                        )}
                      </div>
                    </div>
                    <div className="personal_Info_Error_Mobile">
                      {aadharNumber && (
                        <span className="Error">{aadharNumber}</span>
                      )}
                    </div>

                    <div className="personal_Info_label_input">
                      <div className="personal_Info_label">
                        <span>Email:</span>
                      </div>
                      <div className="personal_Info_input">
                        <input
                          className={this.inputView()}
                          disabled
                          value={localStorage.getItem("email")}
                          placeholder="Ex: Hello@gmail.com"
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="personal_Info">
                  <h1 className="Form55">Contact Information</h1>
                  {this.state.profile1.contactInfo
                    ?.sort((a, b) => a.id - b.id)
                    .map((contactInfo, index) => (
                      // .sort((a, b) => a.id - b.id)
                      <div key={index} style={{ marginBottom: 15 }}>
                        <div className="personal_Info_fields">
                          <div className="personal_Info_label_input">
                            <div className="personal_Info_label">
                              <span>
                                {index === 0 && "Present Address:"}
                                {index === 1 && "Permanent Address:"}
                              </span>
                            </div>
                            <div className="personal_Info_input">
                              {/* <input
                        className={this.inputView()}
                        disabled={this.props.showView}
                        name="address"
                        value={contactInfo.address}
                        placeholder="Address"
                        autocomplete="true"
                        onChange={(e) =>
                          this.contactinfoChanger(e, "address", index)
                        }
                      ></input> */}
                              <InputTextarea
                                rows={2}
                                cols={20}
                                autoResize={true}
                                className={
                                  this.props.showView
                                    ? "Input_fields_textArea_disable"
                                    : "Input_fields_textArea"
                                }
                                disabled={
                                  this.props.showView ||
                                  (index === 1 && this.state.checked1)
                                }
                                name="address"
                                value={contactInfo?.address}
                                placeholder="Address"
                                autocomplete="true"
                                onChange={(e) =>
                                  this.contactinfoChanger(e, "address", index)
                                }
                              />
                            </div>
                            <div className="personal_Info_Error">
                              {index === 0 && address && (
                                <span className="Error">{address}</span>
                              )}{" "}
                              {this.state.checked1
                                ? ""
                                : index === 1 &&
                                  address1 && (
                                    <span className="Error">{address1}</span>
                                  )}
                            </div>
                          </div>
                          <div className="personal_Info_Error_Mobile">
                            {index === 0 && address && (
                              <span className="Error">{address}</span>
                            )}{" "}
                            {index === 1 && address1 && (
                              <span className="Error">{address1}</span>
                            )}
                          </div>

                          <div className="personal_Info_label_input">
                            <div className="personal_Info_label">
                              <span>City:</span>
                            </div>
                            <div className="personal_Info_input">
                              <input
                                className={this.inputView()}
                                disabled={
                                  this.props.showView ||
                                  (index === 1 && this.state.checked1)
                                }
                                name="city"
                                value={contactInfo?.city}
                                placeholder="City"
                                autocomplete="true"
                                onChange={(e) =>
                                  this.contactinfoChanger(e, "city", index)
                                }
                              ></input>
                            </div>
                            <div className="personal_Info_Error">
                              {index === 0 && city && (
                                <span className="Error">{city}</span>
                              )}{" "}
                              {this.state.checked1
                                ? ""
                                : index === 1 &&
                                  citty && (
                                    <span className="Error">{citty}</span>
                                  )}
                            </div>
                          </div>
                          <div className="personal_Info_Error_Mobile">
                            {index === 0 && city && (
                              <span className="Error">{city}</span>
                            )}{" "}
                            {this.state.checked1
                              ? ""
                              : index === 1 &&
                                citty && <span className="Error">{citty}</span>}
                          </div>

                          <div className="personal_Info_label_input">
                            <div className="personal_Info_label">
                              <span>Pincode:</span>
                            </div>
                            <div className="personal_Info_input">
                              <input
                                className={this.inputView()}
                                disabled={
                                  this.props.showView ||
                                  (index === 1 && this.state.checked1)
                                }
                                name="pincode"
                                value={contactInfo?.pincode}
                                placeholder="Pincode"
                                autocomplete="true"
                                onChange={(e) =>
                                  this.contactinfoChanger(e, "pincode", index)
                                }
                              ></input>
                            </div>
                            <div className="personal_Info_Error">
                              {index === 0 && contactInfopincode && (
                                <span className="Error">
                                  {contactInfopincode}
                                </span>
                              )}{" "}
                              {this.state.checked1
                                ? ""
                                : index === 1 &&
                                  contactInfopincode1 && (
                                    <span className="Error">
                                      {contactInfopincode1}
                                    </span>
                                  )}
                            </div>
                          </div>
                          <div className="personal_Info_Error_Mobile">
                            {index === 0 && contactInfopincode && (
                              <span className="Error">
                                {contactInfopincode}
                              </span>
                            )}{" "}
                            {this.state.checked1
                              ? ""
                              : index === 1 &&
                                contactInfopincode1 && (
                                  <span className="Error">
                                    {contactInfopincode1}
                                  </span>
                                )}
                          </div>

                          <div className="personal_Info_label_input">
                            <div className="personal_Info_label">
                              <span>State Of Residence:</span>
                            </div>
                            <div className="personal_Info_input">
                              <select
                                className={this.inputView()}
                                name="state"
                                disabled={
                                  this.props.showView ||
                                  (index === 1 && this.state.checked1)
                                }
                                value={contactInfo?.state}
                                onChange={(e) =>
                                  this.contactinfoChanger(e, "state", index)
                                }
                              >
                                <option value="" disabled>
                                  Select State
                                </option>
                                <option value="Andhra Pradesh">
                                  Andhra Pradesh
                                </option>
                                <option value="Andaman and Nicobar Islands">
                                  Andaman and Nicobar Islands
                                </option>
                                <option value="Arunachal Pradesh">
                                  Arunachal Pradesh
                                </option>
                                <option value="Assam">Assam</option>
                                <option value="Bihar">Bihar</option>
                                <option value="Chandigarh">Chandigarh</option>
                                <option value="Chhattisgarh">
                                  Chhattisgarh
                                </option>
                                <option value="Dadar and Nagar Haveli">
                                  Dadar and Nagar Haveli
                                </option>
                                <option value="Daman and Diu">
                                  Daman and Diu
                                </option>
                                <option value="Delhi">Delhi</option>
                                <option value="Lakshadweep">Lakshadweep</option>
                                <option value="Puducherry">Puducherry</option>
                                <option value="Goa">Goa</option>
                                <option value="Gujarat">Gujarat</option>
                                <option value="Haryana">Haryana</option>
                                <option value="Himachal Pradesh">
                                  Himachal Pradesh
                                </option>
                                <option value="Jammu and Kashmir">
                                  Jammu and Kashmir
                                </option>
                                <option value="Jharkhand">Jharkhand</option>
                                <option value="Karnataka">Karnataka</option>
                                <option value="Kerala">Kerala</option>
                                <option value="Madhya Pradesh">
                                  Madhya Pradesh
                                </option>
                                <option value="Maharashtra">Maharashtra</option>
                                <option value="Manipur">Manipur</option>
                                <option value="Meghalaya">Meghalaya</option>
                                <option value="Mizoram">Mizoram</option>
                                <option value="Nagaland">Nagaland</option>
                                <option value="Odisha">Odisha</option>
                                <option value="Punjab">Punjab</option>
                                <option value="Rajasthan">Rajasthan</option>
                                <option value="Sikkim">Sikkim</option>
                                <option value="Tamil Nadu">Tamil Nadu</option>
                                <option value="Telangana">Telangana</option>
                                <option value="Tripura">Tripura</option>
                                <option value="Uttar Pradesh">
                                  Uttar Pradesh
                                </option>
                                <option value="Uttarakhand">Uttarakhand</option>
                                <option value="West Bengal">West Bengal</option>
                              </select>
                            </div>
                            <div className="personal_Info_Error">
                              {index === 0 && state && (
                                <span className="Error">{state}</span>
                              )}{" "}
                              {this.state.checked1
                                ? ""
                                : index === 1 &&
                                  state1 && (
                                    <span className="Error">{state1}</span>
                                  )}
                            </div>
                          </div>
                          <div className="personal_Info_Error_Mobile">
                            {index === 0 && state && (
                              <span className="Error">{state}</span>
                            )}{" "}
                            {this.state.checked1
                              ? ""
                              : index === 1 &&
                                state1 && (
                                  <span className="Error">{state1}</span>
                                )}
                          </div>

                          <div className="personal_Info_label_input">
                            <div className="personal_Info_label">
                              <span>Country Of Residence:</span>
                            </div>
                            <div className="personal_Info_input">
                              <select
                                className={this.inputView()}
                                disabled={
                                  this.props.showView ||
                                  (index === 1 && this.state.checked1)
                                }
                                name="country"
                                value={contactInfo?.country}
                                placeholder="Permenant Living"
                                autocomplete="true"
                                onChange={(e) =>
                                  this.contactinfoChanger(e, "country", index)
                                }
                                required
                              >
                                <option value="" disabled>
                                  {" "}
                                  Select Country{" "}
                                </option>
                                <option selected="" value="United States">
                                  United States
                                </option>
                                <option value="Canada">Canada</option>
                                <option value="Albania">Albania</option>
                                <option value="Algeria">Algeria</option>
                                <option value="American Samoa">
                                  American Samoa
                                </option>
                                <option value="Andorra">Andorra</option>
                                <option value="Angola">Angola</option>
                                <option value="Anguilla">Anguilla</option>
                                <option value="Antarctica">Antarctica</option>
                                <option value="Antigua">Antigua</option>
                                <option value="Argentina">Argentina</option>
                                <option value="Armenia">Armenia</option>
                                <option value="Aruba">Aruba</option>
                                <option value="Australia">Australia</option>
                                <option value="Austria">Austria</option>
                                <option value="Azerbaijani">Azerbaijani</option>
                                <option value="Bahamas">Bahamas</option>
                                <option value="Bahrain">Bahrain</option>
                                <option value="Bangladesh">Bangladesh</option>
                                <option value="Barbados">Barbados</option>
                                <option value="Belarus">Belarus</option>
                                <option value="Netherlands">Belgium</option>
                                <option value="Belize">Belize</option>
                                <option value="Benin">Benin</option>
                                <option value="Bermuda">Bermuda</option>
                                <option value="Bhutan">Bhutan</option>
                                <option value="Bolivia">Bolivia</option>
                                <option value="Bosnia-Hercegovina">
                                  Bosnia-Hercegovina
                                </option>
                                <option value="Botswana">Botswana</option>
                                <option value="Bouvet Island">
                                  Bouvet Island
                                </option>
                                <option value="Brazil">Brazil</option>
                                <option value="British IOT">British IOT</option>
                                <option value="Brunei Darussalam">
                                  Brunei Darussalam
                                </option>
                                <option value="Bulgaria">Bulgaria</option>
                                <option value="Burkina Faso">
                                  Burkina Faso
                                </option>
                                <option value="Burundi">Burundi</option>
                                <option value="Cambodia">Cambodia</option>
                                <option value="Cameroon">Cameroon</option>
                                <option value="Cape Verde">Cape Verde</option>
                                <option value="Cayman Islands">
                                  Cayman Islands
                                </option>
                                <option value="Central African Rep">
                                  Central African Rep
                                </option>
                                <option value="Chad">Chad</option>
                                <option value="Chile">Chile</option>
                                <option value="China">China</option>
                                <option value="Christmas Island">
                                  Christmas Island
                                </option>
                                <option value="Cocos Islands">
                                  Cocos Islands
                                </option>
                                <option value="Colombia">Colombia</option>
                                <option value="Comoros">Comoros</option>
                                <option value="Congo">Congo</option>
                                <option value="Cook Islands">
                                  Cook Islands
                                </option>
                                <option value="Costa Rica">Costa Rica</option>
                                <option value="Croatia">Croatia</option>
                                <option value="Cyprus">Cyprus</option>
                                <option value="Czech Republic">
                                  Czech Republic
                                </option>
                                <option value="Denmark">Denmark</option>
                                <option value="Djibouti">Djibouti</option>
                                <option value="Dominica">Dominica</option>
                                <option value="Dominican Republic">
                                  Dominican Republic
                                </option>
                                <option value="East Timor">East Timor</option>
                                <option value="Ecuador">Ecuador</option>
                                <option value="Egypt">Egypt</option>
                                <option value="El Salvador">El Salvador</option>
                                <option value="Equatorial Guinea">
                                  Equatorial Guinea
                                </option>
                                <option value="Eritrea">Eritrea</option>
                                <option value="Estonia">Estonia</option>
                                <option value="Ethiopia">Ethiopia</option>
                                <option value="Faeroe Islands">
                                  Faeroe Islands
                                </option>
                                <option value="Falkland Islands">
                                  Falkland Islands
                                </option>
                                <option value="Fiji">Fiji</option>
                                <option value="Finland">Finland</option>
                                <option value="France">France</option>
                                <option value="French Guiana">
                                  French Guiana
                                </option>
                                <option value="French Polynesia">
                                  French Polynesia
                                </option>
                                <option value="French Southern">
                                  French Southern
                                </option>
                                <option value="French Southern Ter">
                                  French Southern Ter
                                </option>
                                <option value="Gabon">Gabon</option>
                                <option value="Gambia">Gambia</option>
                                <option value="Georgia">Georgia</option>
                                <option value="Germany">Germany</option>
                                <option value="Ghana">Ghana</option>
                                <option value="Gibraltar">Gibraltar</option>
                                <option value="Greece">Greece</option>
                                <option value="Greenland">Greenland</option>
                                <option value="Grenada">Grenada</option>
                                <option value="Guadeloupe">Guadeloupe</option>
                                <option value="Guam">Guam</option>
                                <option value="Guatemala">Guatemala</option>
                                <option value="Guinea">Guinea</option>
                                <option value="Guinea-Bissau">
                                  Guinea-Bissau
                                </option>
                                <option value="Guyana">Guyana</option>
                                <option value="Haiti">Haiti</option>
                                <option value="Heard">Heard</option>
                                <option value="Honduras">Honduras</option>
                                <option value="Hong Kong">Hong Kong</option>
                                <option value="Hungary">Hungary</option>
                                <option value="Iceland">Iceland</option>
                                <option value="India">India</option>
                                <option value="Indonesia">Indonesia</option>
                                <option value="Ireland">Ireland</option>
                                <option value="Israel">Israel</option>
                                <option value="Italy">Italy</option>
                                <option value="Ivory Coast">Ivory Coast</option>
                                <option value="Jamaica">Jamaica</option>
                                <option value="Japan">Japan</option>
                                <option value="Jordan">Jordan</option>
                                <option value="Kazakhstan">Kazakhstan</option>
                                <option value="Kenya">Kenya</option>
                                <option value="Kiribati">Kiribati</option>
                                <option value="Kuwait">Kuwait</option>
                                <option value="Kyrgyzstan">Kyrgyzstan</option>
                                <option value="Laos">Laos</option>
                                <option value="Latvia">Latvia</option>
                                <option value="Lebanon">Lebanon</option>
                                <option value="Lesotho">Lesotho</option>
                                <option value="Liberia">Liberia</option>
                                <option value="Liechtenstein">
                                  Liechtenstein
                                </option>
                                <option value="Lithuania">Lithuania</option>
                                <option value="Luxembourg">Luxembourg</option>
                                <option value="Macau">Macau</option>
                                <option value="Macedonia">Macedonia</option>
                                <option value="Madagascar">Madagascar</option>
                                <option value="Malawi">Malawi</option>
                                <option value="Malaysia">Malaysia</option>
                                <option value="Maldives">Maldives</option>
                                <option value="Mali">Mali</option>
                                <option value="Malta">Malta</option>
                                <option value="Marshall Islands">
                                  Marshall Islands
                                </option>
                                <option value="Martinique">Martinique</option>
                                <option value="Mauritania">Mauritania</option>
                                <option value="Mauritius">Mauritius</option>
                                <option value="Mayotte">Mayotte</option>
                                <option value="Mexico">Mexico</option>
                                <option value="Micronesia">Micronesia</option>
                                <option value="MNP">MNP</option>
                                <option value="Moldova">Moldova</option>
                                <option value="Monaco">Monaco</option>
                                <option value="Mongolia">Mongolia</option>
                                <option value="Montserrat">Montserrat</option>
                                <option value="Morocco">Morocco</option>
                                <option value="Mozambique">Mozambique</option>
                                <option value="Myanmar">Myanmar</option>
                                <option value="Namibia">Namibia</option>
                                <option value="Nauru">Nauru</option>
                                <option value="Nepal">Nepal</option>
                                <option value="NER">NER</option>
                                <option value="Netherlands">Netherlands</option>
                                <option value="Neutral Zone">
                                  Neutral Zone
                                </option>
                                <option value="New Caledonia">
                                  New Caledonia
                                </option>
                                <option value="New Zealand">New Zealand</option>
                                <option value="Nicaragua">Nicaragua</option>
                                <option value="Nigeria">Nigeria</option>
                                <option value="Niue">Niue</option>
                                <option value="Norfolk Island">
                                  Norfolk Island
                                </option>
                                <option value="North Korea">North Korea</option>
                                <option value="Norway">Norway</option>
                                <option value="Oman">Oman</option>
                                <option value="Pakistan">Pakistan</option>
                                <option value="Palau">Palau</option>
                                <option value="Panama">Panama</option>
                                <option value="Papua New Guinea">
                                  Papua New Guinea
                                </option>
                                <option value="Paraguay">Paraguay</option>
                                <option value="Peru">Peru</option>
                                <option value="Philippines">Philippines</option>
                                <option value="Pitcairn">Pitcairn</option>
                                <option value="Poland">Poland</option>
                                <option value="Portugal">Portugal</option>
                                <option value="Puerto Rico">Puerto Rico</option>
                                <option value="Qatar">Qatar</option>
                                <option value="Reunion">Reunion</option>
                                <option value="Romania">Romania</option>
                                <option value="Russia">Russia</option>
                                <option value="Rwanda">Rwanda</option>
                                <option value="Saint Helena">
                                  Saint Helena
                                </option>
                                <option value="Saint Lucia">Saint Lucia</option>
                                <option value="Saint Pierre">
                                  Saint Pierre
                                </option>
                                <option value="Saint Vincent">
                                  Saint Vincent
                                </option>
                                <option value="Samoa">Samoa</option>
                                <option value="San Marino">San Marino</option>
                                <option value="Saudi Arabia">
                                  Saudi Arabia
                                </option>
                                <option value="Scotland">Scotland</option>
                                <option value="Senegal">Senegal</option>
                                <option value="Seychelles">Seychelles</option>
                                <option value="Sierra Leone">
                                  Sierra Leone
                                </option>
                                <option value="Singapore">Singapore</option>
                                <option value="Slovak Republic">
                                  Slovak Republic
                                </option>
                                <option value="Slovenia">Slovenia</option>
                                <option value="Solomon Islands">
                                  Solomon Islands
                                </option>
                                <option value="Somali Democratic">
                                  Somali Democratic
                                </option>
                                <option value="South Africa">
                                  South Africa
                                </option>
                                <option value="South Georgia">
                                  South Georgia
                                </option>
                                <option value="South Korea">South Korea</option>
                                <option value="Spain">Spain</option>
                                <option value="Sri Lanka">Sri Lanka</option>
                                <option value="St. Kitts and Nevis">
                                  St. Kitts and Nevis
                                </option>
                                <option value="STP">STP</option>
                                <option value="Suriname">Suriname</option>
                                <option value="Svalbard">Svalbard</option>
                                <option value="Swaziland">Swaziland</option>
                                <option value="Sweden">Sweden</option>
                                <option value="Switzerland">Switzerland</option>
                                <option value="Syria">Syria</option>
                                <option value="Taiwan">Taiwan</option>
                                <option value="Tajikistan">Tajikistan</option>
                                <option value="Tanzania">Tanzania</option>
                                <option value="TCA">TCA</option>
                                <option value="Thailand">Thailand</option>
                                <option value="Toga">Toga</option>
                                <option value="Togolese">Togolese</option>
                                <option value="Tokelau">Tokelau</option>
                                <option value="Tongo">Tongo</option>
                                <option value="Trinidad and Tobago">
                                  Trinidad and Tobago
                                </option>
                                <option value="Tunisia">Tunisia</option>
                                <option value="Turkey">Turkey</option>
                                <option value="Turkmenistan">
                                  Turkmenistan
                                </option>
                                <option value="Tuvalu">Tuvalu</option>
                                <option value="Uganda">Uganda</option>
                                <option value="Ukraine">Ukraine</option>
                                <option value="United Arab">United Arab</option>
                                <option value="United Kingdom">
                                  United Kingdom
                                </option>
                                <option value="Upper Volta">Upper Volta</option>
                                <option value="Uruguay">Uruguay</option>
                                <option value="USSR(Former)">
                                  USSR(Former)
                                </option>
                                <option value="Uzbekistan">Uzbekistan</option>
                                <option value="Vanuatu">Vanuatu</option>
                                <option value="Vatican City">
                                  Vatican City
                                </option>
                                <option value="Venezuela">Venezuela</option>
                                <option value="VI, British">VI, British</option>
                                <option value="Viet Nam">Viet Nam</option>
                                <option value="Virgin Islands, USA">
                                  Virgin Islands, USA
                                </option>
                                <option value="Western Sahara">
                                  Western Sahara
                                </option>
                                <option value="WLF">WLF</option>
                                <option value="Yemen">Yemen</option>
                                <option value="Yugoslavia">Yugoslavia</option>
                                <option value="Zaire">Zaire</option>
                                <option value="Zambia">Zambia</option>
                                <option value="Zimbabwe">Zimbabwe</option>
                              </select>
                            </div>
                            <div className="personal_Info_Error">
                              {index === 0 && country && (
                                <span className="Error">{country}</span>
                              )}{" "}
                              {this.state.checked1
                                ? ""
                                : index === 1 &&
                                  country1 && (
                                    <span className="Error">{country1}</span>
                                  )}
                            </div>
                          </div>
                          <div className="personal_Info_Error_Mobile">
                            {index === 0 && country && (
                              <span className="Error">{country}</span>
                            )}{" "}
                            {this.state.checked1
                              ? ""
                              : index === 1 &&
                                country1 && (
                                  <span className="Error">{country1}</span>
                                )}
                          </div>

                          <div className="personal_Info_label_input">
                            <div className="personal_Info_label">
                              <span>Nationality:</span>
                            </div>
                            <div className="personal_Info_input">
                              <input
                                className={this.inputView()}
                                disabled={
                                  this.props.showView ||
                                  (index === 1 && this.state.checked1)
                                }
                                name="nationality"
                                value={contactInfo?.nationality}
                                placeholder="Ex: Indian"
                                onChange={(e) =>
                                  this.contactinfoChanger(
                                    e,
                                    "nationality",
                                    index
                                  )
                                }
                              ></input>
                            </div>
                            <div className="personal_Info_Error">
                              {index === 0 && nationality && (
                                <span className="Error">{nationality}</span>
                              )}{" "}
                              {this.state.checked1
                                ? ""
                                : index === 1 &&
                                  nationality1 && (
                                    <span className="Error">
                                      {nationality1}
                                    </span>
                                  )}
                            </div>
                          </div>
                          <div className="personal_Info_Error_Mobile">
                            {index === 0 && nationality && (
                              <span className="Error">{nationality}</span>
                            )}{" "}
                            {this.state.checked1
                              ? ""
                              : index === 1 &&
                                nationality1 && (
                                  <span className="Error">{nationality1}</span>
                                )}
                          </div>

                          <div className="personal_Info_label_input">
                            <div className="personal_Info_label">
                              <span>Mobile:</span>
                            </div>
                            <div className="personal_Info_input">
                              <input
                                className={this.inputView()}
                                disabled={
                                  this.props.showView ||
                                  (index === 1 && this.state.checked1)
                                }
                                name="phone"
                                value={contactInfo?.phone}
                                placeholder="Mobile"
                                onChange={(e) =>
                                  this.contactinfoChanger(e, "phone", index)
                                }
                              ></input>
                            </div>
                            <div className="personal_Info_Error">
                              {index === 0 && phone && (
                                <span className="Error">{phone}</span>
                              )}{" "}
                              {this.state.checked1
                                ? ""
                                : index === 1 &&
                                  phone1 && (
                                    <span className="Error">{phone1}</span>
                                  )}
                            </div>
                          </div>
                          <div className="personal_Info_Error_Mobile">
                            {index === 0 && phone && (
                              <span className="Error">{phone}</span>
                            )}{" "}
                            {this.state.checked1
                              ? ""
                              : index === 1 &&
                                phone1 && (
                                  <span className="Error">{phone1}</span>
                                )}
                          </div>

                          <div className="personal_Info_label_input">
                            <div className="personal_Info_label">
                              <span>Alternate Contact 1:</span>
                            </div>
                            <div className="personal_Info_input">
                              <input
                                className={this.inputView()}
                                disabled={
                                  this.props.showView ||
                                  (index === 1 && this.state.checked1)
                                }
                                name="EmergencyContact"
                                value={contactInfo?.emergencyContact}
                                placeholder="Mobile"
                                onChange={(e) =>
                                  this.contactinfoChanger(
                                    e,
                                    "emergencyContact",
                                    index
                                  )
                                }
                              ></input>
                            </div>
                            <div className="personal_Info_Error">
                              {index === 0 && emergencyContact && (
                                <span className="Error">
                                  {emergencyContact}
                                </span>
                              )}{" "}
                              {this.state.checked1
                                ? ""
                                : index === 1 &&
                                  emergencyContact1 && (
                                    <span className="Error">
                                      {emergencyContact1}
                                    </span>
                                  )}
                            </div>
                          </div>
                          <div className="personal_Info_Error_Mobile">
                            {index === 0 && emergencyContact && (
                              <span className="Error">{emergencyContact}</span>
                            )}{" "}
                            {this.state.checked1
                              ? ""
                              : index === 1 &&
                                emergencyContact1 && (
                                  <span className="Error">
                                    {emergencyContact1}
                                  </span>
                                )}
                          </div>

                          <div className="personal_Info_label_input">
                            <div className="personal_Info_label">
                              <span>Alternate Contact 2:</span>
                            </div>
                            <div className="personal_Info_input">
                              <input
                                className={this.inputView()}
                                disabled={
                                  this.props.showView ||
                                  (index === 1 && this.state.checked1)
                                }
                                name="AlternateContact"
                                value={contactInfo?.alternateContact}
                                placeholder="Mobile"
                                onChange={(e) =>
                                  this.contactinfoChanger(
                                    e,
                                    "alternateContact",
                                    index
                                  )
                                }
                              ></input>
                            </div>
                            <div className="personal_Info_Error">
                              {index === 0 && alternateContact && (
                                <span className="Error">
                                  {alternateContact}
                                </span>
                              )}{" "}
                              {this.state.checked1
                                ? ""
                                : index === 1 &&
                                  alternateContact1 && (
                                    <span className="Error">
                                      {alternateContact1}
                                    </span>
                                  )}
                            </div>
                          </div>
                          <div className="personal_Info_Error_Mobile">
                            {index === 0 && alternateContact && (
                              <span className="Error">{alternateContact}</span>
                            )}{" "}
                            {this.state.checked1
                              ? ""
                              : index === 1 &&
                                alternateContact1 && (
                                  <span className="Error">
                                    {alternateContact1}
                                  </span>
                                )}
                          </div>
                          <br />
                        </div>
                        {index === 0 && this.props.showEdit && (
                          <div className="useSameAddress_">
                            <span>
                              {" "}
                              Use above address as permanent address:
                            </span>
                            <InputSwitch
                              style={{ marginLeft: "6px" }}
                              name="checked1"
                              checked={this.state.checked1}
                              onChange={this.checkedAddress}
                            />
                          </div>
                        )}
                      </div>
                    ))}
                </div>

                <div className="personal_Info">
                  <h1 className="Form55">Education Information</h1>
                  <div className="personal_Info_fields">
                    <div className="personal_Info_label_input">
                      <div className="personal_Info_label">
                        <span>Degree:</span>
                      </div>
                      <div className="personal_Info_input">
                        <input
                          className={this.inputView()}
                          disabled={this.props.showView}
                          name="Degree"
                          value={this.state.profile1.educationInfo?.degree}
                          placeholder="Degree"
                          onChange={(e) =>
                            this.educationInfoChanger(e, "degree")
                          }
                        ></input>
                      </div>
                      <div className="personal_Info_Error">
                        {degree && <span className="Error">{degree}</span>}
                      </div>
                    </div>
                    <div className="personal_Info_Error_Mobile">
                      {degree && <span className="Error">{degree}</span>}
                    </div>

                    <div className="personal_Info_label_input">
                      <div className="personal_Info_label">
                        <span>College Name:</span>
                      </div>
                      <div className="personal_Info_input">
                        <input
                          className={this.inputView()}
                          disabled={this.props.showView}
                          name="collegeName"
                          value={this.state.profile1.educationInfo?.collegeName}
                          placeholder="College Name"
                          onChange={(e) =>
                            this.educationInfoChanger(e, "collegeName")
                          }
                        ></input>
                      </div>
                      <div className="personal_Info_Error">
                        {collegeName && (
                          <span className="Error">{collegeName}</span>
                        )}
                      </div>
                    </div>
                    <div className="personal_Info_Error_Mobile">
                      {collegeName && (
                        <span className="Error">{collegeName}</span>
                      )}
                    </div>

                    <div className="personal_Info_label_input">
                      <div className="personal_Info_label">
                        <span>University:</span>
                      </div>
                      <div className="personal_Info_input">
                        <input
                          className={this.inputView()}
                          disabled={this.props.showView}
                          name="universityName"
                          value={
                            this.state.profile1.educationInfo?.universityName
                          }
                          placeholder="University"
                          onChange={(e) =>
                            this.educationInfoChanger(e, "universityName")
                          }
                        ></input>
                      </div>
                      <div className="personal_Info_Error">
                        {universityName && (
                          <span className="Error">{universityName}</span>
                        )}
                      </div>
                    </div>
                    <div className="personal_Info_Error_Mobile">
                      {universityName && (
                        <span className="Error">{universityName}</span>
                      )}
                    </div>

                    <div className="personal_Info_label_input">
                      <div className="personal_Info_label">
                        <span>Subject:</span>
                      </div>
                      <div className="personal_Info_input">
                        <input
                          className={this.inputView()}
                          disabled={this.props.showView}
                          name="subject"
                          value={this.state.profile1.educationInfo?.subject}
                          placeholder="Subject"
                          onChange={(e) =>
                            this.educationInfoChanger(e, "subject")
                          }
                        ></input>
                      </div>
                      <div className="personal_Info_Error">
                        {subject && <span className="Error">{subject}</span>}
                      </div>
                    </div>

                    <div className="personal_Info_label_input">
                      <div className="personal_Info_label">
                        <span>Course Start Date:</span>
                      </div>
                      <div className="personal_Info_input">
                        <input
                          type="date"
                          className={this.inputView()}
                          min="1930-01-01"
                          max={this.state.currentdate}
                          disabled={this.props.showView}
                          value={
                            this.state.profile1.educationInfo?.courseStartDate?.split(
                              "T"
                            )[0]
                          }
                          placeholder="Course Start Date"
                          onChange={(e) =>
                            this.educationInfoChanger(e, "courseStartDate")
                          }
                        ></input>
                      </div>
                      <div className="personal_Info_Error">
                        {courseStartDate && (
                          <span className="Error">{courseStartDate}</span>
                        )}
                      </div>
                    </div>
                    <div className="personal_Info_Error_Mobile">
                      {courseStartDate && (
                        <span className="Error">{courseStartDate}</span>
                      )}
                    </div>

                    <div className="personal_Info_label_input">
                      <div className="personal_Info_label">
                        <span>Course End Date:</span>
                      </div>
                      <div className="personal_Info_input">
                        <input
                          type="date"
                          className={this.inputView()}
                          min="1930-01-01"
                          max="2021-12-31"
                          disabled={this.props.showView}
                          value={
                            this.state.profile1.educationInfo?.courseEndDate?.split(
                              "T"
                            )[0]
                          }
                          placeholder="Course End Date"
                          onChange={(e) =>
                            this.educationInfoChanger(e, "courseEndDate")
                          }
                        ></input>
                      </div>
                      <div className="personal_Info_Error">
                        {courseEndDate && (
                          <span className="Error">{courseEndDate}</span>
                        )}
                      </div>
                    </div>
                    <div className="personal_Info_Error_Mobile">
                      {courseEndDate && (
                        <span className="Error">{courseEndDate}</span>
                      )}
                    </div>

                    <div className="personal_Info_label_input">
                      <div className="personal_Info_label">
                        <span>Course Type:</span>
                      </div>
                      <div className="personal_Info_input">
                        <input
                          className={this.inputView()}
                          disabled={this.props.showView}
                          name="courseType"
                          value={this.state.profile1.educationInfo?.courseType}
                          placeholder="Course Type"
                          onChange={(e) =>
                            this.educationInfoChanger(e, "courseType")
                          }
                        ></input>
                      </div>
                      <div className="personal_Info_Error">
                        {courseType && (
                          <span className="Error">{courseType}</span>
                        )}
                      </div>
                    </div>
                    <div className="personal_Info_Error_Mobile">
                      {courseType && (
                        <span className="Error">{courseType}</span>
                      )}
                    </div>

                    <div className="personal_Info_label_input">
                      <div className="personal_Info_label">
                        <span>Address Of Institution:</span>
                      </div>
                      <div className="personal_Info_input">
                        {/* <input
                      className={this.inputView()}
                      disabled={this.props.showView}
                      name="addressOfInstitution"
                      value={
                        this.state.profile1.educationInfo?.addressOfInstitution
                      }
                      placeholder="Address Of Institution"
                      autocomplete="true"
                      onChange={(e) =>
                        this.educationInfoChanger(e, "addressOfInstitution")
                      }
                    ></input> */}
                        <InputTextarea
                          rows={2}
                          cols={20}
                          autoResize={true}
                          className={
                            this.props.showView
                              ? "Input_fields_textArea_disable"
                              : "Input_fields_textArea"
                          }
                          type="text"
                          disabled={this.props.showView}
                          name="addressOfInstitution"
                          value={
                            this.state.profile1.educationInfo
                              ?.addressOfInstitution
                          }
                          placeholder="Address Of Institution"
                          autocomplete="true"
                          onChange={(e) =>
                            this.educationInfoChanger(e, "addressOfInstitution")
                          }
                        />
                      </div>
                      <div className="personal_Info_Error">
                        {addressOfInstitution && (
                          <span className="Error">{addressOfInstitution}</span>
                        )}
                      </div>
                    </div>
                    <div className="personal_Info_Error_Mobile">
                      {addressOfInstitution && (
                        <span className="Error">{addressOfInstitution}</span>
                      )}
                    </div>

                    <div className="personal_Info_label_input">
                      <div className="personal_Info_label">
                        <span>City:</span>
                      </div>
                      <div className="personal_Info_input">
                        <input
                          className={this.inputView()}
                          disabled={this.props.showView}
                          name="city"
                          value={this.state.profile1.educationInfo?.city}
                          placeholder="City"
                          autocomplete="true"
                          onChange={(e) => this.educationInfoChanger(e, "city")}
                        ></input>
                      </div>
                      <div className="personal_Info_Error">
                        {cityeducatio && (
                          <span className="Error">{cityeducatio}</span>
                        )}
                      </div>
                    </div>
                    <div className="personal_Info_Error_Mobile">
                      {cityeducatio && (
                        <span className="Error">{cityeducatio}</span>
                      )}
                    </div>

                    <div className="personal_Info_label_input">
                      <div className="personal_Info_label">
                        <span>Pincode:</span>
                      </div>
                      <div className="personal_Info_input">
                        <input
                          className={this.inputView()}
                          disabled={this.props.showView}
                          name="pinCode"
                          value={this.state.profile1.educationInfo?.pinCode}
                          placeholder="Pincode"
                          autocomplete="true"
                          onChange={(e) =>
                            this.educationInfoChanger(e, "pinCode")
                          }
                        ></input>
                      </div>
                      <div className="personal_Info_Error">
                        {educationInfopinCode && (
                          <span className="Error">{educationInfopinCode}</span>
                        )}
                      </div>
                    </div>
                    <div className="personal_Info_Error_Mobile">
                      {educationInfopinCode && (
                        <span className="Error">{educationInfopinCode}</span>
                      )}
                    </div>

                    <div className="personal_Info_label_input">
                      <div className="personal_Info_label">
                        <span>Landline 1 (optional):</span>
                      </div>
                      <div className="personal_Info_input">
                        <input
                          className={this.inputView()}
                          disabled={this.props.showView}
                          name="landline1"
                          value={this.state.profile1.educationInfo?.landline1}
                          placeholder="Landline"
                          onChange={(e) =>
                            this.educationInfoChanger(e, "landline1")
                          }
                        ></input>
                      </div>
                      <div className="personal_Info_Error">
                        {landline1 && (
                          <span className="Error">{landline1}</span>
                        )}
                      </div>
                    </div>
                    <div className="personal_Info_Error_Mobile">
                      {landline1 && <span className="Error">{landline1}</span>}
                    </div>

                    <div className="personal_Info_label_input">
                      <div className="personal_Info_label">
                        <span>Landline 2 (optional):</span>
                      </div>
                      <div className="personal_Info_input">
                        <input
                          className={this.inputView()}
                          disabled={this.props.showView}
                          name="landline2"
                          value={this.state.profile1.educationInfo?.landline2}
                          placeholder="Landline"
                          onChange={(e) =>
                            this.educationInfoChanger(e, "landline2")
                          }
                        ></input>
                      </div>
                      <div className="personal_Info_Error">
                        {landline2 && (
                          <span className="Error">{landline2}</span>
                        )}
                      </div>
                    </div>
                    <div className="personal_Info_Error_Mobile">
                      {landline2 && <span className="Error">{landline2}</span>}
                    </div>
                  </div>
                </div>

                <div>
                  <div className="personal_Info">
                    {/* ) : null} */}
                    {this.state.profile1.employmentInfo?.length > 0 && (
                      <h1 className="Form55">Employment Information</h1>
                    )}
                    {this.state.profile1.employmentInfo?.map(
                      (empInfo, index) => {
                        return (
                          <>
                            <div key={index}>
                              <div
                                key={index}
                                className="personal_Info_fields"
                                style={{ marginTop: index !== 0 && "20px" }}
                              >
                                <div className="personal_Info_label_input">
                                  <div className="personal_Info_label">
                                    <span>Company Name:</span>
                                  </div>
                                  <div className="personal_Info_input">
                                    <input
                                      className={this.inputView()}
                                      disabled={this.props.showView}
                                      name="companyName"
                                      value={empInfo.companyName}
                                      placeholder="Company"
                                      autocomplete="true"
                                      onChange={(e) =>
                                        this.employementInfoChanger(
                                          e,
                                          "companyName",
                                          index
                                        )
                                      }
                                    ></input>
                                  </div>
                                  <div className="personal_Info_Error">
                                    {index == 0 && (
                                      <span className="Error">
                                        {companyName}
                                      </span>
                                    )}
                                    {index == 1 && (
                                      <span className="Error">
                                        {exp1_companyName}
                                      </span>
                                    )}
                                    {index == 2 && (
                                      <span className="Error">
                                        {exp2_companyName}
                                      </span>
                                    )}
                                    {index == 3 && (
                                      <span className="Error">
                                        {exp3_companyName}
                                      </span>
                                    )}
                                    {index == 4 && (
                                      <span className="Error">
                                        {exp4_companyName}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="personal_Info_Error_Mobile">
                                  {index == 0 && (
                                    <span className="Error">{companyName}</span>
                                  )}
                                  {index == 1 && (
                                    <span className="Error">
                                      {exp1_companyName}
                                    </span>
                                  )}
                                  {index == 2 && (
                                    <span className="Error">
                                      {exp2_companyName}
                                    </span>
                                  )}
                                  {index == 3 && (
                                    <span className="Error">
                                      {exp3_companyName}
                                    </span>
                                  )}
                                  {index == 4 && (
                                    <span className="Error">
                                      {exp4_companyName}
                                    </span>
                                  )}
                                </div>

                                <div className="personal_Info_label_input">
                                  <div className="personal_Info_label">
                                    <span>Employed Place:</span>
                                  </div>
                                  <div className="personal_Info_input">
                                    <input
                                      className={this.inputView()}
                                      disabled={this.props.showView}
                                      name="employedPlace"
                                      value={empInfo.employedPlace}
                                      placeholder="Place"
                                      onChange={(e) =>
                                        this.employementInfoChanger(
                                          e,
                                          "employedPlace",
                                          index
                                        )
                                      }
                                    ></input>
                                  </div>
                                  <div className="personal_Info_Error">
                                    {index == 0 && (
                                      <span className="Error">
                                        {employedPlace}
                                      </span>
                                    )}
                                    {index == 1 && (
                                      <span className="Error">
                                        {exp1_employedPlace}
                                      </span>
                                    )}
                                    {index == 2 && (
                                      <span className="Error">
                                        {exp2_employedPlace}
                                      </span>
                                    )}
                                    {index == 3 && (
                                      <span className="Error">
                                        {exp3_employedPlace}
                                      </span>
                                    )}
                                    {index == 4 && (
                                      <span className="Error">
                                        {exp4_employedPlace}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="personal_Info_Error_Mobile">
                                  {index == 0 && (
                                    <span className="Error">
                                      {employedPlace}
                                    </span>
                                  )}
                                  {index == 1 && (
                                    <span className="Error">
                                      {exp1_employedPlace}
                                    </span>
                                  )}
                                  {index == 2 && (
                                    <span className="Error">
                                      {exp2_employedPlace}
                                    </span>
                                  )}
                                  {index == 3 && (
                                    <span className="Error">
                                      {exp3_employedPlace}
                                    </span>
                                  )}
                                  {index == 4 && (
                                    <span className="Error">
                                      {exp4_employedPlace}
                                    </span>
                                  )}
                                </div>

                                <div className="personal_Info_label_input">
                                  <div className="personal_Info_label">
                                    <span>Company Address:</span>
                                  </div>
                                  <div className="personal_Info_input">
                                    <InputTextarea
                                      rows={2}
                                      cols={20}
                                      autoResize={true}
                                      className={
                                        this.props.showView
                                          ? "Input_fields_textArea_disable"
                                          : "Input_fields_textArea"
                                      }
                                      disabled={this.props.showView}
                                      name="companyAddress"
                                      value={empInfo.companyAddress}
                                      placeholder="Company Address"
                                      autocomplete="true"
                                      onChange={(e) =>
                                        this.employementInfoChanger(
                                          e,
                                          "companyAddress",
                                          index
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="personal_Info_Error">
                                    {index == 0 && (
                                      <span className="Error">
                                        {companyAddress}
                                      </span>
                                    )}
                                    {index == 1 && (
                                      <span className="Error">
                                        {exp1_companyAddress}
                                      </span>
                                    )}
                                    {index == 2 && (
                                      <span className="Error">
                                        {exp2_companyAddress}
                                      </span>
                                    )}
                                    {index == 3 && (
                                      <span className="Error">
                                        {exp3_companyAddress}
                                      </span>
                                    )}
                                    {index == 4 && (
                                      <span className="Error">
                                        {exp4_companyAddress}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="personal_Info_Error_Mobile">
                                  {index == 0 && (
                                    <span className="Error">
                                      {companyAddress}
                                    </span>
                                  )}
                                  {index == 1 && (
                                    <span className="Error">
                                      {exp1_companyAddress}
                                    </span>
                                  )}
                                  {index == 2 && (
                                    <span className="Error">
                                      {exp2_companyAddress}
                                    </span>
                                  )}
                                  {index == 3 && (
                                    <span className="Error">
                                      {exp3_companyAddress}
                                    </span>
                                  )}
                                  {index == 4 && (
                                    <span className="Error">
                                      {exp4_companyAddress}
                                    </span>
                                  )}
                                </div>

                                <div className="personal_Info_label_input">
                                  <div className="personal_Info_label">
                                    <span>City:</span>
                                  </div>
                                  <div className="personal_Info_input">
                                    <input
                                      className={this.inputView()}
                                      disabled={this.props.showView}
                                      name="city"
                                      value={empInfo.city}
                                      placeholder="City"
                                      autocomplete="true"
                                      onChange={(e) =>
                                        this.employementInfoChanger(
                                          e,
                                          "city",
                                          index
                                        )
                                      }
                                    ></input>
                                  </div>
                                  <div className="personal_Info_Error">
                                    {index == 0 && (
                                      <span className="Error">
                                        {cityemployment}
                                      </span>
                                    )}
                                    {index == 1 && (
                                      <span className="Error">
                                        {exp1_cityemployment}
                                      </span>
                                    )}
                                    {index == 2 && (
                                      <span className="Error">
                                        {exp2_cityemployment}
                                      </span>
                                    )}
                                    {index == 3 && (
                                      <span className="Error">
                                        {exp3_cityemployment}
                                      </span>
                                    )}
                                    {index == 4 && (
                                      <span className="Error">
                                        {exp4_cityemployment}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="personal_Info_Error_Mobile">
                                  {index == 0 && (
                                    <span className="Error">
                                      {cityemployment}
                                    </span>
                                  )}
                                  {index == 1 && (
                                    <span className="Error">
                                      {exp1_cityemployment}
                                    </span>
                                  )}
                                  {index == 2 && (
                                    <span className="Error">
                                      {exp2_cityemployment}
                                    </span>
                                  )}
                                  {index == 3 && (
                                    <span className="Error">
                                      {exp3_cityemployment}
                                    </span>
                                  )}
                                  {index == 4 && (
                                    <span className="Error">
                                      {exp4_cityemployment}
                                    </span>
                                  )}
                                </div>
                                <div className="personal_Info_label_input">
                                  <div className="personal_Info_label">
                                    <span>State:</span>
                                  </div>
                                  <div className="personal_Info_input">
                                    <select
                                      className={this.inputView()}
                                      disabled={this.props.showView}
                                      name="state"
                                      value={empInfo.state}
                                      placeholder="State"
                                      autocomplete="true"
                                      onChange={(e) =>
                                        this.employementInfoChanger(
                                          e,
                                          "state",
                                          index
                                        )
                                      }
                                    >
                                      <option value="" disabled>
                                        Select State
                                      </option>
                                      <option value="Andhra Pradesh">
                                        Andhra Pradesh
                                      </option>
                                      <option value="Andaman and Nicobar Islands">
                                        Andaman and Nicobar Islands
                                      </option>
                                      <option value="Arunachal Pradesh">
                                        Arunachal Pradesh
                                      </option>
                                      <option value="Assam">Assam</option>
                                      <option value="Bihar">Bihar</option>
                                      <option value="Chandigarh">
                                        Chandigarh
                                      </option>
                                      <option value="Chhattisgarh">
                                        Chhattisgarh
                                      </option>
                                      <option value="Dadar and Nagar Haveli">
                                        Dadar and Nagar Haveli
                                      </option>
                                      <option value="Daman and Diu">
                                        Daman and Diu
                                      </option>
                                      <option value="Delhi">Delhi</option>
                                      <option value="Lakshadweep">
                                        Lakshadweep
                                      </option>
                                      <option value="Puducherry">
                                        Puducherry
                                      </option>
                                      <option value="Goa">Goa</option>
                                      <option value="Gujarat">Gujarat</option>
                                      <option value="Haryana">Haryana</option>
                                      <option value="Himachal Pradesh">
                                        Himachal Pradesh
                                      </option>
                                      <option value="Jammu and Kashmir">
                                        Jammu and Kashmir
                                      </option>
                                      <option value="Jharkhand">
                                        Jharkhand
                                      </option>
                                      <option value="Karnataka">
                                        Karnataka
                                      </option>
                                      <option value="Kerala">Kerala</option>
                                      <option value="Madhya Pradesh">
                                        Madhya Pradesh
                                      </option>
                                      <option value="Maharashtra">
                                        Maharashtra
                                      </option>
                                      <option value="Manipur">Manipur</option>
                                      <option value="Meghalaya">
                                        Meghalaya
                                      </option>
                                      <option value="Mizoram">Mizoram</option>
                                      <option value="Nagaland">Nagaland</option>
                                      <option value="Odisha">Odisha</option>
                                      <option value="Punjab">Punjab</option>
                                      <option value="Rajasthan">
                                        Rajasthan
                                      </option>
                                      <option value="Sikkim">Sikkim</option>
                                      <option value="Tamil Nadu">
                                        Tamil Nadu
                                      </option>
                                      <option value="Telangana">
                                        Telangana
                                      </option>
                                      <option value="Tripura">Tripura</option>
                                      <option value="Uttar Pradesh">
                                        Uttar Pradesh
                                      </option>
                                      <option value="Uttarakhand">
                                        Uttarakhand
                                      </option>
                                      <option value="West Bengal">
                                        West Bengal
                                      </option>
                                    </select>
                                    {/* <input
                                className={this.inputView()}
                                disabled={this.props.showView}
                                name="state"
                                value={empInfo.state}
                                placeholder="State"
                                autocomplete="true"
                                onChange={(e) =>
                                  this.employementInfoChanger(e, "state", index)
                                }
                              ></input> */}
                                  </div>
                                  <div className="personal_Info_Error">
                                    {index == 0 && (
                                      <span className="Error">
                                        {stateemployment}
                                      </span>
                                    )}
                                    {index == 1 && (
                                      <span className="Error">
                                        {exp1_stateemployment}
                                      </span>
                                    )}
                                    {index == 2 && (
                                      <span className="Error">
                                        {exp2_stateemployment}
                                      </span>
                                    )}
                                    {index == 3 && (
                                      <span className="Error">
                                        {exp3_stateemployment}
                                      </span>
                                    )}
                                    {index == 4 && (
                                      <span className="Error">
                                        {exp4_stateemployment}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="personal_Info_Error_Mobile">
                                  {index == 0 && (
                                    <span className="Error">
                                      {stateemployment}
                                    </span>
                                  )}
                                  {index == 1 && (
                                    <span className="Error">
                                      {exp1_stateemployment}
                                    </span>
                                  )}
                                  {index == 2 && (
                                    <span className="Error">
                                      {exp2_stateemployment}
                                    </span>
                                  )}
                                  {index == 3 && (
                                    <span className="Error">
                                      {exp3_stateemployment}
                                    </span>
                                  )}
                                  {index == 4 && (
                                    <span className="Error">
                                      {exp4_stateemployment}
                                    </span>
                                  )}
                                </div>
                                <div className="personal_Info_label_input">
                                  <div className="personal_Info_label">
                                    <span>Pincode:</span>
                                  </div>
                                  <div className="personal_Info_input">
                                    <input
                                      className={this.inputView()}
                                      disabled={this.props.showView}
                                      name="pincode"
                                      value={empInfo.pincode}
                                      placeholder="Pincode"
                                      autocomplete="true"
                                      onChange={(e) =>
                                        this.employementInfoChanger(
                                          e,
                                          "pincode",
                                          index
                                        )
                                      }
                                    ></input>
                                  </div>
                                  <div className="personal_Info_Error">
                                    {index == 0 && (
                                      <span className="Error">
                                        {pincodeemployement}
                                      </span>
                                    )}
                                    {index == 1 && (
                                      <span className="Error">
                                        {exp1_pincodeemployement}
                                      </span>
                                    )}
                                    {index == 2 && (
                                      <span className="Error">
                                        {exp2_pincodeemployement}
                                      </span>
                                    )}
                                    {index == 3 && (
                                      <span className="Error">
                                        {exp3_pincodeemployement}
                                      </span>
                                    )}
                                    {index == 4 && (
                                      <span className="Error">
                                        {exp4_pincodeemployement}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="personal_Info_Error_Mobile">
                                  {index == 0 && (
                                    <span className="Error">
                                      {pincodeemployement}
                                    </span>
                                  )}
                                  {index == 1 && (
                                    <span className="Error">
                                      {exp1_pincodeemployement}
                                    </span>
                                  )}
                                  {index == 2 && (
                                    <span className="Error">
                                      {exp2_pincodeemployement}
                                    </span>
                                  )}
                                  {index == 3 && (
                                    <span className="Error">
                                      {exp3_pincodeemployement}
                                    </span>
                                  )}
                                  {index == 4 && (
                                    <span className="Error">
                                      {exp4_pincodeemployement}
                                    </span>
                                  )}
                                </div>

                                <div className="personal_Info_label_input">
                                  <div className="personal_Info_label">
                                    <span>From Date:</span>
                                  </div>
                                  <div className="personal_Info_input">
                                    <input
                                      type="date"
                                      className={this.inputView()}
                                      min="2000-12-31"
                                      max={this.state.currentdate}
                                      disabled={this.props.showView}
                                      value={empInfo.fromDate?.split("T")[0]}
                                      placeholder="From Date"
                                      autocomplete="true"
                                      onChange={(e) =>
                                        this.employementInfoChanger(
                                          e,
                                          "fromDate",
                                          index
                                        )
                                      }
                                    ></input>
                                  </div>
                                  <div className="personal_Info_Error">
                                    {index == 0 && (
                                      <span className="Error">{fromDate}</span>
                                    )}
                                    {index == 1 && (
                                      <span className="Error">
                                        {exp1_fromDate}
                                      </span>
                                    )}
                                    {index == 2 && (
                                      <span className="Error">
                                        {exp2_fromDate}
                                      </span>
                                    )}
                                    {index == 3 && (
                                      <span className="Error">
                                        {exp3_fromDate}
                                      </span>
                                    )}
                                    {index == 4 && (
                                      <span className="Error">
                                        {exp4_fromDate}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="personal_Info_Error_Mobile">
                                  {index == 0 && (
                                    <span className="Error">{fromDate}</span>
                                  )}
                                  {index == 1 && (
                                    <span className="Error">
                                      {exp1_fromDate}
                                    </span>
                                  )}
                                  {index == 2 && (
                                    <span className="Error">
                                      {exp2_fromDate}
                                    </span>
                                  )}
                                  {index == 3 && (
                                    <span className="Error">
                                      {exp3_fromDate}
                                    </span>
                                  )}
                                  {index == 4 && (
                                    <span className="Error">
                                      {exp4_fromDate}
                                    </span>
                                  )}
                                </div>

                                <div className="personal_Info_label_input">
                                  <div className="personal_Info_label">
                                    <span>To Date:</span>
                                  </div>
                                  <div className="personal_Info_input">
                                    <input
                                      type="date"
                                      className={this.inputView()}
                                      max={this.state.currentdate}
                                      min={empInfo.fromDate}
                                      disabled={this.props.showView}
                                      value={empInfo.toDate?.split("T")[0]}
                                      placeholder="To Date"
                                      autocomplete="true"
                                      onChange={(e) =>
                                        this.employementInfoChanger(
                                          e,
                                          "toDate",
                                          index
                                        )
                                      }
                                    ></input>
                                  </div>
                                  <div className="personal_Info_Error">
                                    {index == 0 && (
                                      <span className="Error">{toDate}</span>
                                    )}
                                    {index == 1 && (
                                      <span className="Error">
                                        {exp1_toDate}
                                      </span>
                                    )}
                                    {index == 2 && (
                                      <span className="Error">
                                        {exp2_toDate}
                                      </span>
                                    )}
                                    {index == 3 && (
                                      <span className="Error">
                                        {exp3_toDate}
                                      </span>
                                    )}
                                    {index == 4 && (
                                      <span className="Error">
                                        {exp4_toDate}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="personal_Info_Error_Mobile">
                                  {index == 0 && (
                                    <span className="Error">{toDate}</span>
                                  )}
                                  {index == 1 && (
                                    <span className="Error">{exp1_toDate}</span>
                                  )}
                                  {index == 2 && (
                                    <span className="Error">{exp2_toDate}</span>
                                  )}
                                  {index == 3 && (
                                    <span className="Error">{exp3_toDate}</span>
                                  )}
                                  {index == 4 && (
                                    <span className="Error">{exp4_toDate}</span>
                                  )}
                                </div>

                                <div className="personal_Info_label_input">
                                  <div className="personal_Info_label">
                                    <span>Designation:</span>
                                  </div>
                                  <div className="personal_Info_input">
                                    <input
                                      className={this.inputView()}
                                      disabled={this.props.showView}
                                      name="designation"
                                      value={empInfo.designation}
                                      placeholder="Designation"
                                      autocomplete="true"
                                      onChange={(e) =>
                                        this.employementInfoChanger(
                                          e,
                                          "designation",
                                          index
                                        )
                                      }
                                    ></input>
                                  </div>
                                  <div className="personal_Info_Error">
                                    {index == 0 && (
                                      <span className="Error">
                                        {designation}
                                      </span>
                                    )}
                                    {index == 1 && (
                                      <span className="Error">
                                        {exp1_designation}
                                      </span>
                                    )}
                                    {index == 2 && (
                                      <span className="Error">
                                        {exp2_designation}
                                      </span>
                                    )}
                                    {index == 3 && (
                                      <span className="Error">
                                        {exp3_designation}
                                      </span>
                                    )}
                                    {index == 4 && (
                                      <span className="Error">
                                        {exp4_designation}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="personal_Info_Error_Mobile">
                                  {index == 0 && (
                                    <span className="Error">{designation}</span>
                                  )}
                                  {index == 1 && (
                                    <span className="Error">
                                      {exp1_designation}
                                    </span>
                                  )}
                                  {index == 2 && (
                                    <span className="Error">
                                      {exp2_designation}
                                    </span>
                                  )}
                                  {index == 3 && (
                                    <span className="Error">
                                      {exp3_designation}
                                    </span>
                                  )}
                                  {index == 4 && (
                                    <span className="Error">
                                      {exp4_designation}
                                    </span>
                                  )}
                                </div>

                                <div className="personal_Info_label_input">
                                  <div className="personal_Info_label">
                                    <span>Remuneration:</span>
                                  </div>
                                  <div className="personal_Info_input">
                                    <input
                                      className={this.inputView()}
                                      disabled={this.props.showView}
                                      name="remuneration"
                                      value={empInfo.remuneration}
                                      placeholder="Salary"
                                      autocomplete="true"
                                      onChange={(e) =>
                                        this.employementInfoChanger(
                                          e,
                                          "remuneration",
                                          index
                                        )
                                      }
                                    ></input>
                                  </div>
                                  <div className="personal_Info_Error">
                                    {index == 0 && (
                                      <span className="Error">
                                        {remuneration}
                                      </span>
                                    )}
                                    {index == 1 && (
                                      <span className="Error">
                                        {exp1_remuneration}
                                      </span>
                                    )}
                                    {index == 2 && (
                                      <span className="Error">
                                        {exp2_remuneration}
                                      </span>
                                    )}
                                    {index == 3 && (
                                      <span className="Error">
                                        {exp3_remuneration}
                                      </span>
                                    )}
                                    {index == 4 && (
                                      <span className="Error">
                                        {exp4_remuneration}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="personal_Info_Error_Mobile">
                                  {index == 0 && (
                                    <span className="Error">
                                      {remuneration}
                                    </span>
                                  )}
                                  {index == 1 && (
                                    <span className="Error">
                                      {exp1_remuneration}
                                    </span>
                                  )}
                                  {index == 2 && (
                                    <span className="Error">
                                      {exp2_remuneration}
                                    </span>
                                  )}
                                  {index == 3 && (
                                    <span className="Error">
                                      {exp3_remuneration}
                                    </span>
                                  )}
                                  {index == 4 && (
                                    <span className="Error">
                                      {exp4_remuneration}
                                    </span>
                                  )}
                                </div>

                                <div className="personal_Info_label_input">
                                  <div className="personal_Info_label">
                                    <span>Employee Code:</span>
                                  </div>
                                  <div className="personal_Info_input">
                                    <input
                                      className={this.inputView()}
                                      disabled={this.props.showView}
                                      name="employeeCode"
                                      value={empInfo.employeeCode}
                                      placeholder="Employee Code"
                                      autocomplete="true"
                                      onChange={(e) =>
                                        this.employementInfoChanger(
                                          e,
                                          "employeeCode",
                                          index
                                        )
                                      }
                                    ></input>
                                  </div>
                                  <div className="personal_Info_Error">
                                    {index == 0 && (
                                      <span className="Error">
                                        {employeeCode}
                                      </span>
                                    )}
                                    {index == 1 && (
                                      <span className="Error">
                                        {exp1_employeeCode}
                                      </span>
                                    )}
                                    {index == 2 && (
                                      <span className="Error">
                                        {exp2_employeeCode}
                                      </span>
                                    )}
                                    {index == 3 && (
                                      <span className="Error">
                                        {exp3_employeeCode}
                                      </span>
                                    )}
                                    {index == 4 && (
                                      <span className="Error">
                                        {exp4_employeeCode}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="personal_Info_Error_Mobile">
                                  {index == 0 && (
                                    <span className="Error">
                                      {employeeCode}
                                    </span>
                                  )}
                                  {index == 1 && (
                                    <span className="Error">
                                      {exp1_employeeCode}
                                    </span>
                                  )}
                                  {index == 2 && (
                                    <span className="Error">
                                      {exp2_employeeCode}
                                    </span>
                                  )}
                                  {index == 3 && (
                                    <span className="Error">
                                      {exp3_employeeCode}
                                    </span>
                                  )}
                                  {index == 4 && (
                                    <span className="Error">
                                      {exp4_employeeCode}
                                    </span>
                                  )}
                                </div>

                                <div className="personal_Info_label_input">
                                  <div className="personal_Info_label">
                                    <span>Employment Nature:</span>
                                  </div>
                                  <div className="personal_Info_input">
                                    <input
                                      className={this.inputView()}
                                      disabled={this.props.showView}
                                      name="employmentNature"
                                      value={empInfo.employmentNature}
                                      placeholder="Employment Nature"
                                      autocomplete="true"
                                      onChange={(e) =>
                                        this.employementInfoChanger(
                                          e,
                                          "employmentNature",
                                          index
                                        )
                                      }
                                    ></input>
                                  </div>
                                  <div className="personal_Info_Error">
                                    {index == 0 && (
                                      <span className="Error">
                                        {employmentNature}
                                      </span>
                                    )}
                                    {index == 1 && (
                                      <span className="Error">
                                        {exp1_employmentNature}
                                      </span>
                                    )}
                                    {index == 2 && (
                                      <span className="Error">
                                        {exp2_employmentNature}
                                      </span>
                                    )}
                                    {index == 3 && (
                                      <span className="Error">
                                        {exp3_employmentNature}
                                      </span>
                                    )}
                                    {index == 4 && (
                                      <span className="Error">
                                        {exp4_employmentNature}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="personal_Info_Error_Mobile">
                                  {index == 0 && (
                                    <span className="Error">
                                      {employmentNature}
                                    </span>
                                  )}
                                  {index == 1 && (
                                    <span className="Error">
                                      {exp1_employmentNature}
                                    </span>
                                  )}
                                  {index == 2 && (
                                    <span className="Error">
                                      {exp2_employmentNature}
                                    </span>
                                  )}
                                  {index == 3 && (
                                    <span className="Error">
                                      {exp3_employmentNature}
                                    </span>
                                  )}
                                  {index == 4 && (
                                    <span className="Error">
                                      {exp4_employmentNature}
                                    </span>
                                  )}
                                </div>
                                <div
                                  className="personal_info_fields2"
                                  style={{ border: "none" }}
                                >
                                  <div className="personal_Info_label_input2">
                                    <div className="personal_Info_label">
                                      <span>Supervisor Name:</span>
                                    </div>
                                    <div className="personal_Info_input">
                                      <input
                                        className={this.inputView()}
                                        disabled={this.props.showView}
                                        name="supervisorName"
                                        value={empInfo.supervisorName}
                                        placeholder="Supervisor Name"
                                        autocomplete="true"
                                        onChange={(e) =>
                                          this.employementInfoChanger(
                                            e,
                                            "supervisorName",
                                            index
                                          )
                                        }
                                      ></input>
                                    </div>
                                    <div className="personal_Info_Error">
                                      {index == 0 && (
                                        <span className="Error">
                                          {supervisorName}
                                        </span>
                                      )}
                                      {index == 1 && (
                                        <span className="Error">
                                          {exp1_supervisorName}
                                        </span>
                                      )}
                                      {index == 2 && (
                                        <span className="Error">
                                          {exp2_supervisorName}
                                        </span>
                                      )}
                                      {index == 3 && (
                                        <span className="Error">
                                          {exp3_supervisorName}
                                        </span>
                                      )}
                                      {index == 4 && (
                                        <span className="Error">
                                          {exp4_supervisorName}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="personal_Info_Error_Mobile">
                                    {index == 0 && (
                                      <span className="Error">
                                        {supervisorName}
                                      </span>
                                    )}
                                    {index == 1 && (
                                      <span className="Error">
                                        {exp1_supervisorName}
                                      </span>
                                    )}
                                    {index == 2 && (
                                      <span className="Error">
                                        {exp2_supervisorName}
                                      </span>
                                    )}
                                    {index == 3 && (
                                      <span className="Error">
                                        {exp3_supervisorName}
                                      </span>
                                    )}
                                    {index == 4 && (
                                      <span className="Error">
                                        {exp4_supervisorName}
                                      </span>
                                    )}
                                  </div>

                                  <div className="personal_Info_label_input2">
                                    <div className="personal_Info_label">
                                      <span>Supervisor Designation:</span>
                                    </div>
                                    <div className="personal_Info_input">
                                      <input
                                        className={this.inputView()}
                                        disabled={this.props.showView}
                                        name="supervisorDesignation"
                                        value={empInfo.supervisorDesignation}
                                        placeholder="Supervisor Designation"
                                        autocomplete="true"
                                        onChange={(e) =>
                                          this.employementInfoChanger(
                                            e,
                                            "supervisorDesignation",
                                            index
                                          )
                                        }
                                      ></input>
                                    </div>
                                    <div className="personal_Info_Error1">
                                      {index == 0 && (
                                        <span className="Error">
                                          {supervisorDesignation}
                                        </span>
                                      )}
                                      {index == 1 && (
                                        <span className="Error">
                                          {exp1_supervisorDesignation}
                                        </span>
                                      )}
                                      {index == 2 && (
                                        <span className="Error">
                                          {exp2_supervisorDesignation}
                                        </span>
                                      )}
                                      {index == 3 && (
                                        <span className="Error">
                                          {exp3_supervisorDesignation}
                                        </span>
                                      )}
                                      {index == 4 && (
                                        <span className="Error">
                                          {exp4_supervisorDesignation}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="personal_Info_Error_Mobile">
                                    {index == 0 && (
                                      <span className="Error">
                                        {supervisorDesignation}
                                      </span>
                                    )}
                                    {index == 1 && (
                                      <span className="Error">
                                        {exp1_supervisorDesignation}
                                      </span>
                                    )}
                                    {index == 2 && (
                                      <span className="Error">
                                        {exp2_supervisorDesignation}
                                      </span>
                                    )}
                                    {index == 3 && (
                                      <span className="Error">
                                        {exp3_supervisorDesignation}
                                      </span>
                                    )}
                                    {index == 4 && (
                                      <span className="Error">
                                        {exp4_supervisorDesignation}
                                      </span>
                                    )}
                                  </div>

                                  <div className="personal_Info_label_input2">
                                    <div className="personal_Info_label">
                                      <span>Supervisor Email:</span>
                                    </div>
                                    <div className="personal_Info_input">
                                      <input
                                        className={this.inputView()}
                                        disabled={this.props.showView}
                                        name="supervisorEmailId"
                                        value={empInfo.supervisorEmailId}
                                        placeholder="Supervisor Email"
                                        autocomplete="true"
                                        onChange={(e) =>
                                          this.employementInfoChanger(
                                            e,
                                            "supervisorEmailId",
                                            index
                                          )
                                        }
                                      ></input>
                                    </div>
                                    <div className="personal_Info_Error1">
                                      {index == 0 && (
                                        <span className="Error">
                                          {supervisorEmailId}
                                        </span>
                                      )}
                                      {index == 1 && (
                                        <span className="Error">
                                          {exp1_supervisorEmailId}
                                        </span>
                                      )}
                                      {index == 2 && (
                                        <span className="Error">
                                          {exp2_supervisorEmailId}
                                        </span>
                                      )}
                                      {index == 3 && (
                                        <span className="Error">
                                          {exp3_supervisorEmailId}
                                        </span>
                                      )}
                                      {index == 4 && (
                                        <span className="Error">
                                          {exp4_supervisorEmailId}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="personal_Info_Error_Mobile">
                                    {index == 0 && (
                                      <span className="Error">
                                        {supervisorEmailId}
                                      </span>
                                    )}
                                    {index == 1 && (
                                      <span className="Error">
                                        {exp1_supervisorEmailId}
                                      </span>
                                    )}
                                    {index == 2 && (
                                      <span className="Error">
                                        {exp2_supervisorEmailId}
                                      </span>
                                    )}
                                    {index == 3 && (
                                      <span className="Error">
                                        {exp3_supervisorEmailId}
                                      </span>
                                    )}
                                    {index == 4 && (
                                      <span className="Error">
                                        {exp4_supervisorEmailId}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="personal_Info_label_input3">
                                  <div className="personal_Info_label">
                                    <span>Leaving Reason:</span>
                                  </div>
                                  <div className="personal_Info_input">
                                    {/* <input
                              className={this.inputView()}
                              disabled={this.props.showView}
                              name="leavingReason"
                              value={empInfo.leavingReason}
                              placeholder="Leaving Reason"
                              autocomplete="true"
                              onChange={(e) =>
                                this.employementInfoChanger(
                                  e,
                                  "leavingReason",
                                  index
                                )
                              }
                            ></input>{" "} */}
                                    <InputTextarea
                                      rows={4}
                                      cols={20}
                                      autoResize={true}
                                      className={
                                        this.props.showView
                                          ? "Input_fields_textArea_disable"
                                          : "Input_fields_textArea"
                                      }
                                      disabled={this.props.showView}
                                      name="leavingReason"
                                      value={empInfo.leavingReason}
                                      placeholder="Leaving Reason"
                                      autocomplete="true"
                                      onChange={(e) =>
                                        this.employementInfoChanger(
                                          e,
                                          "leavingReason",
                                          index
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="personal_Info_Error">
                                    {index == 0 && (
                                      <span className="Error">
                                        {leavingReason}
                                      </span>
                                    )}
                                    {index == 1 && (
                                      <span className="Error">
                                        {exp1_leavingReason}
                                      </span>
                                    )}
                                    {index == 2 && (
                                      <span className="Error">
                                        {exp2_leavingReason}
                                      </span>
                                    )}
                                    {index == 3 && (
                                      <span className="Error">
                                        {exp3_leavingReason}
                                      </span>
                                    )}
                                    {index == 4 && (
                                      <span className="Error">
                                        {exp4_leavingReason}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="personal_Info_Error_Mobile">
                                  {index == 0 && (
                                    <span className="Error">
                                      {leavingReason}
                                    </span>
                                  )}
                                  {index == 1 && (
                                    <span className="Error">
                                      {exp1_leavingReason}
                                    </span>
                                  )}
                                  {index == 2 && (
                                    <span className="Error">
                                      {exp2_leavingReason}
                                    </span>
                                  )}
                                  {index == 3 && (
                                    <span className="Error">
                                      {exp3_leavingReason}
                                    </span>
                                  )}
                                  {index == 4 && (
                                    <span className="Error">
                                      {exp4_leavingReason}
                                    </span>
                                  )}
                                </div>

                                {this.props.showEdit && (
                                  <div className="addExpireienceBtns">
                                    {this.state.profile1.employmentInfo.length <
                                      5 && (
                                      <button
                                        type="button"
                                        className="addExpireienceBtn"
                                        onClick={this.AddExp.bind(this)}
                                      >
                                        Add More Experience
                                      </button>
                                    )}
                                    {this.state.profile1.employmentInfo.length >
                                      1 && (
                                      <button
                                        type="button"
                                        className="addExpireienceBtn"
                                        onClick={this.RemoveExp.bind(
                                          this,
                                          index
                                        )}
                                      >
                                        Remove Experience
                                      </button>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        );
                      }
                    )}
                  </div>
                </div>

                {this.props.showEdit && (
                  <div
                    className="personal_Info"
                    style={{ padding: "15px 74px" }}
                  >
                    <h1 className="Form55">
                      Authorization/ Declaration and Undertaking
                    </h1>
                    <div className="personal_Info_fields">
                      <h6 className="fonter">
                        If Employed with{" "}
                        <strong style={{ fontWeight: "600" }}>
                          CAPRUS IT PRIVATE LIMITED
                        </strong>
                        , I agree to provide copies of all relevant
                        certificates. I understand that employment with{" "}
                        <strong style={{ fontWeight: "600" }}>
                          CAPRUS IT PRIVATE LIMITED
                        </strong>
                        is governed by their employment policies as applicable,
                        including satisfactory information from background
                        checks. I hereby certify that all information provided
                        herein, is true and complete to the best of my knowledge
                        and belief. I authorize{" "}
                        <strong style={{ fontWeight: "800" }}>
                          CAPRUS IT PRIVATE LIMITED
                        </strong>{" "}
                        and its representative to authenticate information I
                        have provided in my resume and this Candidate
                        Declaration Form (CDF). To conduct enquiries as may be
                        necessary at the company’s discretion, I authorize all
                        who may have information relevant to this enquiry to
                        disclose it to{" "}
                        <strong style={{ fontWeight: "600" }}>
                          CAPRUS IT PRIVATE LIMITED
                        </strong>{" "}
                        and/or its representative. I release all concerned from
                        any liability on account of such disclosures. I also
                        declare that the information provided by me in my resume
                        and application for employment to{" "}
                        <strong style={{ fontWeight: "800" }}>
                          CAPRUS IT PRIVATE LIMITED
                        </strong>{" "}
                        and its representative is authentic and I am liable for
                        all inaccuracies and omissions. I promise to extend
                        total co-operation and provide relevant documents
                        required.
                      </h6>

                      <div style={{ width: "100%" }}>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "flex-end",
                            padding: "0px 10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              marginTop: 10,
                            }}
                          >
                            <div className="personal_Info_label">
                              <span>Place:</span>
                            </div>

                            <div className="personal_Info_input">
                              <input
                                className="Input_fields_Disable"
                                disabled
                                name="place"
                                value={this.state.profile1.place}
                              ></input>
                            </div>
                            <div className="declaration_input"></div>
                          </div>
                        </div>
                        <div className="personal_Info_Error_Mobile"></div>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "flex-end",
                            padding: "0px 10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              marginTop: 10,
                            }}
                          >
                            <div className="personal_Info_label">
                              <span>Date:</span>
                            </div>
                            <div className="personal_Info_input">
                              <input
                                className="Input_fields_Disable"
                                style={{ marginTop: 0 }}
                                disabled
                                value={this.state.profile1.date?.split("T")[0]}
                                name="date"
                              ></input>
                            </div>
                            <div className="declaration_input"></div>
                          </div>
                        </div>
                        <div className="personal_Info_Error_Mobile"></div>
                      </div>

                      <Toast
                        ref={(re) => (this.toastBC = re)}
                        position="bottom-center"
                      />
                    </div>
                    <div
                      style={{
                        padding: "0px 10px",
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-end",
                      }}
                    >
                      <div className="Submit_btn_userpage_admin_otp">
                        <button
                          className="save-btn"
                          onClick={() =>
                            this.props.showEdit
                              ? this.props.dispatch(saved(true))
                              : "authentivajj"
                          }
                        >
                          Save<i className="pi pi-send"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    save: state.save,
    empId: state.empId,
    email: state.email,
    profile: state.profile,
    pfdetails: state.pfdetails,
    insurance: state.insurance,
    empClearance: state.empClearance,
    exitinterview: state.exitinterview,
    empDocuments: state.empDocuments,
    isSpinner: state.isSpinner,
    empIdNotAssigned: state.empIdNotAssigned,
    showEdit: state.showEdit,
    showView: state.showView,
    enableFormForPfIns: state.enableFormForPfIns,
    btnStatus: state.btnStatus,

    //code by Janya
    //emailprovided: state.emailprovided,
    EmpData: state.EmpData,
  };
};

// const mapDispatchToProps = (dispatch) => {
//   return {
//     searchQuery: (val) => dispatch(searchQuery(val))
//   }
// }

export default connect(mapStateToProps)(Profile);
